import React from 'react';
import Styled from 'styled';

import down from 'four-nets/icons/svg/icon_arrow-down.svg';

import SVGIcon from 'lib/images/svg-icon/svg-icon';
import { Props as IconProps } from 'lib/images/svg-image/svg-image';

import defaultStyles from './select.scss';

export const Icon = (props: Omit<IconProps, 'styles'>) => <SVGIcon style={{ image: defaultStyles.image }} {...props} />;

export interface Props extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'style'> {
    styles: typeof defaultStyles;
    icon?: React.ReactNode;
    errors?: React.ReactNode[];
    touched?: boolean;
}

class Select extends React.PureComponent<Props> {
    static defaultProps = {
        icon: <Icon src={down} />,
    };

    render() {
        const { styles, icon, errors, touched, ...rest } = this.props;

        const showValidationErrors = errors && touched;

        return (
            <>
                <div className={styles!.wrapper}>
                    <select {...rest} className={styles!.select} />
                    <div className={styles!.icon}>{icon}</div>
                </div>
                {showValidationErrors &&
                    errors!.map((error, index) => {
                        return (
                            <p key={index.toString()} className={styles.error}>
                                {error}
                            </p>
                        );
                    })}
            </>
        );
    }
}

export default Styled(defaultStyles)(Select);
