import React from 'react';
import Route from 'react-router/route';

export interface Props {
    code: number;
}

export default class Status extends React.PureComponent<Props> {
    renderRoute = ({ staticContext }: { staticContext?: { statusCode?: number } }) => {
        if (staticContext) {
            staticContext.statusCode = this.props.code;
        }

        return null;
    };

    render() {
        return <Route render={this.renderRoute} />;
    }
}
