import React from 'react';
import Styled from 'styled';
import TitleView from 'four-nets/ui/title-view';
import Status from 'lib/react-router/status';
import ScrollToTop from 'lib/scroll/scroll-to-top';

import defaultStyles from './not-found.scss';
import { FormattedMessage } from 'react-intl';

export interface Props {
    styles: typeof defaultStyles;
}

class NotFound extends React.PureComponent<Props> {
    render() {
        const { styles } = this.props;

        return (
            <React.Fragment>
                <Status code={404} />
                <ScrollToTop />
                <React.Fragment>
                    <TitleView title='404' />
                    <div>
                        <FormattedMessage id='404_TITLE' />
                        <br />
                        <FormattedMessage id='TRY_SOME_OTHER_PAGE_THERE_ARE_MANY' values={{ gender: true }} />
                        &nbsp;
                        <a href='/' className={styles.link}>
                            <FormattedMessage id='GO_TO_HOMEPAGE' />
                        </a>
                    </div>
                </React.Fragment>
            </React.Fragment>
        );
    }
}

export default Styled(defaultStyles)(NotFound);
