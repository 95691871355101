import decorator from 'lib/lang/decorator';

interface Decorated {
    onRefresh: () => ?Promise<any>;
}

export default decorator((interval = 30000) => (Class: typeof Decorated) => {
    class Refreshable extends Class {
        refreshRequestsCount = 0;
        refreshInterval: ?number;
        promise: ?Promise<any>;

        refresh = () => {
            if (!this.refreshRequestsCount) {
                this.refreshInterval = setInterval(() => {
                    this.promise = this.onRefresh();
                }, interval);
            }
            this.refreshRequestsCount++;
            return this.stopRefreshing;
        };

        stopRefreshing = () => {
            if (this.refreshRequestsCount === 1) {
                clearInterval(this.refreshInterval);
                this.refreshInterval = null;
                if (this.promise && this.promise.isPending && this.promise.isPending()) {
                    this.promise.cancel();
                    this.promise = null;
                }
            }
            if (this.refreshRequestsCount) {
                this.refreshRequestsCount--;
            }
        };
    }

    return Refreshable;
});
