
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Subscription"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Subscription"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"period"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"disabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"promo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"proformaInvoiceUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paymentVs"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":155}};
    doc.loc.source = {"body":"fragment Subscription on Subscription {\n    name \n    note\n    value\n    currency\n    period\n    disabled\n    promo\n    proformaInvoiceUrl\n    paymentVs\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
