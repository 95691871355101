import React, { useRef, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Styled from 'styled';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import SVGIcon from 'lib/images/svg-icon/svg-icon';
import { AlertContext } from 'lib/alert-context/alert-context';
import { usePrevious } from 'lib/react/use-previous';

import closeIcon from 'four-nets/icons/svg/icon_close.svg';

import defaultStyles from './modal.scss';

export const ModalRefContext = React.createContext<HTMLDivElement | null>(null);

export const ModalRefProvider: React.FC = ({ children }) => {
    const [context, setContext] = useState();

    useEffect(() => {
        setContext(createNode());
    }, []);

    const createNode = () => {
        const node = document.createElement('div');
        node.setAttribute('role', 'dialog');
        node.classList.add(defaultStyles.container);
        document.body.appendChild(node);
        return node;
    };

    return <ModalRefContext.Provider value={context}>{children}</ModalRefContext.Provider>;
};

export type FooterButtonType = 'action' | 'warn' | 'cancel';

export interface ModalProps {
    styles: typeof defaultStyles;

    open?: boolean;
    title?: React.ReactNode;
    dirty?: boolean;

    onClose: (event: React.MouseEvent | React.KeyboardEvent, from?: string) => any;

    onCancel?: (event: React.MouseEvent | React.KeyboardEvent, from?: string) => any;
    cancelButtonLabel?: React.ReactNode;

    onAccept?: (event: React.MouseEvent) => any;
    acceptButtonType?: FooterButtonType;
    acceptButtonLabel?: React.ReactNode;
    acceptButtonDisabled?: boolean;

    children: React.ReactChildren | React.ReactChild;

    includeHeader?: boolean;
    includeFooter?: boolean;
}

const Modal: React.FC<ModalProps> = props => {
    const {
        styles = {} as typeof defaultStyles,
        title,
        open,
        dirty,
        children,
        onClose,
        onCancel,
        cancelButtonLabel = <FormattedMessage id='CANCEL' values={{ gender: 'v' }} />,
        onAccept,
        acceptButtonType,
        acceptButtonLabel = <FormattedMessage id='SAVE' values={{ gender: 'v' }} />,
        acceptButtonDisabled,
        includeHeader = Boolean(title),
        includeFooter = Boolean(onAccept),
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const prevOpen = usePrevious(open);

    const [alertOpen, setAlertOpen] = useState(false);

    const modalNode = useContext(ModalRefContext);
    const { confirm } = useContext(AlertContext);

    const closeWithAlert = (event: React.MouseEvent | React.KeyboardEvent) => {
        const close = () => {
            onClose(event, 'overlayClick');
            setAlertOpen(false);
        };

        if (dirty) {
            setAlertOpen(true);
            confirm({
                body: (
                    <>
                        <FormattedMessage id='LEAVE_WITHOUT_SAVING' />?
                    </>
                ),
                onAccept: close,
                onCancel: () => {
                    setAlertOpen(false);
                },
                onClose: () => {
                    setAlertOpen(false);
                },
                acceptButtonLabel: <FormattedMessage id='LEAVE' />,
            });
        } else {
            close();
        }
    };

    const overlayClick = (event: React.MouseEvent) => {
        if (event.target !== event.currentTarget) {
            return;
        }

        event.persist();
        closeWithAlert(event);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Escape' || alertOpen) {
            return;
        }

        event.persist();
        event.stopPropagation();
        closeWithAlert(event);
    };

    useEffect(() => {
        if (open) {
            window.document.body.classList.add(styles.bodyLockScroll);
        } else if (prevOpen) {
            window.document.body.classList.remove(styles.bodyLockScroll);
        }

        return () => {
            window.document.body.classList.remove(styles.bodyLockScroll);
        };
    }, [prevOpen, open, styles]);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.focus();
        }
    }, [open]);

    const header = includeHeader ? (
        <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <button onClick={closeWithAlert} className={styles.closeButton}>
                <SVGIcon src={closeIcon} className={styles.closeIcon} />
            </button>
        </div>
    ) : null;

    const footer = includeFooter ? (
        <div className={styles.footer}>
            {onCancel ? (
                <button onClick={onClose} className={classnames(styles.footerButton, styles.cancel)}>
                    {cancelButtonLabel}
                </button>
            ) : null}
            <button
                onClick={onAccept}
                disabled={acceptButtonDisabled}
                className={classnames(styles.footerButton, {
                    [styles.warn]: acceptButtonType === 'warn',
                    [styles.cancel]: acceptButtonType === 'cancel',
                    [styles.action]: acceptButtonType === 'action',
                })}
            >
                {acceptButtonLabel}
            </button>
        </div>
    ) : null;

    return modalNode && open
        ? ReactDOM.createPortal(
              <div
                  ref={ref}
                  className={classnames(styles.overlay, { [styles.open]: open })}
                  onKeyDown={handleKeyDown}
                  onClick={overlayClick}
                  tabIndex={0}
              >
                  <div ref={bodyRef} className={styles.modal}>
                      {header}
                      <div className={styles.body}>{children}</div>
                      {footer}
                  </div>
              </div>,
              modalNode
          )
        : null;
};

export default Styled(defaultStyles)(Modal);
