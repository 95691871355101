import React, { useContext } from 'react';
import Styled from 'styled';
import { FormattedMessage } from 'react-intl';

import { AlertContext } from 'lib/alert-context/alert-context';
import ButtonView from 'four-nets/ui/button-view';

import defaultStyles from './file-input.scss';

interface Props {
    styles: typeof defaultStyles;
    accept?: string;
    limit?: number;
    multiple?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    inputKey?: number;
}

const FileInput: React.FC<Props> = props => {
    const { fail } = useContext(AlertContext);
    const ref = React.createRef<HTMLInputElement>();

    const { styles, accept, limit, multiple, children, onChange, onClick, inputKey, ...rest } = props;

    const onChangeFn = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        const files = [];
        if (event.target.files) {
            if (limit || accept) {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];
                    files.push(file);

                    if (limit) {
                        if (file.size / 1024 / 1024 >= limit) {
                            fail!({
                                body: (
                                    <FormattedMessage
                                        id='FORM_ERROR_TEXT_FILE_TO_BIG'
                                        values={{ name: file.name, limit }}
                                    />
                                ),
                                onAccept: () => null,
                            });
                            return;
                        }
                    }

                    if (accept) {
                        const items = accept.split(',');

                        // tslint:disable-next-line: prefer-for-of
                        for (let j = 0; j < items.length; j++) {
                            const item = items[j];
                            if (
                                (item.startsWith('.') && !file.name.endsWith(item)) ||
                                !file.type.startsWith(item.replace('/*', ''))
                            ) {
                                fail!({
                                    body: (
                                        <FormattedMessage
                                            id='FORM_ERROR_TEXT_FILE_NOT_ACCEPTED'
                                            values={{ name: file.name }}
                                        />
                                    ),
                                    onAccept: () => null,
                                });
                                return;
                            }
                        }
                    }
                }
            }

            if (onChange) {
                onChange(event);
            }

            return null;
        }
    };

    const onClickFn = (event: React.MouseEvent<HTMLInputElement>) => {
        if (ref && ref.current) {
            ref.current.value = '';
        }

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <span className={styles.fileInput}>
            <ButtonView element={'label'} type={null} {...rest} onClick={onClickFn}>
                {children || <FormattedMessage id='BUTTON_LABEL_ADD_FILE' values={{ multiple }} />}
                <input
                    key={inputKey}
                    ref={ref}
                    className={styles.input}
                    type='file'
                    accept={accept}
                    multiple={multiple}
                    onChange={onChangeFn}
                />
            </ButtonView>
        </span>
    );
};

FileInput.defaultProps = {
    multiple: true,
};

export default Styled(defaultStyles)(FileInput);
