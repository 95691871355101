import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

export interface Props {
    render: (Helmet: React.ComponentClass) => React.ReactNode;
}

function Helmet(props: Props) {
    return props.render(ReactHelmet);
}

(Helmet as any).displayName = 'Helmet';

export default Helmet as React.FC<Props>;
