import React from 'react';
import Styled from 'styled';
import ButtonView from '../button-view';

import defaultStyles from './submit-button-view.scss';

@Styled(defaultStyles)
class SubmitButtonView extends React.PureComponent {
    render() {
        return (
            <ButtonView
                {...this.props}
            />
        );
    }
}

export default SubmitButtonView;


