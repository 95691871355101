export const insertEmojiToText = (text: string = '', emoji: string, position: number) => {
    const textBeforeEmoji = text.substr(0, position);

    let emojiWithSpaces;
    if (textBeforeEmoji === '' || textBeforeEmoji.endsWith(' ')) {
        // only append space
        emojiWithSpaces = `${emoji} `;
    } else {
        // prepend and append space
        emojiWithSpaces = ` ${emoji} `;
    }

    return [textBeforeEmoji + emojiWithSpaces + text.substr(position), emojiWithSpaces.length];
};
