import React from 'react';
import Styled from 'styled';
import classnames from 'classnames';

import defaultStyles from './tabs.scss';


export interface Tab {
    child: JSX.Element;
    onClick: () => void;
    active: boolean;
}

export interface Props {
    styles: typeof defaultStyles;
    tabs: Tab[];
}

const Tabs: React.FC<Props> = props => {
    const { styles, tabs } = props;
    return (
        <div className={styles.tabs}>
            {tabs.map((tab, index) =>
                <button
                    key={index}
                    onClick={tab.onClick}
                    className={classnames([styles.button].concat(tab.active ? [styles.active] : []))}
                >
                    {tab.child}
                </button>
            )}
        </div>
    );
}

export default Styled(defaultStyles)(Tabs);
