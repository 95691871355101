import React from 'react';
import Styled from 'styled';
import Localizable from 'lib/intl/localizable';
import { observer } from 'mobx-react';
import defaultStyles from './field-with-label-view.scss';

@Localizable()
@observer
class FieldWithLabelView extends React.Component {
    static defaultProps = { mini: false };
    state = { id: undefined };
    id = `label-${Math.random()}`;

    render() {
        const { field, label, mini, vertical, styles } = this.props;

        const id = this.props.id || this.id;

        const classNames = [styles.fieldWithLabel];

        if (mini) {
            classNames.push(styles.mini);
        }

        if (vertical) {
            classNames.push(styles.vertical);
        }

        return (
            <div className={classNames.join(' ')}>
                <div className={styles.label}>
                    <label htmlFor={id}>{label}</label>
                </div>
                <div className={styles.field}>{React.cloneElement(field, { id })}</div>
            </div>
        );
    }
}

export default Styled(defaultStyles)(FieldWithLabelView);
