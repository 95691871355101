/* @flow */

import { AppError } from 'lib/errors/errors';
import cookies from 'js-cookie';
import pickBy from 'lodash/pickBy';
import superagent from 'superagent';
import { decamelizeKeys, camelizeKeys } from "humps";
import httpMethods from 'methods';
import URLComponents from 'lib/url/url-components';
import URL from 'url-parse';
import * as Promise from 'bluebird';

export const methods: string[] = httpMethods.map(method => {
    if (method === 'delete') {
        return 'del';
    }

    return method;
});

// TODO: retype to superagent
export type Request = any;
export type Response = {
    body: ?any,
    type: string,
};

export class ApiError extends AppError { }
export class NotFoundError extends ApiError { }

export type RequestDelegate = {
    requestWillStart: (options?: { request: Request }) => void;
    requestDidEnd: (options?: { request: Request, response: ?Response, error: ?ApiError }) => void;
    requestDidCancel: (options?: { request: Request }) => void;
};


type ApiMethod = (options: { path?: string, url?: string }) => Request;
type Callback = (err: ?Error, res: ?Response) => void;

export class Api {
    urlComponents: URLComponents;
    headers: ?(() => Object);

    del: ApiMethod;
    get: ApiMethod;
    patch: ApiMethod;
    post: ApiMethod;
    put: ApiMethod;

    constructor(options: {
        urlComponents: URLComponents,
        headers?: () => Object,
    }) {

        const {
            headers,
            urlComponents,
        } = options;

        this.urlComponents = urlComponents;
        this.headers = headers;

        this.setupMethods();
    }

    setupMethods() {
        methods.forEach(method => {
            if (superagent[method]) {
                this[method] = (options: ApiMethod) => {
                    const { url, path = '' } = options;

                    let requestUrl;

                    if (url) {
                        requestUrl = url;
                    } else {
                        const urlComponents = new URLComponents({
                            ...this.urlComponents,
                            path: path.startsWith('/')
                                ? path
                                : `${this.urlComponents.path}/${path}`,
                        });

                        requestUrl = urlComponents.url();
                    }

                    const request = superagent[method](requestUrl);

                    if (process.env.ENV_NODE && process.env.DEBUG_NETWORK) {
                        const superdebug = require('superagent-debugger').default;
                        request.use(superdebug(console.info));
                    }

                    let didCallEnd = false;

                    if (this.headers) {
                        request.set(this.headers());
                    }

                    const send = request.send.bind(request);
                    request.send = (data: any) => {
                        return send((data instanceof Object || data instanceof Array)
                            ? decamelizeKeys(JSON.parse(JSON.stringify(data)))
                            : data
                        );
                    };

                    const end = request.end.bind(request);
                    request.end = (callback: Callback, options: { // eslint-disable-line no-shadow
                        delegate?: RequestDelegate,
                    }) => {

                        const { delegate } = options || {};

                        if (delegate) {
                            delegate.requestWillStart({
                                request,
                            });
                        }

                        const abort = request.abort.bind(request);

                        request.abort = (...args) => {
                            abort(...args);

                            if (!didCallEnd) {
                                didCallEnd = true;

                                if (delegate) {
                                    delegate.requestDidCancel({
                                        request,
                                    });
                                }
                            }
                        };

                        end((err: ?Error, res: ?Response) => {

                            let error;
                            let response;

                            if (res) {
                                response = { ...res };

                                if (!response.body && response.type === "text/html") {
                                    try {
                                        response.body = JSON.parse(response.text);
                                    } catch (e) {
                                        // pass
                                    }
                                }

                                if (response.body) {
                                    response.body = camelizeKeys(response.body);
                                }

                                let href;

                                if (response.request) {
                                    const query = new URL(response.request.req.path, ' ', true).query;
                                    const responseURL = new URL(response.request.url, true);
                                    responseURL.set('query', {
                                        ...responseURL.query,
                                        ...query,
                                    });

                                    href = responseURL.href;
                                } else {
                                    href = response.req.url;
                                }

                                response.href = href;
                            }

                            if (err) {
                                error = this.apiError({
                                    error: err,
                                    response,
                                });
                                if (error.code !== 401) {
                                    if (error.code === 403 && typeof document !== 'undefined') {

                                        // Try to remove non www csrftoken cookie
                                        cookies.remove('csrftoken-new', { path: '/', domain: window.location.hostname.replace('www', '') });
                                    }
                                }
                            }

                            if (delegate) {
                                didCallEnd = true;

                                delegate.requestDidEnd({
                                    request,
                                    response,
                                    error,
                                });
                            }

                            callback(error, response);
                        });
                    };

                    request.endPromise = (options: { // eslint-disable-line no-shadow
                        delegate?: RequestDelegate,
                    }): Promise<Response> => new Promise((resolve, reject, onCancel) => {

                        try {
                            request.end(
                                (error, response) => {
                                    if (error) {
                                        reject(error);

                                    } else {
                                        resolve(response);
                                    }
                                },
                                options,
                            );

                        } catch (e) {
                            reject(e);
                        }

                        if (onCancel) {
                            onCancel(() => {
                                request.abort();
                            });
                        }
                    });


                    request.json = (options: {  // eslint-disable-line no-shadow
                        delegate: RequestDelegate,
                    }) =>
                        request.endPromise(options).then(response => {
                            const { body } = response || {};
                            return { json: body, response };
                        });

                    if (method !== 'post') {
                        request.timeout(60000);
                    }

                    // remove essoteric .then() function from superagent
                    // to force usage of endPromise

                    try {
                        Object.defineProperty(request, 'then', {
                            value: null,
                        });
                    } catch (e) {
                        // pass
                    }

                    return request;
                };
            }
        });
    }

    apiError(options: { error?: Error, response?: ?Response }): ApiError {
        const { error: underlayingError } = options;

        return new ApiError({
            underlayingError,
        });
    }
}


export class DelegatedApi {
    api: Api;

    constructor(options: {
        api: Api,
        delegate?: RequestDelegate,
    }) {
        const { api, delegate } = options;

        this.api = api;
        this.setDelegate({ delegate });
    }

    setDelegate = (options: { delegate: ?RequestDelegate }) => {

        const { delegate } = options;

        methods.forEach(method => {

            this[method] = (...args) => {
                const request = this.api[method](...args);
                const end = request.end.bind(request);

                request.end = (
                    callback: { callback: Callback },
                    options: { delegate?: RequestDelegate } = {},  // eslint-disable-line no-shadow
                ) =>
                    end(callback, {
                        delegate: options.delegate || delegate,
                    });

                return request;
            };
        });
    }
}


export default Api;
