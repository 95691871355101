import Refreshable from 'lib/utils/refreshable';
import { observable, autorun } from 'mobx';
import superagent from 'superagent';
import { ApiStore } from 'lib/api/django-restframework/api-store';
import { AppError } from 'lib/errors/errors';
import { ApiItem } from 'lib/api/api-item';
import { NotFoundError } from 'lib/api/api';
import JSONSerializable from 'lib/serialization/json-serializable';

import { NotificationStore, NotificationsManager } from 'four-nets/notifications/notifications';
import { ProductStore } from 'four-nets/bazaar/bazaar';
import { FourNetsApiError } from 'four-nets/api/api';

export class LoginFailureError extends AppError {
    constructor(site) {
        let message = 'Login alebo heslo je nesprávne';
        if (site.isCzech) {
            message = 'Login nebo heslo je nesprávné';
        }

        super({ message });
    }
}

export class CrossLoginFailureError extends AppError {
    constructor(site) {
        let message = 'Login alebo heslo je nesprávne';
        if (site.isCzech) {
            message = 'Login nebo heslo je nesprávné';
        }

        super({ message });
    }
}

export class UserNotFoundError extends AppError {
    constructor(site) {
        let message = 'Používateľ neexistuje';
        if (site.isCzech) {
            message = 'Uživatel neexistuje';
        }

        super({ message });
    }
}

export { Vendor };

@JSONSerializable()
class Vendor extends ApiItem {
    fromJSON({ json }) {
        const { paidTillDate, ...rest } = json;

        this.paidTillDate = new Date(paidTillDate);
        super.fromJSON({ json: rest });
    }
}

export { VendorStore };

@JSONSerializable()
class VendorStore extends ApiStore {
    constructor(options) {
        super(options);
        this.delegate = this;
    }
    item() {
        return new Vendor({
            delegate: this,
        });
    }

    load({ id }: { id: number }) {
        if (this.data.has(id)) {
            return Promise.resolve(this.data.get(id));
        }

        return Promise.reject(new NotFoundError({}));
    }
}

export { User };

@JSONSerializable()
class User extends ApiItem {
    vendorStore: VendorStore;
    dateJoined: Date;

    @observable vendor: Vendor;
    @observable heartsCount: number;

    constructor(options) {
        super(options);

        const { vendorStore, productStore } = options;
        this.vendorStore = vendorStore;
        this.productStore = productStore;
    }

    fromJSON({ json }) {
        const { vendor, dateJoined, ...rest } = json;

        this.dateJoined = new Date(dateJoined);
        super.fromJSON({
            json: rest,
        });

        if (vendor) {
            this.vendor = this.vendorStore.processJSON({
                json: vendor,
            });
        }
    }

    loadProducts(orderBy) {
        return this.productStore.userProducts(this, orderBy);
    }
}

export { UserStore };

@Refreshable()
@JSONSerializable()
class UserStore extends ApiStore {
    vendorStore: VendorStore;

    @observable me: ?User = null;
    @observable inbox: ?Inbox;
    @observable newNotificationsCount: ?number;
    @observable newMailsCount: ?number;
    @observable newBazaarMessagesCount: ?number;
    @observable bazaarMessagesShoppingCount: ?number;
    @observable bazaarMessagesSellingCount: ?number;
    @observable notificationsManager: ?NotificationsManager;

    disposer;

    constructor(options) {
        super(options);

        const { api, site } = options;

        this.site = site;

        this.delegate = this;
        this.vendorStore = new VendorStore({
            api,
            userStore: this,
        });

        this.notificationStore = new NotificationStore({
            api,
            userStore: this,
        });

        this.productStore = new ProductStore({
            api,
            userStore: this,
        });

        const disposers = [
            autorun(() => {
                const { me } = this;

                if (!me) {
                    this.inbox = null;
                    this.notificationsManager = new NotificationsManager(this.notificationStore);
                    this.newNotificationsCount = null;
                    this.newMailsCount = null;
                    this.newBazaarMessagesCount = null;

                    this.notificationStore.data.clear();
                } else {
                    this.notificationsManager = new NotificationsManager(this.notificationStore);
                }
            }),
            autorun(() => {
                if (api.lastError) {
                    const { response, error } = api.lastError;

                    if (response && response.href.endsWith('/me')) {
                        return;
                    }

                    if (response && response.href.endsWith('/password-change')) {
                        return;
                    }

                    if (error && error.code === 401) {
                        this.me = null;
                    }
                }
            }),
        ];

        this.disposer = () => disposers.forEach(disposer => disposer());
        this.loadMe = this.loadMe.bind(this);
    }

    fromJSON({ json }: { json: Object }) {
        const {
            me: meJSON,
            newNotificationsCount,
            newMailsCount,
            newBazaarMessagesCount,
            bazaarMessagesSellingCount,
            bazaarMessagesShoppingCount,
        } = json;

        this.newNotificationsCount = newNotificationsCount;
        this.newMailsCount = newMailsCount;
        this.newBazaarMessagesCount = newBazaarMessagesCount;
        this.bazaarMessagesSellingCount = bazaarMessagesSellingCount;
        this.bazaarMessagesShoppingCount = bazaarMessagesShoppingCount;

        if (meJSON) {
            this.me = this.processJSON({ json: meJSON });
        }
    }

    toJSON() {
        return {
            me: this.me,
            newNotificationsCount: this.newNotificationsCount,
            newMailsCount: this.newMailsCount,
            newBazaarMessagesCount: this.newBazaarMessagesCount,
            bazaarMessagesSellingCount: this.bazaarMessagesSellingCount,
            bazaarMessagesShoppingCount: this.bazaarMessagesShoppingCount,
        };
    }

    item() {
        return new User({
            vendorStore: this.vendorStore,
            productStore: this.productStore,
            delegate: this,
        });
    }

    clearNewNotificationsCount() {
        this.newNotificationsCount = null;
    }

    load(options: { id: number }) {
        const { id } = options;

        const request = this.api.get({
            path: `users/${id}`,
        });

        return super.load({
            id,
            request,
        });
    }

    crossCompanyLogin = data => {
        return this.login({ data });
    };

    login = (options: {
        data: {
            username: string,
            password: string,
            js: string,
            remember?: string,
            csrfmiddlewaretoken: string,
            token: string,
            submit: string,
        },
    }) =>
        new Promise((resolve, reject) => {
            const { data } = options;

            superagent
                .post('/login-api/')
                .query({
                    from: 'header-menu',
                })
                .send(data)
                .set({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                })
                .end((err, res) => {
                    if (err) {
                        reject(err);
                        return;
                    }

                    const { body } = res || {};

                    if (body.success) {
                        resolve();
                        return;
                    }

                    if (body.reason == 2) {
                        reject(new CrossLoginFailureError(this.site));
                    }

                    reject(new LoginFailureError(this.site));
                });
        });

    logout = () => {
        return this.api
            .get({
                url: '/logout/',
            })
            .json()
            .then(() => {
                this.me = null;
            });
    };

    requestResetPassword(email) {
        return this.api
            .post({
                path: 'auth/password-reset',
            })
            .send({
                email,
            })
            .json();
    }

    checkPasswordResetToken(userId, token) {
        return this.api
            .post({
                path: 'auth/password-reset-check-token',
            })
            .send({
                userId,
                token,
            })
            .json();
    }

    resetPassowrd(userId, token, password, passwordAgain) {
        return this.api
            .post({
                path: 'auth/password-reset-form',
            })
            .send({
                userId,
                token,
                password,
                passwordAgain,
            })
            .json();
    }

    search(options: { username: string }) {
        const { username } = options;

        return this.api
            .post({
                path: 'users-search',
            })
            .send({
                username,
            })
            .json()
            .then(data => this.processPagedJSON(data));
    }

    searchExact(options: { username: string }) {
        const { username } = options;

        return this.search({ username }).then(result => {
            for (let i = 0; i < result.results.length; i++) {
                const resultUser = result.results[i];

                if (resultUser.username === username) {
                    return resultUser;
                }
            }

            return Promise.reject(new UserNotFoundError(this.site));
        });
    }

    sendHeart(options: { user: User }) {
        const { user } = options;

        return this.api
            .post({
                path: `users/${user.id}/hearts`,
            })
            .json()
            .then(result => {
                if (this.me) {
                    this.me.heartsCount = this.me.heartsCount - 1;
                }
                return result;
            });
    }

    myEmail() {
        return this.api
            .get({
                path: 'email',
            })
            .json();
    }

    setEmail({ email }) {
        return this.api
            .post({
                path: 'email',
            })
            .send({
                email,
            })
            .json();
    }

    verifyAccount(activationCode) {
        return this.api
            .post({
                path: 'auth/activation',
            })
            .send({
                verificationCode: activationCode,
            })
            .json();
    }

    changePassword(data) {
        const { oldPassword, newPassword } = data;

        return this.api
            .post({
                path: 'auth/password-change',
            })
            .send({
                oldPassword,
                newPassword,
            })
            .json();
    }

    loadMe() {
        const request = this.api.get({
            path: 'me',
        });

        return super
            .load({
                request,
            })
            .then(user => {
                this.me = user;

                if (user) {
                    return this.loadNewNotificationsCount().then(() => {
                        return user;
                    });
                }
                return user;
            });
    }

    onRefresh = () => {
        return this.loadNewNotificationsCount().catch(() => null);
    };

    loadNewNotificationsCount() {
        if (!this.me) {
            return Promise.resolve();
        }

        const { me } = this;

        return this.notificationStore
            .newCount()
            .then(({ newMailsCount, newNotificationsCount, newBazaarMessagesCount }) => {
                if (this.me === me) {
                    this.newMailsCount = newMailsCount;
                    this.newNotificationsCount = newNotificationsCount;
                    this.newBazaarMessagesCount = newBazaarMessagesCount;
                }
            });
    }

    register(options) {
        const { username, password, sex, email, county, newsletter } = options;

        return this.api
            .post({
                path: 'auth/registration',
            })
            .send({
                username,
                password,
                gender: sex,
                sex,
                email,
                newsletter,
                countyId: county || undefined,
            })
            .json();
    }

    getNotificationMailingLevel() {
        return this.api
            .get({
                path: 'notification-mailing-level',
            })
            .json();
    }

    setNotificationMailingLevel(options) {
        const { level } = options;

        return this.api
            .post({
                path: 'notification-mailing-level',
            })
            .send({
                level,
            })
            .json();
    }
}
