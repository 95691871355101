import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import Helmet from 'helmet/helmet';

import { loadScriptWithBackoff } from 'lib/utils/dom';

import { SiteContext } from 'four-nets';

/*
 * Gemius debugger browser plugin:
 * https://chrome.google.com/webstore/detail/gemius-debugger/bepedcaklkafciccnmeilifclkcgfcnl
 */

export function gemiusHit(code: string) {
    if (window.pp_gemius_hit) {
        window.pp_gemius_hit(code);
    }
}

const Gemius: React.FC = (_, context) => {
    const [gemLoaded, setGemLoaded] = useState<boolean>(false);
    const site = useContext(SiteContext);
    const location = useLocation();

    useEffect(() => {
        if (!gemLoaded) {
            loadScriptWithBackoff({ src: site.gemiusScriptSrc }).then(() => setGemLoaded(true));
        } else {
            gemiusHit(site.getGemiusCode(location.pathname));
        }
    }, [location]);

    const renderHelmet = (H: React.ComponentClass) => {
        if (!site || !location) {
            return null;
        }

        return (
            <H>
                <script type='text/javascript'>
                    {`
                        var pp_gemius_identifier = '${site.getGemiusCode(location.pathname)}';
                        var pp_gemius_use_cmp = true;
                        var pp_gemius_cmp_timeout = Infinity;
                    `}
                </script>
            </H>
        );
    };

    return (
        <>
            <Helmet render={renderHelmet} />
        </>
    );
};

export default Gemius;
