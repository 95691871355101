import React, { useContext } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Formik, Form, Field, FieldProps } from 'formik';
import Styled from 'styled';

import { SiteContext } from 'four-nets';

import LoadingWrapperView from 'four-nets/ui/loaders/loading-wrapper-view';
import SubmitButtonView from 'four-nets/ui/submit-button/submit-button-view';

import Modal from 'four-nets/ui-v2/modal/modal';
import Checkbox from 'four-nets/ui-v2/form/checkbox/checkbox';
import { mergeValidators, trueValidator } from 'four-nets/ui-v2/form/validators';

import { Values as LoginValues } from './login-form';
import loginMutation, { LoginMutation, LoginMutationVariables } from './login.graphql';
import defaultStyles from './cross-company-rules-form.scss';

interface Props {
    styles: typeof defaultStyles;
    loginValues: LoginValues;
    open: boolean;
    onSuccess: () => void;
    onClose: () => void;
}

interface Values {
    rules: boolean;
}

type Errors = Partial<{ [key in keyof Values]: React.ReactElement<FormattedMessage>[] | true }>;

const CrossLoginForm: React.FC<Props> = props => {
    const { styles, loginValues } = props;
    const [login, { data }] = useMutation<LoginMutation, LoginMutationVariables>(loginMutation);
    const site = useContext(SiteContext);

    return (
        <Modal title={<FormattedMessage id='LOGIN' />} open={props.open} onClose={props.onClose}>
            <div className={styles.wrapper}>
                <p>
                    <FormattedMessage id='CROSS_COMPANY_LOGIN_NOTICE' values={{ server: site ? site.name : 'web' }} />
                </p>
                <Formik<Values>
                    initialValues={{ rules: false }}
                    validate={values => {
                        const errors: Errors = {};
                        const rulesError = mergeValidators([trueValidator])(values.rules);
                        if (rulesError) {
                            errors.rules = rulesError;
                        }
                        return errors;
                    }}
                    onSubmit={values => {
                        login({
                            variables: {
                                ...loginValues,
                                ...values,
                            },
                        }).then(props.onSuccess);
                    }}
                >
                    {formik => {
                        const { touched, errors, isSubmitting } = formik;
                        return (
                            <LoadingWrapperView loading={isSubmitting}>
                                <Form>
                                    <div className={styles.fields}>
                                        <Field name='rules'>
                                            {({ field }: FieldProps) => (
                                                <Checkbox
                                                    errors={errors.rules ? [errors.rules] : undefined}
                                                    label={
                                                        <FormattedHTMLMessage
                                                            id='I_AGREE_WITH_RULES_AND_GDPR'
                                                            values={{ className: styles.link }}
                                                        />
                                                    }
                                                    touched={touched.rules}
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <SubmitButtonView type='submit' disabled={isSubmitting}>
                                        <FormattedMessage id='LOGIN_BUTTON' />
                                    </SubmitButtonView>
                                </Form>
                            </LoadingWrapperView>
                        );
                    }}
                </Formik>
            </div>
        </Modal>
    );
};

export default Styled(defaultStyles)(CrossLoginForm);
