// Use this file for custom polyfills (shims)

import Bluebird from 'bluebird';

/* tslint:disable */
require('core-js/modules/es.object.values');
require('core-js/modules/es.object.entries');

// Date
require('Datejs');

/* tslint:enable */

// Config Bluebird
Bluebird.config({
    // Enable warnings
    warnings:
        process.env.NODE_ENV === 'development'
            ? {
                wForgottenReturn: false,
            }
            : false,
    // Enable long stack traces
    longStackTraces: process.env.NODE_ENV === 'development',
    // Enable cancellation
    cancellation: true,
    // Enable monitoring
    monitoring: false,
});

if (process.env.ENV_WEB) {
    (window as any).$RefreshReg$ = () => {
        /**/
    };
    (window as any).$RefreshSig$ = () => () => {
        /**/
    };
    window.Promise = Bluebird;
    require('custom-event-polyfill');



}

if (process.env.ENV_NODE) {
    global.Promise = Bluebird;
}
