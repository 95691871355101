import Refreshable from 'lib/utils/refreshable';
import { ApiStore } from 'lib/api/django-restframework/api-store';
import { ApiItem } from 'lib/api/api-item';
import computed from 'lib/mobx/computed';
import JSONSerializable from 'lib/serialization/json-serializable';
import { OffsetResultCollection } from 'lib/api/django-restframework/offset-result/offset-result';
import PAGED_TYPE from 'lib/api/django-restframework/paged-type';

export const NOTIFICATION_TYPE = {
    mention: 0,
    like: 2,
    recommendation: 3,
    comment: 4,
    review: 5,
    alsoComment: 6,
    inspiration: 7,
    forumMessage: 8,
    thread: 9,
    groupPost: 10,
};

export const NOTIFICATION_OBJECT_TYPE = {
    photoblog: 31,
    post: 62,
    image: 63,
    comment: 64,
    friendship: 96,
    friendshipInvitation: 97,
};

export const NOTIFICATION_ACTION = {
    actionForumMsg: 1,
    actionForumAnswer: 14,
    actionBlogCommentAlbum: 2,
    actionBlogCommentPhoto: 3,
    actionBlogCommentArticle: 4,
    actionBlogCommentTwitter: 5,
    actionBlogAlbum: 6,
    actionBlogPhoto: 7,
    actionBlogArticle: 8,
    actionBlogTwitter: 9,
    actionCollection: 10,
    actionCollectionComment: 11,
    actionVendorPresentation: 12,
    actionUserProfile: 13,
    actionRecommendation: 15,
    actionBazaarSeller: 16,
    actionForumThreadQuestion: 17,
    actionForumThreadDiscussion: 18,
    actionReviewSeller: 19,
    actionReviewBuyer: 20,
    actionReviewReply: 21,
    actionWikiExp: 22,
    actionBlogPhotoInAlbum: 23,
    actionBlogPhotoInShortMessage: 24,
    actionRecommendationReply: 25,
};

export { Notification };

@JSONSerializable()
class Notification extends ApiItem {
    createdTime: Date;
    user: User;
    userStore: UserStore;

    constructor(options) {
        super(options);
        const { userStore } = options;
        this.userStore = userStore;
    }

    fromJSON({ json }) {
        const { createdTime, user: userJSON, ...rest } = json;

        this.createdTime = new Date(createdTime);

        super.fromJSON({
            json: rest,
        });

        this.user = this.userStore.processJSON({
            json: userJSON,
        });
    }
}

export class LikeNotification extends Notification {}
export class MentionNotification extends Notification {}
export class CommentNotification extends Notification {}
export class AlsoCommentNotification extends Notification {}
export class ForumMessageNotification extends Notification {}
export class ThreadNotification extends Notification {}
export class ReviewNotification extends Notification {}
export class InspirationsNotification extends Notification {}
export class GroupPostNotification extends Notification {}
export class RecommendationNotification extends Notification {}
export class RecommendationReplyNotification extends Notification {}

export class NotificationStore extends ApiStore {
    userStore: UserStore;

    constructor(options) {
        super(options);

        const { userStore } = options;
        this.userStore = userStore;
        this.delegate = this;
    }

    item({ json }) {
        let cls;

        switch (json.type) {
            case NOTIFICATION_TYPE.like:
                cls = LikeNotification;
                break;
            case NOTIFICATION_TYPE.comment:
                cls = CommentNotification;
                break;
            case NOTIFICATION_TYPE.alsoComment:
                cls = AlsoCommentNotification;
                break;
            case NOTIFICATION_TYPE.forumMessage:
                cls = ForumMessageNotification;
                break;
            case NOTIFICATION_TYPE.thread:
                cls = ThreadNotification;
                break;
            case NOTIFICATION_TYPE.mention:
                cls = MentionNotification;
                break;
            case NOTIFICATION_TYPE.review:
                cls = ReviewNotification;
                break;
            case NOTIFICATION_TYPE.inspiration:
                cls = InspirationsNotification;
                break;
            case NOTIFICATION_TYPE.groupPost:
                cls = GroupPostNotification;
                break;
            case NOTIFICATION_TYPE.recommendation:
                if (json.action == NOTIFICATION_ACTION.actionRecommendationReply) {
                    cls = RecommendationReplyNotification;
                } else {
                    cls = RecommendationNotification;
                }
                break;
            default:
                cls = Notification;
        }

        return new cls({
            delegate: this,
            userStore: this.userStore,
        });
    }

    loadAll() {
        const request = this.api.get({
            path: `web-notifications`,
        });

        request.query({
            pager: 'offset',
        });

        return super.loadAll({
            request,
            pagedType: PAGED_TYPE.OFFSET,
        });
    }

    newCount() {
        return this.api
            .get({
                path: 'notifications-mails-new-count',
            })
            .json()
            .then(({ json }) => json);
    }
}

function sort(a, b) {
    return b.createdTime.getTime() - a.createdTime.getTime();
}

export class NotificationsManager {
    refreshRequestsCount = 0;
    interval: ?number;
    collection: OffsetResultCollection<Notification>;

    constructor(notificationStore: NotificationStore) {
        this.collection = new OffsetResultCollection(
            notificationStore.loadAll.bind(notificationStore),
            notificationStore
        );
    }

    @computed get notifications() {
        if (!this.collection.results) {
            return null;
        }

        return this.collection.results.sort(sort);
    }

    toJSON() {
        return {
            collection: this.collection,
        };
    }

    fromJSON({ json }) {
        return this.collection.fromJSON({ json: json.collection });
    }

    onRefresh = () => {
        return this.collection.load().catch(() => null);
    };
}
