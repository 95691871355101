import React from 'react';
import PropTypes from 'prop-types';

export interface Props {
    animated?: boolean;
    scrollOnMount?: boolean;
    [key: string]: any;
}

class ScrollToTop extends React.PureComponent<Props> {
    static contextTypes = {
        scrollView: PropTypes.object,
    };

    static defaultProps = {
        scrollOnMount: true,
    };

    scrollToTop() {
        if (this.context.scrollView) {
            if (this.props.animated) {
                this.context.scrollView.scrollToTop();
            } else {
                this.context.scrollView.toTop();
            }
        }
    }

    componentDidMount() {
        if (this.props.scrollOnMount) {
            this.scrollToTop();
        }
    }

    componentDidUpdate() {
        this.scrollToTop();
    }

    render() {
        return null;
    }
}

export default ScrollToTop;
