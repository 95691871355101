import React from 'react';
import PropTypes from 'prop-types';
import { observable, autorun, computed, action, when, IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';
import Styled from 'styled';

import defaultStyles from './scroll-view.scss';

const NOOP = () => null;

export interface Props {
    asWindow: boolean;
    preventWindowScroll?: boolean;
    setStackViewBoundary?: boolean;
    touch?: boolean;
    styles: typeof defaultStyles;
}

interface ScrollToOptions {
    duration?: number;
    onComplete?: () => void;
}

@observer
class ScrollView extends React.Component<Props> {
    static defaultProps = {
        setStackViewBoundary: true,
    };

    static contextTypes = {
        stackView: PropTypes.object,
    };

    @observable container?: Window | HTMLDivElement | null;
    disposer?: IReactionDisposer;
    preventWindowScrollDisposer?: (() => void) | null;
    nextScrollTop = 0;

    getChildContext() {
        return {
            scrollView: this,
        };
    }

    componentDidMount() {
        if (this.props.asWindow) {
            this.setContainer(window);
        }

        this.disposer = autorun(() => {
            if (this.props.preventWindowScroll) {
                this.enablePreventWindowScroll();
            } else {
                this.disablePreventWindowScroll();
            }
        });
    }

    componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }

        this.disablePreventWindowScroll();
    }

    @action setContainer = (ref: HTMLDivElement | null | Window) => {
        this.container = ref;

        if (this.context.stackView && this.props.setStackViewBoundary) {
            if (ref) {
                if (!this.context.stackView.scrollBoundary) {
                    this.context.stackView.setScrollBoundary(ref);
                }
            } else {
                this.context.stackView.setScrollBoundary(null);
            }
        }
    };

    @computed get ref(): HTMLElement | null {
        if (process.env.ENV_WEB && this.container) {
            return this.container === window
                ? document.scrollingElement || document.documentElement
                : (this.container as any);
        }

        return null;
    }

    enablePreventWindowScroll() {
        this.disablePreventWindowScroll();

        const context: {
            disposer?: () => void;
        } = {};

        const disposer = when(
            () => this.ref != null && this.container != null,
            () => {
                const { container, ref } = this;

                const onWheel = (event: WheelEvent) => {
                    const { deltaY } = event;

                    if (
                        (ref!.scrollTop === ref!.scrollHeight - ref!.clientHeight && deltaY > 0) ||
                        (ref!.scrollTop === 0 && deltaY < 0)
                    ) {
                        event.preventDefault();
                    }
                };

                container!.addEventListener('wheel', onWheel as any);
                context.disposer = () => container!.removeEventListener('wheel', onWheel as any);
            }
        );

        this.preventWindowScrollDisposer = () => {
            disposer();

            if (context.disposer) {
                context.disposer();
            }
        };
    }

    disablePreventWindowScroll() {
        if (this.preventWindowScrollDisposer) {
            this.preventWindowScrollDisposer();
            this.preventWindowScrollDisposer = null;
        }
    }

    scrollToValue = (value: number) => {
        const { ref } = this;

        if (ref) {
            this.nextScrollTop = value;

            if (window.requestAnimationFrame) {
                requestAnimationFrame(() => {
                    if (ref.scrollTop !== this.nextScrollTop) {
                        ref.scrollTop = this.nextScrollTop;
                    }
                });
            } else if (value !== ref.scrollTop) {
                ref.scrollTop = value;
            }
        }
    };

    scrollToTop = (options: ScrollToOptions = {}) => {
        const { ref } = this;

        if (ref) {
            const { duration, onComplete = NOOP } = options;

            if (ref.scrollTop !== 0) {
                this.scrollToValue(0);
                onComplete();
            }
        }
    };

    scrollTo = (options: ScrollToOptions & { scrollTop: number }) => {
        const { ref } = this;

        if (ref) {
            const { scrollTop, duration, onComplete = NOOP } = options;

            if (ref.scrollTop !== scrollTop) {
                this.scrollToValue(scrollTop);
                onComplete();
            }
        }
    };

    scrollToRef = (
        options: ScrollToOptions & {
            ref?: HTMLElement;
            offset?: number;
        }
    ) => {
        const { ref, offset = 0, duration, onComplete = NOOP } = options;

        const scrollRef = this.ref;

        if (ref && scrollRef) {
            let scrollTop;

            if (this.container === window) {
                scrollTop = 0;

                let element: HTMLElement = ref;
                do {
                    scrollTop += element.offsetTop || 0;
                    element = element.offsetParent as HTMLElement;
                } while (element);

                scrollTop += offset;
            } else {
                scrollTop = ref.getBoundingClientRect().top - scrollRef.getBoundingClientRect().top + offset;

                scrollTop = scrollRef.scrollTop + scrollTop;
            }

            if (scrollTop !== scrollRef.scrollTop) {
                this.scrollToValue(scrollTop);
                onComplete();
            }
        }
    };

    scrollToRefBottom = (
        options: ScrollToOptions & {
            ref?: HTMLElement;
            offset?: number;
        }
    ) => {
        const { ref, offset = 0, duration, onComplete = NOOP } = options;

        const scrollRef = this.ref;

        if (ref && scrollRef) {
            let scrollTop;

            if (this.container === window) {
                const element: HTMLElement = ref;
                scrollTop = element.offsetTop + element.clientHeight;
            } else {
                scrollTop = ref.getBoundingClientRect().bottom - scrollRef.getBoundingClientRect().top + offset;
                scrollTop = scrollRef.scrollTop + scrollTop;
            }

            if (scrollTop !== scrollRef.scrollTop) {
                this.scrollToValue(scrollTop);
                onComplete();
            }
        }
    };

    toTop = () => this.scrollToValue(0);

    render() {
        const { asWindow, touch = true } = this.props;

        if (asWindow) {
            return this.props.children || null;
        }

        const classNames = [defaultStyles.scroll];

        if (touch) {
            classNames.push(defaultStyles.touch);
        }

        return (
            <div className={classNames.join(' ')} ref={this.setContainer}>
                {this.props.children}
            </div>
        );
    }
}

(ScrollView as any).childContextTypes = {
    scrollView: PropTypes.object,
};

export default Styled(defaultStyles)(ScrollView);
