import {ApiStore} from './api-store';


class Cached {
    promise: Promise<any>;
    request: Request;

    constructor(options) {
        const {request, promise} = options;

        this.promise = promise;
        this.request = request;
    }
}

export class RestApiStore extends ApiStore {
    cache: {[id: string|number]: Cached} = {};

    load(options: {
        id?: number|string,
        request: Request,
    }) {
        const {id, request, ...rest} = options;

        if (id) {
            let cached = this.cache[id];

            if (!cached || (cached && !cached.promise.isPending())) {
                cached = new Cached({
                    request,
                    promise: request.json(),
                });

                this.cache[id] = cached;
            }

            return cached.promise.then(data => super.load({
                ...rest,
                ...data,
                json: Promise.resolve(data.json),
            }))
            .finally(() => {
                if (this.cache[id] === cached) {
                    delete this.cache[id];
                }
            });
        }

        return request.json()
            .then(({json, response}) => super.load({
                ...rest,
                response,
                json: Promise.resolve(json),
            }));
    }

    loadAll(options: {request: Request, jsonOptions?: JSONOptions}) {
        const {request, ...rest} = options;

        return request.json()
            .then(({json, response}) => super.loadAll({
                ...rest,
                response,
                json: Promise.resolve(json),
            }));
    }

    create(options: {request: Request, jsonOptions?: JSONOptions}) {
        const {request, ...rest} = options;

        return request.json()
            .then(({json, response}) => super.create({
                ...rest,
                response,
                json: Promise.resolve(json),
            }));
    }
}


export default RestApiStore;
