export default function (Class) {
    function ExtendableBuiltin() {
        Class.apply(this, arguments); // eslint-disable-line prefer-rest-params
    }

    ExtendableBuiltin.prototype = Object.create(Class.prototype, {
        constructor: {
            value: Class,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    if (Object.setPrototypeOf) {
        Object.setPrototypeOf(ExtendableBuiltin, Class);
    } else {
        ExtendableBuiltin.__proto__ = Class;
    }
    return ExtendableBuiltin;

}
