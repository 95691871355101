import isString from 'lodash/isString';
import convert from 'react-attr-converter';
import mapKeys from 'lodash/mapKeys';
import {camelize} from 'humps';

export default function (attributes: {[key: string]: string}) {
    const props = mapKeys(attributes, (value, key) => convert(key));

    if (props.style && isString(props.style)) {
        const style = {};

        props.style.split(';').forEach(rule => {
            const [key, value] = rule.split(":");
            style[camelize(key)] = value;
        });

        props.style = style;
    }

    return props;
}
