import React from 'react';
import Styled from 'styled';
import Row, { FooterRow } from './row/row';
import defaultStyles from './column.scss';

export interface FooterColumn {
    rows: FooterRow[];
}

export interface Props extends FooterColumn {
    styles: typeof defaultStyles;
}

class Column extends React.PureComponent<Props> {
    render() {
        const { styles, rows } = this.props;
        return (
            <li className={styles!.column}>
                <ul className={styles!.rows}>
                    {rows.map((row, i) => (
                        <Row key={i} {...row} />
                    ))}
                </ul>
            </li>
        );
    }
}

export default Styled(defaultStyles)(Column);
