/* @flow */

import JSONSerializable from 'lib/serialization/json-serializable';
import { action, observable } from 'mobx';

export interface ApiItemDelegate {
    saveItem<T: ApiItem>(options: { item: T }): Promise<T>;
    deleteItem<T: ApiItem>(options: { item: T }): Promise<any>;
}

export { ApiItem };

@JSONSerializable()
class ApiItem {
    delegate: ApiItemDelegate;
    @observable isDeleted = false;
    @observable isDeleteInProgress = false;
    @observable isSaveInProgress = false;

    savePromise: ?Promise;
    deletePromise: ?Promise;

    constructor(options: { delegate: ApiItemDelegate }) {
        if (options) {
            const { delegate } = options;
            this.delegate = delegate;
        }
    }

    saveDidFinish = () => {
        this.isSaveInProgress = false;
    };

    cancelPromise(promise) {
        if (promise && promise.isPending()) {
            promise.cancel();
        }
    }

    save(): Promise<this> {
        this.cancelPromise(this.savePromise);

        this.isSaveInProgress = true;

        const promise = this.delegate
            .saveItem({
                item: this,
            })
            .finally(this.saveDidFinish);

        this.savePromise = promise;
        return promise;
    }

    saveWithCopy(makeChanges: (copy: ApiItem) => Promise) {
        this.cancelPromise(this.savePromise);
        this.isSaveInProgress = true;

        const copy = this.copy();

        const result = makeChanges(copy);

        const promise = (result instanceof Promise ? result : Promise.resolve(result))
            .then(() => copy.save())
            .finally(this.saveDidFinish);

        this.savePromise = promise;
        return promise;
    }

    saveWithRollback(makeChanges: (() => void) | Promise<any>) {
        const beforeSave = JSON.stringify(this);

        const promise = makeChanges instanceof Promise ? makeChanges : Promise.resolve(makeChanges(this));

        return promise
            .then(() => this.save())
            .catch(err => {
                this.fromJSON({ json: JSON.parse(beforeSave) });

                throw err;
            });
    }

    @action deleteDidFinish = () => {
        this.isDeleteInProgress = false;
    };

    @action deleteDidSucceed = () => {
        this.isDeleted = true;
    };

    @action deleteDidFail = (err: Error) => {
        throw err;
    };

    @action delete() {
        this.cancelPromise(this.deletePromise);

        this.isDeleteInProgress = true;

        const promise = this.delegate
            .deleteItem({
                item: this,
            })
            .then(this.deleteDidSucceed)
            .catch(this.deleteDidFail)
            .finally(this.deleteDidFinish);

        this.deletePromise = promise;

        return promise;
    }
}
