import React from 'react';
import PropTypes from 'prop-types';

export interface ChildContextTypes {
    renderContext: object;
    origin: string;
}

export interface Props {
    error?: Error;
    origin: string;
}

export default class App extends React.PureComponent<Props> {
    static childContextTypes = {
        renderContext: PropTypes.object,
        origin: PropTypes.string,
    };

    renderContext = {};

    getChildContext(): ChildContextTypes {
        return {
            origin: this.props.origin,
            renderContext: this.renderContext,
        };
    }

    render() {
        return this.props.children;
    }
}
