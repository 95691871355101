
class URLComponents {
    host: string;
    path: ?string;
    port: ?number;
    scheme: ?string;

    constructor(options: {
        host: string,
        path?: string,
        port?: number,
        scheme?: string,
    }) {

        const {host, path, port, scheme} = options;

        this.host = host;
        this.path = path;
        this.port = port;
        this.scheme = scheme;
    }

    url() {
        const scheme = (this.scheme && `${this.scheme}://`) || '//';
        const host = this.host;
        const port = (this.port && `:${this.port}`) || '';
        let path = this.path || '';

        if (!path.startsWith('/')) {
            path = `/${path}`;
        }

        return `${scheme}${host}${port}${path}`;
    }
}

export default URLComponents;
