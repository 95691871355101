/* @flow */

import {observable, action} from 'mobx';
import computed from 'lib/mobx/computed';


export class Result<T> {
    store: ApiStore;
    pagedType: string;
    jsonOptions: ?JSONOptions;

    count: number;
    results: T[];

    @observable responseResults: T[];
    @observable responseCount: number;
    @observable next: ?string;
    @observable previous: ?string;

    @observable loadNext: ?(() => Promise<Result<T>>);
    @observable loadPrevious: ?(() => Promise<Result<T>>);

    @observable loading = false;

    constructor(options: {
        store: ApiStore,
        count: number,
        next: ?string,
        previous: ?string,
        results: T[],
        jsonOptions?: JSONOptions,
        pagedType: string,
    }) {

        const {
            count,
            next,
            previous,
            results,
            store,
            pagedType,
        } = options;

        this.store = store;

        this.next = next;
        this.previous = previous;
        this.responseCount = count;
        this.responseResults = results;
        this.pagedType = pagedType;
    }

    @computed get results(): T[] {
        return this.responseResults.filter(item => !item.isDeleteInProgress && !item.isDeleted);
    }

    @computed get count(): number {
        return this.responseCount - (this.responseResults.length - this.results.length);
    }

    toJSON() {
        return {
            count: this.count,
            next: this.next,
            previous: this.previous,
            results: this.results,
        };
    }

    @action loadAllDidSucceed = (options) => {
        return this.store.processJSONArray({
            ...options,
            jsonOptions: this.jsonOptions,
            pagedType: this.pagedType,
        });
    }

    @action loadAllDidFinish = () => {
        this.loading = false;
    }
}
