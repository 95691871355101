import React from 'react';
import Styled from 'styled';
import { FooterChild } from './child/child';
import Action, { FooterActionChild } from './child/action/action';
import Link, { FooterLinkChild } from './child/link/link';
import Image, { FooterImageChild } from './child/image/image';
import Icon, { FooterIconChild } from './child/icon/icon';

import defaultStyles from './row.scss';

export interface FooterRow {
    title: string;
    children: FooterChild[];
    rowStyle?: object;
}

export interface Props extends FooterRow {
    styles: typeof defaultStyles;
}

class Row extends React.PureComponent<Props> {
    constructor(props) {
        super(props);
        // We need to hide one row (desktop version button)
        // based on its child status
        this.state = {
            hidden: false,
        };
    }
    onActionClick = (hidden: Boolean) => {
        this.setState({
            hidden,
        });
    };

    render() {
        const { styles, rowStyle, title, children } = this.props;

        const rowClasses = [styles!.row];

        if (this.state.hidden) {
            rowClasses.push(styles!.mobileOnly);
        }

        return (
            <li className={rowClasses.join(' ')}>
                <h3 className={styles!.title}>{title}</h3>
                <ul className={styles!.children} style={rowStyle}>
                    {children.map((child, i) => {
                        let element;

                        switch (child.__typename) {
                            case 'FooterLinkChild': {
                                element = <Link {...(child as FooterLinkChild)} />;
                                break;
                            }
                            case 'FooterActionChild': {
                                element = <Action onClick={this.onActionClick} {...(child as FooterActionChild)} />;
                                break;
                            }
                            case 'FooterIconChild':
                                element = <Icon {...(child as FooterIconChild)} />;
                                break;

                            case 'FooterImageChild':
                                element = <Image {...(child as FooterImageChild)} />;
                                break;
                        }

                        return (
                            <li
                                key={i}
                                className={styles!.child}
                                style={{
                                    marginTop: child.title === 'mastercard' ? 15 : undefined,
                                }}
                            >
                                {element}
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }
}

export default Styled(defaultStyles)(Row);
