/* @flow */

import { observable, action, when, intercept } from 'mobx';
import computed from 'lib/mobx/computed';
import { IntlMessage } from 'react-intl/intl-message';
import { defineMessages } from 'react-intl';
import { AppError, NotImplementedError } from 'lib/errors/errors';
import { FourNetsApiError } from 'four-nets/api/api';

export class ValidationError extends AppError {}

export class Validator<T: FieldData> {
    @observable fieldData: ?T;
    @observable error: ?ValidationError = null;

    @computed get valid(): boolean {
        return this.error === null;
    }

    @action setFieldData = (fieldData: T) => {
        this.fieldData = fieldData;
    };
}

export class OrValidator extends Validator {
    validators: Validator[];

    constructor(options) {
        super(options);

        const { validators } = options;
        this.validators = validators;

        when(
            () => this.fieldData,
            () => {
                this.validators.forEach(validator => validator.setFieldData(this.fieldData));
            }
        );
    }

    @computed get error() {
        let error;

        for (let i = 0; i < this.validators.length; i++) {
            error = this.validators[i].error;

            if (!error) {
                return null;
            }
        }

        return error;
    }
}

export class OtherFieldValidator<T: Validator> extends Validator {
    validator: T;

    constructor(options: { fieldName: string, validator: T }) {
        super(options);

        const { fieldName, validator } = options;
        this.validator = validator;

        when(
            () =>
                this.fieldData &&
                this.fieldData.field &&
                this.fieldData.field.form &&
                this.fieldData.field.form.field.has(fieldName),
            () => {
                const field = this.fieldData.field.form.field.get(fieldName);
                this.validator.setFieldData(field.data);
            }
        );
    }

    @computed get error() {
        return this.validator.error;
    }
}

export class SameFieldValueValidator extends Validator {
    @observable otherField;
    fieldName;

    constructor(options: { fieldName: string }) {
        super(options);

        const { fieldName } = options;
        this.fieldName = fieldName;

        when(() => {
            if (
                this.fieldData &&
                this.fieldData.field &&
                this.fieldData.field.form &&
                this.fieldData.field.form.field.has(fieldName)
            ) {
                this.otherField = this.fieldData.field.form.field.get(fieldName);
            }
        });
    }

    @computed get error() {
        if (this.otherField.data.value !== this.fieldData.value) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_OTHER_FIELD_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }

        return null;
    }
}

export class FieldData<T, U> {
    valid: boolean;
    @observable field: Field<T, U>;
    @observable validators: Validator<T>[] = [];
    // Used to display error from server
    @observable serverError: String;

    constructor(options = {}) {
        const { validators = [] } = options;

        intercept(this.validators, change => {
            if (change.added) {
                change.added.forEach(validator => {
                    validator.setFieldData(this);
                });
            }

            return change;
        });

        this.validators.replace(validators);
        this.serverError = null;
    }

    setField(field: Field<T, U>) {
        this.field = field;
    }

    setServerError(serverErrorMsg: String) {
        this.serverError = serverErrorMsg;
    }

    @computed get valid(): boolean {
        for (let i = 0; i < this.validators.length; i++) {
            const validator = this.validators[i];

            if (!validator.valid) {
                return false;
            }
        }

        if (this.serverError) {
            return false;
        }

        return true;
    }

    @computed get validationErrors(): ValidationError[] {
        const errors = [];

        for (let i = 0; i < this.validators.length; i++) {
            const validator = this.validators[i];
            const error = validator.error;

            if (error) {
                errors.push(error);
            }
        }

        if (this.serverError) {
            errors.push(this.serverError);
        }

        return errors;
    }

    // Implement these methods in subclasses

    @computed get dirty(): boolean {
        throw new NotImplementedError();
    }

    reset() {
        throw new NotImplementedError();
    }

    submitData(): U {
        throw new NotImplementedError();
    }
}

export class Field<T, U> {
    @observable editable: boolean;
    @observable form: ?Form;
    label: ?string;
    placeholder: ?string;

    constructor(options: { label?: string, placeholder?: string, data: FieldData<T, U>, editable?: boolean }) {
        const { editable = true, label, placeholder, data } = options;

        this.label = label;
        this.placeholder = placeholder;
        this.editable = editable;
        this.data = data;

        data.setField(this);
    }

    @computed get disabled(): boolean {
        if (this.form && this.form.submitting) {
            return true;
        }

        return this.editable;
    }

    @action setForm = (form: ?Form) => {
        this.form = form;
    };
}

export class Form {
    handleSubmit: (options: { data: Object, form: Form }) => Promise<any>;
    promise: ?Promise<any>;

    @observable submitted = false;
    @observable field = observable.map({});
    @observable submitting: boolean = false;

    constructor(options: {
        field?: {
            [id: string]: Field<any>,
        },
        handleSubmit: (options: { data: Object }) => Promise<any>,
    }) {
        const { field = {}, handleSubmit } = options;

        this.handleSubmit = handleSubmit;

        intercept(this.field, change => {
            if (change.newValue && change.newValue instanceof Field) {
                change.newValue.setForm(this);
            }

            return change;
        });

        this.field.merge(field);
        this.submit = this.submit.bind(this);
        this.submitDidFail = this.submitDidFail.bind(this);
        this.submitDidSucceed = this.submitDidSucceed.bind(this);
    }

    @action cancel() {
        const promise = this.promise;

        if (promise && promise.isPending()) {
            promise.cancel();
        }
        this.submitting = false;
    }

    reset() {
        this.cancel();
        this.field.keys().forEach(key =>
            this.field.get(key).data.reset({
                cancel: !this.submitted,
            })
        );

        this.submitted = false;
    }

    @action resetAfterSubmit() {
        this.field.keys().forEach(key => this.field.get(key).data.resetAfterSubmit());
    }

    @action submitDidFail(err) {
        this.submitting = false;
        if (err instanceof FourNetsApiError) {
            if (err.extra) {
                Object.keys(err.extra).forEach(key => {
                    const field = this.field.get(key);
                    if (field) {
                        field.data.setServerError(err.extra[key]);
                        err.handled = true;
                    }
                });
            }
        }

        throw err;
    }

    @action submitDidSucceed(data) {
        this.submitting = false;
        this.submitted = true;
        return data;
    }

    @action submit(extraData = {}) {
        this.cancel();

        const data = this.field.keys().reduce((obj, key) => {
            obj[key] = this.field.get(key).data.submitData(); // eslint-disable-line no-param-reassign
            return obj;
        }, {});

        this.submitted = false;
        this.submitting = true;

        const promise = this.handleSubmit({ data: { ...data, ...extraData }, form: this }).then(
            this.submitDidSucceed,
            this.submitDidFail
        );

        this.promise = promise;
        return promise;
    }

    @computed get valid(): boolean {
        const keys = this.field.keys();

        for (let i = 0; i < keys.length; i++) {
            const field = this.field.get(keys[i]);

            if (!field.data.valid) {
                return false;
            }
        }

        return true;
    }

    @computed get dirty(): boolean {
        const keys = this.field.keys();

        for (let i = 0; i < keys.length; i++) {
            const field = this.field.get(keys[i]);

            if (field.data.dirty) {
                return true;
            }
        }

        return false;
    }
}
