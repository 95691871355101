import React from 'react';
import Styled from 'styled';
import escapeRegexp from 'escape-string-regexp';

import map from 'lib/react/html/map';
import emojione from 'lib/emojione/emojione';

import defaultStyles from './emojione-view.scss';
import ASCII_MAPPING from './ascii-mapping';

@Styled(defaultStyles)
class EmojioneView extends React.Component {
    onElement = (name, props) => {
        const { children, rootStyle, onClick, pngToSvg, options, styles, ...rest } = this.props; // eslint-disable-line no-unused-vars

        if (name === 'img' && props.src && props.className === 'emojione') {
            if (onClick || pngToSvg) {
                props.src.match(/\Wpng\W/g).forEach(match => {
                    props.src = props.src.replace(match, match.replace('png', 'svg'));
                });

                const child = React.createElement(name, {
                    ...props,
                    ...rest,
                    className: styles.emoji,
                });

                if (onClick) {
                    return (
                        <button type='button' className={styles.button} onClick={onClick}>
                            {child}
                        </button>
                    );
                }

                return child;
            }
        }

        return undefined;
    };

    render() {
        const { options, styles } = this.props;
        let { children } = this.props;

        const html = emojione.toImage(
            Object.keys(ASCII_MAPPING).reduce((string, key) => {
                if (string.replace) {
                    return string.replace(new RegExp(escapeRegexp(key), 'g'), ASCII_MAPPING[key]);
                }
                return '';
            }, children)
        );
        children = map(html, this.onElement);

        return children;
    }
}

export default EmojioneView;
