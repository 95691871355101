/* @flow */
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { when, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { URLBuilder } from 'url-builder/url-builder';

import localizable from 'lib/intl/localizable';
import Styled from 'styled';
import { Link } from 'lib/ui/link';
import { URLBuilderLink } from 'lib/url/url-builder-link';

import { UserStore } from 'four-nets/user/user';

import PartnersAndLoginView, { PartnersAndLoginViewModel } from './partners-and-login/partners-and-login-view';
import NavigationView, { NavigationViewModel } from './navigation/navigation/navigation-view';

import defaultStyles from './header-view.scss';

export class HeaderViewModel {
    partnersAndLoginViewModel: PartnersAndLoginViewModel;
    navigationViewModel: NavigationViewModel;
    site: Site;

    constructor(options: { userStore: UserStore; urlBuilder: URLBuilder; site: Site; intl: any }) {
        const { userStore, urlBuilder, site, intl } = options;

        const partnersLinks = [];
        const navigationLinks = [];

        if (site.isLiving && site.isSlovak) {
            partnersLinks.push(
                new Link({
                    label: 'Modrastrecha PRO',
                    href: '/pro-landing/',
                }),
                new Link({
                    label: 'Modrastrecha.cz',
                    href: '//www.modrastrecha.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojasvadba.sk',
                    href: 'https://mojasvadba.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Beremese.cz',
                    href: '//www.beremese.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Záhrada.sk',
                    href: '//www.zahrada.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojdom.sk',
                    href: 'http://mojdom.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
            );

            navigationLinks.push(
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ FORUM: { id: 'FORUM' } }).FORUM),
                    url: 'forum',
                    urlBuilder,
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ PHOTOBLOGS: { id: 'PHOTOBLOGS' } }).PHOTOBLOGS),
                    urlBuilder,
                    url: 'photoblogs',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ BAZAAR: { id: 'BAZAAR' } }).BAZAAR),
                    urlBuilder,
                    url: 'bazaar',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(
                        defineMessages({ BUSINESS_DIRECTORY: { id: 'BUSINESS_DIRECTORY' } }).BUSINESS_DIRECTORY
                    ),
                    urlBuilder,
                    url: 'vendors',
                }),
            );
        } else if (site.isLiving && site.isCzech) {
            partnersLinks.push(
                new Link({
                    label: 'Modrastrecha PRO',
                    href: '/pro-landing/',
                }),
                new Link({
                    label: 'Beremese.cz',
                    href: '//www.beremese.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Modrastrecha.sk',
                    href: '//www.modrastrecha.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojasvadba.sk',
                    href: 'https://mojasvadba.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Záhrada.sk',
                    href: '//www.zahrada.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Homebydleni.cz',
                    href: 'https://homebydleni.cz',
                    target: '_blank',
                    rel: 'noopener',
                })
            );

            navigationLinks.push(
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ FORUM: { id: 'FORUM' } }).FORUM),
                    url: 'forum',
                    urlBuilder,
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ PHOTOBLOGS: { id: 'PHOTOBLOGS' } }).PHOTOBLOGS),
                    urlBuilder,
                    url: 'photoblogs',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ BAZAAR: { id: 'BAZAAR' } }).BAZAAR),
                    urlBuilder,
                    url: 'bazaar',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(
                        defineMessages({ BUSINESS_DIRECTORY: { id: 'BUSINESS_DIRECTORY' } }).BUSINESS_DIRECTORY
                    ),
                    urlBuilder,
                    url: 'vendors',
                }),
            );
        } else if (site.isGarden) {
            partnersLinks.push(
                new Link({
                    label: 'Modrastrecha.sk',
                    href: '//www.modrastrecha.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojasvadba.sk',
                    href: 'https://mojasvadba.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojdom.sk',
                    href: 'http://mojdom.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Urobsisam.sk',
                    href: 'https://urobsisam.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Doma.sk',
                    href: 'https://www.doma.sk/',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Naše časopisy',
                    href: 'https://predplatne.jaga.sk/',
                    target: '_blank',
                    rel: 'noopener',
                })
            );

            navigationLinks.push(
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ MAGAZINE: { id: 'MAGAZINE' } }).MAGAZINE),
                    url: 'blog',
                    // hack to force react-router to let the browser handle the click, bacause
                    // garden magazine is hosted on separate WP
                    target: '_parent',
                    urlBuilder,
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ FORUM: { id: 'FORUM' } }).FORUM),
                    url: 'forum',
                    urlBuilder,
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ BAZAAR: { id: 'BAZAAR' } }).BAZAAR),
                    urlBuilder,
                    url: 'bazaar',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ PHOTOBLOGS: { id: 'PHOTOBLOGS' } }).PHOTOBLOGS),
                    urlBuilder,
                    url: 'photoblogs',
                }),
                new Link({
                    label: intl.formatMessage(
                        defineMessages({ BUSINESS_DIRECTORY: { id: 'BUSINESS_DIRECTORY' } }).BUSINESS_DIRECTORY
                    ),
                    href: site.catalogHref,
                })
            );
        } else if (site.isWedding && site.isCzech) {
            partnersLinks.push(
                new Link({
                    label: 'Beremese PRO',
                    href: '/pro-landing/',
                }),
                new Link({
                    label: 'Modrastrecha.cz',
                    href: '//www.modrastrecha.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Homebydleni.cz',
                    href: 'https://homebydleni.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Modrastrecha.sk',
                    href: '//www.modrastrecha.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojasvadba.sk',
                    href: 'https://mojasvadba.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Záhrada.sk',
                    href: '//www.zahrada.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
            );

            navigationLinks.push(
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ FORUM: { id: 'FORUM' } }).FORUM),
                    url: 'forum',
                    urlBuilder,
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ PHOTOBLOGS: { id: 'PHOTOBLOGS' } }).PHOTOBLOGS),
                    urlBuilder,
                    url: 'photoblogs',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ BAZAAR: { id: 'BAZAAR' } }).BAZAAR),
                    urlBuilder,
                    url: 'bazaar',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(
                        defineMessages({ BUSINESS_DIRECTORY: { id: 'BUSINESS_DIRECTORY' } }).BUSINESS_DIRECTORY
                    ),
                    urlBuilder,
                    url: 'vendors',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ MAGAZINE: { id: 'MAGAZINE' } }).MAGAZINE),
                    urlBuilder,
                    url: 'blog',
                }),
            );
        } else if (site.isWedding && site.isSlovak) {
            partnersLinks.push(
                new Link({
                    label: 'Mojasvadba PRO',
                    href: '/pro-landing/',
                }),
                new Link({
                    label: 'Modrastrecha.sk',
                    href: '//www.modrastrecha.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Modrastrecha.cz',
                    href: '//www.modrastrecha.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Beremese.cz',
                    href: '//www.beremese.cz',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Záhrada.sk',
                    href: '//www.zahrada.sk',
                    target: '_blank',
                    rel: 'noopener',
                }),
                new Link({
                    label: 'Mojdom.sk',
                    href: 'http://mojdom.zoznam.sk',
                    target: '_blank',
                    rel: 'noopener',
                })
            );

            navigationLinks.push(
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ FORUM: { id: 'FORUM' } }).FORUM),
                    url: 'forum',
                    urlBuilder,
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ PHOTOBLOGS: { id: 'PHOTOBLOGS' } }).PHOTOBLOGS),
                    urlBuilder,
                    url: 'photoblogs',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ BAZAAR: { id: 'BAZAAR' } }).BAZAAR),
                    urlBuilder,
                    url: 'bazaar',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(
                        defineMessages({ BUSINESS_DIRECTORY: { id: 'BUSINESS_DIRECTORY' } }).BUSINESS_DIRECTORY
                    ),
                    urlBuilder,
                    url: 'vendors',
                }),
                new URLBuilderLink({
                    label: intl.formatMessage(defineMessages({ MAGAZINE: { id: 'MAGAZINE' } }).MAGAZINE),
                    urlBuilder,
                    url: 'blog',
                }),
            );
        }

        this.partnersAndLoginViewModel = new PartnersAndLoginViewModel({
            userStore,
            links: partnersLinks,
        });

        this.navigationViewModel = new NavigationViewModel({
            userStore,
            urlBuilder,
            links: navigationLinks,
            site,
            intl,
        });
    }
}

@localizable()
@Styled(defaultStyles)
@observer
class HeaderView extends React.Component {
    static contextTypes = {
        scrollView: PropTypes.object,
    };

    container: ?(Window | ?HTMLDivElement);
    disposer: ?Function;

    @observable scrolled = false;

    UNSAFE_componentWillMount() {
        this.disposer = when(
            () => this.context.scrollView.container,
            () => {
                this.container = this.context.scrollView.container;
                this.container.addEventListener('scroll', this.onScroll);
            }
        );
    }

    componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }

        if (this.container) {
            this.container.removeEventListener('scroll', this.onScroll);
            this.container = null;
        }
    }

    @action onScroll = () => {
        const { ref } = this.context.scrollView;

        if (ref) {
            this.scrolled = ref.scrollTop > 0;
        }
    };

    render() {
        const { scrolled } = this;

        const {
            headerViewModel: { partnersAndLoginViewModel, navigationViewModel },
            styles,
        } = this.props;

        return (
            <div className={`${styles.header} ${scrolled ? styles.scrolled : ''}`}>
                <nav className={styles.navigation}>
                    <PartnersAndLoginView partnersAndLoginViewModel={partnersAndLoginViewModel} />
                    <NavigationView navigationViewModel={navigationViewModel} />
                </nav>
            </div>
        );
    }
}

export default HeaderView;
