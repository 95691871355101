import { observable, action } from 'mobx';

import computed from 'lib/mobx/computed';
import JSONSerializable from 'lib/serialization/json-serializable';
import { ApiItem } from 'lib/api/api-item';
import { ApiStore } from 'lib/api/django-restframework/api-store';

import { ROLE } from 'four-nets/user/role';
import { CountyStore, CountryStore } from 'four-nets/user/settings/user-settings';
import SlugLoadableApiStore from 'four-nets/api/slug-loadable-api-store';
import { ComplaintStore } from 'four-nets/admin/admin';
import { PhotoFileStore } from 'four-nets/photofile/photofile';

export { SuperCategory };

@JSONSerializable()
class SuperCategory extends ApiItem {
    @observable categories: Category[];

    constructor(options) {
        super(options);
        this.categoryStore = new CategoryStore(options);
    }

    fromJSON({ json }) {
        const { children } = json;

        this.categories = this.categoryStore.processJSONArray({
            json: children,
            paged: false,
        });

        super.fromJSON({ json });
    }
}

export class SuperCategoryStore extends ApiStore<SuperCategory> {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { userStore } = options;
        this.userStore = userStore;
    }

    item() {
        return new SuperCategory({
            delegate: this,
            userStore: this.userStore,
        });
    }

    loadAll() {
        const request = this.api.get({
            path: `bazaar/categories`,
        });

        return super.loadAll({
            request,
            paged: false,
        });
    }
}

export { Category };

@JSONSerializable()
class Category extends ApiItem {
    constructor(options) {
        super(options);

        const { api, userStore } = options;
        this.userStore = userStore;
        this.productStore = new ProductStore({
            api,
            category: this,
            userStore: this.userStore,
        });
    }

    @computed get href() {
        return `/market/${this.slug}/`;
    }

    loadProducts(page, orderBy, county, price, search, excludeUsed, excludeVendor) {
        return this.productStore.loadAll(this, page, orderBy, county, price, search, excludeUsed, excludeVendor);
    }
}

export { CategoryStore };

@SlugLoadableApiStore({ path: `bazaar/categories` })
class CategoryStore extends ApiStore<Category> {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { userStore } = options;
        this.userStore = userStore;
    }

    item() {
        return new Category({
            delegate: this,
            api: this.api.api,
            userStore: this.userStore,
        });
    }
}

export { Product };

@JSONSerializable()
class Product extends ApiItem {
    @observable buyers: Data<T> = observable.map({});
    @observable category;
    @observable photofiles;
    @observable user;
    @observable county;
    @observable createdTime;
    @observable startTime;
    @observable endTime;
    @observable lastUpdatedTime;
    @observable priceHistory;

    constructor(options) {
        super(options);
        const { category, photoFileStore, buyerStore, userStore, countyStore } = options;

        this.category = category;
        this.photoFileStore = photoFileStore;
        this.buyerStore = buyerStore;
        this.userStore = userStore;
        this.countyStore = countyStore;
    }

    @computed get href() {
        return `/market/${this.slug}`;
    }

    @computed get categoryID() {
        const id = (this.category && this.category.id) || this.serverCategory; // FIX
        return id;
    }

    fromJSON({ json }) {
        const { lastUpdatedTime, createdTime, startTime, endTime, priceHistory, user, photofiles, county, ...rest } =
            json;

        super.fromJSON({
            json: rest,
        });

        this.photofiles = this.photoFileStore.processJSONArray({
            json: photofiles,
            paged: false,
        });

        this.user = user ? this.userStore.processJSON({ json: user }) : null;
        this.county = county ? this.countyStore.processJSON({ json: county }) : null;
        this.createdTime = new Date(createdTime);
        this.startTime = new Date(startTime);
        this.endTime = new Date(endTime);
        this.lastUpdatedTime = new Date(lastUpdatedTime);

        this.priceHistory = [];
        priceHistory.forEach(record => {
            const { createdTime: recordCreatedTime, price } = record;
            this.priceHistory.push({
                createdTime: new Date(recordCreatedTime),
                price,
            });
        });
    }

    loadBuyer(username) {
        return this.buyerStore.loadUsername(this, username).then(buyer => {
            this.buyers.set(username, buyer);
            return buyer;
        });
    }

    loadBuyerIdentifiers() {
        return this.delegate.loadBuyerIdentifiers(this);
    }

    createMessage(text, name, email, photofileIds, buyer) {
        return this.buyerStore.createMessage(this, text, name, email, photofileIds, buyer);
    }

    activate() {
        return this.delegate.activate(this);
    }

    repost() {
        return this.delegate.repost(this);
    }

    deactivate() {
        return this.delegate.deactivate(this);
    }

    update(category, title, description, price, countyId, photofileIds, propertyValuesIds) {
        return this.delegate.update(
            this,
            category,
            title,
            description,
            price,
            countyId,
            photofileIds,
            propertyValuesIds
        );
    }

    delete() {
        return this.delegate.delete(this);
    }

    toggleStarred() {
        return this.delegate.toggleStarred(this);
    }

    complain(comment) {
        return this.delegate.complain({ product: this, comment });
    }

    increaseViewsCount() {
        return this.delegate.increaseViewsCount(this);
    }
}

export { ProductStore };

@SlugLoadableApiStore({ path: store => `bazaar/categories/${store.category.id}/products` })
class ProductStore extends ApiStore<Product> {
    complaintStore: ComplaintStore;
    photoFileStore: PhotoFileStore;
    buyerStore: BuyerStore;

    @observable category;

    constructor(options) {
        super(options);
        this.delegate = this;

        const { category, api, userStore } = options;
        this.category = category;
        this.api = api;
        this.userStore = userStore;
        this.photoFileStore = new PhotoFileStore({
            api: this.api,
        });
        this.buyerStore = new BuyerStore({
            api: this.api,
            userStore: this.userStore,
            photoFileStore: this.photoFileStore,
        });

        this.complaintStore = new ComplaintStore(options);
    }

    @action setCategory = category => {
        this.category = category;
    };

    item() {
        const countryStore = new CountryStore({
            api: this.api,
        });
        return new Product({
            delegate: this,
            category: this.category,
            photoFileStore: this.photoFileStore,
            buyerStore: this.buyerStore,
            userStore: this.userStore,
            countyStore: new CountyStore({
                api: this.api,
                countryStore,
            }),
        });
    }

    loadAll(category, page, orderBy, county, price, search, excludeUsed, excludeVendor) {
        const request = this.api.get({
            path: `bazaar/categories/${category.id}/products?active=${true}`,
        });

        if (orderBy) {
            request.query({ order_by: orderBy });
        }

        if (county) {
            request.query({ county });
        }

        if (price) {
            request.query({ price });
        }

        if (search) {
            request.query({ search });
        }

        if (excludeUsed) {
            request.query({ excludeUsed });
        }

        if (excludeVendor) {
            request.query({ excludeVendor });
        }

        return super.loadAll({
            page,
            request,
        });
    }

    loadUncategorized(page, orderBy, county, price, search, excludeUsed, excludeVendor) {
        const request = this.api.get({
            path: `bazaar/products?active=${true}`,
        });

        if (orderBy) {
            request.query({ order_by: orderBy });
        }

        if (county) {
            request.query({ county });
        }

        if (price) {
            request.query({ price });
        }

        if (search) {
            request.query({ search });
        }

        if (excludeUsed) {
            request.query({ excludeUsed });
        }

        if (excludeVendor) {
            request.query({ excludeVendor });
        }

        return super.loadAll({
            page,
            request,
        });
    }

    loadHomepageProducts() {
        const request = this.api.get({
            path: `bazaar/homepage-products`,
        });

        return super.loadAll({
            request,
        });
    }

    loadCatalogProducts() {
        const request = this.api.get({
            path: `bazaar/catalog-products`,
        });

        return super.loadAll({
            request,
        });
    }

    create(category, title, description, price, countyId, photofileIds, propertyValuesIds = []) {
        const request = this.api.post({
            path: `bazaar/categories/${category.id}/products`,
        });

        request.send({
            serverCategory: category.id,
            title,
            description,
            price,
            photofileIds,
            propertyValuesIds,
        });

        if (parseInt(countyId, 10)) {
            request.send({ countyId: parseInt(countyId, 10) });
        }

        return super.create({
            request,
        });
    }

    update(product, category, title, description, price, countyId, photofileIds, propertyValuesIds = []) {
        const request = this.api.patch({
            path: `bazaar/categories/${product.category.id}/products/${product.id}`,
        });

        request.send({
            title,
            description,
            price,
            serverCategory: category.id,
            photofileIds,
            propertyValuesIds,
        });

        if (parseInt(countyId, 10)) {
            request.send({ countyId: parseInt(countyId, 10) });
        }

        return request.json().then(({ json }) => this.processJSON({ json }));
    }

    activate(product) {
        const request = this.api.post({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/activate`,
        });
        return request.json().then(({ json }) => this.processJSON({ json }));
    }

    repost(product) {
        const request = this.api.post({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/repost`,
        });
        return request.json().then(({ json }) => this.processJSON({ json }));
    }

    deactivate(product) {
        const request = this.api.post({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/deactivate`,
        });
        return request.json().then(({ json }) => this.processJSON({ json }));
    }

    delete(product) {
        const request = this.api.post({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/delete`,
        });
        return request.json();
    }

    loadBuyerIdentifiers(product) {
        const request = this.api.get({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/buyer_identifiers`,
        });

        return request.json().then(({ json }) => this.buyerStore.processPlainJSONArray({ json }));
    }

    usernameProducts(username, page, orderBy) {
        const request = this.api.get({
            path: `bazaar/products?username=${username}&active=${true}`,
        });

        if (orderBy) {
            request.query({ order_by: orderBy });
        }

        if (page) {
            request.query({ page });
        }

        return request.json().then(({ json }) => this.processJSONArray({ json }));
    }

    userProducts(user, page, orderBy) {
        const request = this.api.get({
            path: `bazaar/products?username=${user.username}&active=${true}`,
        });

        if (orderBy) {
            request.query({ order_by: orderBy });
        }

        if (page) {
            request.query({
                page,
            });
        }

        return request.json().then(({ json }) => this.processJSONArray({ json }));
    }

    toggleStarred(product) {
        const request = this.api.patch({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/${
                product.isStarred ? 'unstar' : 'star'
            }`,
        });

        product.isStarred = !product.isStarred;
        const onResolve = ({ json }) => this.processJSON({ json });
        const onReject = () => {
            product.isStarred = !product.isStarred;
        };

        return request.json().then(onResolve, onReject);
    }

    complain({ product, comment }: { product: Product, comment?: string }) {
        return this.complaintStore.create({
            comment,
            objectId: product.id,
            appLabel: 'bazaar',
            model: 'product',
        });
    }

    increaseViewsCount(product) {
        const request = this.api.get({
            path: `bazaar/categories/${product.categoryID}/products/${product.id}/increase`,
        });

        return request.json();
    }
}

export { Buyer };

@JSONSerializable()
class Buyer extends ApiItem {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { messageStore, userStore } = options;
        this.messageStore = messageStore;
        this.userStore = userStore;
    }

    fromJSON({ json }) {
        const { createdDate, messages, user, ...rest } = json;

        if (messages) {
            this.messages = this.messageStore.processPlainJSONArray({ json: messages });
        }

        if (user) {
            this.user = this.userStore.processJSON({ json: user });
        }

        this.createdDate = new Date(createdDate);

        super.fromJSON({
            json: rest,
        });
    }
}

export class BuyerStore extends ApiStore<Buyer> {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { api, userStore, photoFileStore } = options;
        this.api = api;
        this.userStore = userStore;
        this.photoFileStore = photoFileStore;
        this.messageStore = new MessageStore({
            api: this.api,
            photoFileStore: this.photoFileStore,
        });
    }

    item() {
        return new Buyer({
            delegate: this,
            messageStore: this.messageStore,
            userStore: this.userStore,
        });
    }

    loadAll(product) {
        const request = this.api.get({
            path: `bazaar/categories/${product.category.id}/products/${product.id}/buyers`,
        });

        return super.loadAll({
            request,
        });
    }

    loadUsername(product, username) {
        const request = this.api.get({
            path: `bazaar/categories/${product.category.id}/products/${product.id}/buyers?username=${username}`,
        });

        return request
            .json()
            .then(({ json }) => this.processPlainJSONArray({ json }))
            .then(results => {
                return results && results[0];
            });
    }

    loadToken(product, token) {
        const request = this.api.get({
            path: `bazaar/categories/${product.category.id}/products/${product.id}/buyers?token=${token}`,
        });

        return request
            .json()
            .then(({ json }) => this.processPlainJSONArray({ json }))
            .then(results => {
                return results && results[0];
            });
    }

    createMessage(product, text, name, email, photofileIds, buyer) {
        return this.messageStore.createMessage(product, text, name, email, photofileIds, buyer);
    }
}

export { Message };

@JSONSerializable()
class Message extends ApiItem {
    constructor(options) {
        super(options);
        const { photoFileStore } = options;
        this.photoFileStore = photoFileStore;
    }

    fromJSON({ json }) {
        const { sentDate, photofiles, ...rest } = json;

        this.sentDate = new Date(sentDate);
        this.photofiles = this.photoFileStore.processJSONArray({
            json: photofiles,
            paged: false,
        });

        super.fromJSON({
            json: rest,
        });
    }

    @computed get time() {
        return this.sentDate;
    }

    @computed get displayedContent() {
        return this.message;
    }

    @computed get userRole() {
        if (this.isSeller) {
            return ROLE.author;
        }
        return ROLE.none;
    }

    @computed get gallery() {
        return this.photofiles;
    }

    photoSwipeItem = photofile => photofile.photoSwipeItem;
}

export class MessageStore extends ApiStore<Message> {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { photoFileStore } = options;
        this.photoFileStore = photoFileStore;
    }

    item() {
        return new Message({
            delegate: this,
            photoFileStore: this.photoFileStore,
        });
    }

    createMessage(product, text, name, email, photofileIds, buyer) {
        const request = this.api
            .post({
                path: `bazaar/categories/${product.category.id}/products/${product.id}/contact`,
            })
            .send({
                name,
                email,
                message: text,
                photofileIds,
                buyerId: buyer ? buyer.id : undefined,
            });

        return request.json().then(messageJSON => this.processJSON(messageJSON));
    }
}

export class BazaarStore extends ApiStore<Category> {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { userStore, api } = options;
        this.userStore = userStore;
        this.productStore = new ProductStore({
            api,
            userStore: this.userStore,
        });
        this.bazaarProfileStore = new BazaarProfileStore({
            api,
            userStore: this.userStore,
        });
    }

    item() {
        return new Bazaar({ delegate: this });
    }

    loadMyBazaar() {
        const request = this.api.get({
            path: 'bazaar/bazaar',
        });

        return request.json().then(({ json }) => this.onJSON({ json }));
    }

    loadMyProfile() {
        return this.bazaarProfileStore.loadMyProfile();
    }

    loadUserProfile(username) {
        return this.bazaarProfileStore.loadUserProfile(username);
    }

    myProducts(type, page, status, search, orderBy) {
        const request = this.api.get({
            path: `bazaar/my-products?type=${type}`,
        });

        if (status) {
            request.query({ status });
        }

        if (page) {
            request.query({ page });
        }

        if (search) {
            request.query({ search });
        }

        if (orderBy) {
            request.query({ order_by: orderBy });
        }

        return request.json().then(({ json }) => this.productStore.processJSONArray({ json }));
    }

    repostAll() {
        const request = this.api.post({
            path: `bazaar/repost-all`,
        });
        return request.json();
    }

    sellersDelete(productIds) {
        const request = this.api
            .post({
                path: `bazaar/sellers-delete`,
            })
            .send({
                productIds,
            });
        return request.json();
    }

    buyersDelete(productIds) {
        const request = this.api
            .post({
                path: `bazaar/buyers-delete`,
            })
            .send({
                productIds,
            });
        return request.json();
    }

    wishlistDelete(productIds) {
        const request = this.api
            .post({
                path: `bazaar/wishlist-delete`,
            })
            .send({
                productIds,
            });
        return request.json();
    }
}

export { Bazaar };

@JSONSerializable()
class Bazaar extends ApiItem {}

export class BazaarProfileStore extends ApiStore<Category> {
    constructor(options) {
        super(options);
        this.delegate = this;
        const { userStore } = options;
        this.userStore = userStore;
    }

    item() {
        return new BazaarProfile({ delegate: this });
    }

    loadMyProfile() {
        return this.loadProfile();
    }

    loadUserProfile(username) {
        return this.loadProfile(username);
    }

    loadProfile(username) {
        const request = this.api.get({
            path: 'bazaar/profile',
        });

        if (username) {
            request.query({ username });
        } else if (!this.userStore.me) {
            return Promise.reject();
        }

        return request.json().then(({ json }) => this.onJSON({ json }));
    }

    edit(info) {
        if (!this.userStore.me) {
            return Promise.reject();
        }
        const request = this.api
            .post({
                path: 'bazaar/profile',
            })
            .send({ info });

        return request.json().then(({ json }) => this.onJSON({ json }));
    }
}

export { BazaarProfile };

@JSONSerializable()
class BazaarProfile extends ApiItem {
    fromJSON({ json }) {
        const { user, ...rest } = json;
        this.user = this.delegate.userStore.processJSON({ json: user });
        super.fromJSON({ json: rest });
    }
}

export class ReviewStore extends ApiStore<Category> {
    constructor(options) {
        super(options);
        this.delegate = this;

        const { userStore } = options;
        this.userStore = userStore;
    }

    item() {
        return new Review({
            delegate: this,
        });
    }

    loadReviews(username, fromSellers, page) {
        const request = this.api.get({
            path: `bazaar/reviews`,
        });

        request.query({ username });

        if (fromSellers) {
            request.query({ from_sellers: true });
        }

        if (page) {
            request.query({ page });
        }

        return request.json().then(({ json }) => this.processJSONArray({ json }));
    }

    create(buyer, message, rating) {
        const request = this.api.post({
            path: `bazaar/reviews`,
        });

        request.send({
            buyer: buyer.id,
            rating: parseInt(rating, 10),
            message,
        });

        return request.json().then(({ json }) => this.onJSON({ json }));
    }

    sendReply(review, message, fromSellers) {
        const request = this.api
            .patch({
                path: `bazaar/reviews/${review.id}`,
            })
            .send({
                reply: message,
            });

        if (fromSellers) {
            request.query({ from_sellers: true });
        }

        return request.json().then(({ json }) => this.onJSON({ json }));
    }

    deleteReply(review, fromSellers) {
        const request = this.api.del({
            path: `bazaar/reviews/${review.id}/delete_reply`,
        });

        if (fromSellers) {
            request.query({ from_sellers: true });
        }

        return request.json().then(({ json }) => this.onJSON({ json }));
    }

    delete(review, fromSellers) {
        const request = this.api.del({
            path: `bazaar/reviews/${review.id}`,
        });

        if (fromSellers) {
            request.query({ from_sellers: true });
        }

        return request.json();
    }
}

export { Review };

@JSONSerializable()
class Review extends ApiItem {
    @observable user;
    @observable reviewer;
    @observable reviewedDate;
    @observable repliedDate;
    @observable reply;

    fromJSON({ json }) {
        const { user, reviewer: reviewerJSON, reviewedDate, repliedDate, reply, ...rest } = json;

        this.user = this.delegate.userStore.processJSON({ json: user });
        this.reviewer = this.delegate.userStore.processJSON({ json: reviewerJSON });
        this.reply = reply || null;
        this.reviewedDate = new Date(reviewedDate);
        this.repliedDate = new Date(repliedDate);

        super.fromJSON({ json: rest });
    }

    sendReply(message, fromSellers) {
        return this.delegate.sendReply(this, message, fromSellers);
    }

    deleteReply(fromSellers) {
        return this.delegate.deleteReply(this, fromSellers);
    }

    delete(fromSellers) {
        return this.delegate.delete(this, fromSellers);
    }
}
