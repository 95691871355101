import { siteTrackerName } from './constants';

export default (...args) => {
    if (typeof window === 'undefined') {
        return;
    }
    const { ga } = window;
    if (ga) {
        ga('send', ...args);
        ga(`${siteTrackerName}.send`, ...args);
    }
};
