/* @flow */

import decorator from 'lib/lang/decorator';
import {action, extendObservable, observable} from 'mobx';
import pick from 'lodash/pick';

export default () => Class => {

    let Decorated;

    class JSONSerializable extends Class {
        jsonSerializableKeys = new Set();
        jsonSerializableArgs;

        constructor(...args) {
            super(...args);
            this.jsonSerializableArgs = args;
        }

        copy() {
            return (new Decorated(...this.jsonSerializableArgs)).fromJSON({
                json: JSON.parse(JSON.stringify(this)),
            });
        }

        @action fromJSON(options) {
            Object.keys(options.json).forEach(key => {
                this.jsonSerializableKeys.add(key);
            });

            if (super.fromJSON) {
                super.fromJSON(options);

                return this;
            }

            const {json} = options || {};
            extendObservable(this, json);

            return this;
        }

        @action deserializeJSONArray({json: jsonArray, property, serializable}) {
            const items = jsonArray.map(json => serializable({json}).fromJSON({json}));

            if (!this[property]) {
                this[property] = observable([]);
            }
            this[property].replace(items);

            return this;
        }

        toJSON() {
            if (super.toJSON) {
                return super.toJSON();
            }

            return pick(this, Array.from(this.jsonSerializableKeys));
        }
    }

    Decorated = decorator(() => () => JSONSerializable)()(Class);
    return Decorated;
};
