import React from 'react';
import Styled from 'styled';

import observeOnce from 'lib/intersection-observer/observe-once';

import defaultStyles from './svg-image.scss';

export interface Props {
    alt?: string;
    src?: string;
    className?: string;
    styles: typeof defaultStyles;
    preserveOnChange?: boolean;
    intersectionOptions?: IntersectionObserverInit;

    width?: number;
    height?: number;
}

interface State {
    cached: boolean;
    html: { __html: string } | null;
    visible: boolean;
    loaded: boolean;
}

export class SVGImage extends React.PureComponent<Props, State> {
    static defaultProps = {
        preserveOnChange: false,
        intersectionOptions: {
            rootMargin: '360px',
        },
    };

    state = {
        cached: false,
        html: null,
        visible: false,
        loaded: false,
    };

    request?: XMLHttpRequest;
    requestDate?: Date;
    unobserve?: () => void;

    ref = React.createRef();

    componentDidMount() {
        const element = this.ref.current as Element | null;

        if (element) {
            this.unobserve = observeOnce(
                element,
                () => {
                    this.setState({
                        visible: true,
                    });
                },
                this.props.intersectionOptions
            );
        }
    }

    componentWillUnmount() {
        if (this.unobserve) {
            this.unobserve();
        }

        this.abort();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.src !== this.props.src || (this.state.visible && !prevState.visible)) {
            this.load(this.props.src);
        }
    }

    abort() {
        if (this.request && this.request.readyState === 3) {
            // LOADING
            this.request.abort();
        }
    }

    load(src?: string) {
        this.setState({
            html: this.props.preserveOnChange ? this.state.html : null,
            loaded: false,
        });

        this.abort();

        if (src) {
            const request = new XMLHttpRequest();
            request.open('GET', src);
            request.onload = () => {
                this.setState({
                    loaded: true,
                    cached: new Date(request.getResponseHeader('date')!) <= this.requestDate!,
                    html: request.status >= 200 && request.status < 300 ? { __html: request.responseText } : null,
                });
            };
            this.request = request;
            this.requestDate = new Date();
            request.send();
        }
    }

    render() {
        const { alt, styles, className, width, height } = this.props;
        const { cached, html, loaded } = this.state;
        const classNames = [styles.image];

        if (cached) {
            classNames.push(styles.cached);
        }

        if (loaded) {
            classNames.push(styles.loaded);
        }

        if (className) {
            classNames.push(className);
        }

        return (
            <span
                style={{ width: width ? width : undefined, height: height ? height : undefined }}
                ref={this.ref as any}
                aria-label={alt}
                className={classNames.join(' ')}
                dangerouslySetInnerHTML={html || undefined}
            />
        );
    }
}

export default Styled(defaultStyles)(SVGImage);
