import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AlertContext } from 'lib/alert-context/alert-context';

import { InfoDialog, ErrorDialog, RetryDialog, DeleteDialog, CriticalDialog } from './dialog';
import { IntlShape } from 'react-intl';

type DialogType = InfoDialog | ErrorDialog | RetryDialog | DeleteDialog | CriticalDialog;

export default (Component: React.ComponentClass) => {
    const WithDialogProvider: React.FC = (props: any, context: { intl: IntlShape }) => {
        const { confirm, retry, fail, acknowledge } = useContext(AlertContext);
        const isMounted = useRef(false);

        const intl = context && context.intl;

        const showDialog = (dialog: DialogType) => {
            if (dialog instanceof InfoDialog) {
                acknowledge({
                    title: (intl.formatMessage as any)(dialog.title),
                    body: (intl.formatMessage as any)(dialog.message),
                    acceptButtonLabel: (intl.formatMessage as any)(
                        dialog.actions && dialog.actions.length && dialog.actions[0].label
                    ),
                    onAccept: (args: any) => {
                        if (dialog.actions && dialog.actions.length && isMounted.current) {
                            (dialog.actions[0].callback as any)(args);
                        }
                    },
                });
            } else if (dialog instanceof ErrorDialog || dialog instanceof CriticalDialog) {
                fail({
                    title: (intl.formatMessage as any)(dialog.title),
                    body: (intl.formatMessage as any)(dialog.message),
                    acceptButtonLabel: (intl.formatMessage as any)(
                        dialog.actions && dialog.actions.length && dialog.actions[0].label
                    ),
                    onAccept: (args: any) => {
                        if (dialog.actions && dialog.actions.length && isMounted.current) {
                            (dialog.actions[0].callback as any)(args);
                        }
                    },
                });
            } else if (dialog instanceof RetryDialog) {
                retry({
                    title: (intl.formatMessage as any)(dialog.title),
                    body: (intl.formatMessage as any)(dialog.message),
                    acceptButtonLabel: (intl.formatMessage as any)(
                        dialog.actions && dialog.actions.length > 1 && dialog.actions[1].label
                    ),
                    onAccept: (args: any) => {
                        if (dialog.actions && dialog.actions.length > 1 && isMounted.current) {
                            (dialog.actions[1].callback as any)(args);
                        }
                    },
                    onCancel: dialog.actions && dialog.actions.length && (dialog.actions[0].callback as any),
                    cancelButtonLabel: (intl.formatMessage as any)(
                        dialog.actions && dialog.actions.length && dialog.actions[0].label
                    ),
                });
            } else if (dialog instanceof DeleteDialog) {
                confirm({
                    title: (intl.formatMessage as any)(dialog.title),
                    body: (intl.formatMessage as any)(dialog.message),
                    cancelButtonLabel: (intl.formatMessage as any)(
                        dialog.actions && dialog.actions.length && dialog.actions[0].label
                    ),
                    onCancel: dialog.actions && dialog.actions.length && (dialog.actions[0].callback as any),
                    acceptButtonLabel: (intl.formatMessage as any)(
                        dialog.actions && dialog.actions.length > 1 && dialog.actions[1].label
                    ),
                    onAccept: (args: any) => {
                        if (dialog.actions && dialog.actions.length > 1 && isMounted.current) {
                            (dialog.actions[1].callback as any)(args);
                        }
                    },
                    acceptButtonType: 'warn',
                });
            }
        };

        useEffect(() => {
            isMounted.current = true;
            return () => {
                isMounted.current = false;
            };
        }, []);

        return (
            <>
                <Component {...props} showDialog={showDialog} />
            </>
        );
    };

    WithDialogProvider.contextTypes = {
        intl: PropTypes.object,
    };

    return WithDialogProvider;
};
