import {computed} from 'mobx';
import upperFirst from 'lodash/upperFirst';

export default function (target, key, descriptor) {
    const name = `compute${upperFirst(key)}`;
    const value = descriptor.get;

    descriptor.get = value ? function () { // eslint-disable-line object-shorthand
        return this[name]();
    } : null;

    Object.defineProperty(target, name, {
        value,
    });

    return computed(target, key, descriptor);
}
