import {URLBuilder} from 'url-builder/url-builder';
import {observable, computed} from 'mobx';
import isFunction from 'lodash/isFunction';
import {Link} from 'lib/ui/link';
import URL from 'url-parse';

export class URLBuilderLink<T> extends Link {
    urlBuilder: URLBuilder;
    url: string;

    constructor(options: {
        label: string | () => string,
        target?: string | () => string,
        url: string | () => string,
        urlBuilder: URLBuilder,
        value?: Object | () => Object,
        hash?: string,
        model?: T,
    }) {

        const {
            url,
            value,
            label,
            target,
            model,
            hash,
        } = options;

        const urlBuilder = URLBuilder.defaultURLBuilder;

        const context = observable({
            value: isFunction(value) ? computed(value) : value,
        });

        super({
            label,
            target,
            href: () => {
                const parsedURL = new URL(
                    urlBuilder.getUrl({
                        url,
                        value: context.value,
                    }),
                    ' ',
                );
                if (hash) {
                    parsedURL.set('hash', `#${hash}`);
                }
                return parsedURL.href;
            },
            model,
        });
    }
}
