/* @flow */

import ExtendableBuiltin from 'lib/lang/extendable-builtin';
import { IntlMessage } from 'react-intl/intl-message';

export class AppError extends ExtendableBuiltin(Error) {
    underlayingError: ?Error;
    intlMessage: ?IntlMessage;
    message: ?string;

    constructor(
        options: {
            message?: string,
            intlMessage?: IntlMessage,
            underlayingError?: ?Error,
        } = {}
    ) {
        const { message, intlMessage, underlayingError } = options;
        super();

        this.intlMessage = intlMessage;
        this.underlayingError = underlayingError;

        try {
            if (underlayingError) {
                this.stack = underlayingError.stack;
            } else {
                this.stack = new Error().stack;
            }
        } catch (e) {
            // pass
        }

        this.message = intlMessage || (message || (underlayingError && underlayingError.message)) || '';
    }
}

export class NotImplementedError extends AppError {}
export class NotSupportedError extends AppError {}
