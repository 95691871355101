import React from 'react';
import classnames from 'classnames';
import Styled from 'styled';
import { Query } from '@apollo/client/react/components';

import SVGIcon from 'lib/images/svg-icon/svg-icon';
import { keyEnterPressed, keyEscapePressed, keyDownPressed, keyUpPressed } from 'lib/key-code/key-code';

import Avatar from 'four-nets/avatar/avatar';
import deleteIcon from 'four-nets/icons/svg/icon_delete 2.svg';

import query, { UserAutocompleteQuery, UserAutocompleteQueryVariables } from './users-autocomplete.graphql';
import defaultStyles from './users-autocomplete.scss';

export interface Props {
    styles: typeof defaultStyles;
    value: string;
    groupId?: string | null;
    onChange: (value: string) => void;
    children: (fn: (event: React.KeyboardEvent<HTMLInputElement>) => void, loading?: boolean) => React.ReactNode;
}

interface State {
    usernameSearch: string;
    selectedIndex?: number;
    forceHide: boolean;
}

type Edges = NonNullable<NonNullable<NonNullable<UserAutocompleteQuery['usersAutocomplete']>['edges']>>;
type UserNode = NonNullable<
    NonNullable<NonNullable<NonNullable<UserAutocompleteQuery['usersAutocomplete']>['edges']>[0]>['node']
>;

class UserAutocomplete extends React.PureComponent<Props, State> {
    state: State = { usernameSearch: '', forceHide: false };

    componentDidUpdate(prevProps: Props) {
        if (prevProps.value !== this.props.value && this.state.forceHide) {
            this.setState({ forceHide: false });
        }
    }

    focus = (node: UserNode, index: number) => {
        this.props.onChange(node.username);
    };

    onKeyDown = (edges: Edges) => (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (keyDownPressed(event)) {
            this.setState(prevState => ({
                selectedIndex: Math.min(
                    edges.length - 1,
                    prevState.selectedIndex === undefined ? 0 : prevState.selectedIndex + 1
                ),
            }));
            event.stopPropagation();
        } else if (keyUpPressed(event)) {
            this.setState(prevState => ({
                selectedIndex: Math.max(0, (prevState.selectedIndex || 0) - 1),
            }));
            event.stopPropagation();
        } else if (keyEnterPressed(event)) {
            this.props.onChange(
                (edges[this.state.selectedIndex || 0] &&
                    edges[this.state.selectedIndex || 0]!.node &&
                    edges[this.state.selectedIndex || 0]!.node!.username) ||
                    this.state.usernameSearch
            );
            this.setState({ usernameSearch: '', selectedIndex: undefined });
        } else if (keyEscapePressed(event)) {
            this.setState({ forceHide: true });
        }
    };

    render() {
        const { styles, value, groupId } = this.props;
        const { forceHide } = this.state;

        return (
            <Query<UserAutocompleteQuery, UserAutocompleteQueryVariables>
                query={query}
                variables={{ username: value, group: groupId }}
                notifyOnNetworkStatusChange={true}
                skip={!value || value.length < 3}
            >
                {({ data, loading }) => {
                    return (
                        <React.Fragment>
                            {this.props.children &&
                                this.props.children(
                                    this.onKeyDown(
                                        (data && data.usersAutocomplete && data.usersAutocomplete.edges) || []
                                    ),
                                    loading
                                )}
                            {value &&
                            !forceHide &&
                            !loading &&
                            data &&
                            data.usersAutocomplete &&
                            data.usersAutocomplete.edges ? (
                                <div className={styles.wrapper}>
                                    <div className={styles.contentWrapper}>
                                        {data.usersAutocomplete.edges.map((edge, index) =>
                                            edge && edge.node ? (
                                                <button
                                                    key={edge.node.id}
                                                    type='button'
                                                    onClick={() => {
                                                        this.focus(edge.node!, index);
                                                    }}
                                                    className={classnames(styles.user, {
                                                        [styles.focused]: index === this.state.selectedIndex,
                                                    })}
                                                >
                                                    <Avatar style={{ avatar: styles.avatar }} user={edge.node} />
                                                    <div className={styles.info}>
                                                        <div className={styles.username}>{edge.node.username}</div>
                                                        <div className={styles.county}>
                                                            {edge.node.profile &&
                                                                edge.node.profile.county &&
                                                                edge.node.profile.county.name}
                                                        </div>
                                                    </div>
                                                </button>
                                            ) : null
                                        )}
                                    </div>
                                    {value &&
                                    !forceHide &&
                                    !loading &&
                                    data &&
                                    data.usersAutocomplete &&
                                    data.usersAutocomplete.edges &&
                                    data.usersAutocomplete.edges.length ? (
                                        <button
                                            className={styles.forceHide}
                                            onClick={() => this.setState({ forceHide: true })}
                                        >
                                            <SVGIcon style={{ image: styles.icon }} src={deleteIcon} />
                                        </button>
                                    ) : null}
                                </div>
                            ) : null}
                        </React.Fragment>
                    );
                }}
            </Query>
        );
    }
}

export default Styled(defaultStyles)(UserAutocomplete);
