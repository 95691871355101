import React from 'react';
import PropTypes from 'prop-types';
import { Site } from 'four-nets/site';
import Styled from 'styled';

import Column from './column/column';
import Query from './query';
import defaultStyles from './footer.scss';

export interface Props {
    styles: typeof defaultStyles;
}

interface Context {
    site: Site;
}

class Footer extends React.PureComponent<Props> {
    static contextTypes = {
        site: PropTypes.instanceOf(Site),
    };

    context!: Context;

    render() {
        const { site } = this.context;
        const { styles } = this.props;

        return (
            <Query>
                {({ data }) => (
                    <>
                        <div className={styles.margin} />
                        <footer className={styles!.footer}>
                            <ul className={styles!.columns}>
                                {data.columns.map((column, i) => (
                                    <Column key={i} {...column} />
                                ))}
                            </ul>
                            <div className={styles!.copyright}>
                                <p className={styles!.text}>{`© ${new Date().getFullYear()} ${site.company}`}</p>
                            </div>
                        </footer>
                    </>
                )}
            </Query>
        );
    }
}

export default Styled(defaultStyles)(Footer);
