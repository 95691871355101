/* @flow */

import { Children } from 'react';
import nodeToElement from '../dom/node-to-element';

export function map(
    html: string,
    onElement: ?() => ?React.Element<any>,
    onText: ?() => ?React.Element<any>,
    onComment: ?() => ?React.Element<any>
) {
    const result = [];
    let childNodes;

    try {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        childNodes = doc.body.childNodes;
    } catch (e) {
        console.warn(e);
        const el = document.createElement('div');
        el.innerHTML = html;
        childNodes = el.childNodes;
    }

    for (let i = 0; i < childNodes.length; i++) {
        result.push(nodeToElement(childNodes[i], onElement, onText, onComment));
    }
    return Children.toArray(result);
}
