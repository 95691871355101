import React, { useEffect } from 'react';
import classnames from 'classnames';
import { SiteContext } from 'four-nets';
import { FormattedMessage } from 'react-intl';
import styles from './search-input.scss';

const searchIcon = (
    <svg
        fill='currentColor'
        height={14}
        width={14}
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 256 256'
        xmlSpace='preserve'
    >
        <path
            d='M104.7,184.7c-44.1,0-80-35.9-80-80s35.9-80,80-80s80,35.9,80,80S148.8,184.7,104.7,184.7z M249.5,218.2l-40.1-40.1
c-8.8,3-18.6,1.7-26.4-3.9c36.5-41.1,35.1-104.2-4.3-143.6c-40.8-40.8-107.3-40.8-148.1,0s-40.8,107.3,0,148.1
C70,218,133.1,219.5,174.1,183c5.6,7.8,6.9,17.6,3.9,26.4l40.1,40.1c8.6,8.7,22.7,8.7,31.3,0C258.2,240.9,258.2,226.8,249.5,218.2z'
        />
    </svg>
);

const loadScript = (attrsObj: any) => {
    const script = document.createElement('script');
    for (const key of Object.keys(attrsObj)) {
        script.setAttribute(key, attrsObj[key]);
    }

    document.head.appendChild(script);

    return new Promise((resolve, reject) => {
        script.onload = () => {
            resolve(script);
        };
    });
};

const loadScripts = (attrsObjects: any) => {
    return Promise.all(attrsObjects.map((attrObject: any) => loadScript(attrObject)));
};

type SearchInputProps = {
    isRedirectToMainSearch?: boolean;
    placeholder?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, isRedirectToMainSearch = null }) => {
    const site = React.useContext(SiteContext);
    const serverId = site.id;

    const bgColor = site.isWedding ? 'bg-primary-300' : site.isGarden ? 'bg-gray-100' : 'bg-primary-800';
    const textColor = site.isWedding
        ? 'text-[#222425] group-focus-within:text-gray-700 placeholder:text-gray-600 group-focus-within:placeholder:text-gray-700'
        : site.isGarden
        ? 'text-gray-500 placeholder:text-gray-500 group-focus-within:placeholder:text-gray-700'
        : 'text-white group-focus-within:text-gray-100 placeholder:text-white group-focus-within:placeholder:text-gray-300';
    const iconColor = site.isWedding ? 'text-[#1d8e8e]' : site.isGarden ? 'text-gray-600' : 'text-white';

    useEffect(() => {
        if (!isRedirectToMainSearch) {
            let arr_scripts_to_load = [];

            if (typeof window.instantsearch === 'undefined') {
                arr_scripts_to_load.push({
                    src: '/django-static/search/instantsearch-4.51.1.production.min.js',
                    crossorigin: 'anonymous',
                });
            }

            if (typeof window.TypesenseInstantSearchAdapter === 'undefined') {
                arr_scripts_to_load.push({
                    src: '/django-static/search/instant-search-adapter-2.4.0.min.js',
                });
            }

            loadScripts(arr_scripts_to_load).then(() => {
                initializeSearch();
            });
        }
    }, []);

    const search = React.useRef<any>(null);
    const mainSearchDropdown = React.useRef<any>(null);
    const threadsMoreLink = React.useRef<any>(null);
    const albumsMoreLink = React.useRef<any>(null);
    const magazineMoreLink = React.useRef<any>(null);
    const closeButton = React.useRef<any>(null);

    const initializeSearch = () => {
        const typesenseInstantsearchAdapter = new window.TypesenseInstantSearchAdapter({
            server: {
                apiKey: 'yv04vmwJwiI40xOgTfWhTBni6Nxu5OhN', // Be sure to use an API key that only allows search operations
                nodes: [
                    {
                        host: site.hostname.replace('local.', 'www.'),
                        path: '/typesense',
                        port: '',
                        protocol: 'https',
                    },
                ],
                cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
            },
            collectionSpecificSearchParameters: {
                [`threads-${serverId}`]: {
                    query_by: 'title,username,category_name',
                    query_by_weight: '7,2,1',
                    per_page: site.isGarden ? 4 : 8,
                },
                [`photoblog-${serverId}`]: {
                    query_by: 'title,username',
                    query_by_weight: '7,1',
                    filter_by: 'type:=Album',
                    per_page: 2,
                },
                [`magazine-${serverId}`]: {
                    query_by: 'title,text',
                    query_by_weight: '5,1',
                    per_page: site.isGarden ? 4 : 2,
                },
            },
        });

        const searchClient = typesenseInstantsearchAdapter.searchClient;

        search.current = window.instantsearch({
            searchClient,
            indexName: `threads-${serverId}`,
            searchFunction(helper: any) {
                if (!mainSearchDropdown.current) {
                    mainSearchDropdown.current = document.getElementById('main-search-dropdown-container');
                }

                if (!closeButton.current) {
                    closeButton.current = document.getElementById('main-search-close-button');
                }

                const dropdownContainer = mainSearchDropdown.current;
                const closeBtn = closeButton.current;

                if (helper.state.query === '') {
                    dropdownContainer.style.display = 'none';
                    closeBtn.style.display = 'none';
                } else {
                    dropdownContainer.style.display = 'flex';
                    closeBtn.style.display = 'flex';
                    helper.search();
                }
            },
        });

        search.current &&
            search.current.on('render', e => {
                if (!threadsMoreLink.current) {
                    threadsMoreLink.current = document.getElementById('threads-more-results-link');
                }

                if (!albumsMoreLink.current) {
                    albumsMoreLink.current = document.getElementById('albums-more-results-link');
                }

                if (!magazineMoreLink.current) {
                    magazineMoreLink.current = document.getElementById('magazine-more-results-link');
                }

                threadsMoreLink.current.setAttribute(
                    'href',
                    encodeURI(`/search?threads-${serverId}[query]=${search.current.helper.state.query}`)
                );

                albumsMoreLink.current.setAttribute(
                    'href',
                    encodeURI(`/search/photoblog/?photoblog-${serverId}[query]=${search.current.helper.state.query}`)
                );

                magazineMoreLink.current.setAttribute(
                    'href',
                    encodeURI(`/search/magazine?magazine-${serverId}[query]=${search.current.helper.state.query}`)
                );
            });

        const connectSearchBox = window.instantsearch.connectors.connectSearchBox;

        const fn = event => {
            const queryValue = event.target.value;
            const uriToRedirect = site.isGarden
                ? `/search/magazine?magazine-${serverId}[query]=${queryValue}`
                : `/search/?threads-${serverId}[query]=${queryValue}`;

            if (event.key === 'Enter') {
                window.location.assign(encodeURI(uriToRedirect));
            }
        };

        const renderSearchBox = (renderOptions, isFirstRender) => {
            const { query, refine } = renderOptions;
            const container = document.querySelector(renderOptions.widgetParams.container);

            if (isFirstRender) {
                const input = document.createElement('input');

                input.setAttribute('class', renderOptions.widgetParams.cssClasses.input);
                input.setAttribute('placeholder', renderOptions.widgetParams.placeholder);

                input.addEventListener('keydown', fn);

                input.addEventListener('input', event => {
                    refine(event.target.value);
                });

                container.appendChild(input);
            }

            container.querySelector('input').value = query;
        };

        const customSearchWidget = connectSearchBox(renderSearchBox);

        search.current.addWidgets([
            customSearchWidget({
                container: '#main-search-searchbox',
                placeholder: site.isCzech ? 'Hledejte. Začněte psát...' : 'Hľadajte. Začnite písať...',
                autofocus: false,
                showReset: false,
                showSubmit: false,
                showLoadingIndicator: true,
                cssClasses: {
                    input: `w-[200px] items-center  flex-grow p-1 pl-2 bg-transparent border-none ${textColor} placeholder:text-sm placeholder:text-gray-400 outline-none border-none `,
                },
            }),
            window.instantsearch.widgets.panel({
                cssClasses: {
                    header: 'text-gray-700 font-black text-sm mb-2',
                },
                templates: {
                    header: `💬 <a class="no-underline text-primary-700 hover:underline" href="/search" target="_blank">FÓRUM</a>`,
                },
            })(window.instantsearch.widgets.hits)({
                escapeHTML: false,
                container: '#main-search-thread-results',
                cssClasses: {
                    list: 'list-none flex flex-col gap-2 p-0 my-0 mt-2',
                    item: 'border-solid border-0 border-b last:border-b-0 border-gray-200 pb-2',
                    emptyRoot: 'text-gray-600 text-sm',
                },
                templates: {
                    empty: site.isCzech ? 'Žádné diskuse' : 'Žiadne diskusie',
                    item: (hit, { html, components }) => {
                        return `
                        <div>
                            <a class="font-semibold no-underline hover:underline text-primary-700" href="${hit.href}">
                                ${hit._highlightResult.title.value}
                            </a>
                            <div class="text-xs text-gray-500">${hit._highlightResult.category_name.value} | @${hit._highlightResult.username.value}</div>
                        </div>
                        `;
                    },
                },
            }),
            window.instantsearch.widgets
                .index({
                    indexName: `photoblog-${serverId}`,
                })
                .addWidgets([
                    window.instantsearch.widgets.panel({
                        cssClasses: {
                            header: 'text-gray-700 font-bold text-sm mb-2',
                        },
                        templates: {
                            header: `<span class="-mt-1 mr-0.5">📷</span><a class="no-underline text-primary-700 hover:underline" href="/search/photoblog/" target="_blank">${
                                site.isCzech ? 'ALBA A FOTKY' : 'ALBUMY A FOTKY'
                            }</a>`,
                        },
                    })(window.instantsearch.widgets.hits)({
                        escapeHTML: false,
                        container: '#main-search-photoblog-results',
                        cssClasses: {
                            list: 'grid grid-cols-2 gap-2 m-0 p-0 list-none',
                            emptyRoot: 'text-gray-600 text-sm',
                        },
                        templates: {
                            empty: site.isCzech ? 'Žádná alba ani fotky' : 'Žiadne albumy ani fotky',
                            item: (hit, { html, components }) => {
                                return `
                                <div class="flex flex-col gap-2 flex-grow-0">
                                    <a href="${hit.href}" target="_blank" class="relative no-underline">
                                        <img
                                            class="rounded-md object-cover object-center"
                                            src="${hit.img}"
                                            style="width: 100%; height: 150px;"
                                        >
                                        <div
                                            class="absolute right-0 bottom-1 text-white text-xs px-2"
                                            style="background-color: rgba(0,0,0,0.6)"
                                        >
                                            @${hit._highlightResult.username.value}
                                        </div>
                                    </a>
                                    <div class="text-xs text-gray-500">${hit._snippetResult.title.value}</div>
                                </div>`;
                            },
                        },
                    }),
                ]),
            ,
            window.instantsearch.widgets
                .index({
                    indexName: `magazine-${serverId}`,
                })
                .addWidgets([
                    window.instantsearch.widgets.panel({
                        cssClasses: {
                            header: 'text-gray-700 font-bold text-sm mb-2',
                        },
                        templates: {
                            header: '✍️ <a class="no-underline text-primary-700 hover:underline" href="/search/magazine" target="_blank">MAGAZÍN</a>',
                        },
                    })(window.instantsearch.widgets.hits)({
                        escapeHTML: false,
                        container: '#main-search-magazine-results',
                        cssClasses: {
                            list: 'grid grid-cols-2 gap-2 m-0 p-0 list-none',
                            emptyRoot: 'text-gray-600 text-sm',
                        },
                        templates: {
                            empty: site.isCzech ? 'Žádné články' : 'Žiadne články',
                            item: (hit, { html, components }) => {
                                return `
                                <a href="${hit.link}" target="_blank" class="no-underline flex flex-col gap-2 flex-grow-0">
                                    <img
                                        class="rounded-md object-cover object-center"
                                        src="${hit.featured_photo_url}"
                                        style="width: 100%; height: 150px;"
                                    >
                                    <div class="text-xs text-gray-500">...${hit._snippetResult.title.value}...</div>
                                </a>`;
                            },
                        },
                    }),
                ]),
        ]);
        search.current.start();
    };

    const focusedIconColor = site.isWedding
        ? 'group-focus-within:text-white group-focus-within:bg-[#1d8e8e]'
        : site.isGarden
        ? 'group-focus-within:text-white group-focus-within:bg-primary-600'
        : 'group-focus-within:bg-primary-500 group-focus-within:bg-primary-600';

    const closeButtonClickHandler = () => {
        mainSearchDropdown.current.style.display = 'none';
        closeButton.current.style.display = 'none';
    };

    if (isRedirectToMainSearch) {
        return (
            <a href='/search' className={classnames(styles.searchInput, 'my-4 no-underline')}>
                <span className={styles.iconWrapper}>{searchIcon}</span>
                <FormattedMessage id='SEARCH_INPUT_PLACEHOLDER'>
                    {message => (
                        <React.Fragment>
                            <label className={classnames(styles.label, 'no-underline placeholder:no-underline')}>
                                {message}
                            </label>
                            <input
                                type='text'
                                className={classnames(styles.input, 'no-underline')}
                                placeholder={placeholder || (message as string)}
                            />
                        </React.Fragment>
                    )}
                </FormattedMessage>
            </a>
        );
    }

    let innerColumns = (
        <>
            <div className='row-span-2 flex flex-col gap-4'>
                <div id='main-search-thread-results'></div>
                <div className='flex justify-center'>
                    <a
                        id='threads-more-results-link'
                        href='/search'
                        target='_blank'
                        className='px-8 py-2 rounded-sm text-sm font-bold bg-primary-600 hover:bg-primary-500 transition-colors duration-300 text-white no-underline'
                    >
                        {site.isCzech ? 'Zobrazit další diskuse' : 'Zobraziť ďalšie diskusie'}
                    </a>
                </div>
            </div>
            <div className='col-start-2 flex flex-col gap-4'>
                <div id='main-search-photoblog-results'></div>
                <div className='flex justify-center'>
                    <a
                        id='albums-more-results-link'
                        href='/search'
                        target='_blank'
                        className='px-8 py-2 rounded-sm text-sm font-bold bg-primary-600 hover:bg-primary-500 transition-colors duration-300 text-white no-underline'
                    >
                        {site.isCzech ? 'Zobrazit další alba' : 'Zobraziť ďalšie albumy'}
                    </a>
                </div>
            </div>
            <div className='col-start-2 flex flex-col gap-4'>
                <div id='main-search-magazine-results'></div>
                <div className='flex justify-center'>
                    <a
                        id='magazine-more-results-link'
                        href='/search'
                        target='_blank'
                        className='px-8 py-2 rounded-sm text-sm font-bold bg-primary-600 hover:bg-primary-500 transition-colors duration-300 text-white no-underline'
                    >
                        {site.isCzech ? 'Zobrazit další články' : 'Zobraziť ďalšie články'}
                    </a>
                </div>
            </div>
        </>
    );

    if (site.isGarden) {
        innerColumns = (
            <>
                <div className='flex flex-col gap-4 row-span-3'>
                    <div id='main-search-magazine-results'></div>
                    <div className='flex justify-center'>
                        <a
                            id='magazine-more-results-link'
                            href='/search'
                            target='_blank'
                            className='px-8 py-2 rounded-sm text-sm font-bold bg-primary-600 hover:bg-primary-500 transition-colors duration-300 text-white no-underline'
                        >
                            {site.isCzech ? 'Zobrazit další články' : 'Zobraziť ďalšie články'}
                        </a>
                    </div>
                </div>
                <div className='col-start-2 flex flex-col gap-4 row-span-2'>
                    <div id='main-search-thread-results'></div>
                    <div className='flex justify-center'>
                        <a
                            id='threads-more-results-link'
                            href='/search'
                            target='_blank'
                            className='px-8 py-2 rounded-sm text-sm font-bold bg-primary-600 hover:bg-primary-500 transition-colors duration-300 text-white no-underline'
                        >
                            {site.isCzech ? 'Zobrazit další diskuse' : 'Zobraziť ďalšie diskusie'}
                        </a>
                    </div>
                </div>
                <div className='col-start-2 flex flex-col gap-4'>
                    <div id='main-search-photoblog-results'></div>
                    <div className='flex justify-center'>
                        <a
                            id='albums-more-results-link'
                            href='/search'
                            target='_blank'
                            className='px-8 py-2 rounded-sm text-sm font-bold bg-primary-600 hover:bg-primary-500 transition-colors duration-300 text-white no-underline'
                        >
                            {site.isCzech ? 'Zobrazit další alba' : 'Zobraziť ďalšie albumy'}
                        </a>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className='relative rounded-md' style={{ minWidth: 200 }}>
            <div className='group'>
                <div className={`px-2 py-1 gap-2 flex items-center ${bgColor}`}>
                    <div className={`p-2 flex justify-center items-center rounded-sm ${iconColor} ${focusedIconColor}`}>
                        {searchIcon}
                    </div>
                    <div id='main-search-searchbox' className='w-full flex items-center'></div>
                </div>
            </div>
            <div
                id='main-search-close-button'
                className='bg-white absolute shadow-md rounded-full -right-12 w-10 h-10 flex items-center justify-center font-bold text-gray-600 select-none cursor-pointer'
                style={{ top: 'calc(100% + 10px)', display: 'none' }}
                onClick={closeButtonClickHandler}
            >
                X
            </div>
            <div
                id='main-search-dropdown-container'
                className='absolute z-50 right-0 bg-white shadow-lg rounded-lg p-6 flex flex-col gap-4'
                style={{
                    display: 'none',
                    top: 'calc(100% + 10px)',
                    width: 700,
                    maxWidth: 700,
                }}
            >
                <div className='grid grid-cols-2 grid-rows-2 gap-4'>{innerColumns}</div>
            </div>
        </div>
    );
};

export default SearchInput;
