import { action } from 'mobx';

import { InvalidJSONError, JSONOptions } from 'lib/api/api-store';
import { RestApiStore } from 'lib/api/rest-api-store';
import { ApiItem } from 'lib/api/api-item';
import { PagedResult } from './paged-result/paged-result';
import { OffsetResult } from './offset-result/offset-result';
import PAGED_TYPE from './paged-type';

export class ApiStore<T: ApiItem> extends RestApiStore<T> {
    @action onPagedJSON(options: { json: Object, jsonOptions?: JSONOptions }): Promise<T[]> {
        const { json, ...rest } = options;
        const { next, previous, count, results } = json;

        return new PagedResult({
            store: this,
            next,
            previous,
            count,
            results: results.map(j => this.onJSON({ ...rest, json: j })),
        });
    }

    @action onOffsetJson(options: { json: Object, jsonOptions?: JSONOptions }): Promise<T[]> {
        const { json, ...rest } = options;
        const { next, count, results } = json;

        return new OffsetResult({
            store: this,
            next,
            count,
            results: results.map(j =>
                this.onJSON({
                    ...rest,
                    json: j,
                })
            ),
        });
    }

    processOffsetJSON(options: { json: Object, jsonOptions?: JSONOptions }) {
        return this.processPagedJSON({
            ...options,
            pagedType: PAGED_TYPE.OFFSET,
        });
    }

    processPagedJSON(options: {
        json: Object,
        jsonOptions?: JSONOptions,
        pagedType?: string,
    }): Promise<PagedResult<T>> {
        const { json, pagedType, ...rest } = options;

        if (!json) {
            throw new InvalidJSONError();
        }

        switch (pagedType) {
            case PAGED_TYPE.OFFSET:
                return this.onOffsetJson({
                    ...rest,
                    json,
                });
            case PAGED_TYPE.PAGED:
            default:
                return this.onPagedJSON({
                    ...rest,
                    json,
                });
        }
    }

    processJSONArray(options: { json: ?(Object[]), jsonOptions?: JSONOptions, paged: boolean }) {
        const { paged = true, ...rest } = options;

        return paged ? this.processPagedJSON(rest) : super.processJSONArray(rest);
    }

    loadAll(options: { request: Request, jsonOptions?: JSONOptions, page?: number }): Promise<T[]> {
        const { request, page, ...rest } = options;

        if (page) {
            request.query({
                page,
            });
        }

        return super.loadAll({
            ...rest,
            request,
        });
    }
}

export default ApiStore;
