import decorator from 'lib/lang/decorator';
import { NotFoundError } from 'four-nets/errors/errors';
import { observable } from 'mobx';

export default decorator(
    (config: { path: string | Function; cached: boolean; jsonOptions?: JSONOptions } = {}) => ApiStore => {
        class SlugableApiStore extends ApiStore {
            @observable slug = observable.map({});

            clear() {
                // super.clear();
                this.slug.clear();
            }

            loadAll(...args: any[]) {
                return super.loadAll(...args);
            }

            loadSlug(options: { slug: string }) {
                const { slug, ...rest } = options;

                const { path, jsonOptions } = config;

                const request = this.api
                    .get({
                        path: path instanceof Function ? path(this) : path,
                    })
                    .query({
                        slug,
                    });

                return request
                    .json()
                    .then(result =>
                        this.processPagedJSON({
                            jsonOptions,
                            ...rest,
                            ...result,
                        })
                    )
                    .then(result => {
                        if (result.results.length) {
                            const item = result.results[0];
                            return item;
                        }
                        throw new NotFoundError();
                    });
            }
        }

        return SlugableApiStore;
    }
);
