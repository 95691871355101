import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled';

import Avatar from 'four-nets/avatar/avatar';
import { ViewerContext } from 'four-nets/user/viewer/viewer';
import { displayedName } from 'four-nets/user/utils';

import defaultStyles from './logged-in-status-view.scss';


interface Props {
    styles: typeof defaultStyles;
}

const LoggedInStatusView: React.FC<Props> = props => {
    const { styles } = props;
    const { viewer } = useContext(ViewerContext);
    if (!viewer) {
        return null;
    }
    const link = `/${viewer && viewer.profile && viewer.profile.vendor ? 'catalog/vendor' : 'blog'}/${
        viewer.username
    }/`;

    return (
        <div className={styles.loggedInStatus}>
            <span>
                <Avatar style={{avatar: styles.avatar}} user={viewer} />
            </span>
            <Link to={link} className={styles.username}>
                {displayedName(viewer)}
            </Link>
            {viewer.profile && viewer.profile.vendor ? <div className={styles.pro}>PRO</div> : null}
        </div>
    );
};

export default Styled(defaultStyles)(LoggedInStatusView);
