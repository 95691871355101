import decorator from 'lib/lang/decorator';

export default decorator(({propName = 'innerRef'} = {}) => Component => {
    class InnerRefProvider extends Component {
        componentDidMount(...args) {
            if (super.componentDidMount) {
                super.componentDidMount(...args);
            }

            if (this.props[propName]) {
                this.props[propName](this);
            }
        }

        componentWillUnmount(...args) {
            if (super.componentWillUnmount) {
                super.componentWillUnmount(...args);
            }

            if (this.props[propName]) {
                this.props[propName](null);
            }
        }
    }

    return InnerRefProvider;
});
