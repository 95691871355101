import React from 'react';
import Styled from 'styled';

import ClickAwayDetectableView from 'lib/ui/click-away-detectable-view';

import EmojiSelectorView from './emoji-selector';

import defaultStyles from './emoji-selector-container.scss';

interface Props {
    styles: typeof defaultStyles;
    onClickAway: () => void;
    onEmojiSelect: (_: any, emoji: string) => void;
}

export { EmojiSelectorContainer };

@ClickAwayDetectableView()
class EmojiSelectorContainer extends React.PureComponent<Props> {
    onClickAway: () => void;

    constructor(props: Props) {
        super(props);
        this.onClickAway = props.onClickAway;
    }

    render() {
        const { styles, onEmojiSelect } = this.props;

        return (
            <div className={styles.emojiWindow}>
                <div className={styles.emojiArrow} />
                <div className={styles.emojiSelectorDiv}>
                    <EmojiSelectorView onClick={onEmojiSelect} />
                </div>
            </div>
        );
    }
}

export default Styled(defaultStyles)(EmojiSelectorContainer);
