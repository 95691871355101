import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import { getApolloContext } from '@apollo/client/react/context';

const ApolloContext = getApolloContext();

export type Fetch<D extends {}, P> = (
    setData: <T extends Partial<D>>(data: T) => Partial<D> & T,
    props: P
) => Promise<any>;

export type Render<D extends {}> = (props: State<D>) => React.ReactNode;

export type Transform<D extends {}, S extends { [K in keyof D]: any }, P> = (data: S, props: P) => D;

export interface Props<D extends {}, S extends { [K in keyof D]: any }> {
    transform?: Transform<D, S, any>;
    fetch: Fetch<D, any>;
    render: Render<D>;
    serializerKey: string;
    renderPromises: any;
}

export { State as RenderProps };

interface State<D extends {}> {
    loading: boolean;
    data?: D;
    error?: Error;
}

class FetchDataUpdater<P extends { fetch: () => Promise<void> }> extends React.PureComponent<P> {
    render() {
        return null;
    }

    componentDidUpdate() {
        this.props.fetch().catch(() => null);
    }
}

class FetchData<
    P extends Props<D, S> = any,
    D extends {} = any,
    S extends { [K in keyof D]: any } = any
> extends React.PureComponent<P, State<D>> {
    static contextTypes = {
        serializer: PropTypes.object,
    };

    promise?: Promise<void>;
    dataContainer: any;
    query: string;
    queryOptions: any;

    constructor(props: P, context: any) {
        super(props, context);
        this.query = this.props.serializerKey;

        this.queryOptions = {
            query: this.query,
            variables: '__FETCHDATA__',
        };

        if (props.renderPromises) {
            this.dataContainer = props.renderPromises.getSSRObservable(this.queryOptions);
        }

        if (!this.dataContainer) {
            this.dataContainer = {
                data: null,
                setData: (data: Partial<D>, merge: boolean = true) => {
                    if (!this.dataContainer.data) {
                        this.dataContainer.data = {};
                    }

                    if (merge) {
                        Object.assign(this.dataContainer.data, data);
                    } else {
                        this.dataContainer.data = data;
                    }
                },
                setError: (error: Error | null) => {
                    this.dataContainer.error = error;
                },
                error: null,
            };

            if (props.renderPromises) {
                this.props.renderPromises.registerSSRObservable(this.dataContainer, this.queryOptions);
            }
        }
        if (process.env.ENV_WEB && context.serializer) {
            const data = context.serializer[this.props.serializerKey];
            delete context.serializer[this.props.serializerKey];

            if (data) {
                if (props.transform) {
                    this.dataContainer.data = props.transform(data, this.props);
                } else {
                    this.dataContainer.data = data;
                }
            }
        } else if (this.dataContainer.data) {
            context.serializer[this.props.serializerKey] = this.dataContainer.data;
        } else if (context.serializer && context.serializer[this.props.serializerKey]) {
            this.dataContainer.data = context.serializer[this.props.serializerKey];
        }

        this.state = {
            loading: this.dataContainer.data == null,
            data: this.dataContainer.data,
            error: this.dataContainer.error,
        };
    }

    getOptions = () => {
        return this.queryOptions;
    };

    componentDidMount() {
        if (!this.dataContainer.data && !this.dataContainer.error) {
            this.fetchData().catch(err => console.error(err.stack));
        }
    }

    cancel() {
        if (this.promise && this.promise.cancel) {
            this.promise.cancel();
        }
    }

    fetchData = () => {
        const { setData, setError } = this.dataContainer;

        this.cancel();

        setError(null);
        this.setState({ loading: true, error: undefined });

        const promise = this.props
            .fetch(setData, this.props as P)
            .then(() => {
                this.setState({ loading: false, data: this.dataContainer.data as D });
            })
            .catch((error: Error) => {
                setError(error);
                this.setState({ error, loading: false });
            });

        this.promise = promise;
        return promise;
    };

    componentWillUnmount() {
        this.cancel();
    }

    render() {
        const finish = () => (
            <React.Fragment>
                <FetchDataUpdater {...this.props} fetch={this.fetchData} />
                {this.props.render(this.state)}
            </React.Fragment>
        );

        if (this.dataContainer.data) {
            return finish();
        }

        if (this.props.renderPromises) {
            return this.props.renderPromises.addQueryPromise(this, finish);
        }
        return finish();
    }
}

export default props => {
    return (
        <ApolloContext.Consumer>
            {context => <FetchData {...props} renderPromises={context.renderPromises} />}
        </ApolloContext.Consumer>
    );
};
