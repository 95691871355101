import escapeRegexp from 'escape-string-regexp';

const ASCII_MAPPING = {
    ':|': ':neutral_face:',
    ':-|': ':neutral_face:',
    ']-(': ':angry:',
    ':-S': ':unamused:',
    '|-|': ':sleeping:',
    ':-N': ':grimacing:',
    '=-)': ':thinking:',
    '8-(': ':frowning:',
    'x-)': ':dizzy_face:',
    ':)': ':slight_smile:',
    ':-)': ':slight_smile:',
    ';-)': ':wink:',
    ';)': ':wink:',
    ':D': ':smiley:',
    ':-D': ':smiley:',
    '8-)': ':sunglasses:',
    '8)': ':sunglasses:',
    ':-(': ':disappointed:',
    ':(': ':disappointed:',
    ':-.': ':confused:',
    ':-x': ':no_mouth:',
    ';-(': ':cry:',
    ':-p': ':stuck_out_tongue:',
    ':-P': ':stuck_out_tongue:',
    ':p': ':stuck_out_tongue:',
    ':P': ':stuck_out_tongue:',
    ':-o': ':open_mouth:',
};

export function replace(text = '') {
    const output = Object.keys(ASCII_MAPPING).reduce(
        (string, key) => string.replace(new RegExp(escapeRegexp(key), 'g'), ASCII_MAPPING[key]),
        text
    );
    return output;
}

export default ASCII_MAPPING;
