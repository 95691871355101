import React from 'react';
import PropTypes from "prop-types";
import {autorun, observable, action, computed, untracked} from 'mobx';
import {loadScriptWithBackoff} from 'lib/utils/dom';
import URL from 'url-parse';


class GoogleMapsLoader extends React.PureComponent {

    static defaultProps = {
        url: 'https://maps.googleapis.com/maps/api/js',
    };

    getChildContext() {
        return {
            googleMapsLoader: this,
        };
    }

    @computed get src() {
        const url = new URL(this.url);
        const query = Object.assign({}, this.query.toJS(), {
            callback: 'googleMapsDidLoad',
        });

        url.set('query', query);

        return url.toString();
    }

    @computed get requested() {
        return this.requests > 0;
    }

    @observable url: string;
    @observable query = observable.map({});

    @observable loaded = false;
    @observable loading = false;
    @observable error;

    @observable requests = 0;

    promise: ?Promise<any>;
    disposer: ?Function;

    @action request() {
        this.requests += 1;
    }

    @action unrequest() {
        this.requests -= 1;
    }

    @action handleProps(props = this.props) {
        const {url, query} = props;

        this.url = url;
        this.query.merge(query);
    }

    UNSAFE_componentWillMount() {
        this.handleProps();
    }

    componentWillReceiveProps(props) {
        this.handleProps(props);
    }

    @action loadDidSucceed = () => {
        this.loaded = true;
        this.loading = false;
    }

    @action loadDidFail = (event) => {
        this.loading = false;
        this.error = event;
    }

    @action load = () => {
        this.error = null;
        this.loaded = false;

        const scripts = document.getElementsByTagName("script");

        const url = new URL(this.src);

        for (let i = 0; i < scripts.length; i++) {
            const script = scripts[i];

            const srcUrl = new URL(script.src);

            if (srcUrl.hostname === url.hostname) {
                if (script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            }
        }

        if (window.google && window.google.maps) {
            delete window.google.maps;
        }

        this.loading = true;
        window.googleMapsDidLoad = this.loadDidSucceed;

        this.promise = loadScriptWithBackoff({
            src: this.src,
        })
        .catch(this.loadDidFail);
    }

    retry = () => {
        if (this.error && !this.loading) {
            this.load();
        }
    }

    componentDidMount() {
        this.disposer = autorun(() => {
            if (!this.requested && untracked(() => this.loading) &&
                this.promise && this.promise.isPending()) {

                this.promise.cancel();
                this.loading = false;
            }

            if (this.requested && this.src) {
                this.load();
            }
        });
    }

    componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }
        if (this.promise && this.promise.isPending()) {
            this.promise.cancel();
        }

    }

    render() {
        return React.Children.only(this.props.children);
    }

}

GoogleMapsLoader.childContextTypes = {
    googleMapsLoader: PropTypes.instanceOf(GoogleMapsLoader),
};

export default GoogleMapsLoader;
