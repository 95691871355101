import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Route from 'react-router/route';
import Helmet from 'helmet/helmet';

import SectionCountsProvider from 'lib/section-count-context/section-count-context';
import Styled from 'styled';

import { Branding, ZoznamLeaderboard, ZahradaBranding } from 'lib/google/dfp/ad-units/branding';
import { Skyscraper, ZoznamSkyscraper, ZahradaSkyscraper } from 'lib/google/dfp/ad-units/skyscraper';
import {
    Interscroller,
    InterscrollerContext,
    ZahradaInterscroller,
    ZoznamInterscroller,
} from 'lib/google/dfp/ad-units/interscroller';
import { Billboard, ZoznamBillboard, ZahradaBillboard } from 'lib/google/dfp/ad-units/billboard';

import LoadingWrapperView from 'four-nets/ui/loaders/loading-wrapper-view';
import { Site } from 'four-nets/site';

import UnpaidBanner from './vendor/unpaid-banner/unpaid-banner';
import Footer from './footer/footer';
import HeaderView, { HeaderViewModel } from './header/header-view';

import { withRouter } from 'react-router';

import defaultStyles from './main-wrapper-view.scss';

export class MainWrapperViewModel {
    headerViewModel: HeaderViewModel;
    footerViewModel: FooterViewModel;

    @observable hidden = false;

    constructor(options: { userStore: UserStore, urlBuilder: UrlBuilder, inbox: MailBox, site: Site, intl: any }) {
        const { userStore, urlBuilder, site, intl } = options;

        this.site = site;
        this.userStore = userStore;

        this.headerViewModel = new HeaderViewModel({
            userStore,
            urlBuilder,
            site,
            intl,
        });

        this.intl = intl;
    }

    setHidden({ hidden }: { hidden: boolean }) {
        this.hidden = hidden;
    }
}

export const BrandingContext = React.createContext(false);

@Styled(defaultStyles)
@observer
class MainWrapperView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandingEmpty: true,
            brandingIsMegaboard: false,
            megaboardHeight: null,
            interscrollerContext: {
                offsetTop: null,
                isActive: true,
                setOffsetTop: this.setInterscrollerOffset,
                setActive: this.setInterscrollerActive,
                updateOffsetTop: null,
                setUpdateOffsetTopFn: this.setUpdateOffsetTopFn,
            },
            trebaSkrytBranding: false,
        };
    }

    onBradningRender = event => {
        const { site } = this.props.mainWrapperViewModel;

        if (
            site.isSlovak &&
            site.isLiving &&
            this.props.location.pathname.includes('dni-s-heluz') &&
            event.campaignId !== 2972521141
        ) {
            this.setState({
                trebaSkrytBranding: true,
            });
        }

        const brandingIsMegaboard = event.size && event.size[0] < 1470;

        let height = null;
        if (brandingIsMegaboard) {
            height = 120 + event.size[1];
        }

        this.setState({
            brandingEmpty: event.isEmpty,
            brandingIsMegaboard,
            height,
        });
    };

    setInterscrollerOffset = offset => {
        this.setState(prevState => {
            return {
                interscrollerContext: {
                    ...prevState.interscrollerContext,
                    offsetTop: offset,
                },
            };
        });
    };

    setInterscrollerActive = active => {
        this.setState(prevState => {
            return {
                interscrollerContext: {
                    ...prevState.interscrollerContext,
                    isActive: active,
                },
            };
        });
    };

    setUpdateOffsetTopFn = fn => {
        this.setState(prevState => {
            return {
                interscrollerContext: {
                    ...prevState.interscrollerContext,
                    updateOffsetTop: fn,
                },
            };
        });
    };

    render() {
        const { mainWrapperViewModel, styles } = this.props;
        const { hidden, site } = mainWrapperViewModel;

        const children = (
            <SectionCountsProvider>
                <header>
                    <HeaderView headerViewModel={mainWrapperViewModel.headerViewModel} />
                </header>
                <div className={styles.branding}>
                    {(() => {
                        if (this.state.trebaSkrytBranding) {
                            return;
                        }

                        if (site.isGarden) {
                            return;
                        }

                        if (site.isSlovak && site.isWedding) {
                            return <ZoznamLeaderboard slotRenderEndedCb={this.onBradningRender} />;
                        }

                        return <Branding slotRenderEndedCb={this.onBradningRender} />;
                    })()}
                </div>
                <div
                    style={{ height: this.state.height }}
                    className={classnames(styles.brandingPlaceholder, {
                        [styles.brandingEmpty]: this.state.brandingEmpty,
                    })}
                />
                <div className={classnames(styles.children, { [styles.brandingEmpty]: this.state.brandingEmpty })}>
                    <InterscrollerContext.Provider value={this.state.interscrollerContext}>
                        <LoadingWrapperView loading={!this.props.children}>
                            <BrandingContext.Provider value={!this.state.brandingEmpty}>
                                <div className={styles.childrenInnerWrapper}>
                                    <UnpaidBanner />
                                    {this.props.children}

                                    {(() => {
                                        if (site.isWedding && site.isSlovak) {
                                            return <ZoznamBillboard wrapperStyle={{ paddingTop: 20 }} />;
                                        }
                                        if (site.isGarden) {
                                            return <ZahradaBillboard wrapperStyle={{ paddingTop: 20 }} />;
                                        }

                                        return <Billboard wrapperStyle={{ paddingTop: 20 }} />;
                                    })()}
                                </div>
                                {this.state.brandingEmpty || this.state.brandingIsMegaboard ? (
                                    <div className={styles.skyscraperWrapper}>
                                        <div className={styles.skyscraper}>
                                            {(() => {
                                                if (site.isWedding && site.isSlovak) {
                                                    return <ZoznamSkyscraper />;
                                                }

                                                if (site.isGarden) {
                                                    return <ZahradaSkyscraper />;
                                                }

                                                return <Skyscraper />;
                                            })()}
                                        </div>
                                    </div>
                                ) : null}
                            </BrandingContext.Provider>
                        </LoadingWrapperView>
                        {(() => {
                            if (site.isWedding && site.isSlovak) {
                                return <ZoznamInterscroller />;
                            }

                            if (site.isGarden) {
                                return <ZahradaInterscroller />;
                            }

                            return <Interscroller />;
                        })()}
                    </InterscrollerContext.Provider>
                </div>
                <Footer />
                <noscript className={styles.noscript}>
                    <div className={styles.noscriptWarning}>
                        Upozornenie: V nastaveniach svojho prehliadača si zapni JavaScript. Bez toho stránka nebude
                        fungovať.
                    </div>
                </noscript>
            </SectionCountsProvider>
        );

        return (
            <Route>
                {({ location }) => (
                    <div className={styles.mainWrapper}>
                        {hidden || location.pathname.startsWith('/url-shortener') ? (
                            <LoadingWrapperView
                                loaderWrapperStyle={{
                                    position: 'fixed',
                                }}
                                loading={!this.props.children}
                            >
                                {this.props.children}
                            </LoadingWrapperView>
                        ) : (
                            <>{children}</>
                        )}
                    </div>
                )}
            </Route>
        );
    }
}

export default withRouter(MainWrapperView);
