import React from 'react';
import AdUnitWithRoute, { AdUnitProps } from '../google-dfp';

export const Billboard = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [0, 0],
            ['fluid', [320, 150]],
        ],
        [
            [1024, 0],
            ['fluid', [970, 250]],
        ],
    ];
    return (
        <AdUnitWithRoute
            adUnitCode='billboard'
            sizes={['fluid', [320, 150], [970, 250]]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};

export const ZahradaBillboard = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [0, 0],
            [
                [300, 300],
                [320, 150],
                [320, 100],
                [300, 100],
                [300, 250],
            ],
        ],
        [
            [1024, 0],
            [
                [970, 90],
                [970, 250],
                [1100, 250],
                [1100, 200],
                [1100, 100],
            ],
        ],
    ];

    return (
        <AdUnitWithRoute
            overrideAdUnitCode
            adUnitCode='/1023789/zahrada-billboard'
            divAdId='div-gpt-ad-1634133437466-0'
            sizes={[
                [300, 300],
                [970, 90],
                [970, 250],
                [320, 150],
                [320, 100],
                [1100, 250],
                [300, 100],
                [1100, 200],
                [300, 250],
                [1100, 100],
            ]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};

export const ZoznamBillboard = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [0, 0],
            ['fluid', [320, 150]],
        ],
        [
            [1024, 0],
            ['fluid', [970, 250]],
        ],
    ];

    return (
        <AdUnitWithRoute
            adUnitCode='/60012913/Mojasvadba-billboard'
            overrideAdUnitCode={true}
            sizes={[[970, 250], [970, 90], [300, 100], 'fluid', [300, 250], [300, 300], [320, 50]]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};
