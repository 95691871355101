import React from 'react';

import Styled from 'styled';
import Img from 'lib/images/responsive-image-view';

import RouteLinkView from 'lib/ui/route-link-view';

import { FooterChild } from '../child';
import defaultStyles from './image.scss';

export interface FooterImageChild extends FooterChild {
    href?: string;
    target?: string;
    image: {
        src: string;
        srcSet?: string;
        sizes?: string;
    };
    title?: string;
    rel?: string;
    width?: number;
}

export interface Props extends FooterImageChild {
    styles?: typeof defaultStyles;
}

class Image extends React.PureComponent<Props> {
    render() {
        const { href, target, title, image, rel, styles, width } = this.props;

        return (
            <RouteLinkView href={href} target={target} title={title} className={styles!.image} rel={rel}>
                <Img
                    linkify={false}
                    alt={title}
                    fill={true}
                    image={image}
                    noBackground={true}
                    noPlaceholder={true}
                    width={width}
                />
            </RouteLinkView>
        );
    }
}

export default Styled(defaultStyles)(Image);
