import {createElement, Children} from 'react';
import * as TYPE from 'dom-node-types';
import attributesToProps from '../attributes-to-props';

function attributes(element: HTMLElement) {
    const result = {};

    for (let i = 0; i < element.attributes.length; i++) {
        const attribute = element.attributes[i];

        result[attribute.name] = attribute.value;
    }

    return result;
}

export default function nodeToElement(node: Node, onElement, onText, onComment) {
    switch (node.nodeType) {
        case TYPE.ELEMENT_NODE: {
            const props = attributesToProps(attributes(node));
            const name = node.nodeName.toLowerCase();

            let children;
            const length = node.childNodes.length;

            if (length) {
                const elementChildren = [];

                for (let i = 0; i < length; i++) {
                    elementChildren.push(
                        nodeToElement(node.childNodes[i], onElement, onText, onComment),
                    );
                }

                children = Children.toArray(elementChildren);
            }

            if (onElement) {
                const element = onElement(name, props, children);
                if (element !== undefined) {
                    return element;
                }
            }

            return createElement(
                name,
                props,
                children,
            );
        }
        case TYPE.TEXT_NODE: {
            const text = node.nodeValue;

            if (onText) {
                const element = onText(text);

                if (element !== undefined) {
                    return element;
                }
            }

            return text;
        }
        case TYPE.COMMENT_NODE: {
            const comment = node.nodeValue;

            if (onComment) {
                const element = onComment(comment);

                if (element !== undefined) {
                    return element;
                }
            }

            return null;
        }
        default:
            return null;
    }
}
