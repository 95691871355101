import React from 'react';

export enum SiteSection {
    FORUM = 'SECTION_FORUM',
    BLOGS = 'SECTION_BLOGS',
    MARKET = 'SECTION_MARKET',
    CATALOG = 'SECTION_CATALOG',
}

type SectionCounts = { [section in SiteSection]: number };

interface SectionCountsContextType {
    sectionCounts: SectionCounts;
    updateSectionCount: (section: SiteSection) => void;
}

export const SectionCountsContext = React.createContext<SectionCountsContextType>({
    sectionCounts: {
        [SiteSection.FORUM]: 0,
        [SiteSection.BLOGS]: 0,
        [SiteSection.MARKET]: 0,
        [SiteSection.CATALOG]: 0,
    },
    updateSectionCount: () => {
        /* pass */
    },
});

export const SectionCountConsumer = SectionCountsContext.Consumer;

class SectionCountsProvider extends React.Component<{}, SectionCounts> {
    state: SectionCounts = {
        [SiteSection.FORUM]: 0,
        [SiteSection.BLOGS]: 0,
        [SiteSection.MARKET]: 0,
        [SiteSection.CATALOG]: 0,
    };

    updateSectionCount = (section: SiteSection) => {
        if (section === SiteSection.FORUM) {
            this.setState((prevState: SectionCounts) => ({
                [SiteSection.FORUM]: prevState[SiteSection.FORUM] + 1,
            }));
        } else if (section === SiteSection.BLOGS) {
            this.setState((prevState: SectionCounts) => ({
                [SiteSection.BLOGS]: prevState[SiteSection.BLOGS] + 1,
            }));
        } else if (section === SiteSection.MARKET) {
            this.setState((prevState: SectionCounts) => ({
                [SiteSection.MARKET]: prevState[SiteSection.MARKET] + 1,
            }));
        } else if (section === SiteSection.CATALOG) {
            this.setState((prevState: SectionCounts) => ({
                [SiteSection.CATALOG]: prevState[SiteSection.CATALOG] + 1,
            }));
        }
    };

    render() {
        return (
            <SectionCountsContext.Provider
                value={{ sectionCounts: this.state, updateSectionCount: this.updateSectionCount }}
            >
                {this.props.children}
            </SectionCountsContext.Provider>
        );
    }
}

export default SectionCountsProvider;
