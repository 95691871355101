import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Styled from 'styled';
import { observer } from 'mobx-react';

import Localizable from 'lib/intl/localizable';
import SVGIcon from 'lib/images/svg-pure-icon/svg-pure-icon';
import { Field } from 'lib/form/form';

import { insertEmojiToText } from 'four-nets/ui-v2/emoji-selector/insert-emoji-to-text';
import IconSmile from 'four-nets/icons/svg/icon_smile.svg';
import EmojiSelectorContainer from 'four-nets/ui-v2/emoji-selector/emoji-selector-container';

import defaultStyles from './text-area.scss';

const MIN_HEIGHT = 190;

interface Props extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'style'> {
    styles: typeof defaultStyles;
    field?: Field;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    touched?: boolean;
    errors?: any;
    intl?: any;
    width?: number;
    emojis: boolean;
    focus?: boolean;
    className?: string;
}

interface State {
    emojiSelection: boolean;
    textAreaHeight: number;
    focused: boolean;
    selectionStart?: number;
    scrolledOnMention: boolean;
}

@Localizable()
@observer
class TextArea extends React.Component<Props, State> {
    static contextTypes = {
        scrollView: PropTypes.object,
    };

    public static defaultProps = {
        emojis: true,
    };

    state: State = { emojiSelection: false, textAreaHeight: MIN_HEIGHT, focused: false, scrolledOnMention: false };

    ref = React.createRef<HTMLTextAreaElement>();
    divRef = React.createRef<HTMLDivElement>();

    componentDidUpdate(prevProps: Props) {
        const { scrolledOnMention, focused } = this.state;
        const { focus } = this.props;
        const hasMention = /@.+/.test((this.props.field && this.props.field.data && this.props.field.data.value) || '');

        if (this.ref.current && !focused && hasMention && !scrolledOnMention) {
            this.context.scrollView.scrollToRef({
                ref: this.ref.current,
                offset: -120,
                onComplete: () => {
                    this.focus();
                    this.setState({ scrolledOnMention: true });
                },
            });
        } else if (!prevProps.focus && focus) {
            this.focus();
        }
    }

    onClickAway = () => {
        this.setState({ emojiSelection: false });
    };

    onEmojiSelect = (_: any, emoji: string) => {
        if (!this.ref.current) {
            return;
        }

        const { field, onChange } = this.props;
        const { selectionStart } = this.ref.current;

        const [newValue, insertedLen] = insertEmojiToText(this.ref.current.value, emoji, selectionStart);
        const newCursorPosition = selectionStart + (insertedLen as number);

        if (field) {
            field.data.setValue({ value: newValue });
        } else if (onChange) {
            onChange({ target: { value: newValue } } as any);
        }

        this.setState({ selectionStart: newCursorPosition });

        setTimeout(() => {
            this.focus();
            this.handleResize();
        }, 0);
    };

    toggleEmojiSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState(prevState => ({ emojiSelection: !prevState.emojiSelection }));
        if (this.ref.current) {
            this.ref.current.focus();
        }
    };

    focus = () => {
        const { ref } = this;

        if (ref.current) {
            let { selectionStart } = this.state;
            ref.current.focus();

            if (!selectionStart) {
                selectionStart = ref.current.value.length || 0;
            }

            ref.current.setSelectionRange(selectionStart, selectionStart);
            this.setState({ focused: true, selectionStart: undefined });
        }
    };

    onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const { field, onBlur } = this.props;
        this.setState({ focused: false });
        if (field) {
            field.data.onBlur(event);
        }
        if (onBlur) {
            onBlur(event);
        }
    };

    onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const { onFocus } = this.props;
        this.setState({ focused: true });
        if (onFocus) {
            onFocus(event);
        }
    };

    handleResize = () => {
        const { divRef, ref } = this;
        const { field } = this.props;

        if (divRef && divRef.current && ref) {
            let value = '';
            if (field && field.data && field.data.value) {
                value = field.data.value;
            }

            divRef.current.innerText = value;

            this.setState({
                textAreaHeight: Math.max(divRef.current.clientHeight, MIN_HEIGHT),
            });
        }
    };

    render() {
        const {
            field,
            intl: { formatMessage },
            styles,
            width,
            defaultValue,
            emojis,
            maxLength,
            className,
        } = this.props;

        const id = this.props.id || this.props.name;

        let placeholder,
            name,
            dirty,
            onChange: React.ChangeEventHandler<HTMLTextAreaElement>,
            touched,
            valid,
            validationErrors,
            value,
            visited;

        if (field) {
            ({
                name,
                placeholder,
                data: { dirty, onChange, touched, valid, validationErrors, value, visited },
            } = field);
        } else {
            ({
                onChange,
                value,
                touched,
                errors: validationErrors,
                name,
                placeholder,
            } = {
                ...this.props,
                onChange: this.props.onChange!,
            });
        }

        const showValidationErrors = field ? (visited || dirty || touched) && !valid : Boolean(validationErrors);

        return (
            <div className={styles.textAreaWrapper}>
                <div className={styles.textareaDiv} ref={this.divRef} style={{ width: width || undefined }} />
                <label htmlFor={id} className={styles.label}>
                    {placeholder || 'text'}
                </label>
                <textarea
                    id={id}
                    name={name}
                    ref={this.ref}
                    autoFocus={this.props.focus}
                    placeholder={placeholder}
                    className={classnames(styles.textArea, className, {
                        [styles.invalid]: showValidationErrors,
                    })}
                    style={{ height: this.state.textAreaHeight }}
                    value={value || ''}
                    defaultValue={defaultValue}
                    onChange={event => {
                        onChange(event);
                        this.handleResize();
                    }}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    maxLength={maxLength}
                />
                {emojis ? (
                    <div
                        className={classnames(styles.emojiAbsoluteWrapper, {
                            [styles.withErrors]: showValidationErrors,
                        })}
                    >
                        <button
                            type='button'
                            className={styles.emojiButton}
                            onClick={this.toggleEmojiSelector}
                            aria-label='Emoji Button'
                        >
                            <SVGIcon className={styles.iconSmile} src={IconSmile} />
                        </button>
                        <div className={styles.emojiRelativeWrapper}>
                            {this.state.emojiSelection ? (
                                <EmojiSelectorContainer
                                    style={{ emojiWindow: styles.emojiWindow }}
                                    onClickAway={this.onClickAway}
                                    onEmojiSelect={this.onEmojiSelect}
                                />
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {showValidationErrors &&
                    validationErrors &&
                    validationErrors.map((error: any, index: number) => {
                        if (error.intlMessage) {
                            return (
                                <p key={index.toString()} className={styles.error}>
                                    {formatMessage(error.intlMessage)}
                                </p>
                            );
                        }
                        return (
                            <p key={index.toString()} className={styles.error}>
                                {error}
                            </p>
                        );
                    })}
            </div>
        );
    }
}

export default Styled(defaultStyles)(TextArea);
