import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import Styled from 'styled';

import { Site } from 'four-nets/site';

import defaultStyles from './logo-view.scss';

interface Props {
    styles: typeof defaultStyles;
}

const LogoView: React.FC<Props> = (props, context: { site: Site }) => {
    const { styles } = props;
    const { site } = context;

    return (
        <a href='/' className={styles.logo}>
            <img className={styles.image} src={site.logoLight!} alt={site.tagline} />
        </a>
    )
};

LogoView.contextTypes = {
    site: PropTypes.instanceOf(Site),
};

export default Styled(defaultStyles)(LogoView);
