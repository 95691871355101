import withCompute from 'lib/mobx/with-compute';
import compute from 'lib/mobx/compute';

export { Link };

@withCompute()
class Link {
    @compute href: string;
    @compute label: string;
    @compute target: string;
    @compute disabled: boolean;
    @compute rel: string;
}
