export class RedirectError extends Error {
    href: string;
    code: number;

    constructor(href: string, code = 301) {
        super(`REDIRECT ${code} ${href}`);
        this.href = href;
        this.code = code;
    }
}

export class UnauthorizedError extends Error {
    code: number;

    constructor(code = 401) {
        super(`UNAUTHORIZED ${code}`);
        this.code = code;
    }
}

export class NotFoundError extends Error {
    constructor(code = 404) {
        super(`NOT FOUND ${code}`);
        this.code = code;
    }
}
