/* @flow */

import { observable, action } from 'mobx';
import computed from 'lib/mobx/computed';
import URL from 'url-parse';
import JSONSerializable from 'lib/serialization/json-serializable';
import PAGED_TYPE from '../paged-type';
import { Result } from '../result';

export class OffsetResult<T> extends Result<T> {
    @observable responseOffset: number;
    @observable limit: number;

    constructor(options: { store: ApiStore, count: number, next: ?string, results: T[], jsonOptions?: JSONOptions }) {
        super({
            ...options,
            pagedType: PAGED_TYPE.OFFSET,
        });

        this.parseAttributesFromNext();
    }

    @computed get offset(): number {
        return this.responseOffset - (this.responseCount - this.count);
    }

    @action parseAttributesFromNext = () => {
        const next = this.next;

        if (next) {
            const url = new URL(next, true);
            const { offset, limit } = url.query;

            // if next page doesnt exist, set offset to count
            this.responseOffset = parseInt(offset, 10) || this.responseCount;
            this.limit = parseInt(limit, 10) || 10;
        }
    };

    @computed get loadNext() {
        const next = this.next;

        if (!next) {
            return null;
        }

        const offset = this.offset;
        return ({ query, ...rest } = {}) => {
            const url = new URL(next, true);
            url.set('query', {
                ...url.query,
                offset,
                limit: this.limit,
                ...query,
                pager: 'offset',
            });

            this.loading = true;

            return this.store.api
                .get({ url })
                .json()
                .then(args => this.loadAllDidSucceed({ ...rest, ...args }))
                .finally(this.loadAllDidFinish);
        };
    }
}

type LoadHandler = (args: any[]) => Promise<OffsetResult<T>>;

@JSONSerializable()
class Base {}

export class OffsetResultCollection<T: { id: number }> extends Base {
    @observable items: ?(T[]);
    @observable offsetResult: ?OffsetResult<T>;

    @observable responseCount: ?number;
    @observable responseOffset: ?number;
    @observable addedCount = 0;

    @observable error: ?Error;
    @observable loading = false;

    promise: ?Promise<OffsetResult<T>>;
    loadHandler: LoadHandler;
    store: ApiStore<T>;

    constructor(load: LoadHandler, store: ApiStore<T>) {
        super();
        this.loadHandler = load;
        this.store = store;
    }

    toJSON() {
        return {
            offsetResult: this.offsetResult,
            items: this.results,
            responseCount: this.responseCount,
            responseOffset: this.responseOffset,
            addedCount: this.addedCount,
        };
    }

    fromJSON({ json }) {
        const { offsetResult, items: itemsJSON, ...rest } = json;

        this.offsetResult = offsetResult
            ? this.store.processPagedJSON({
                  json: offsetResult,
                  pagedType: PAGED_TYPE.OFFSET,
              })
            : null;

        super.fromJSON({ json: rest });

        if (itemsJSON) {
            this.items = this.store.processPlainJSONArray({ json: itemsJSON });
        }

        return this;
    }

    @computed get results(): ?(T[]) {
        if (!this.items) {
            return null;
        }

        return this.items.filter(item => !item.isDeleteInProgress && !item.isDeleted);
    }

    @computed get first(): ?Mail {
        if (this.results && this.results.length) {
            return this.results[0];
        }

        return null;
    }

    @computed get count(): ?number {
        const responseCount = this.responseCount;

        if (responseCount == null || !this.items) {
            return null;
        }

        const deleted = this.items.filter(item => item.isDeleteInProgress || item.isDeleted).length;

        return responseCount - deleted;
    }

    @computed get offset(): number {
        const { responseOffset, responseCount, count } = this;

        if (responseOffset == null || responseCount == null || count == null) {
            return 0;
        }

        let offset = responseOffset - (responseCount - count);

        offset += this.addedCount;

        return offset;
    }

    loadDidFail = (err: Error) => {
        this.error = err;
        return Promise.reject(err);
    };

    loadDidFinish = () => {
        this.loading = false;
    };

    didLoadResult = (result: OffsetResult<T>) => {
        if (!this.offsetResult || this.responseOffset == null || this.responseOffset < result.responseOffset) {
            this.offsetResult = result;
            this.responseOffset = result.responseOffset;
        }

        if (!this.offsetResult.loadNext && result.loadNext) {
            this.offsetResult = result;
        }

        this.responseCount = result.responseCount;
        this.addedCount = 0;

        return result;
    };

    load = (...args: any[]): Promise<OffsetResult<T>> => {
        if (this.promise) {
            this.promise.cancel();
        }

        const first = this.first;
        const set = new Set(this.results);

        const loadDidSucceed = result => {
            if (first) {
                let found = false;

                for (let i = 0; i < result.results.length; i++) {
                    const item = result.results[i];
                    if (item === first) {
                        found = true;
                    }
                    set.add(item);
                }

                if (found || !result.loadNext) {
                    if (this.items) {
                        this.items.replace(Array.from(set));
                    }

                    return result;
                }

                return result.loadNext(loadDidSucceed);
            }

            this.items = result.results;
            return result;
        };

        this.error = null;
        this.loading = true;

        const promise = this.loadHandler(...args)
            .then(loadDidSucceed)
            .then(this.didLoadResult)
            .catch(this.loadDidFail)
            .finally(this.loadDidFinish);

        this.promise = promise;
        return promise;
    };

    @computed get loadNext(): ?() => Promise<OffsetResult<T>> {
        const loadNext = this.offsetResult && this.offsetResult.loadNext;

        if (!loadNext) {
            return null;
        }

        if (this.count) {
            if (this.offset > this.count) {
                return null;
            }

            if (this.results && this.results.length === this.count) {
                return null;
            }
        }

        return () => {
            if (this.promise) {
                this.promise.cancel();
            }

            this.loading = true;
            this.error = null;

            const promise = loadNext({ query: { offset: this.offset } })
                .then(result => {
                    const set = new Set(this.results);

                    result.results.forEach(item => {
                        set.add(item);
                    });

                    if (this.items) {
                        this.items.replace(Array.from(set));
                    }

                    return result;
                })
                .then(this.didLoadResult)
                .catch(this.loadDidFail)
                .finally(this.loadDidFinish);

            this.promise = promise;
            return promise;
        };
    }

    loadId(id: number) {
        const find = (items: T[]): Promise<T> => {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if (item.id === id) {
                    return Promise.resolve(item);
                }
            }

            if (this.loadNext) {
                return this.loadNext().then(result => find(result.results));
            }

            return Promise.reject();
        };

        if (!this.items) {
            return this.load().then(result => find(result.results));
        }

        return find(this.items);
    }
}
