import React from 'react';
import { observer } from 'mobx-react';
import FadingCircleView from 'lib/loaders/spinkit/fading-circle-view';
import Styled from 'styled';

import Button from 'four-nets/ui/button-view';

import defaultStyles from './loading-wrapper-view.scss';
import { FormattedMessage } from 'react-intl';

export default Styled(defaultStyles)(
    observer(function LoaderWrapperView(props) {
        const { loading, error, retry, styles, children } = props;

        const classNames = [styles.loadingWrapper];

        if (children) {
            classNames.push(styles.hasContent);
        }

        if (loading) {
            classNames.push(styles.loading);
        }
        return (
            <div className={classNames.join(' ')}>
                {error ? (
                    <div key='error' className={styles.error}>
                        <p className={styles.errorMessage}>
                            <FormattedMessage id='ERROR_TRY_AGAIN' />
                        </p>
                        {retry ? (
                            <Button onClick={retry}>
                                <FormattedMessage id='BUTTON_LABEL_RETRY_FAILED' />
                            </Button>
                        ) : null}
                    </div>
                ) : (
                    <div key='content' className={styles.content}>
                        {children}
                    </div>
                )}
                {loading ? (
                    <div key='loader' className={styles.loader}>
                        <FadingCircleView />
                    </div>
                ) : null}
            </div>
        );
    })
);
