import React from 'react';
import Styled from 'styled';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { User } from 'four-nets/user/user';
import { UserFragment } from 'four-nets/photoblog/users/users-loader.graphql';

import SVGImage from 'lib/images/svg-image/svg-image';
import Image from 'lib/images/image-view';

import IconUserFemale from './icon_user-female.svg';
import IconUserMale from './icon_user-male.svg';

import defaultStyles from './avatar.scss';

interface Props {
    styles: typeof defaultStyles;
    user?: any;
    src?: string;
    alt?: string;
    width?: number;
    height?: number;
    linkify?: boolean;
}

const Avatar: React.FC<Props> = props => {
    const { styles, user, src: propSrc, alt, linkify = true } = props;

    let src = '',
        srcset = '',
        hasAvatar = false;
    const username = (user && user.username) || '';

    if (user && (user as User).avatarUrl && !/no_photo/.test((user as User).avatarUrl)) {
        src = (user as User).avatarUrl;
        hasAvatar = true;
    } else if (user && (user as User).sex != null) {
        if ((user as User).sex === 1) {
            src = IconUserFemale;
        } else {
            src = IconUserMale;
        }
    } else if (user && (user as UserFragment).profile && (user as UserFragment).profile!.avatar) {
        src = (user as UserFragment).profile!.avatar!.src;
        srcset = (user as UserFragment).profile!.avatar!.srcset!;
        hasAvatar = true;
    } else if (user && (user as UserFragment).profile) {
        if ((user as UserFragment).profile!.sex === 1) {
            src = IconUserFemale;
        } else {
            src = IconUserMale;
        }
    } else if (propSrc) {
        src = propSrc;
        hasAvatar = true;
    } else {
        src = IconUserMale;
    }

    const isVendor = () => user && ((user.profile && user.profile.isVendor) || user.vendor);

    const userLink = () => {
        return username ? (isVendor() ? `/catalog/vendor/${username}/` : `/blog/${username}/`) : '';
    };

    if (linkify) {
        return hasAvatar ? (
            <Link to={userLink()} className={styles.avatar}>
                <Image
                    src={src}
                    srcSet={srcset}
                    sizes={{ xs: 50, lg: 80 }}
                    aspectFill={true}
                    aspectRatio={1}
                    linkify={false}
                />
            </Link>
        ) : (
            <Link to={userLink()} className={styles.link}>
                <SVGImage
                    style={{ image: styles.svg }}
                    src={src}
                    alt={alt || `Avatar ${username}`}
                    className={classnames(styles.avatar, styles.whiteBg)}
                />
            </Link>
        );
    } else {
        return hasAvatar ? (
            <span className={styles.avatar}>
                <Image
                    src={src}
                    srcSet={srcset}
                    sizes={{ xs: 50, lg: 80 }}
                    aspectFill={true}
                    aspectRatio={1}
                    linkify={false}
                />
            </span>
        ) : (
            <span className={styles.link}>
                <SVGImage
                    style={{ image: styles.svg }}
                    src={src}
                    alt={alt || `Avatar ${username}`}
                    className={classnames(styles.avatar, styles.whiteBg)}
                />
            </span>
        );
    }
};

export default Styled(defaultStyles)(Avatar);
