import React from 'react';
import { FormattedMessage } from 'react-intl';

const GARDEN_CONFIG = new Map([
    [
        { label: 'Fórum' },
        [
            { label: <FormattedMessage id='FORUM_CATEGORIES' />, to: '/forum/' },
            { label: <FormattedMessage id='FORUM_LATEST_DISCUSSIONS' />, to: '/forum/latest-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_IS_DISCUSSED' />, to: '/forum/updated-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_I_POSTED_TITLE' />, to: '/forum/where-i-have-posted/' },
            { label: <FormattedMessage id='SUBSCRIBED_THREADS' />, to: '/forum/subscribed-topics/' },
            { label: 'SIEN_SLAVY', to: '/forum/leaderboard/messages/' },
        ],
    ],
    [{ label: 'Magazín', to: '/magazine/', target: '_parent' }, []],
    [
        { label: 'Bazár' },
        [
            { label: <FormattedMessage id='HEADING_PRODUCTS' />, to: '/market/' },
            { label: <FormattedMessage id='HEADING_MY' />, to: '/market/my_bazaar/' },
        ],
    ],
    [
        { label: 'Fotoblogy' },
        [
            { label: <FormattedMessage id='PHOTOBLOGS_ALL' />, to: '/blogs/' },
            { label: <FormattedMessage id='PHOTOBLOGS_MY' />, to: '/blogs/my-selection/' },
            { label: <FormattedMessage id='PB_MENU_FRIENDS_AND_FAVORITES' />, to: '/blogs/friends' },
            { label: <FormattedMessage id='COLLECTIONS' />, to: '/blogs/collections/' },
            { label: <FormattedMessage id='TAGGING_TITLE_LIVING' />, to: '/inspirations/' },
        ],
    ],
    [
        { label: 'Profesionáli' },
        [
            { label: <FormattedMessage id='ALL_PRESENTATIONS' />, to: '/catalog/category/profesionali/' },
            { label: <FormattedMessage id='VENDOR_PRODUCTS' />, to: '/market/?excludeUsed=true' },
        ],
    ],
    [{ label: 'Atlas', to: '/atlas/', target: '_parent' }, []],
]);

const LIVING_CONFIG = new Map([
    [
        { label: 'Fórum' },
        [
            { label: <FormattedMessage id='FORUM_CATEGORIES' />, to: '/forum/' },
            { label: <FormattedMessage id='FORUM_LATEST_DISCUSSIONS' />, to: '/forum/latest-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_IS_DISCUSSED' />, to: '/forum/updated-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_I_POSTED_TITLE' />, to: '/forum/where-i-have-posted/' },
            { label: <FormattedMessage id='SUBSCRIBED_THREADS' />, to: '/forum/subscribed-topics/' },
            { label: 'SIEN_SLAVY', to: '/forum/leaderboard/messages/' },
        ],
    ],
    [
        { label: 'Fotoblogy' },
        [
            { label: <FormattedMessage id='PHOTOBLOGS_ALL' />, to: '/blogs/' },
            { label: <FormattedMessage id='PHOTOBLOGS_MY' />, to: '/blogs/my-selection/' },
            { label: <FormattedMessage id='PB_MENU_FRIENDS_AND_FAVORITES' />, to: '/blogs/friends/' },
            { label: <FormattedMessage id='GROUP_LIST' />, to: '/list-of-groups/' },
            { label: <FormattedMessage id='GROUPS_MY_GROUPS' />, to: '/list-of-groups/mine/', authOnly: true },
            { label: <FormattedMessage id='COLLECTIONS' />, to: '/blogs/collections/' },
            { label: <FormattedMessage id='TAGGING_TITLE_LIVING' />, to: '/inspirations/' },
        ],
    ],
    [
        { label: 'Bazár' },
        [
            { label: <FormattedMessage id='HEADING_PRODUCTS' />, to: '/market/' },
            { label: <FormattedMessage id='HEADING_MY' />, to: '/market/my_bazaar/' },
        ],
    ],
    [
        { label: 'Profesionáli' },
        [
            { label: <FormattedMessage id='ALL_PRESENTATIONS' />, to: '/catalog/' },
            { label: 'Inzeráty Profesionálov', to: '/market/?excludeUsed=true' },
        ],
    ],
]);

const LIVING_CONFIG_CZECH = new Map([
    [
        { label: 'Fórum' },
        [
            { label: <FormattedMessage id='FORUM_CATEGORIES' />, to: '/forum/' },
            { label: <FormattedMessage id='FORUM_LATEST_DISCUSSIONS' />, to: '/forum/latest-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_IS_DISCUSSED' />, to: '/forum/updated-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_I_POSTED_TITLE' />, to: '/forum/where-i-have-posted/' },
            { label: <FormattedMessage id='SUBSCRIBED_THREADS' />, to: '/forum/subscribed-topics/' },
            { label: 'SIEN_SLAVY', to: '/forum/leaderboard/messages/' },
        ],
    ],
    [
        { label: 'Fotoblogy' },
        [
            { label: <FormattedMessage id='PHOTOBLOGS_ALL' />, to: '/blogs/' },
            { label: <FormattedMessage id='PHOTOBLOGS_MY' />, to: '/blogs/my-selection/' },
            { label: <FormattedMessage id='PB_MENU_FRIENDS_AND_FAVORITES' />, to: '/blogs/friends/' },
            { label: <FormattedMessage id='GROUP_LIST' />, to: '/list-of-groups/' },
            { label: <FormattedMessage id='GROUPS_MY_GROUPS' />, to: '/list-of-groups/mine/', authOnly: true },
            { label: <FormattedMessage id='COLLECTIONS' />, to: '/blogs/collections/' },
            { label: <FormattedMessage id='TAGGING_TITLE_LIVING' />, to: '/inspirations/' },
        ],
    ],
    [
        { label: 'Bazar' },
        [
            { label: <FormattedMessage id='HEADING_PRODUCTS' />, to: '/market/' },
            { label: <FormattedMessage id='HEADING_MY' />, to: '/market/my_bazaar/' },
        ],
    ],
    [
        { label: 'Profesionálové' },
        [
            { label: <FormattedMessage id='ALL_PRESENTATIONS' />, to: '/catalog/' },
            { label: <FormattedMessage id='VENDOR_PRODUCTS' />, to: '/market/?excludeUsed=true' },
        ],
    ],
]);

const WEDDING_CONFIG = new Map([
    [
        { label: 'Fórum' },
        [
            { label: <FormattedMessage id='FORUM_CATEGORIES' />, to: '/forum/' },
            { label: <FormattedMessage id='FORUM_LATEST_DISCUSSIONS' />, to: '/forum/latest-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_IS_DISCUSSED' />, to: '/forum/updated-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_I_POSTED_TITLE' />, to: '/forum/where-i-have-posted/' },
            { label: <FormattedMessage id='SUBSCRIBED_THREADS' />, to: '/forum/subscribed-topics/' },
            { label: 'SIEN_SLAVY', to: '/forum/leaderboard/messages/' },
        ],
    ],
    [
        { label: 'Fotoblogy' },
        [
            { label: <FormattedMessage id='PHOTOBLOGS_ALL' />, to: '/blogs/' },
            { label: <FormattedMessage id='PHOTOBLOGS_MY' />, to: '/blogs/my-selection/' },
            { label: <FormattedMessage id='PB_MENU_FRIENDS_AND_FAVORITES' />, to: '/blogs/friends/' },
            { label: <FormattedMessage id='GROUP_LIST' />, to: '/list-of-groups/' },
            { label: <FormattedMessage id='GROUPS_MY_GROUPS' />, to: '/list-of-groups/mine/', authOnly: true },
            { label: <FormattedMessage id='COLLECTIONS' />, to: '/blogs/collections/' },
            { label: <FormattedMessage id='TAGGING_TITLE_LIVING' />, to: '/inspirations/' },
        ],
    ],
    [
        { label: 'Bazár' },
        [
            { label: <FormattedMessage id='HEADING_PRODUCTS' />, to: '/market/' },
            { label: <FormattedMessage id='HEADING_MY' />, to: '/market/my_bazaar/' },
        ],
    ],
    [
        { label: 'Profesionáli' },
        [
            { label: <FormattedMessage id='ALL_PRESENTATIONS' />, to: '/catalog/' },
            { label: 'Inzeráty Profesionálov', to: '/market/?excludeUsed=true' },
        ],
    ],
    [{ label: 'Magazín', to: '/magazine/' }, []],
]);

const WEDDING_CONFIG_CZECH = new Map([
    [
        { label: 'Fórum' },
        [
            { label: <FormattedMessage id='FORUM_CATEGORIES' />, to: '/forum/' },
            { label: <FormattedMessage id='FORUM_LATEST_DISCUSSIONS' />, to: '/forum/latest-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_IS_DISCUSSED' />, to: '/forum/updated-topics/' },
            { label: <FormattedMessage id='FORUM_WHERE_I_POSTED_TITLE' />, to: '/forum/where-i-have-posted/' },
            { label: <FormattedMessage id='SUBSCRIBED_THREADS' />, to: '/forum/subscribed-topics/' },
            { label: 'SIEN_SLAVY', to: '/forum/leaderboard/messages/' },
        ],
    ],
    [
        { label: 'Fotoblogy' },
        [
            { label: <FormattedMessage id='PHOTOBLOGS_ALL' />, to: '/blogs/' },
            { label: <FormattedMessage id='PHOTOBLOGS_MY' />, to: '/blogs/my-selection/' },
            { label: <FormattedMessage id='PB_MENU_FRIENDS_AND_FAVORITES' />, to: '/blogs/friends/' },
            { label: <FormattedMessage id='GROUP_LIST' />, to: '/list-of-groups/' },
            { label: <FormattedMessage id='GROUPS_MY_GROUPS' />, to: '/list-of-groups/mine/', authOnly: true },
            { label: <FormattedMessage id='COLLECTIONS' />, to: '/blogs/collections/' },
            { label: <FormattedMessage id='TAGGING_TITLE_LIVING' />, to: '/inspirations/' },
        ],
    ],
    [
        { label: 'Bazar' },
        [
            { label: <FormattedMessage id='HEADING_PRODUCTS' />, to: '/market/' },
            { label: <FormattedMessage id='HEADING_MY' />, to: '/market/my_bazaar/' },
        ],
    ],
    [
        { label: 'Profesionálové' },
        [
            { label: <FormattedMessage id='ALL_PRESENTATIONS' />, to: '/catalog/' },
            { label: <FormattedMessage id='VENDOR_PRODUCTS' />, to: '/market/?excludeUsed=true' },
        ],
    ],
    [{ label: 'Magazín', to: '/magazine/' }, []],
]);

export const CONFIG = new Map([
    [301, LIVING_CONFIG],
    [302, LIVING_CONFIG_CZECH],
    [401, GARDEN_CONFIG],
    [101, WEDDING_CONFIG],
    [102, WEDDING_CONFIG_CZECH],
]);
