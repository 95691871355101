import React, { useState, useContext, useRef, useEffect } from 'react';
import classnames from 'classnames';

import Styled from 'styled';

import AdUnitWithRoute from '../google-dfp';

import defaultStyles from '../google-dfp.scss';

export const InterscrollerContext = React.createContext({
    offsetTop: null,
    isActive: true,
    setOffsetTop: (offset: number | null) => {},
    setActive: (active: boolean) => {},
    updateOffsetTop: () => {},
    setUpdateOffsetTopFn: (fn: Function) => {},
});

const calculateOffsetTop = (node: HTMLElement, offsetTop: number): number => {
    const offset = node.offsetTop + offsetTop;
    if (node.offsetParent === null || node.nodeName.toLowerCase() === 'body') {
        return offset;
    }

    return calculateOffsetTop(node.offsetParent as HTMLElement, offset);
};

export const InterscrollerPlaceholder: React.FC<{ style?: {} }> = React.memo(
    Styled(defaultStyles)(props => {
        const ref: React.RefObject<HTMLDivElement> = useRef(null);
        const context = useContext(InterscrollerContext);
        const { styles } = props;

        const classes = [styles.interscrollerPlaceholder];
        if (!context.isActive) {
            classes.push(styles.interscrollerPlaceholderInactive);
        }

        const setOffset = () => {
            if (ref && ref.current) {
                const offset = calculateOffsetTop(ref.current, 0);
                context.setOffsetTop(offset);
            }
        };

        useEffect(() => {
            window.addEventListener('loadingCompleted', setOffset);
            context.setUpdateOffsetTopFn(setOffset);
            return () => {
                context.setOffsetTop(null);
                window.removeEventListener('loadingCompleted', setOffset);
            };
        }, []);

        return <div ref={ref} className={classnames(classes)} style={props} />;
    })
);

export const Interscroller: React.FC<{}> = React.memo(
    Styled(defaultStyles)(props => {
        const context = useContext(InterscrollerContext);
        const { styles } = props;
        const [wrapperStyle, setWrapperStyle] = useState({
            top: context.offsetTop ? context.offsetTop + 60 : 0,
        });

        const classes = [styles.interscrollerWrapper];
        if (context.offsetTop) {
            classes.push(styles.interscrollerWrapperActive);
        }

        const sizeMapping = [
            [[0, 0], [[400, 600]]],
            [[768, 0], []],
        ];

        const slotRenderEndedCb = event => {
            context.setActive(!event.isEmpty);
            if (context.updateOffsetTop) {
                context.updateOffsetTop();
            }
        };

        useEffect(() => {
            setWrapperStyle({
                top: context.offsetTop || 0,
            });
        }, [context.offsetTop]);

        return (
            <div style={wrapperStyle} className={classnames(classes)}>
                <div className={styles.interscroller}>
                    <AdUnitWithRoute
                        adUnitCode='interscroller'
                        sizes={[[400, 600]]}
                        sizeMapping={sizeMapping}
                        slotRenderEndedCb={slotRenderEndedCb}
                        shrinkAtMobile={true}
                        {...props}
                    />
                </div>
            </div>
        );
    })
);

export const ZoznamInterscroller: React.FC<{}> = React.memo(
    Styled(defaultStyles)(props => {
        const context = useContext(InterscrollerContext);
        const { styles } = props;
        const [wrapperStyle, setWrapperStyle] = useState({
            top: context.offsetTop ? context.offsetTop + 60 : 0,
        });

        const classes = [styles.interscrollerWrapper];
        if (context.offsetTop) {
            classes.push(styles.interscrollerWrapperActive);
        }

        const sizeMapping = [
            [
                [0, 0],
                [
                    [300, 300],
                    [300, 600],
                    [400, 600],
                ],
            ],
            [[768, 0], []],
        ];

        const slotRenderEndedCb = event => {
            context.setActive(!event.isEmpty);
            if (context.updateOffsetTop) {
                context.updateOffsetTop();
            }
        };

        useEffect(() => {
            setWrapperStyle({
                top: context.offsetTop || 0,
            });
        }, [context.offsetTop]);

        return (
            <div style={wrapperStyle} className={classnames(classes)}>
                <div className={styles.interscroller}>
                    <AdUnitWithRoute
                        overrideAdUnitCode={true}
                        adUnitCode='/60012913/Mojasvadba-mobil-magazin-titulka-sekcia-topbox1'
                        sizes={[[300, 300], [300, 600], [400, 600], 'fluid']}
                        sizeMapping={sizeMapping}
                        slotRenderEndedCb={slotRenderEndedCb}
                        shrinkAtMobile={true}
                        {...props}
                    />
                </div>
            </div>
        );
    })
);

export const ZahradaInterscroller: React.FC<{}> = React.memo(
    Styled(defaultStyles)(props => {
        const context = useContext(InterscrollerContext);
        const { styles } = props;
        const [wrapperStyle, setWrapperStyle] = useState({
            top: context.offsetTop ? context.offsetTop + 60 : 0,
        });

        const classes = [styles.interscrollerWrapper];
        if (context.offsetTop) {
            classes.push(styles.interscrollerWrapperActive);
        }

        const sizeMapping = [
            [[0, 0], [[400, 600]]],
            [[1024, 0], [[730, 940]]],
        ];

        const slotRenderEndedCb = event => {
            context.setActive(!event.isEmpty);
            if (context.updateOffsetTop) {
                context.updateOffsetTop();
            }
        };

        useEffect(() => {
            setWrapperStyle({
                top: context.offsetTop || 0,
            });
        }, [context.offsetTop]);

        return (
            <div style={wrapperStyle} className={classnames(classes)}>
                <div className={styles.interscroller}>
                    <AdUnitWithRoute
                        overrideAdUnitCode
                        adUnitCode='/1023789/zahrada-intesrcroller'
                        divAdId='div-gpt-ad-1634134827547-0'
                        sizes={[
                            [730, 940],
                            [400, 600],
                        ]}
                        sizeMapping={sizeMapping}
                        slotRenderEndedCb={slotRenderEndedCb}
                        shrinkAtMobile={true}
                        {...props}
                    />
                </div>
            </div>
        );
    })
);
