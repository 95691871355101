import React from 'react';
import Route from 'react-router/route';
import LoadingSwitch from 'lib/react-router/loading-switch';

import FourNetsView from 'four-nets/four-nets-view';
import loadable from '@loadable/component';

export interface Props {
    facebookAppId?: string;
    error?: Error;
}

const LoadableUrlShortener = loadable(() => import('four-nets/url-shortener/shortener'));
const LoadableAdminclub = loadable(() => import('four-nets/adminclub/adminclub'));
const LoadableLandingPage = loadable(() => import('four-nets/vendor/landing-page/landing-page'));
const LoadableInvitation = loadable(() => import('four-nets/invitation/invitation'));

class FourNetsSwitch extends React.PureComponent<Props> {
    renderFourNets = () => <FourNetsView {...this.props} />;

    render() {
        return (
            <LoadingSwitch>
                <Route exact path='/invitation/v2/:username' component={LoadableInvitation} />
                <Route path='/url-shortener/:url' exact={true} component={LoadableUrlShortener} />
                <Route path='/adminclub/v2' component={LoadableAdminclub} />
                <Route exact path='/pro-landing' component={LoadableLandingPage} />
                <Route render={this.renderFourNets} />
            </LoadingSwitch>
        );
    }
}

export default FourNetsSwitch;
