import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as NavLink } from 'react-router-dom';
import Styled from 'styled';
import PropTypes from 'prop-types';

import { ViewerContext } from 'four-nets/user/viewer/viewer';
import { VendorCardChangeNoticeContext } from 'four-nets/vendor-card-change-notice';
import { Site } from 'four-nets/site';

import PartnerLinkView from './partner-link-view';
import LoggedInStatusView from './logged-in-status-view';

import defaultStyles from './partners-and-login-view.scss';

export class PartnersAndLoginViewModel {
    links: any[];
    userStore: any;

    constructor({ links, userStore }: { links: any[]; userStore: any }) {
        this.links = links;
        this.userStore = userStore;
    }
}

interface Props {
    styles: typeof defaultStyles;
    partnersAndLoginViewModel: any;
}

const PartnersAndLoginView: React.FC<Props> = (props, context) => {
    const { styles } = props;
    const site = context && (context.site as Site);
    const { viewer } = useContext(ViewerContext);
    const showVendorCardChangeNotice = useContext(VendorCardChangeNoticeContext);

    let {
        partnersAndLoginViewModel: { links, userStore },
    } = props;

    const vendor = viewer?.profile.vendor;
    const isPaid = vendor?.isPresentationPaid;

    if (vendor && !isPaid) {
        links = links.slice(0, -1);
    }

    return (
        <div className={styles.partnersAndLogin}>
            <div className={styles.login}>
                <div>
                    <LoggedInStatusView />
                </div>
                {vendor && site ? (
                    <>
                        <span className={styles.contactUs}>
                            <FormattedMessage id='CONTACT_US' />:
                        </span>
                        <span className={styles.vendorLink}>{site.PROPhone}</span>
                        <span className={styles.midot}>&middot;</span>
                        <a href={`mailto:${site.PROEmail}`} className={styles.vendorLink}>
                            {site.PROEmail}
                        </a>
                    </>
                ) : (
                    links.map(({ label, href, target, rel }: any) => (
                        <PartnerLinkView label={label} href={href} target={target} key={href} rel={rel} />
                    ))
                )}
                {vendor ? (
                    !isPaid ? (
                        <NavLink
                            to={`/pro/${userStore.me && userStore.me.username}/payments/`}
                            className={styles.expiredLink}
                        >
                            <FormattedMessage id='BUY_SUBSCRIPTION' />
                        </NavLink>
                    ) : vendor.isRecurringWc ? (
                        <button onClick={showVendorCardChangeNotice} className={styles.expiredLink}>
                            <FormattedMessage id='ADD_CARD' />
                        </button>
                    ) : null
                ) : null}
            </div>
        </div>
    );
};

PartnersAndLoginView.contextTypes = {
    site: PropTypes.instanceOf(Site),
};

export default Styled(defaultStyles)(PartnersAndLoginView);
