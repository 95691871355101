import { observable, computed, action } from 'mobx';
import { Api, DelegatedApi, ApiError } from 'lib/api/api';
import { ApiItem } from 'lib/api/api-item';

type Data<T> = {
    [id: number | string]: T,
};

interface ApiStoreDelegate<T: ApiItem> {
    save<D>(options: { item: T }): Promise<D>;
    delete<D>(options: { item: T }): Promise<D>;
    item(options: { from: Object }): Promise<T>;
}

export type JSONOptions = {
    idKey: string,
};

export class IdNotFoundError extends ApiError {}

export class InvalidJSONError extends ApiError {}

export class ApiStore<T: ApiItem> {
    // implements RequestDelegate
    api: DelegatedApi;
    delegate: ApiStoreDelegate<T>;

    @observable pendingRequestCount = 0;
    @observable data: Data<T> = observable.map({});

    constructor(options: { api: Api, delegate: ApiStoreDelegate<T> }) {
        const { api, delegate } = options;

        this.api = new DelegatedApi({
            api,
            delegate: this,
        });

        this.delegate = delegate;
        this.processJSON = this.processJSON.bind(this);
        this.processJSONArray = this.processJSONArray.bind(this);
    }

    // ApiRequestDelegate

    @action requestWillStart() {
        this.pendingRequestCount++;
    }

    @action requestDidCancel() {
        this.pendingRequestCount--;
    }

    @action requestDidEnd() {
        this.pendingRequestCount--;
    }

    saveItem<D>(options: { item: T }): Promise<D> {
        return this.delegate.save(options).then(result => {
            return this.processJSON(result);
        });
    }

    deleteDidSucceed<D>(options: { item: T, data: D }): D {
        const { item, data } = options;

        if (this.data.has(item.id)) {
            this.data.delete(item.id);
        }

        return data;
    }

    deleteItem<D>(options: { item: T }): Promise<D> {
        const { item } = options;

        return this.delegate.delete(options).then(data => {
            return this.deleteDidSucceed({
                item,
                data,
            });
        });
    }

    // Methods

    @computed get isLoading(): boolean {
        return this.pendingRequestCount > 0;
    }

    @action clear() {
        this.data.clear();
    }

    @action onJSON(options: { json: Object, jsonOptions?: JSONOptions }): Promise<T> {
        const { json, jsonOptions } = options;
        const { idKey = 'id', id: getId } = jsonOptions || {};

        const id = getId ? getId(json) : json[idKey];

        if (!id) {
            throw new IdNotFoundError();
        }

        let item;

        if (this.data.has(id)) {
            item = this.data.get(id);
        } else {
            item = this.delegate.item({ ...options, from: json });
            this.data.set(id, item);
        }

        return item.fromJSON({
            json,
        });
    }

    processJSON(options: { json: ?Object, jsonOptions?: JSONOptions }): T {
        const { json } = options;

        if (json) {
            return this.onJSON(options);
        }

        return null;
    }

    processPlainJSONArray(options: { json: ?(Object[]), jsonOptions?: JSONOptions }): T[] {
        const { json, ...rest } = options;

        if (json) {
            return json.map(j =>
                this.onJSON({
                    ...rest,
                    json: j,
                })
            );
        }

        throw new InvalidJSONError();
    }

    processJSONArray(options) {
        return this.processPlainJSONArray(options);
    }

    create(options: { json: Promise<Object>, jsonOptions?: JSONOptions }): Promise<T> {
        const { json, ...rest } = options;

        return json.then(data =>
            this.processJSON({
                json: data,
                ...rest,
            })
        );
    }

    load(options: { json: Promise<Object>, jsonOptions?: JSONOptions }): Promise<T> {
        const { json, ...rest } = options;

        return json.then(data =>
            this.processJSON({
                json: data,
                ...rest,
            })
        );
    }

    loadAll(options: { json: Promise<Object[]>, jsonOptions?: JSONOptions }) {
        const { json, ...rest } = options;

        return json.then(data =>
            this.processJSONArray({
                json: data,
                ...rest,
            })
        );
    }

    toJSON() {
        return null;
    }
}

export default ApiStore;
