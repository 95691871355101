import React from 'react';

import AdUnitWithRoute, { AdUnitProps } from '../google-dfp';

const sizeMapping = [
    [[0, 0], []],
    [[1024, 0], [[160, 600]]],
];

export const Skyscraper = (props: AdUnitProps) => {
    return (
        <AdUnitWithRoute adUnitCode='skyscraper' sizes={['fluid', [160, 600]]} sizeMapping={sizeMapping} {...props} />
    );
};

export const ZoznamSkyscraper = (props: AdUnitProps) => {
    const sizeMapping = [
        [[0, 0], []],
        [[1024, 0], [[160, 600]]],
    ];
    return (
        <AdUnitWithRoute
            overrideAdUnitCode={true}
            adUnitCode='/60012913/Mojasvadba-topbox-1'
            sizes={['fluid', [160, 600]]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};

export const ZahradaSkyscraper = (props: AdUnitProps) => {
    const sizeMapping = [
        [[0, 0], []],
        [
            [1024, 0],
            [
                [120, 600],
                [160, 600],
            ],
        ],
    ];
    return (
        <AdUnitWithRoute
            overrideAdUnitCode
            adUnitCode='/1023789/zahrada-skyscraper'
            divAdId='div-gpt-ad-1634134883281-0'
            sizes={[
                [120, 600],
                [160, 600],
            ]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};
