import React from 'react';
import URL from 'url-parse';
import PropTypes from 'prop-types';
import { Site } from 'four-nets/site';

import { NavLink, NavLinkProps } from 'react-router-dom';

export interface Props extends NavLinkProps {
    href: string;
    target?: string;
    follow?: boolean;
}

interface Context {
    site: Site;
}

const ALLOWED_FOLLOW_LINKS = [
    'modrastrecha.sk',
    'modrastrecha-pro.sk',
    'modrastrecha.cz',
    'modrastrecha-pro.cz',
    'mojasvadba.sk',
    'mojasvadba-pro.sk',
    'mojasvadba.zoznam.sk',
    'beremese.cz',
    'beremese-pro.cz',
    'jaga.sk',
    'zahrada.sk',
    'mojdom.sk',
    'mojdom.zoznam.sk',
    'urobsisam.zoznam.sk',
    'asb.sk',
    'jagastore.sk',
    'pohodo.sk',
    'home-bydleni.cz',
    'receptyprimanapadu.cz',
    'asb-portal.cz',
    'homebydleni.cz',
    'doma.sk',
    'predplatne.jaga.sk',
];

const FOLLOW_LINK_REGEXP = new RegExp(`^(https?:)?//(www.)?(${ALLOWED_FOLLOW_LINKS.join('|')})(\/\\w*\/?)?$`, 'i');

class RouteLinkView extends React.PureComponent<Props> {
    static contextTypes = {
        site: PropTypes.instanceOf(Site),
    };

    context!: Context;

    render() {
        const { href, target, rel, follow, ...rest } = this.props;

        const url = URL(href, {});

        if (!url.hostname && !/^.+:.+/.test(href)) {
            return <NavLink to={href} target={target} {...rest} />;
        }

        let nofollow = 'nofollow';
        if (FOLLOW_LINK_REGEXP.test(href) || follow) {
            nofollow = '';
        }

        let adjustedRel = rel || '';
        adjustedRel = adjustedRel.replace('noreferrer', '').trim();

        return (
            <a
                href={href}
                target={target || '_blank'}
                rel={`noopener${nofollow ? ` ${nofollow} ` : ''}${adjustedRel}`}
                {...rest}
            >
                {this.props.children}
            </a>
        );
    }
}

export default RouteLinkView;
