import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router';

import LinkView from 'lib/ui/link-view';
import Styled from 'styled';

import defaultStyles from './link-view.scss';

@Styled(defaultStyles)
@observer
class NavigationLinkView extends React.Component {
    render() {
        const { link, styles, additionalActiveLinks, onClick } = this.props;

        return (
            <Route>
                {({ location }) => {
                    const classNames = [styles.link];

                    if (location.pathname.startsWith(link.href)) {
                        classNames.push(styles.link__active);
                    }
                    if (additionalActiveLinks) {
                        additionalActiveLinks.forEach(additionalActiveLink => {
                            if (location.pathname.startsWith(additionalActiveLink)) {
                                classNames.push(styles.link__active);
                            }
                        });
                    }
                    return (
                        <LinkView link={link} onClick={onClick}>
                            <span className={classNames.join(' ')}>{link.label}</span>
                        </LinkView>
                    );
                }}
            </Route>
        );
    }
}

export default NavigationLinkView;
