import React from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router';
import Status from 'lib/react-router/status';
import { RedirectError } from 'four-nets/router/errors';
import NotFound404 from './404/not-found';
import Unauthorized401 from './401/unauthorized';
import InternalError500 from './500/internal-server-error';

interface RedirectError extends Error {
    code: number;
    href: string;
}

interface ErrorWithCode extends Error {
    code?: number;
}

export interface Props extends RouteComponentProps<any> {
    error?: ErrorWithCode | RedirectError | null;
}

interface State {
    error: ErrorWithCode | null;
}

class ErrorPage extends React.PureComponent<Props, State> {
    state: State = {
        error: null,
    };

    componentDidCatch(error: ErrorWithCode) {
        this.setState({
            error,
        });
    }

    componentDidUpdate(props: Props) {
        if (props.location !== this.props.location) {
            this.setState({ error: null });
        }
    }

    render() {
        const error = this.props.error || this.state.error;

        if (error) {
            if (error instanceof RedirectError) {
                return (
                    <React.Fragment>
                        <Redirect to={(error as RedirectError).href} />
                        <Status code={(error as RedirectError).code} />
                    </React.Fragment>
                );
            }

            let element;

            switch ((error as ErrorWithCode).code) {
                case 404:
                    element = <NotFound404 />;
                    break;
                case 401:
                    element = <Unauthorized401 />;
                    break;
                default:
                    element = <InternalError500 error={error} />;
            }

            return (
                <React.Fragment>
                    {element}
                    <Status code={error.code || 500} />
                </React.Fragment>
            );
        }

        return this.props.children || null;
    }
}

export default withRouter(ErrorPage);
