import decorator from 'lib/lang/decorator';
import debounce from 'lodash/debounce';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';

export default decorator(({offset, debounceLength = 300}) => Component => {

    @observer
    class MaxHeightSettable extends React.Component {

        @observable maxHeight;

        windowDidResize = debounce(() => {
            this.maxHeight = window.innerHeight - offset;
        }, debounceLength);


        componentDidMount() {
            window.addEventListener('resize', this.windowDidResize);
            requestAnimationFrame(this.windowDidResize);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.windowDidResize);
        }

        render() {
            return (
                <Component
                    {...this.props}
                    maxHeight={this.maxHeight}
                    />
            );
        }
    }

    return MaxHeightSettable;

});
