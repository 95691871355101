import React from 'react';
import Styled from 'styled';

import SVGIconView from 'lib/images/svg-icon-view';

import picture from 'four-nets/icons/svg/icon_picture.svg';
import pdf from 'four-nets/icons/svg/icon_file-pdf.svg';
import pin from 'four-nets/icons/svg/icon_attachment.svg';
import documentIcon from 'four-nets/icons/svg/icon_download.svg';

import defaultStyles from './attachment.scss';

interface Props {
    styles: typeof defaultStyles;
    attachment: {
        id: string;
        meta: string | null;
        mimeType: string;
        downloadUrl: string | null;
        originalFilename: string | null;
    };
}

class Attachment extends React.Component<Props> {
    getIconByExtension = (extension: string) => {
        const extension2icon: any = {
            jpg: picture,
            png: picture,
            gif: picture,
            svg: picture,
            jpeg: picture,
            pdf,
            doc: documentIcon,
            dot: documentIcon,
            docx: documentIcon,
            xls: documentIcon,
            xlsx: documentIcon,
            ppt: documentIcon,
            pptx: documentIcon,
        };
        const lowerExtension = extension.toLowerCase();

        if (lowerExtension in extension2icon) {
            return extension2icon[lowerExtension];
        }
        return pin;
    };

    render() {
        const { attachment, styles } = this.props;
        const { originalFilename = '', downloadUrl } = attachment;
        const extension = originalFilename!.split('.').pop();
        const src = this.getIconByExtension(extension!);
        const classNames = [styles.attachment];

        if (downloadUrl) {
            classNames.push(styles.downloadable);
        }

        return (
            <a href={downloadUrl!} download className={classNames.join(' ')}>
                <SVGIconView src={src} className={styles.icon} />
                <span className={styles.label}>{originalFilename}</span>
            </a>
        );
    }
}

export default Styled(defaultStyles)(Attachment);
