import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import SVGIcon from 'lib/images/svg-pure-icon/svg-pure-icon';
import withCompute from 'lib/mobx/with-compute';
import compute from 'lib/mobx/compute';

import Styled from 'styled';
import BadgeWrapperView from 'four-nets/ui/badge-wrapper-view';

import defaultStyles from './user-panel-button-view.scss';

export { UserPanelButtonViewModel };

@withCompute()
class UserPanelButtonViewModel {
    @compute badge: ?number | ?string;

    openedIcon: Icon;
    closedIcon: Icon;

    @observable menuIsShown: boolean;

    constructor(options: { closedIcon?: Icon, openedIcon?: Icon }) {
        const { closedIcon = null, openedIcon = null } = options;

        this.openedIcon = openedIcon;
        this.closedIcon = closedIcon;
    }

    @action toggle() {
        this.menuIsShown = !this.menuIsShown;
    }

    @action open() {
        this.menuIsShown = true;
    }

    @action close() {
        this.menuIsShown = false;
    }
}

@Styled(defaultStyles)
@observer
class UserPanelButtonView extends React.Component {
    static childContextTypes = {
        userPanelButtonViewModel: PropTypes.instanceOf(UserPanelButtonViewModel),
    };

    getChildContext() {
        return {
            userPanelButtonViewModel: this.props.userPanelButtonViewModel,
        };
    }

    onClick = (event: Event) => {
        this.props.onClick(event, this.props.userPanelButtonViewModel);
    };

    render() {
        const { userPanelButtonViewModel, styles, preserve, index } = this.props;

        const { badge } = userPanelButtonViewModel;

        return (
            <div className={`${styles.userPanelButton} ${userPanelButtonViewModel.menuIsShown ? styles.active : ''}`}>
                <button
                    className={`${styles.button} `}
                    onClick={this.onClick}
                    aria-label={`User Panel Button ${index}`}
                >
                    <BadgeWrapperView value={badge}>
                        {React.createElement(SVGIcon, {
                            src: userPanelButtonViewModel.menuIsShown
                                ? userPanelButtonViewModel.openedIcon
                                : userPanelButtonViewModel.closedIcon,
                            preserveOnChange: true,
                            className: styles.icon,
                        })}
                    </BadgeWrapperView>
                </button>
                <div className={styles.menu}>
                    {userPanelButtonViewModel.menuIsShown || preserve ? this.props.children : null}
                </div>
            </div>
        );
    }
}

export default UserPanelButtonView;
