import React from 'react';

import Styled from 'styled';
import SVGIcon from 'lib/images/svg-icon-view';
import RouteLinkView from 'lib/ui/route-link-view';

import { FooterChild } from '../child';
import defaultStyles from './icon.scss';

export interface FooterIconChild extends FooterChild {
    href?: string;
    target?: string;
    src: string;
    title?: string;
    rel?: string;
}

export interface Props extends FooterIconChild {
    styles: typeof defaultStyles;
}

class Icon extends React.PureComponent<Props> {
    render() {
        const { href, target, title, src, rel, styles } = this.props;

        return (
            <RouteLinkView
                href={href}
                target={target}
                title={title}
                className={styles.icon}
                rel={rel}
                aria-label={title || href}
            >
                <SVGIcon className={styles.icon} src={src} />
            </RouteLinkView>
        );
    }
}

export default Styled(defaultStyles)(Icon);
