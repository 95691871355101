export const siteTrackerName = 'site';

export const GA_ID = {
    main: 'UA-50986451-1',
    'mojasvadba.sk': 'UA-50986451-6',
    'mojasvadba.zoznam.sk': 'UA-50986451-6',
    'mojasvadba-pro.sk': 'UA-50986451-7',
    'beremese.cz': 'UA-50986451-8',
    'beremese-pro.cz': 'UA-50986451-9',
    'modrastrecha.sk': 'UA-50986451-2',
    'modrastrecha-pro.sk': 'UA-50986451-3',
    'modrastrecha.cz': 'UA-50986451-4',
    'modrastrecha-pro.cz': 'UA-50986451-5',
    'zahrada.sk': 'UA-50986451-14',
};
