import computed from './computed';

export default function compute(target, key, descriptor) {

    if (!target.$computeProperties) {
        Object.defineProperty(target, '$computeProperties', {
            // enumerable: false,
            value: [],
        });
    }

    target.$computeProperties.push(key);

    return computed(target, key, Object.assign(descriptor, {
        get: function () { // eslint-disable-line
            if (this.$computeModel && this.$computeModel[key] !== undefined) {
                return this.$computeModel[key];
            }
            return null;
        },
    }));
}
