import React from 'react';
import PropTypes from 'prop-types';
import { Site } from 'four-nets/site';

import facebook from 'four-nets/icons/svg/icon_social-facebook.svg';
import pinterest from 'four-nets/icons/svg/icon_social-pinterest.svg';
// import twitter from 'four-nets/icons/svg/icon_social-twitter.svg';
import instagram from 'four-nets/icons/svg/icon_social-instagram.svg';

import visa from './vbyvisa.png';
import mastercard from './mc_idcheck_black.png';

import { FooterColumn } from './column/column';
import { Type as Action, FooterActionChild } from './column/row/child/action/action';
import { FooterLinkChild } from './column/row/child/link/link';
import { FooterIconChild } from './column/row/child/icon/icon';
import { FooterImageChild } from './column/row/child/image/image';

/**
 * Maybe some day the footer will be configurable on server throught graphql, so this component emulates apollo's query,
 * so later you could just swap this component with real Query
 */

interface Data {
    columns: FooterColumn[];
}

const GARDEN_DATA: Data = {
    columns: [
        {
            rows: [
                {
                    title: 'Komu môžeš napísať',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'info@zahrada.sk',
                            href: `mailto:info@zahrada.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `Nahlás chybu`,
                            href: `mailto:bugs@zahrada.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterActionChild',
                            label: 'Mám otázku na admina',
                            action: Action.MAIL_ADMIN,
                        } as FooterActionChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Užitočné odkazy',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Podmienky používania',
                            href: '/rules',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Cookie pravidlá',
                            href: '/cookie-policy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Ochrana osobných údajov',
                            href: '/about-privacy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Zoznam používateľov',
                            href: '/users_directory',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Inzercia',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'inzercia@zahrada.sk',
                            href: 'mailto:inzercia@zahrada.sk',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Obchodný tím',
                            href: 'https://www.jaga.sk/inzercia/kontakty/',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Sociálne siete',
                    children: [
                        {
                            __typename: 'FooterIconChild',
                            src: facebook,
                            title: 'Záhrada na Facebooku',
                            href: 'https://www.facebook.com/casopisZahrada/',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: pinterest,
                            title: 'Záhrada na Pinterest',
                            href: 'https://pinterest.com/zahradask',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: instagram,
                            title: 'Záhrada na Instagram',
                            href: 'https://www.instagram.com/zahrada.sk/',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                    ],
                },
            ],
        },
    ],
};

const LIVING_DATA: Data = {
    columns: [
        {
            rows: [
                {
                    title: 'Komu môžeš napísať',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'info@modrastrecha.sk',
                            href: `mailto:info@modrastrecha.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `Nahlás chybu`,
                            href: `mailto:bugs@modrastrecha.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterActionChild',
                            label: 'Mám otázku na admina',
                            action: Action.MAIL_ADMIN,
                        } as FooterActionChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Verzia stránky',
                    children: [
                        {
                            __typename: 'FooterActionChild',
                            label: 'Prejsť na PC verziu',
                            action: Action.CHANGE_VIEWPORT,
                        } as FooterActionChild,
                    ],
                },
                {
                    title: 'Užitočné odkazy',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Podmienky používania',
                            href: '/rules',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Cookie pravidlá',
                            href: '/cookie-policy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Ochrana osobných údajov',
                            href: '/about-privacy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Zoznam používateľov',
                            href: '/users_directory',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Reklama u nás',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Profil profesionála za 14 € bez DPH',
                            href: '/pro-landing/',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Návštevnosť podľa Google',
                            href: 'https://www.seethestats.com/site/modrastrecha.sk',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `inzercia@modrastrecha.sk`,
                            href: `mailto:inzercia@modrastrecha.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Cenník',
                            href: 'https://static.4nets.sk/docs/modrastrecha.sk-ads.pdf',
                            download: true,
                        } as FooterLinkChild,
                    ],
                },
                {
                    title: 'Ak už reklamu u nás máte',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Modrá strecha PRO - prihlásenie',
                            href: '/pro/',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Sociálne siete',
                    children: [
                        {
                            __typename: 'FooterIconChild',
                            src: facebook,
                            title: 'Modrá strecha na Facebooku',
                            href: 'https://www.facebook.com/Modrastrecha.sk',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: pinterest,
                            title: 'Modrá strecha na Pinterest',
                            href: 'https://pinterest.com/modrastrecha',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: instagram,
                            title: 'Modrá strecha na Instagrame',
                            href: 'https://www.instagram.com/modrastrechask',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                    ],
                },
                {
                    rowStyle: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    title: '',
                    children: [
                        {
                            __typename: 'FooterImageChild',
                            title: 'Visa',
                            image: visa as any,
                            href: 'https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/help?type=cardpay_vbv_help&amp;lang=sk',
                            rel: 'noopener',
                            width: 65,
                        } as FooterImageChild,
                        {
                            __typename: 'FooterImageChild',
                            image: mastercard as any,
                            title: 'Mastercard',
                            href: 'https://www.mastercard.us/en-us/consumers/payment-technologies/securecode.html',
                            rel: 'noopener',
                            width: 106,
                        } as FooterImageChild,
                    ],
                },
            ],
        },
    ],
};

const LIVING_DATA_CZECH: Data = {
    columns: [
        {
            rows: [
                {
                    title: 'Komu můžeš napsat',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'info@modrastrecha.cz',
                            href: `mailto:info@modrastrecha.cz`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `Ohlásit chybu`,
                            href: `mailto:bugs@modrastrecha.cz`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterActionChild',
                            label: 'Mám otázku na admina',
                            action: Action.MAIL_ADMIN,
                        } as FooterActionChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Verze stránky',
                    children: [
                        {
                            __typename: 'FooterActionChild',
                            label: 'Přejít na PC verzi',
                            action: Action.CHANGE_VIEWPORT,
                        } as FooterActionChild,
                    ],
                },
                {
                    title: 'Užitečné odkazy',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Podmínky používání',
                            href: '/rules',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Cookie pravidla',
                            href: '/cookie-policy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Ochrana osobních údajů',
                            href: '/about-privacy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Seznam uživatelů',
                            href: '/users_directory',
                        },
                        // } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Reklama u nás',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Profil Profesionála za 270,00 Kč bez DPH',
                            href: '/pro-landing/',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Návštěvnost dle Google',
                            href: 'https://www.seethestats.com/site/modrastrecha.cz',
                            target: '_blank',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Inzerce',
                            href: 'https://static.4nets.sk/docs/modrastrecha.cz-ads.pdf',
                            target: '_blank',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `inzerce@modrastrecha.cz`,
                            href: `mailto:inzerce@modrastrecha.cz`,
                        } as FooterLinkChild,
                    ],
                },
                {
                    title: 'Pokud již reklamu u nás máte',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Přihlášení - Modrá střecha PRO',
                            href: '/pro/',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Sociální sítě',
                    children: [
                        {
                            __typename: 'FooterIconChild',
                            src: facebook,
                            title: 'Modrá střecha na Facebooku',
                            href: 'https://www.facebook.com/Modrastrecha.cz',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: pinterest,
                            title: 'Modrá střecha na Pinterest',
                            href: 'https://pinterest.com/modrastrecha',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: instagram,
                            title: 'Modrá střecha na Instagramu',
                            href: 'https://www.instagram.com/modrastrechacz',
                            target: '_blank',
                            rel: 'noopener',
                        } as FooterIconChild,
                    ],
                },
                {
                    rowStyle: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    title: '',
                    children: [
                        {
                            __typename: 'FooterImageChild',
                            title: 'Visa',
                            image: visa as any,
                            href: 'https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/help?type=cardpay_vbv_help&amp;lang=sk',
                            rel: 'noopener',
                            width: 65,
                        } as FooterImageChild,
                        {
                            __typename: 'FooterImageChild',
                            image: mastercard as any,
                            title: 'Mastercard',
                            href: 'https://www.mastercard.us/en-us/consumers/payment-technologies/securecode.html',
                            rel: 'noopener',
                            width: 106,
                        } as FooterImageChild,
                    ],
                },
            ],
        },
    ],
};

const WEDDING_DATA_CZECH: Data = {
    columns: [
        {
            rows: [
                {
                    title: 'Komu můžeš napsat',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'info@beremese.cz',
                            href: `mailto:info@beremese.cz`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `Ohlásit chybu`,
                            href: `mailto:bugs@beremese.cz`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterActionChild',
                            label: 'Mám otázku na admina',
                            action: Action.MAIL_ADMIN,
                        } as FooterActionChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Verze stránky',
                    children: [
                        {
                            __typename: 'FooterActionChild',
                            label: 'Přejít na PC verzi',
                            action: Action.CHANGE_VIEWPORT,
                        } as FooterActionChild,
                    ],
                },
                {
                    title: 'Užitečné odkazy',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Podmínky používání',
                            href: '/rules',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Cookie pravidla',
                            href: '/cookie-policy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Ochrana osobních údajů',
                            href: '/about-privacy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Seznam uživatelů',
                            href: '/users_directory',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Reklama u nás',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Profil Profesionála za 270,00 Kč bez DPH',
                            href: '/pro-landing/',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Návštěvnost dle Google',
                            href: 'https://www.seethestats.com/site/beremese.cz',
                            target: '_blank',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Inzerce',
                            href: 'https://static.4nets.sk/docs/beremese.cz-ads.pdf',
                            target: '_blank',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `inzerce@beremese.cz`,
                            href: `mailto:inzerce@beremese.cz`,
                        } as FooterLinkChild,
                    ],
                },
                {
                    title: 'Pokud již reklamu u nás máte',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Přihlášení - Bereme se PRO',
                            href: '/pro/',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Sociální sítě',
                    children: [
                        {
                            __typename: 'FooterIconChild',
                            src: facebook,
                            title: 'Bereme se na Facebooku',
                            href: 'https://www.facebook.com/Beremese.cz',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: pinterest,
                            title: 'Bereme se na Pinterest',
                            href: 'https://pinterest.com/mojasvadba/',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: instagram,
                            title: 'Bereme se na Instagrame',
                            href: 'https://www.instagram.com/bereme_se/',
                            rel: 'noopener',
                        } as FooterIconChild,
                    ],
                },
                {
                    rowStyle: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    title: '',
                    children: [
                        {
                            __typename: 'FooterImageChild',
                            title: 'Visa',
                            image: visa as any,
                            href: 'https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/help?type=cardpay_vbv_help&amp;lang=sk',
                            rel: 'noopener',
                            width: 65,
                        } as FooterImageChild,
                        {
                            __typename: 'FooterImageChild',
                            image: mastercard as any,
                            title: 'Mastercard',
                            href: 'https://www.mastercard.us/en-us/consumers/payment-technologies/securecode.html',
                            rel: 'noopener',
                            width: 106,
                        } as FooterImageChild,
                    ],
                },
            ],
        },
    ],
};

const WEDDING_DATA: Data = {
    columns: [
        {
            rows: [
                {
                    title: 'Komu môžeš napísať',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'info@mojasvadba.sk',
                            href: `mailto:info@mojasvadba.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `Nahlásiť chybu`,
                            href: `mailto:bugs@mojasvadba.sk`,
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterActionChild',
                            label: 'Mám otázku na admina',
                            action: Action.MAIL_ADMIN,
                        } as FooterActionChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Verzia stránky',
                    children: [
                        {
                            __typename: 'FooterActionChild',
                            label: 'Prejsť na PC verziu',
                            action: Action.CHANGE_VIEWPORT,
                        } as FooterActionChild,
                    ],
                },
                {
                    title: 'Užitečné odkazy',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Podmienky používania',
                            href: '/rules',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Cookie pravidlá',
                            href: '/cookie-policy',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Ochrana osobných údajov',
                            href: '/about-privacy',
                        },
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Zoznam používateľov',
                            href: '/users_directory',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Reklama u nás',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Profil Profesionála za 14 € bez DPH',
                            href: '/pro-landing/',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Návštevnosť podľa Google',
                            href: 'https://www.seethestats.com/site/mojasvadba.sk',
                            target: '_blank',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Inzercia',
                            href: 'https://static.4nets.sk/docs/mojasvadba.zoznam.sk-ads.pdf',
                            target: '_blank',
                        } as FooterLinkChild,
                        {
                            __typename: 'FooterLinkChild',
                            label: `inzercia@mojasvadba.sk`,
                            href: `mailto:inzerce@mojasvadba.sk`,
                        } as FooterLinkChild,
                    ],
                },
                {
                    title: 'Ak už u nás reklamu máte',
                    children: [
                        {
                            __typename: 'FooterLinkChild',
                            label: 'Prihlásenie - Moja Svadba PRO',
                            href: '/pro/',
                        } as FooterLinkChild,
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    title: 'Sociálne siete',
                    children: [
                        {
                            __typename: 'FooterIconChild',
                            src: facebook,
                            title: 'Moja Svadba na Facebooku',
                            href: 'https://www.facebook.com/Mojasvadba.sk',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: pinterest,
                            title: 'Moja Svadba na Pinterest',
                            href: 'https://pinterest.com/mojasvadba/',
                            rel: 'noopener',
                        } as FooterIconChild,
                        {
                            __typename: 'FooterIconChild',
                            src: instagram,
                            title: 'Moja Svadba na Instagrame',
                            href: 'https://www.instagram.com/mojasvadba/',
                            rel: 'noopener',
                        } as FooterIconChild,
                    ],
                },
                {
                    rowStyle: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    title: '',
                    children: [
                        {
                            __typename: 'FooterImageChild',
                            title: 'visa',
                            image: visa as any,
                            href: 'https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/help?type=cardpay_vbv_help&amp;lang=sk',
                            rel: 'noopener',
                            width: 65,
                        } as FooterImageChild,
                        {
                            __typename: 'FooterImageChild',
                            image: mastercard as any,
                            title: 'mastercard',
                            href: 'https://www.mastercard.us/en-us/consumers/payment-technologies/securecode.html',
                            rel: 'noopener',
                            width: 106,
                        } as FooterImageChild,
                    ],
                },
            ],
        },
    ],
};

class Query extends React.PureComponent<{ children: (options: { data: Data }) => React.ReactNode }> {
    static contextTypes = {
        site: PropTypes.instanceOf(Site),
    };

    context!: {
        site: Site;
    };

    render() {
        const { site } = this.context;

        let data: Data;

        if (site.isGarden) {
            data = GARDEN_DATA;
        } else if (site.isLiving) {
            if (site.isCzech) {
                data = LIVING_DATA_CZECH;
            } else {
                data = LIVING_DATA;
            }
        } else {
            if (site.isCzech) {
                data = WEDDING_DATA_CZECH;
            } else {
                data = WEDDING_DATA;
            }
        }

        return this.props.children({ data });
    }
}

export default Query;
