/* @flow */

export class Action {
    label: LocalizedString;
    callback: ?() => void;

    constructor(options) {
        const {label, callback} = options;

        this.label = label;
        this.callback = callback;
    }
}
