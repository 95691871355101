import {MessageValue} from 'react-intl';

interface MessageDescriptor {
    id: string;
    defaultMessage?: string;
    description?: string;
}

export class IntlMessage {
    message: MessageDescriptor;
    values?: {[key: string]: MessageValue};

    constructor({
        message,
        values,
    }: {
        message: MessageDescriptor,
        values?: {[key: string]: MessageValue},
    }) {

        this.message = message;
        this.values = values;
    }
}
