export enum AuthOrigin {
    Unknown = 'unknown',
    NewThread = 'new thread',
    NewComment = 'new comment',
    NewBlogPost = 'new blog post',
    LikeBlogPost = 'like blog post',
    LikeForumMessage = 'like forum message',
    LikeImage = 'like image',
    LikeComment = 'like comment',
    Notifications = 'turn on notifications',
    NewForumMessage = 'new forum message',
    NewBazaarProduct = 'new bazaar product',
    NewComplain = 'new complain',
    NewMail = 'new mail',
    EditForumMessage = 'edit forum message',
    DeleteForumMessage = 'delete forum message',
    ToggleBazaarStar = 'toggle bazaar star',
    EditBazaarProduct = 'edit bazaar product',
    JoinGroup = 'join group',
    AcivationLink = 'activation link',
    Unauthorized = 'unauthorized',
    Header = 'header',
    Demander = 'demander'
}
