import React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from 'lib/images/svg-pure-icon/svg-pure-icon';
import ScrollToTop from 'lib/scroll/scroll-to-top';
import exclamation from 'four-nets/icons/svg/icon_alert.svg';
import Styled from 'styled';
import Status from 'lib/react-router/status';

import defaultStyles from './internal-server-error.scss';
import { ApolloError } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// import buttonStyles from './button-view.scss';

export interface Props {
    styles: typeof defaultStyles;
    error: Error & { code?: number } & Partial<ApolloError>;
}

@observer
class InternalError extends React.Component<Props> {
    refresh = () => {
        window.location.href = window.location.href;
    };

    render() {
        const { styles, error } = this.props;
        console.error(error);

        return (
            <React.Fragment>
                <Status code={error.code || 500} />
                <ScrollToTop />
                <div className={styles.centered}>
                    <div className={styles.wrapper}>
                        <SVGIcon style={{ image: styles.icon }} src={exclamation} className={styles.icon} />
                        <span className={styles.errorText}>
                            <p>
                                <FormattedMessage id='ERROR_500' />
                            </p>
                            {error.graphQLErrors
                                ? error.graphQLErrors.map(e =>
                                      e.message ? <p className={styles.errorMessage}>{e.message}</p> : null
                                  )
                                : null}
                            {process.env.NODE_ENV === 'development' ? (
                                <p className={styles.errorMessage}>
                                    {error.message}
                                    {error.networkError
                                        ? (error.networkError as any).result.errors.map(({ message }: Error) => (
                                              <React.Fragment key={message}>
                                                  <br />
                                                  {message}
                                              </React.Fragment>
                                          ))
                                        : null}
                                </p>
                            ) : null}
                        </span>
                    </div>
                    <p className={styles.text}>
                        <FormattedMessage id='YOU_CAN_RETURN_TO' /> &nbsp;
                        <a className={styles.link} href='/'>
                            <FormattedMessage id='TO_HOMEPAGE' />
                        </a>
                        &nbsp; <FormattedMessage id='OR' /> &nbsp;
                        <button onClick={this.refresh} className={styles.link}>
                            <FormattedMessage id='BUTTON_LABEL_RETRY_FAILED' />
                        </button>
                        .
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default Styled(defaultStyles)(InternalError);
