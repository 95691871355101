import React, { useContext, useEffect, useRef } from 'react';
import Styled from 'styled';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import defaultStyles from './alert.scss';
import { FooterButtonType, ModalRefContext } from './modal';

export interface AlertProps {
    styles: typeof defaultStyles;

    open?: boolean;
    title?: React.ReactNode;
    body?: React.ReactNode;

    onClose: (event: React.MouseEvent | React.KeyboardEvent, from?: string) => any;

    onCancel?: (event: React.MouseEvent | React.KeyboardEvent, from?: string) => any;
    cancelButtonLabel?: React.ReactNode;

    onAccept?: (event?: React.MouseEvent) => any;
    acceptButtonType?: FooterButtonType;
    acceptButtonLabel?: React.ReactNode;
    acceptButtonDisabled?: boolean;
}

const _Alert: React.FC<AlertProps> = props => {
    const {
        styles = {} as typeof defaultStyles,
        title,
        body,
        open,
        onClose,
        onCancel,
        cancelButtonLabel = <FormattedMessage id='CANCEL' values={{ gender: 'v' }} />,
        onAccept: propsOnAccept,
        acceptButtonType,
        acceptButtonLabel = <FormattedMessage id='OK' />,
    } = props;

    const ref = useRef<HTMLDivElement>(null);

    const modalNode = useContext(ModalRefContext);

    const overlayClick = (event: React.MouseEvent) => {
        if (event.target !== event.currentTarget) {
            return;
        }
        onClose(event, 'overlayClick');
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Escape') {
            return;
        }

        event.stopPropagation();
        if (onClose) {
            onClose(event, 'escapeKeyDown');
        }
    };

    const onAccept = (event: React.MouseEvent) => {
        const res = propsOnAccept && propsOnAccept(event);
        if (res && res.then) {
            res.then(onClose);
        } else {
            onClose({} as any);
        }
    };

    useEffect(() => {
        if (open) {
            window.document.body.classList.add(styles.bodyLockScroll);
        } else {
            window.document.body.classList.remove(styles.bodyLockScroll);
        }
    }, [open, styles]);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.focus();
        }
    }, [open]);

    return modalNode && open
        ? ReactDOM.createPortal(
              <div ref={ref} className={styles.overlay} onKeyDown={handleKeyDown} onClick={overlayClick} tabIndex={0}>
                  <div className={styles.dialog}>
                      {title ? (
                          <div className={styles.header}>
                              <div className={styles.title}>{title}</div>
                          </div>
                      ) : null}
                      {body ? <div className={styles.body}>{body}</div> : null}
                      <div className={styles.footer}>
                          {onCancel ? (
                              <button onClick={onClose} className={classnames(styles.footerButton, styles.cancel)}>
                                  {cancelButtonLabel}
                              </button>
                          ) : null}
                          <button
                              onClick={onAccept}
                              className={classnames(styles.footerButton, {
                                  [styles.warn]: acceptButtonType === 'warn',
                                  [styles.cancel]: acceptButtonType === 'cancel',
                                  [styles.action]: acceptButtonType === 'action',
                              })}
                          >
                              {acceptButtonLabel}
                          </button>
                      </div>
                  </div>
              </div>,
              modalNode
          )
        : null;
};

export const Alert = Styled(defaultStyles)(_Alert);
