import React from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import Styled from 'styled';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field, FieldProps } from 'formik';
import { Dialogs } from 'four-nets/ui-v2/modal/modal';

import SubmitButtonView from 'four-nets/ui/submit-button/submit-button-view';
import Input from 'four-nets/ui-v2/form/input/input';
import { mergeValidators, emptyValidator, emailValidator } from 'four-nets/ui-v2/form/validators';

import passwordResetMutation, { PasswordResetMutation, PasswordResetMutationVariables } from './password-reset.graphql';
import defaultStyles from './password-reset-form.scss';

interface Props {
    styles: typeof defaultStyles;
    dialogs: Dialogs;
    onSuccess: () => void;
}

interface Values {
    email: string;
}

type Errors = Partial<{ [key in keyof Values]: React.ReactElement<FormattedMessage>[] | true }>;

const PasswordResetForm: React.FC<Props> = props => {
    const { styles, onSuccess } = props;
    const [resetPassword, { data, error }] = useMutation<PasswordResetMutation, PasswordResetMutationVariables>(
        passwordResetMutation
    );
    return (
        <Formik<Values>
            initialValues={{ email: '' }}
            onSubmit={(values, { setSubmitting }) => {
                resetPassword({
                    variables: values,
                }).finally(() => {
                    setSubmitting(false);
                    props.dialogs.acknowledge({
                        body: <FormattedMessage id='IF_YOU_USED_THIS_EMAIL_WE_SENT_INSTRUCTIONS' />,
                        onAccept: onSuccess,
                    });
                });
            }}
            validate={values => {
                const errors: Errors = {};
                const emailError = mergeValidators([emptyValidator, emailValidator])(values.email);
                if (emailError) {
                    errors.email = emailError;
                }
                return errors;
            }}
        >
            {formik => {
                const { touched, errors, isSubmitting } = formik;
                return (
                    <React.Fragment>
                        <p>
                            <FormattedMessage
                                id='ENTER_YOUR_EMAIL_AND_WE_WILL_SEND_YOU_INSTRUCTIONS_TO_FOLLOW'
                                values={{ gender: true }}
                            />
                            <br />
                            <strong>
                                <FormattedMessage id='ENTER_EMAIL_YOU_HAVE_REGISTERED' values={{ gender: true }} />
                            </strong>
                        </p>
                        <Form id='password-reset-form' className={styles.form}>
                            <Field name='email'>
                                {({ field }: FieldProps) => (
                                    <FormattedMessage id='EMAIL'>
                                        {placeholder => (
                                            <Input
                                                style={{ wrapper: styles.wrapper }}
                                                errors={errors.email}
                                                placeholder={placeholder}
                                                touched={touched.email}
                                                {...field}
                                            />
                                        )}
                                    </FormattedMessage>
                                )}
                            </Field>
                            <SubmitButtonView
                                style={{ button: styles.button }}
                                type='submit'
                                disabled={isSubmitting}
                                form='password-reset-form'
                            >
                                <FormattedMessage id='SEND' values={{ gender: 'v' }} />
                            </SubmitButtonView>
                        </Form>
                    </React.Fragment>
                );
            }}
        </Formik>
    );
};

export default Styled(defaultStyles)(PasswordResetForm);
