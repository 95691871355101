// See: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state

import React, { useEffect, useRef } from 'react';

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
