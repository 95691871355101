import {observable, action} from 'mobx';
import {ApiError} from 'lib/api/api';
import URL from 'url-parse';
import queryString from 'query-string';
import {Result} from '../result';
import PAGED_TYPE from '../paged-type';

export class PageOutOfBoundsError extends ApiError {

}

export class PagedResult<T> extends Result<T> {
    @observable page: number;
    @observable pages: number;

    @observable loadNext: ?(() => Promise<this>);
    @observable loadPrevious: ?(() => Promise<this>);
    @observable loadPage: ?((options: {page: number}) => Promise<this>);

    constructor(options: {
        store: ApiStore;
        count: number,
        next: ?string,
        previous: ?string,
        results: T[],
        jsonOptions?: JSONOptions,
    }) {

        super({
            ...options,
            pagedType: PAGED_TYPE.PAGED,
        });

        this.setupPages();
    }

    @action setupPages = () => {
        const {previous, next, count, responseResults} = this;

        if (!previous && !next) {
            this.pages = 1;
            this.page = 1;

        } else if (next) {
            this.pages = Math.ceil(count / responseResults.length);
            const url = new URL(next);

            this.page = parseInt(
                queryString.parse(url.query).page,
            10) - 1;

        } else {
            const url = new URL(previous);

            const page = parseInt(
                queryString.parse(
                    (url.query && url.query) || '',
                ).page || 1,
            10) + 1;

            this.pages = page;
            this.page = page;
        }

        if (next) {
            this.loadNext = (options = {}) => {
                this.loading = true;

                return this.store.api
                    .get({url: next})
                    .json()
                    .then((args) => this.loadAllDidSucceed({...options, ...args}))
                    .finally(this.loadAllDidFinish);
            };
        } else {
            this.loadNext = null;
        }

        if (previous) {
            this.loadPrevious = (options = {}) => {
                this.loading = true;

                return this.store.api
                    .get({url: previous})
                    .json()
                    .then((args) => this.loadAllDidSucceed({...options, ...args}))
                    .finally(this.loadAllDidFinish);
            };

        } else {
            this.loadPrevious = null;
        }

        if (this.pages > 1) {
            this.loadPage = (options: {page: number}) => {
                const {page, ...rest} = options;

                if (page > this.pages) {
                    return Promise.reject(new PageOutOfBoundsError());
                }

                const url = new URL(next || previous, true);

                this.loading = true;

                return this.store.api
                    .get({path: url.pathname})
                    .query({
                        ...url.query,
                        page,
                    })
                    .json()
                    .then((args) => this.loadAllDidSucceed({...rest, ...args}))
                    .finally(this.loadAllDidFinish);
            };

        } else {
            this.loadPage = null;
        }
    };
}
