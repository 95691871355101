import { computed } from 'mobx';

import gardenLight from 'four-nets/img/zahrada-sk-for-light-background.svg';
import livingLight from 'four-nets/img/living-sk-logo-dark-bg.svg';
import livingLightCzech from 'four-nets/img/living-cz-logo-dark-bg.svg';
import livingDark from 'four-nets/img/living-sk-logo-light-bg.svg';
import livingDarkCzech from 'four-nets/img/living-cz-logo-light-bg.svg';
import weddingLogo from 'four-nets/img/wedding-sk-logo.svg';
import weddingLogoCzech from 'four-nets/img/wedding-cz-logo.svg';

import gardenAvatar from 'four-nets/img/garden-sk-avatar.jpg';
import livingAvatar from 'four-nets/img/living-avatar.jpg';

export type SiteId = 101 | 102 | 301 | 302 | 401;

export class Site {
    static idToName = {
        101: 'Mojasvadba.sk',
        102: 'Beremese.cz',
        301: 'Modrastrecha.sk',
        302: 'Modrastrecha.cz',
        401: 'Záhrada.sk',
    };

    static idToHostname = {
        101: 'mojasvadba.zoznam.sk',
        102: 'beremese.cz',
        301: 'modrastrecha.sk',
        302: 'modrastrecha.cz',
        401: 'zahrada.sk',
    };

    static idToNetworkCode = {
        101: '39524221',
        102: '39524221',
        301: '39524221',
        302: '39524221',
        401: '1023789',
    };

    static idToPROPhone = {
        101: '+421 940 414 009',
        102: '+420 245 501 169',
        301: '+421 940 414 009',
        302: '+420 245 501 169',
        401: '+421 940 414 009',
    };

    static idToPROEmail = {
        101: 'profesionali@mojasvadba.sk',
        102: 'profesionalove@beremese.cz',
        301: 'profesionali@modrastrecha.sk',
        302: 'profesionalove@modrastrecha.cz',
        401: 'profesionali@zahrada.sk',
    };

    static idToGemiusCodes = {
        101: {
            homepage: 'zDhAfbAgs1TfcPsxzDsxA8TVfQTZVzu6qWYQSUplssn.77',
            bazaar: '11A6tqr0L0x.FuhMt0e95rPWXhY_ni800EZmj9qFwuv.K7',
            forum: '11Ca1qr0P01FM1IH6KEys2XFXhZBlC80IYLsSz9CGjH.97',
            photoblog: 'bPAwEkNbU7ORsurQRY_t77S4.s0_km7mcNDs24qVkir.n7',
            other: 'bD3qH9Acfv8PsFh8JIbxF7d8fbt8rjruZEl6GCPUzUz.t7',
        },
        301: {
            homepage: 'zN5AfTg5aweS1H_Jke0xK_UUHZIpr2LIc4xeR5dnXQX.i7',
            bazaar: '10Y6tiNx5v0t3qYajMJi8IZiXoyRGEcASxolMrRTwsP.V7',
            forum: 'bPqQ0sreG_Yo6F9XKdCdSLS47Am_Y2xJkJFiNqJyFUj.g7',
            photoblog: 'bVCa1srB9v3uYiCVUhT2b2XjTBLBoix3QYroFCcL7dv.A7',
            other: 'bDhKgVhxRy.M3FYuUPNus7ccfZr8wCL2JIeeuGvMtQr.f7',
        },
        102: {
            homepage: 'AkflpK9QSQhd5ruXTEgNEZevXhYUAa71LmaVv4Puu5b.B7',
            bazaar: '15aaeGNYv2cS9G92zDvUVcPcP1HWaqNBaBnOCdVxm3T.f7',
            forum: 'd2AwFIN.0xaoQogRLbQgx5a0fdqpl7upGPjGNSqmRkz.l7',
            photoblog: 'baCQ1Aup484zfSfl6qSAqaPN3wgG8OLwKIg4RdVea2P.z7',
            other: 'nG5LAAi2tDXyB8SuFlCxLuTk7OkRswPgE0zDF5nd3jj.W7',
        },
        302: {
            homepage: 'AkhFBq7Q2dr9yls9rHKVlcVHTBLZpyw3xDPrM3v_a4P.27',
            bazaar: 'zUqQcurbY3wDKMgnSfqYRnYXP1JBPjsohd_dILylHVn.c7',
            forum: 'd2A6FoL.L5RCX3_67KKcFcQ8fdo_gyLANL5UxC7vSM..b7',
            photoblog: 'd1aa1oMyPxUIWOjD9RaBo3YX3whBPnra5YJdMGPkbYb.c7',
            other: 'ogHrQL_bJBonPtQSY7N5J5evTMmspyw7FWALGAEY00..n7',
        },
        401: null,
    };

    static quantcastCodes = {
        101: 'bP3pSgQmEGCFG',
        301: 'bP3pSgQmEGCFG',
        102: 'bP3pSgQmEGCFG',
        302: 'bP3pSgQmEGCFG',
        401: '-YyjHcCaprhaF'
    }

    static getName(id: SiteId) {
        return this.idToName[id];
    }

    static getUrl(id: SiteId) {
        return id === 101 ? `https://${this.idToHostname[id]}` : `https://www.${this.idToHostname[id]}`;
    }

    hostname: string;

    constructor(hostname: string) {
        this.hostname = hostname;
    }

    @computed get quantcastCode(): string | null {
        return Site.quantcastCodes[this.id]
    }

    @computed get url(): string {
        return Site.getUrl(this.id);
    }

    @computed get networkCode(): string {
        return Site.idToNetworkCode[this.id];
    }

    @computed get domain(): string {
        return this.hostname.split('.').slice(-2).join('.');
    }

    @computed get locale(): string {
        return (this.hostname.endsWith('.cz') && 'cs-cz') || 'sk-sk';
    }

    @computed get isCzech(): boolean {
        return this.locale === 'cs-cz';
    }

    @computed get isSlovak(): boolean {
        return this.locale === 'sk-sk';
    }

    @computed get countryCode(): string {
        return this.isSlovak ? 'sk' : 'cz';
    }

    @computed get isGarden(): boolean {
        return this.hostname.match(/zahrada/) !== null;
    }

    @computed get isLiving(): boolean {
        return this.hostname.match(/modrastrecha/) !== null;
    }

    @computed get isWedding(): boolean {
        return this.hostname.match(/mojasvadba|beremese/) !== null;
    }

    @computed get type(): string {
        if (this.isLiving) {
            return 'living';
        }
        if (this.isWedding) {
            return 'wedding';
        }
        return 'garden';
    }

    @computed get isPro(): boolean {
        return this.hostname.match(/-pro/) !== null;
    }

    @computed get company() {
        if (this.isGarden) {
            return 'JAGA GROUP, s.r.o.';
        }

        return '4networks SK&CZ, s.r.o.';
    }

    @computed get id(): SiteId {
        if (this.isSlovak) {
            if (this.isLiving) {
                return 301;
            } else if (this.isWedding) {
                return 101;
            }
            // garden
            return 401;
        }
        if (this.isLiving) {
            return 302;
        } else if (this.isWedding) {
            return 102;
        }

        throw new Error();
    }

    @computed get tagline(): string {
        if (this.isLiving) {
            return this.isSlovak
                ? 'Modrá strecha - Komunita okolo bývania, stavania a realít'
                : 'Modrá střecha - Komunita okolo bydlení, stavění a realit';
        }
        if (this.isGarden) {
            return 'Záhrada.sk - Jedinečná stránka tvorená záhradkármi pre záhradkárov';
        }
        return '';
    }

    @computed get defaultAvatar(): Img | null {
        if (this.isGarden) {
            return gardenAvatar;
        } else if (this.isLiving) {
            return livingAvatar;
        }

        return null;
    }

    @computed get name(): string {
        return Site.getName(this.id);
    }

    @computed get logoLight(): string | null {
        if (this.isGarden) {
            return gardenLight;
        }

        if (this.isLiving && this.isSlovak) {
            return livingLight;
        }

        if (this.isLiving && this.isCzech) {
            return livingLightCzech;
        }

        if (this.isWedding && this.isSlovak) {
            return weddingLogo;
        }

        if (this.isWedding && this.isCzech) {
            return weddingLogoCzech;
        }

        return null;
    }

    @computed get logoDark(): string | null {
        if (this.isLiving && this.isSlovak) {
            return livingDark;
        }

        if (this.isLiving && this.isCzech) {
            return livingDarkCzech;
        }

        if (this.isWedding && this.isSlovak) {
            return weddingLogo;
        }

        if (this.isWedding && this.isCzech) {
            return weddingLogoCzech;
        }

        return null;
    }

    @computed get admin(): string {
        if (this.isGarden) {
            return 'miselaho';
        }

        if (this.isCzech) {
            return 'michaela_recht';
        }

        return 'michaelarecht';
    }

    @computed get price(): number | string {
        if (this.isGarden) {
            return 'ZADARMO';
        }
        if (this.isSlovak) {
            return 16.8;
        } else {
            return 326.7;
        }
    }

    @computed get currency(): string {
        if (this.isSlovak) {
            return '€';
        }
        return 'Kč';
    }

    @computed get PROPhone(): string {
        return Site.idToPROPhone[this.id];
    }

    @computed get PROEmail(): string {
        return Site.idToPROEmail[this.id];
    }

    @computed get seethestats(): string {
        if (this.isLiving && this.isSlovak) {
            return 'http://www.seethestats.com/site/modrastrecha.sk';
        } else if (this.isLiving && this.isCzech) {
            return 'https://www.seethestats.com/site/modrastrecha.cz';
        } else if (this.isWedding && this.isSlovak) {
            return 'https://www.seethestats.com/site/mojasvadba.sk';
        } else if (this.isWedding && this.isCzech) {
            return 'https://www.seethestats.com/site/beremese.cz';
        }
        return '';
    }

    @computed get catalogHref(): string {
        if (this.isGarden) {
            return '/catalog/category/profesionali/';
        }
        return '/catalog/';
    }

    getGemiusCode = (pathname: string) => {
        const codes = Site.idToGemiusCodes[this.id];

        if (this.id === 101) {
            return 'nc.r4r8vJ.UOnFLuGmHnV5YUj_hpgGePIhZc92UU9Rf.u7';
        }

        if (!codes || !pathname) {
            return null;
        }

        if (pathname === '/') {
            return codes.homepage;
        }
        if (pathname.startsWith('/market')) {
            return codes.bazaar;
        }
        if (pathname.startsWith('/forum')) {
            return codes.forum;
        }
        if (pathname.startsWith('/blog')) {
            return codes.photoblog;
        }
        return codes.other;
    };

    @computed get gemiusScriptSrc() {
        if (this.isWedding && this.isSlovak) {
            return `//gemius.zoznam.sk/xgemius.js`;
        }
        return `//ga${this.countryCode}.hit.gemius.pl/xgemius.js`;
    }

    @computed get prArticlesUsername() {
        return this.isSlovak ? 'pr_clanok' : 'pr_clanek';
    }
}
