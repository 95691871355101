import React, { useRef, useState, useEffect, useContext } from 'react';
import Styled from 'styled';

import { FormattedMessage } from 'react-intl';

import gaTrack from 'four-nets/lib/google-analytics/track';
import LoadingWrapperView from 'four-nets/ui/loaders/loading-wrapper-view';
import { AlertContext } from 'lib/alert-context/alert-context';
import Modal from 'four-nets/ui-v2/modal/modal';
import Tabs from 'four-nets/ui-v2/tabs/tabs';

import FbLoginButton from './fb-login-button';
import { AuthOrigin } from './auth-ga-events';
import LoginForm, { gaEventCategory as LoginGaEventCategory } from './login-form';
import RegisterForm, { gaEventCategory as RegistrationGaEventCategory } from './register-form';
import defaultStyles from './login-dialog.scss';

export enum Tab {
    Login = 1,
    Register,
}

export interface Props {
    styles: typeof defaultStyles;
    open: boolean;
    origin: AuthOrigin;
    tab: Tab;
    onClose: () => void;
    onLoginSuccess?: () => void;
}

const LoginDialog: React.FC<Props> = props => {
    const { styles, tab, open } = props;
    const [activeTab, setActiveTab] = useState(props.tab);
    const dialogs = useContext(AlertContext);
    const [loading, setLoading] = useState(false);

    const trackedTabs = useRef([]);

    useEffect(() => {
        setActiveTab(tab);
    }, [tab]);

    useEffect(() => {
        if (!open || trackedTabs.current.indexOf(activeTab) !== -1) {
            return;
        }
        if (activeTab === Tab.Login) {
            gaTrack('event', LoginGaEventCategory, 'show login form', props.origin);
        } else {
            gaTrack('event', RegistrationGaEventCategory, 'registration login form', props.origin);
        }
        trackedTabs.current.push(activeTab);
    }, [open, activeTab]);

    const onClose = () => {
        trackedTabs.current = [];
        props.onClose();
    };

    return (
        <Modal open={props.open} onClose={onClose} includeHeader={true}>
            <div className={styles.wrapper}>
                <LoadingWrapperView loading={loading}>
                    <Tabs
                        tabs={[
                            {
                                onClick: () => setActiveTab(Tab.Login),
                                active: activeTab === Tab.Login,
                                child: <FormattedMessage id='LOGIN' />,
                            },
                            {
                                onClick: () => setActiveTab(Tab.Register),
                                active: activeTab === Tab.Register,
                                child: <FormattedMessage id='REGISTRATION' />,
                            },
                        ]}
                    />
                    <React.Fragment>
                        <div className={styles.socialWrapper}>
                            <FbLoginButton />
                            <div className={styles.divider}>
                                <div>alebo</div>
                            </div>
                        </div>
                        {activeTab === Tab.Login ? (
                            <LoginForm
                                setSubmitting={setLoading}
                                onSuccess={props.onLoginSuccess}
                                dialogs={dialogs}
                                onClose={onClose}
                                origin={props.origin}
                            />
                        ) : (
                            <RegisterForm
                                setSubmitting={setLoading}
                                onSuccess={props.onLoginSuccess}
                                dialogs={dialogs}
                                onClose={onClose}
                                origin={props.origin}
                            />
                        )}
                    </React.Fragment>
                </LoadingWrapperView>
            </div>
        </Modal>
    );
};

LoginDialog.defaultProps = {
    tab: Tab.Login,
};

export default Styled(defaultStyles)(LoginDialog);
