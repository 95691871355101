import React from 'react';
import H from 'history';
import { RouteComponentProps, match, useLocation, Route } from 'react-router';
import queryString from 'query-string';
import scalarQuery from './scalar-query';

export interface RouteWithSearchRenderProps<P = {}, S = {}> extends RouteComponentProps<P> {
    search: S;
}

interface RouteWithSearchProps {
    component?: React.ComponentType<RouteWithSearchRenderProps<any>> | React.ComponentType<any>;
    render?: (props: RouteWithSearchRenderProps<any>) => React.ReactNode;
    children?: ((props: RouteWithSearchRenderProps<any>) => React.ReactNode) | React.ReactNode;
    location?: H.Location;
    path?: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
    computedMatch?: match<any>;
}

const RouteWithSearch: React.FC<RouteWithSearchProps> = <P extends {}, S extends {}>(props: RouteWithSearchProps) => {
    const location = useLocation();
    const search = scalarQuery(queryString.parse(location.search)) as S;

    const { children, component, render, ...rest } = props;

    return (
        <Route {...rest}>
            {(routeProps: RouteComponentProps<P>) => {
                if (routeProps.match) {
                    if (Array.isArray(children) && children.length === 0) {
                        return null;
                    }

                    if (typeof children === 'function') {
                        return (children as (props: RouteWithSearchRenderProps<P, S>) => React.ReactNode)({
                            ...routeProps,
                            search,
                        } as RouteWithSearchRenderProps<P, S>);
                    }

                    if (render) {
                        return (render as (props: RouteWithSearchRenderProps<P, S>) => React.ReactNode)({
                            ...routeProps,
                            search,
                        } as RouteWithSearchRenderProps<P, S>);
                    }

                    if (component) {
                        return React.createElement(component, {
                            ...routeProps,
                            search,
                        } as RouteWithSearchRenderProps<P, S>);
                    }

                    return children || null;
                }

                return null;
            }}
        </Route>
    );
};

export default RouteWithSearch;
