import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field, FieldProps } from 'formik';
import Styled from 'styled';
import { Dialogs } from 'four-nets/ui-v2/modal/modal';

import SubmitButtonView from 'four-nets/ui/submit-button/submit-button-view';

import gaTrack from 'four-nets/lib/google-analytics/track';
import Input from 'four-nets/ui-v2/form/input/input';
import Checkbox from 'four-nets/ui-v2/form/checkbox/checkbox';
import Modal from 'four-nets/ui-v2/modal/modal';
import { mergeValidators, emptyValidator } from 'four-nets/ui-v2/form/validators';
import { ViewerContext } from 'four-nets/user/viewer/viewer';

import { AuthOrigin } from './auth-ga-events';
import PasswordResetForm from './password-reset-form';
import CrossLoginForm from './cross-company-rules-form';
import loginMutation, { LoginMutation, LoginMutationVariables } from './login.graphql';
import { LoginStatus } from './login.graphql.types';
import defaultStyles from './login-form.scss';

interface Props {
    styles: typeof defaultStyles;
    dialogs: Dialogs;
    // Origin represents an action which triggered rendering of login form.
    // It's used to track login conversions in google analytics
    origin?: AuthOrigin;
    initialValues?: Partial<Values>;
    onClose?: () => {};
    onSuccess?: () => {};
    setSubmitting?: (isSubmitting: boolean) => {};
    social?: boolean;
}

export interface Values {
    username: string;
    password: string;
    remember: boolean;
}

type Errors = Partial<{ [key in keyof Values]: React.ReactElement<FormattedMessage>[] | true }>;

export const gaEventCategory = 'Login Form';

const Login: React.FC<Props> = props => {
    const { styles, dialogs } = props;
    const { fail } = dialogs;

    const viewerContext = useContext(ViewerContext);

    const [crossLoginFormOpen, setCrossLoginOpen] = useState(false);
    const [forgottenPassOpen, setForgottenPassOpen] = useState(false);

    const [login] = useMutation<LoginMutation, LoginMutationVariables>(loginMutation);

    const closeCrossLoginForm = () => {
        setCrossLoginOpen(false);
    };

    const closeForgottenPassowrd = () => setForgottenPassOpen(false);

    const handleSuccess = () => {
        gaTrack('event', gaEventCategory, 'submit login form', props.origin);
        return viewerContext.updateViewer().then(props.onSuccess).then(props.onClose);
    };

    const onCrossLoginSuccess = () => {
        closeCrossLoginForm();
        return handleSuccess();
    };

    return (
        <>
            <Formik<Values>
                initialValues={{ username: '', password: '', remember: false, ...props.initialValues }}
                onSubmit={(values, { setSubmitting, setFieldValue, setFieldTouched }) => {
                    if (props.setSubmitting) {
                        props.setSubmitting(true);
                    }
                    login({
                        variables: {
                            ...values,
                        },
                    })
                        .then((value: LoginMutation) => {
                            const { status } = value.data.loginMutation;
                            if (status === LoginStatus.SUCCESS) {
                                return handleSuccess();
                            } else if (status === LoginStatus.CROSS) {
                                setCrossLoginOpen(true);
                            } else if (status === LoginStatus.NOT_VERIFIED) {
                                fail!({
                                    body: <FormattedMessage id='EMAIL_NOT_VERIFIED_VERBOSE' />,
                                    onAccept: () => {
                                        setFieldValue('password', '');
                                        setFieldTouched('password', false);
                                    },
                                });
                            } else {
                                fail!({
                                    body: <FormattedMessage id='API_UNABLE_TO_LOGIN' />,
                                    onAccept: () => {
                                        setFieldValue('password', '');
                                        setFieldTouched('password', false);
                                    },
                                });
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                            if (props.setSubmitting) {
                                props.setSubmitting(false);
                            }
                        });
                }}
                validate={values => {
                    const errors: Errors = {};
                    const usernameError = mergeValidators([emptyValidator])(values.username);
                    if (usernameError) {
                        errors.username = usernameError;
                    }
                    const passwordError = mergeValidators([emptyValidator])(values.password);
                    if (passwordError) {
                        errors.password = passwordError;
                    }
                    return errors;
                }}
            >
                {formik => {
                    const { touched, errors, isSubmitting, values } = formik;
                    return (
                        <React.Fragment>
                            <CrossLoginForm
                                open={crossLoginFormOpen}
                                loginValues={values}
                                onSuccess={onCrossLoginSuccess}
                                onClose={closeCrossLoginForm}
                            />
                            <Form id='login-form' className={styles.form}>
                                <Field name='username'>
                                    {({ field }: FieldProps) => (
                                        <FormattedMessage id='USERNAME_OR_EMAIL'>
                                            {placeholder => (
                                                <Input
                                                    errors={errors.username}
                                                    placeholder={placeholder}
                                                    touched={touched.username}
                                                    {...field}
                                                />
                                            )}
                                        </FormattedMessage>
                                    )}
                                </Field>
                                <Field name='password'>
                                    {({ field }: FieldProps) => (
                                        <FormattedMessage id='PASSWORD'>
                                            {placeholder => (
                                                <Input
                                                    errors={errors.password}
                                                    type='password'
                                                    placeholder={placeholder}
                                                    touched={touched.password}
                                                    {...field}
                                                />
                                            )}
                                        </FormattedMessage>
                                    )}
                                </Field>
                                <div className={styles.passwordResetWrapper}>
                                    <button
                                        type='button'
                                        className={styles.passwordResetButton}
                                        onClick={() => setForgottenPassOpen(true)}
                                    >
                                        <FormattedMessage id='DID_YOU_FORGET_YOUR_PASSWORD' values={{ gender: 'm' }} />
                                    </button>
                                </div>
                                <Field name='remember'>
                                    {({ field }: FieldProps) => (
                                        <FormattedMessage id='REMEMBER_ME'>
                                            {label => <Checkbox label={label} touched={touched.remember} {...field} />}
                                        </FormattedMessage>
                                    )}
                                </Field>
                                <SubmitButtonView type='submit' disabled={isSubmitting} form='login-form'>
                                    <FormattedMessage id='LOGIN_BUTTON' />
                                </SubmitButtonView>
                            </Form>
                            <Modal
                                style={{ modal: styles.modal }}
                                title={<FormattedMessage id='DID_YOU_FORGET_YOUR_PASSWORD' values={{ gender: 'm' }} />}
                                open={forgottenPassOpen}
                                onClose={closeForgottenPassowrd}
                            >
                                <PasswordResetForm onSuccess={closeForgottenPassowrd} dialogs={props.dialogs} />
                            </Modal>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </>
    );
};

Login.defaultProps = {
    social: true,
    onClose: () => Promise.resolve(),
    onSuccess: () => Promise.resolve(),
};

export default Styled(defaultStyles)(Login);
