import React from 'react';
import AdUnitWithRoute, { AdUnitProps } from '../google-dfp';
import styles from '../google-dfp.scss';

export const Branding = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [0, 0],
            ['fluid', [320, 50], [320, 100], [320, 150]],
        ],
        [
            [1024, 0],
            ['fluid', [1470, 600], [1110, 150]],
        ],
    ];
    return (
        <AdUnitWithRoute
            adUnitCode='branding'
            sizes={['fluid', [1470, 600], [1110, 150], [320, 50], [320, 100], [320, 150]]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};

export const PhotoPresentationBranding = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [1024, 0],
            [
                [320, 50],
                [320, 100],
                [320, 150],
            ],
        ],
    ];

    const wrapperStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '15px 0px 5px 15px',
    };

    return (
        <AdUnitWithRoute
            adUnitCode='branding'
            sizes={[
                [320, 50],
                [320, 100],
                [320, 150],
            ]}
            sizeMapping={sizeMapping}
            wrapperStyle={wrapperStyle}
            innerStyle={{
                justifyContent: 'flex-start',
            }}
            refreshSeconds={8}
            {...props}
        />
    );
};

export const PhotoPresentationMobileBranding = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [0, 1024],
            [
                [320, 50],
                [320, 100],
                [320, 150],
            ],
        ],
    ];

    const wrapperStyle = {
        justifyContent: 'center',
        backgroundColor: 'black',
    };

    return (
        <AdUnitWithRoute
            adUnitCode='branding'
            sizes={[
                [320, 50],
                [320, 100],
                [320, 150],
            ]}
            sizeMapping={sizeMapping}
            wrapperStyle={wrapperStyle}
            innerStyle={{
                justifyContent: 'center',
            }}
            styles={{
                wrapper: styles.brandingMobile,
            }}
            {...props}
        />
    );
};

export const ZoznamLeaderboard = (props: AdUnitProps) => {
    const sizeMapping = [
        [
            [0, 0],
            ['fluid', [320, 50], [320, 100], [320, 150]],
        ],
        [
            [1024, 0],
            ['fluid', [1470, 600], [1110, 150], [970, 90], [990, 100]],
        ],
    ];
    return (
        <AdUnitWithRoute
            overrideAdUnitCode={true}
            adUnitCode='/60012913/Mojasvadba_leaderboard_hore'
            sizes={[[1470, 600], [970, 90], [990, 100], [300, 100], 'fluid', [300, 250], [300, 300], [320, 50]]}
            sizeMapping={sizeMapping}
            {...props}
        />
    );
};
