import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import SVGIcon from 'lib/images/svg-icon/svg-icon';
import { keyBackSpacePressed, keySpacePressed } from 'lib/key-code/key-code';

import deleteIcon from 'four-nets/icons/svg/icon_delete 2.svg';
import UsersAutocomplete from 'four-nets/user/users-autocomplete/users-autocomplete';
import { Site } from 'four-nets/site';
import Spinner from 'four-nets/ui/spinner/spinner';

import defaultStyles from './users-input.scss';

export interface Props {
    styles: typeof defaultStyles;
    onChange: (users: string[], value: string) => void;
    maxLength?: number;
    groupId?: string;
    users?: string[];
}

interface State {
    users: string[];
    searchValue: string;
    value: string;
}

class UsersInput extends React.PureComponent<Props, State> {
    static contextTypes = {
        site: PropTypes.instanceOf(Site),
    };

    static defaultProps = {
        maxLength: 5,
    };

    ref: React.RefObject<HTMLDivElement> = React.createRef();

    setSearchValue = debounce((searchValue: string) => this.setState({ searchValue }), 300);

    constructor(props: Props) {
        super(props);
        this.state = { users: props.users || [], searchValue: '', value: '' };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { users, value } = prevState;
        this.props.onChange(users, value);
    }

    onChange = (username: string) => {
        this.setState(
            prevState => {
                const users = Array.from(prevState.users);
                const trimmedUsername = username.toLowerCase().trim();
                if (!users.includes(trimmedUsername)) {
                    users.push(trimmedUsername);
                }
                return { users, searchValue: '', value: '' };
            },
            () => {
                this.props.onChange(this.state.users, this.state.value);
            }
        );
        this.focus();
    };

    focus = () => {
        if (this.ref && this.ref.current) {
            this.ref.current.focus();
        }
    };

    onType = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.users.length < this.props.maxLength!) {
            event.persist();
            const trimmedUsername = event.target.value.toLowerCase().trim();
            this.setState({ value: trimmedUsername });
            this.setSearchValue(trimmedUsername);
        } else {
            event.stopPropagation();
        }
    };

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (keyBackSpacePressed(event)) {
            if (!this.state.value && this.state.users.length) {
                this.setState(
                    prevState => {
                        const users = Array.from(prevState.users);
                        users.pop();
                        return { users };
                    },
                    () => {
                        this.props.onChange(this.state.users, this.state.value);
                    }
                );
            }
        } else if (keySpacePressed(event)) {
            if (this.state.value.trim()) {
                this.onChange(this.state.value);
            }
        }
    };

    render() {
        const { styles, groupId } = this.props;
        const maxLength = this.props.maxLength!;
        return (
            <UsersAutocomplete value={this.state.searchValue} onChange={this.onChange} groupId={groupId}>
                {(onKeyDown, loading) => (
                    <div
                        className={classnames(styles.inputWrapper, {
                            [styles.focused]: this.state.value,
                            [styles.errorBorder]: this.state.users.length === maxLength && this.state.value,
                        })}
                        onClick={this.focus}
                    >
                        {this.state.users.map(user => (
                            <div key={user} className={styles.user}>
                                {user}
                                <button
                                    className={styles.deleteButton}
                                    type='button'
                                    onClick={() => {
                                        this.setState(prevState => {
                                            const users = prevState.users;
                                            if (users.includes(user)) {
                                                users.splice(users.indexOf(user), 1);
                                            }
                                            return {
                                                users: Array.from(users),
                                            };
                                        });
                                    }}
                                >
                                    <SVGIcon style={{ image: styles.deleteIcon }} src={deleteIcon} />
                                </button>
                            </div>
                        ))}
                        <input
                            className={styles.usersInput}
                            value={this.state.value}
                            onChange={this.onType}
                            onKeyDown={event => {
                                event.persist();
                                this.onKeyDown(event);
                                onKeyDown(event);
                            }}
                            ref={this.ref as any}
                        />
                        {loading ? <Spinner style={{ spinner: styles.spinner }} mini={true} /> : null}
                    </div>
                )}
            </UsersAutocomplete>
        );
    }
}

export default Styled(defaultStyles)(UsersInput);
