import React from 'react';
import Styled from 'styled';
import { observer } from 'mobx-react';
import defaultStyles from './title-view.scss';

@observer
class TitleView extends React.Component {
    render() {
        const { styles, leftComponent, rightComponent, title } = this.props;

        const classNames = [styles.title];

        if (leftComponent && rightComponent) {
            classNames.push(styles.hasBothComponents);
        }

        return (
            <div className={classNames.join(' ')}>
                <div className={styles.text}>{title}</div>
                {leftComponent || rightComponent ? (
                    <div className={styles.components}>
                        {leftComponent}
                        {rightComponent}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Styled(defaultStyles)(TitleView);
