import React from 'react';
import { observer } from 'mobx-react';
import Styled from 'styled';

import { RetryDialog } from 'lib/alerts/dialog';
import withDialog from 'lib/alerts/with-dialog';
import { keyEnterPressed } from 'lib/key-code/key-code';

import defaultStyles from './form-view.scss';

@withDialog
@Styled(defaultStyles)
@observer
class FormView extends React.Component {
    static defaultProps = {
        reset: true,
        submitOnEnter: false,
    };

    onKeyUp = e => {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(e);
        }

        const { form, submitOnEnter, submit, submitNotDirty } = this.props;
        const { valid, dirty } = form;

        const submitFuction = submit || form.submit;

        if (submitOnEnter && keyEnterPressed(e)) {
            if (valid && (submitNotDirty || dirty)) {
                const retry = () => {
                    submitFuction().catch(err => {
                        this.props.showDialog(
                            new RetryDialog({
                                message: err.message,
                                retry,
                            })
                        );
                    });
                };

                retry();
            }
        }
    };

    componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }

        if (this.props.form && this.props.form.reset && this.props.form.dirty) {
            this.props.form.reset();
        }
    }

    render() {
        const {
            form,
            children,
            reset,
            submitNotDirty,
            submitOnEnter,
            showDialog,
            submit,
            styles,
            ...rest
        } = this.props;

        return (
            <form className={styles.form} onKeyUp={this.onKeyUp}>
                {children}
                <input key='hidden' type='text' className={styles.input} style={{ display: 'none' }} />
            </form>
        );
    }
}

export default FormView;
