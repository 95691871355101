import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Styled from 'styled';
import { Formik } from 'formik';
import { Field, FieldProps } from 'formik';
import { Form } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/client/react/hooks';
import { fromGlobalId } from 'graphql-relay';

import SVGIcon from 'lib/images/svg-icon/svg-icon';

import IconMessage from 'four-nets/icons/svg/icon_message-2.svg';
import IconClose from 'four-nets/icons/svg/icon_close.svg';
import { mergeValidators, emptyValidator } from 'four-nets/ui-v2/form/validators';
import Input from 'four-nets/ui-v2/form/input/input';
import TextArea from 'four-nets/ui-v2/form/text-area/text-area';
import UserInput from 'four-nets/photoblog/groups/users-input/users-input';
import FileInput from 'four-nets/ui-v2/form/file-input/file-input';
import { AlertContext } from 'lib/alert-context/alert-context';
import Attachment from 'four-nets/mails/attachment/attachment';
import LoadingWrapperView from 'four-nets/ui/loaders/loading-wrapper-view';

import createMailAttachmentsMutation, {
    CreateMailAttachmentsMutation,
    CreateMailAttachmentsMutationVariables,
} from './mutations/create-mail-attachments.graphql';
import removeMailAttachmentMutation, {
    RemoveMailAttachmentMutation,
    RemoveMailAttachmentMutationVariables,
} from './mutations/remove-mail-attachment.graphql';
import createMailMutation, { CreateMailMutation, CreateMailMutationVariables } from './mutations/create-mail.graphql';
import defaultStyles from './compose-mail.scss';

interface Values {
    subject: string;
    body: string;
}

export interface Props extends RouteComponentProps<Values, {}, Values & { to: string[] }> {
    styles: typeof defaultStyles;
    onMessageSuccessfullySent: (messageId: String) => void;
    hideTitle?: boolean;
}

type Errors = Partial<{ [key in keyof Values]: Array<React.ReactElement<FormattedMessage>> | true }>;

type Attachments = NonNullable<CreateMailAttachmentsMutation['createMailAttachments']>['attachments'];

const ComposeMail: React.FC<Props> = props => {
    const { styles, location, hideTitle, onMessageSuccessfullySent: messageSent } = props;
    const to = (location.state && location.state.to) || [];
    const subject = (location.state && location.state.subject) || '';
    const body = (location.state && location.state.body) || '';

    const [recipients, setRecipients] = useState<string[]>(to);
    const [value, setValue] = useState('');
    const [attachments, setAttachments] = useState<Attachments>([]);

    const { fail } = useContext(AlertContext);

    const [createMailAttachments, { loading }] = useMutation<
        CreateMailAttachmentsMutation,
        CreateMailAttachmentsMutationVariables
    >(createMailAttachmentsMutation);
    const [removeMailAttachmentFn] = useMutation<RemoveMailAttachmentMutation, RemoveMailAttachmentMutationVariables>(
        removeMailAttachmentMutation
    );
    const [createMail] = useMutation<CreateMailMutation, CreateMailMutationVariables>(createMailMutation);

    const onChange = (users: string[], value: string) => {
        setRecipients(users);
        setValue(value);
    };

    const removeMailAttachment = (id: string) => {
        removeMailAttachmentFn({ variables: { input: { id } } });
        const newAttachments: Attachments = [];
        if (attachments) {
            attachments.forEach(attachment => {
                if (attachment && attachment.id !== id) {
                    newAttachments.push(attachment);
                }
            });
        }
        setAttachments(newAttachments);
    };

    return (
        <Formik<Values>
            validate={values => {
                const errors: Errors = {};
                const subjectErrors = mergeValidators([emptyValidator])(values.subject);
                const bodyErrors = mergeValidators([emptyValidator])(values.body);
                if (subjectErrors) {
                    errors.subject = subjectErrors;
                }
                if (bodyErrors) {
                    errors.body = bodyErrors;
                }
                return errors;
            }}
            validateOnMount={true}
            initialValues={{ subject, body }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true);

                const { body, subject } = values;
                const users = Array.from([...recipients, value.trim()].filter(Boolean));
                const attachmentsIds =
                    attachments && attachments.map(attachment => attachment && attachment.id).filter(Boolean);

                createMail({
                    variables: { input: { subject, body, attachmentsIds, to: users } },
                })
                    .then(({ data }) => {
                        if (data && data.createMail && data.createMail.mailUser) {
                            messageSent(fromGlobalId(data.createMail.mailUser.id).id);
                        } else {
                            fail!({
                                onAccept: () => null,
                                body: <FormattedMessage id='ERROR' />,
                            });
                        }
                    })
                    .catch(error => {
                        let msg = error.message;
                        const regexp = /^GraphQL error: \['(.*?)'\]$/g;
                        const match = regexp.exec(error.message);
                        if (match && match.length) {
                            msg = match[1];
                        }
                        fail!({ onAccept: () => null, body: msg });
                    })
                    .finally(() => {
                        actions.setSubmitting(false);
                    });
            }}
        >
            {formik => {
                const { isSubmitting, isValid, setFieldValue, touched, errors } = formik;

                return (
                    <LoadingWrapperView loading={isSubmitting}>
                        {hideTitle ? null : (
                            <FormattedMessage id='MAILS_FORM_TITLE_WRITE_NEW'>
                                {msg => <h1 className={styles.title}>{msg}</h1>}
                            </FormattedMessage>
                        )}
                        <Form className={styles.form}>
                            <FormattedMessage id='TO_WHOM'>
                                {msg => <span className={styles.label}>{msg}</span>}
                            </FormattedMessage>
                            <UserInput users={to} onChange={onChange} maxLength={10} />
                            <Field name='subject'>
                                {({ field }: FieldProps) => (
                                    <label>
                                        <FormattedMessage id='SUBJECT'>
                                            {msg => <span className={styles.label}>{msg}</span>}
                                        </FormattedMessage>
                                        <Input
                                            errors={errors.subject ? (errors.subject as any) : undefined}
                                            touched={touched.subject}
                                            {...field}
                                        />
                                    </label>
                                )}
                            </Field>
                            <Field name='body'>
                                {({ field }: FieldProps) => (
                                    <label>
                                        <FormattedMessage id='FORM_FIELD_LABEL_BODY'>
                                            {msg => <span className={styles.label}>{msg}</span>}
                                        </FormattedMessage>
                                        <TextArea
                                            errors={errors.body ? errors.body : undefined}
                                            touched={touched.body}
                                            {...field}
                                            onChange={e => setFieldValue('body', e.target.value)}
                                        />
                                    </label>
                                )}
                            </Field>
                            <FileInput
                                style={{ fileInput: styles.fileInput }}
                                limit={5}
                                disabled={loading}
                                onChange={event => {
                                    if (event.target.files) {
                                        createMailAttachments({
                                            variables: {
                                                input: {
                                                    files: Array.from(event.target.files),
                                                },
                                            },
                                        }).then(result => {
                                            if (
                                                result.data &&
                                                result.data.createMailAttachments &&
                                                result.data.createMailAttachments.attachments
                                            ) {
                                                setAttachments(result.data.createMailAttachments.attachments);
                                            }
                                        });
                                    }
                                }}
                                onClick={() => {
                                    /* pass */
                                }}
                            />
                            <LoadingWrapperView loading={loading}>
                                {attachments &&
                                    attachments.map(attachment =>
                                        attachment && attachment.id ? (
                                            <div className={styles.attachemntWrapper}>
                                                <button
                                                    type='button'
                                                    className={styles.deleteAttachemnt}
                                                    onClick={() => {
                                                        removeMailAttachment(attachment.id);
                                                    }}
                                                >
                                                    <SVGIcon style={{ image: styles.deleteIcon }} src={IconClose} />
                                                </button>
                                                <Attachment
                                                    style={{ atachment: styles.attachment }}
                                                    attachment={attachment}
                                                    key={attachment.id}
                                                />
                                            </div>
                                        ) : null
                                    )}
                            </LoadingWrapperView>
                            <button
                                type='submit'
                                className={styles.submitButton}
                                disabled={
                                    !isValid || isSubmitting || loading || (recipients.length === 0 && value === '')
                                }
                            >
                                <SVGIcon style={{ image: styles.submitIcon }} src={IconMessage} />
                                <FormattedMessage id='BAZAAR_SEND_MESSAGE' />
                            </button>
                        </Form>
                    </LoadingWrapperView>
                );
            }}
        </Formik>
    );
};

export default Styled(defaultStyles)(withRouter(ComposeMail));
