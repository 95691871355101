import React from 'react';
import {observable, untracked, autorun} from 'mobx';
import {observer} from 'mobx-react';

@observer
class CodeSplitView extends React.Component {
    static defaultProps = {
        shouldLoad: true,
    };

    @observable error = null;
    @observable loading = false;
    @observable view = null;
    @observable retry;

    promise;

    loadWillStart = () => {
        this.cancelLoad();
        this.loading = true;
    }

    loadDidSucceed = (view) => {
        this.view = view;
    }

    loadDidFail = (error) => {
        this.error = error;
    }

    loadDidFinish = () => {
        this.loading = false;
    }

    cancelLoad() {
        if (this.promise) {
            this.promise.cancel();
        }

        this.error = null;
        this.promise = null;
    }

    load(fn) {
        this.loadWillStart();

        this.promise = fn()
            .then(this.loadDidSucceed)
            .catch(this.loadDidFail)
            .finally(this.loadDidFinish);
    }

    componentDidMount() {
        setTimeout(() => {
            this.disposer = autorun(() => {
                const fn = this.props.load;
                const shouldLoad = this.props.shouldLoad;
    
                this.view = null;
    
                untracked(() => {
                    if (fn && shouldLoad) {
                        const retry = () => {
                            this.load(fn);
                        };
    
                        retry();
                        this.retry = retry;
    
                    } else {
                        this.retry = null;
                        this.cancelLoad();
                    }
                });
            });
        }, 0);
    }

    componentWillUnmount() {

        if (this.disposer) {
            this.disposer();
        }
        this.cancelLoad();
    }

    render() {
        const {loading, error, retry} = this;

        let children = null;

        if (this.props.children) {
            children = React.cloneElement(
                React.Children.only(this.props.children), {
                    loading,
                    error,
                    retry,
                },
            );
        }

        return this.view || children;
    }
}

export default CodeSplitView;
