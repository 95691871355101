import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Action } from './common';

const NOOP = () => {};

export class Dialog {
    title: ?LocalizedString;
    message: ?LocalizedString;

    actions: Action[];
    blocking: boolean;
    cancel: ?() => void;
    store;

    presented = false;

    setPresented(presented: boolean) {
        this.presented = presented;
    }

    setStore = store => {
        this.store = store;
    };

    constructor(options) {
        const { actions = [], blocking = false, message, title, cancel = NOOP } = options;

        this.cancel = cancel;
        this.title = title;
        this.message = message;
        this.actions = actions.map(item => {
            if (item.callback) {
                if (item.callback === cancel) {
                    item.callback = this.cancel;
                }
            } else {
                item.callback = this.cancel;
            }

            return item;
        });

        this.blocking = blocking;
    }
}

export class InfoDialog extends Dialog {
    constructor(options) {
        const { title, message, onOK, blocking } = options;

        super({
            title,
            message,
            actions: [
                new Action({
                    label: defineMessages({ BUTTON_LABEL_OK: { id: 'BUTTON_LABEL_OK' } }).BUTTON_LABEL_OK,
                    callback: onOK,
                }),
            ],
            blocking,
            cancel: onOK,
        });
    }
}

export class ErrorDialog extends Dialog {
    constructor(options) {
        const { title, message, onOK, blocking } = options;

        super({
            title: title || defineMessages({ ERROR: { id: 'ERROR' } }).ERROR,
            message,
            actions: [
                new Action({
                    label: defineMessages({ BUTTON_LABEL_OK: { id: 'BUTTON_LABEL_OK' } }).BUTTON_LABEL_OK,
                    callback: onOK,
                }),
            ],
            blocking,
            cancel: onOK,
        });
    }
}

export class RetryDialog extends Dialog {
    constructor(options) {
        const { title, message, cancel, retry } = options;

        super({
            title,
            message,
            actions: [
                new Action({
                    label: defineMessages({ BUTTON_LABEL_CANCEL: { id: 'BUTTON_LABEL_CANCEL' } }).BUTTON_LABEL_CANCEL,
                    callback: cancel,
                }),
                new Action({
                    label: defineMessages({ BUTTON_LABEL_RETRY: { id: 'BUTTON_LABEL_RETRY' } }).BUTTON_LABEL_RETRY,
                    callback: retry,
                }),
            ],
            cancel,
        });
    }
}

export class DeleteDialog extends Dialog {
    constructor(options) {
        const { title, message, cancel, del, labelCancel, label } = options;

        super({
            title,
            message,
            actions: [
                new Action({
                    label:
                        labelCancel ||
                        defineMessages({ BUTTON_LABEL_CANCEL: { id: 'BUTTON_LABEL_CANCEL' } }).BUTTON_LABEL_CANCEL,
                    callback: cancel,
                }),
                new Action({
                    label:
                        label ||
                        defineMessages({ BUTTON_LABEL_DELETE: { id: 'BUTTON_LABEL_DELETE' } }).BUTTON_LABEL_DELETE,
                    callback: del,
                }),
            ],
            cancel,
        });
    }
}

export class CriticalDialog extends Dialog {
    constructor(options) {
        const { title, message, retry } = options;

        super({
            title,
            message,
            actions: [
                new Action({
                    label: defineMessages({ BUTTON_LABEL_RETRY: { id: 'BUTTON_LABEL_RETRY' } }).BUTTON_LABEL_RETRY,
                    callback: retry,
                }),
            ],
            blocking: true,
        });
    }
}
