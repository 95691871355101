/* @flow */
import PropTypes from 'prop-types';

import React from 'react';
import { observer } from 'mobx-react';
import { autorun, observable, action, computed, untracked } from 'mobx';
import { loadScriptWithBackoff } from 'lib/utils/dom';

const ID = 'facebook-jssdk';

@observer
class FacebookSDKLoaderView extends React.Component {
    static contextTypes = {
        intl: PropTypes.object,
    };

    getChildContext() {
        return {
            facebookSDKLoaderView: this,
        };
    }

    @computed get requested() {
        return this.requests > 0;
    }

    @observable params = observable.map({});

    @observable loaded = false;
    @observable loading = false;
    @observable error;

    meta: HTMLMetaElement;

    @observable requests = 0;

    promise: ?Promise<any>;
    disposer: ?Function;

    @action request() {
        this.requests += 1;
    }

    @action unrequest() {
        this.requests -= 1;
    }

    @action handleProps(props = this.props) {
        const { params } = props;
        this.params.merge(params);
    }

    UNSAFE_componentWillMount() {
        this.handleProps();
    }

    componentWillReceiveProps(props) {
        this.handleProps(props);
    }

    @action loadDidFail = event => {
        this.loading = false;
        this.error = event;
    };

    @action load = () => {
        const script = document.getElementById(ID);

        let locale;

        switch (this.context.intl.locale) {
            case 'sk-sk':
                locale = 'sk_SK';
                break;
            case 'cs-cz':
                locale = 'cs_CZ';
                break;
            default:
                locale = 'en_US';
        }

        const url = `//connect.facebook.net/${locale}/sdk.js`;

        if (script && script.src === url) {
            return;
        }

        this.error = null;
        this.loaded = false;

        if (script) {
            script.parentNode.removeChild(script);
        }

        if (window.FB) {
            delete window.FB;
        }

        this.loading = true;

        this.promise = loadScriptWithBackoff({
            src: url,
        })
            .then((event: Event) => {
                event.target.id = ID;

                window.FB.init(this.params.toJS());
                window.FB.AppEvents.logPageView();

                this.loaded = true;
                this.loading = false;
            })
            .catch(this.loadDidFail);

        return this.promise;
    };

    retry = () => {
        if (this.error && !this.loading) {
            this.load();
        }
    };

    componentDidMount() {
        const disposers = [
            autorun(() => {
                if (!this.requested && untracked(() => this.loading) && this.promise && this.promise.isPending()) {
                    this.promise.cancel();
                    this.loading = false;
                }

                if (this.requested && this.params) {
                    this.load();
                }
            }),
        ];

        this.disposer = () => {
            disposers.forEach(disposer => {
                disposer();
            });
        };
    }

    componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }
        if (this.promise && this.promise.isPending()) {
            this.promise.cancel();
        }

        if (this.meta) {
            this.meta.parentNode.removeChild(this.meta);
            this.meta = null;
        }
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

FacebookSDKLoaderView.childContextTypes = {
    facebookSDKLoaderView: PropTypes.instanceOf(FacebookSDKLoaderView),
};

export default FacebookSDKLoaderView;
