import React from 'react';
import KeyCode from 'keycode-js';

/* https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code */

export const keyEscapePressed = (event: React.KeyboardEvent) => {
    return event.key === 'Escape' || event.keyCode === KeyCode.KEY_ESCAPE;
};

export const keyEnterPressed = (event: React.KeyboardEvent) => {
    return event.key === 'Enter' || event.keyCode === KeyCode.KEY_RETURN || event.keyCode === KeyCode.KEY_ENTER;
};

export const keyBackSpacePressed = (event: React.KeyboardEvent) => {
    return event.key === 'Backspace' || event.keyCode === KeyCode.KEY_BACK_SPACE;
};

export const keySpacePressed = (event: React.KeyboardEvent) => {
    return event.key === 'Space' || event.keyCode === KeyCode.KEY_SPACE;
};

export const keyDownPressed = (event: React.KeyboardEvent) => {
    return event.key === 'ArrowDown' || event.keyCode === KeyCode.KEY_DOWN;
};

export const keyUpPressed = (event: React.KeyboardEvent) => {
    return event.key === 'ArrowUp' || event.keyCode === KeyCode.KEY_UP;
};
