/* @flow */

import React from 'react';
import { observer } from 'mobx-react';
import Styled from 'styled';
import RouteLinkView from 'lib/ui/route-link-view';
import defaultStyles from './partner-link-view.scss';

@Styled(defaultStyles)
@observer
class PartnerLinkView extends React.Component {
    render() {
        const { label, href, target, rel, styles } = this.props;

        return (
            <RouteLinkView href={href} target={target} rel={rel} className={styles.partnerLink}>
                {label}
            </RouteLinkView>
        );
    }
}

export default PartnerLinkView;
