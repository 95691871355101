import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Styled from 'styled';
import { withRouter } from 'react-router';

import FormView from 'lib/form/form-view';
import { Form, Field } from 'lib/form/form';
import { ValueFieldData, RequiredValidator } from 'lib/form/value-field-data';
import { SectionCountConsumer, SiteSection } from 'lib/section-count-context/section-count-context';
import { Link } from 'lib/ui/link';
import { Link as RouterLink } from 'react-router-dom';

import SVGIcon from 'lib/images/svg-icon/svg-icon';

import SearchInputV2 from 'four-nets/ui-v2/search-input/search-input-v2';
import { UserStore } from 'four-nets/user/user';
import zoznamLogo from 'four-nets/img/zoznam-biele.svg';

import LinkView from '../link-view';
import LogoView from '../logo-view';
import UserPanelView, { UserPanelViewModel } from '../user-panel/user-panel-view';

import defaultStyles from './navigation-view.scss';
import highlightedLinkStyles from './highlighted-link.scss';
import searchInputStyles from './search-input-view.scss';
import { FormattedMessage } from 'react-intl';

import linkStyles from '../link-view.scss';

const PHOTOBLOG_ADDITIONAL_ACTIVE_LINKS = ['/group/', '/list-of-groups/', '/blog', '/new-group', '/inspirations'];

export class NavigationViewModel {
    @observable menuIsShown: boolean;

    links: Link[];
    userPanelViewModel: UserPanelViewModel;

    searchForm: Form;

    constructor(options: {
        menuIsShown?: boolean,
        site: Site,
        links: Link[],
        userStore: UserStore,
        urlBuilder: URLBuilder,
        menuMailListViewModel: MenuMailListViewModel,
        intl: any,
    }) {
        const { menuIsShown = false, userStore, links, urlBuilder, site, intl } = options;

        this.userPanelViewModel = new UserPanelViewModel({
            userStore,
            links,
            urlBuilder,
            site,
            intl,
        });

        this.menuIsShown = menuIsShown;
        this.links = links;
        this.userStore = userStore;

        this.searchForm = new Form({
            field: {
                query: new Field({
                    data: new ValueFieldData({
                        value: '',
                        validators: [new RequiredValidator()],
                    }),
                }),
            },
            handleSubmit: this.handleSearchSubmit,
        });

        this.atlasLink = site.isGarden ? new Link({ href: '/atlas', label: 'Atlas', target: '_parent' }) : null;
    }

    handleSearchSubmit = options => {
        const { data } = options;
        const { query } = data;

        this.searchForm.field.get('query').data.value = '';

        return new Promise((resolve, reject) => {
            this.history.push(`/search/?query=${query}`);
            resolve();
        });
    };
}

@Styled(defaultStyles)
@observer
class NavigationView extends React.Component {
    static contextTypes = {
        site: PropTypes.object,
    };

    UNSAFE_componentWillMount() {
        this.props.navigationViewModel.history = this.props.history;
    }

    render() {
        const { navigationViewModel, styles } = this.props;

        const { site } = this.context;

        return (
            <div className={styles.navigation}>
                <div className={styles.navigationWrapper}>
                    <div className={styles.centerer}>
                        <div key='logo'>
                            <LogoView />
                        </div>
                        <nav
                            key='links'
                            className={`${styles.nav} ${navigationViewModel.menuIsShown ? styles.shown : ''}`}
                        >
                            <SectionCountConsumer>
                                {({ updateSectionCount }) => (
                                    <ul className={styles.list}>
                                        {navigationViewModel.links.map(link => {
                                            if (link.href == '/market/') {
                                                return (
                                                    <li className={styles.listItem} key='/market/'>
                                                        <a href='/market/'>
                                                            <span className={linkStyles.link}>
                                                                <FormattedMessage id='BAZAAR' />
                                                            </span>
                                                        </a>
                                                    </li>
                                                );
                                            }

                                            if (link.href == '/forum/' && site.isGarden == false) {
                                                return (
                                                    <li className={styles.listItem} key='/forum/'>
                                                        <a href='/forum/'>
                                                            <span className={linkStyles.link}>
                                                                <FormattedMessage id='FORUM' />
                                                            </span>
                                                        </a>
                                                    </li>
                                                );
                                            }

                                            if (link.href == '/catalog/' && !site.isGarden) {
                                                return (
                                                    <li className={styles.listItem} key='/catalog/'>
                                                        <a href='/catalog/'>
                                                            <span className={linkStyles.link}>
                                                                <FormattedMessage id={link.label} />
                                                            </span>
                                                        </a>
                                                    </li>
                                                );
                                            }

                                            return (
                                                <li className={styles.listItem} key={link.href}>
                                                    <LinkView
                                                        link={link}
                                                        additionalActiveLinks={
                                                            link.label === 'Fotoblogy'
                                                                ? PHOTOBLOG_ADDITIONAL_ACTIVE_LINKS
                                                                : null
                                                        }
                                                        onClick={() => {
                                                            if (link.href === '/forum/') {
                                                                updateSectionCount(SiteSection.FORUM);
                                                            } else if (link.href === '/blogs/') {
                                                                updateSectionCount(SiteSection.BLOGS);
                                                            } else if (link.href === '/market/') {
                                                                // updateSectionCount(SiteSection.MARKET);
                                                            } else if (link.href === '/catalog/') {
                                                                updateSectionCount(SiteSection.CATALOG);
                                                            }
                                                        }}
                                                    />
                                                </li>
                                            );
                                        })}
                                        {navigationViewModel.atlasLink ? (
                                            <li className={styles.listItem} key='/atlas'>
                                                <LinkView
                                                    styles={highlightedLinkStyles}
                                                    link={navigationViewModel.atlasLink}
                                                >
                                                    Atlas
                                                </LinkView>
                                            </li>
                                        ) : null}
                                        {(site.isWedding || site.isLiving) && (
                                            <li className={styles.listItem} key='/tools'>
                                                <a href='/tools'>
                                                    <span className={linkStyles.link}>
                                                        <FormattedMessage id='Nástroje' />
                                                    </span>
                                                </a>
                                            </li>
                                        )}
                                        {site.isLiving && (
                                            <li className={styles.listItem} key='/magazine'>
                                                <a href='/magazine'>
                                                    <span className={linkStyles.link}>Magazín</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </SectionCountConsumer>
                        </nav>
                        {site && site.isSlovak && site.isWedding ? (
                            <a href='https://www.zoznam.sk/' target='_blank' className={styles.zoznamLink}>
                                <SVGIcon src={zoznamLogo} style={{ image: styles.icon }} />
                            </a>
                        ) : null}
                    </div>
                    <div className={styles.searchAndUser}>
                        <div className={styles.search}>
                            <FormView
                                style={{ form: styles.form }}
                                submitOnEnter={true}
                                submitNotDirty={true}
                                form={navigationViewModel.searchForm}
                            >
                                <SearchInputV2 />
                            </FormView>
                        </div>
                        <div key='user-panel' className={styles.userPanel}>
                            <UserPanelView userPanelViewModel={navigationViewModel.userPanelViewModel} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NavigationView);
