const cloneDeepWith: _.LoDashStatic['cloneDeepWith'] = require('lodash/cloneDeepWith'); //tslint:disable-line
const isString: _.LoDashStatic['isString'] = require('lodash/isString'); //tslint:disable-line

const NUM_REGEX = /^[0-9]+$/;

export default function scalarQuery<T = object>(query = {}): T {
    return cloneDeepWith(query, value => {
        if (isString(value)) {
            if (value === 'true') {
                return true;
            } else if (value === 'false') {
                return false;
            }

            if (value.match(NUM_REGEX)) {
                const float = parseFloat(value);
                if (!isNaN(float)) {
                    return float;
                }
            }

            return value;
        }

        return undefined;
    });
}
