import React from 'react';
import { observable, autorun } from 'mobx';
import { observer } from 'mobx-react';
import Styled from 'styled';
import defaultStyles from './button-view.scss';

@Styled(defaultStyles)
@observer
class ButtonView extends React.Component {
    static defaultProps = {
        type: 'button',
        element: 'button',
    };

    render() {
        const { styles, type, icon, element, unbounded, ...rest } = this.props;

        const classNames = [styles.button];
        const other = {};

        if (this.props.disabled) {
            classNames.push(styles.disabled);
        }

        return React.createElement(
            element,
            {
                type,
                unbounded: unbounded !== undefined ? unbounded.toString() : undefined,
                ...rest,
                ...other,
                className: classNames.join(' '),
            },
            <span className={styles.layout}>
                <span className={styles.content}>
                    {icon ? <span className={styles.icon}>{icon}</span> : null}
                    {this.props.children}
                </span>
            </span>
        );
    }
}

export default ButtonView;
