/* @flow */

import { observable, action } from 'mobx';
import computed from 'lib/mobx/computed';
import { IntlMessage } from 'react-intl/intl-message';
import { defineMessages } from 'react-intl';
import isEqual from 'lodash/isEqual';
import padStart from 'lodash/padStart';
// import moment from 'moment';

import { FieldData, Validator, ValidationError } from './form';

export class ValueFieldData<T> extends FieldData<ValueFieldData<T>> {
    @observable initialValue: ?T;
    @observable value: ?T;
    @observable touched: boolean = false;
    @observable visited: boolean = false;

    constructor(options = {}) {
        super(options);

        const { value } = options;

        this.initialValue = value;
        this.value = value;
    }

    @action setValue = (options: { value: ?T }) => {
        const { value } = options;
        this.value = value;

        this.touched = true;
    };

    computeDirty(): boolean {
        return !isEqual(this.value, this.initialValue);
    }

    submitData() {
        return this.value;
    }

    cancel() {
        return Promise.resolve();
    }

    reset() {
        this.visited = false;
        this.touched = false;
        this.value = this.initialValue;
    }

    resetAfterSubmit() {
        this.visited = false;
        this.touched = false;
        this.initialValue = this.value;
    }

    onBlur = () => {
        this.visited = true;
    };

    onChange = (event: Event) => {
        this.setValue({ value: event.target.value || '' });

        // reset server error on every change
        this.serverError = null;
    };
}

export class RequiredValidator<T: number | string | boolean> extends Validator<ValueFieldData<T>> {
    @computed get error() {
        const {
            fieldData: { value },
        } = this;

        if (value === undefined || value === null || value === '') {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_REQUIRED_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }

        return null;
    }
}

export class TrueValidator extends Validator<ValueFieldData<boolean>> {
    @computed get error() {
        const {
            fieldData: { value },
        } = this;

        if (value !== true) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_TRUE_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }

        return null;
    }
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class EmailValidator extends Validator<ValueFieldData<string>> {
    @computed get error() {
        const {
            fieldData: { value },
        } = this;

        if (!EMAIL_REGEX.test(value)) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_INVALID_EMAIL_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }
        return null;
    }
}

export class MinMaxLengthValidator extends Validator<ValueFieldData<string | number>> {
    max: number;
    min: number;

    constructor(options: { min?: number, max?: number }) {
        super(options);
        const { max = null, min = 0 } = options;

        this.max = max;
        this.min = min;
    }

    @computed get error() {
        const {
            fieldData: { value },
        } = this;

        if ((value || '').length < this.min) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_MIN_VALIDATOR_ERROR',
                        },
                    }),
                    values: {
                        min: this.min,
                    },
                }),
            });
        }

        if (this.max && (value || '').length > this.max) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_MAX_VALIDATOR_ERROR',
                        },
                    }),
                    values: {
                        max: this.max,
                    },
                }),
            });
        }

        return null;
    }
}

export class DateValidator<Date> extends Validator<DateFieldData<Date>> {
    @computed get error() {
        const {
            fieldData: { value },
        } = this;

        if (!(value instanceof Date) || !this.isValidDate(value)) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_DATE_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }
        return null;
    }

    isValidDate(date) {
        const iso8601 = [
            padStart(date.getFullYear() || '', 4, '0'),
            padStart(date.getMonth() || '', 2, '0'),
            padStart(date.getDate() || '', 2, '0'),
        ].join('-');

        const timestamp = Date.parse(iso8601);

        if (timestamp === null || isNaN(timestamp)) {
            return false;
        }
        return true;
    }
}

export class BirthdayValidator extends DateValidator {
    @computed get error() {
        const superResult = super.error();
        if (superResult !== null) {
            return superResult;
        }

        const {
            fieldData: { value },
        } = this;

        if (value > new Date()) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_BIRTHDAY_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }
        return null;
    }
}

export class PositiveValueValidator extends Validator<ValueFieldData<number>> {
    @computed get error() {
        const {
            fieldData: { value },
        } = this;
        if (value && parseFloat(value, 10) < 0) {
            return new ValidationError({
                intlMessage: new IntlMessage({
                    ...defineMessages({
                        message: {
                            id: 'FORM_POSITIVE_VALUE_VALIDATOR_ERROR',
                        },
                    }),
                }),
            });
        }

        return null;
    }
}
