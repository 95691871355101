import React from 'react';
import Styled from 'styled';
import objectFitImages from 'object-fit-images';
import classnames from 'classnames';

import observeOnce from 'lib/intersection-observer/observe-once';
import media from 'lib/media/media';

import defaultStyles from './image-view.scss';

const VW_REGEX = /vw$/;
const PX_REGEX = /px$/;

interface State {
    visible: boolean;
    loaded: boolean;
}

interface Props {
    styles: typeof defaultStyles;
    alt?: string | null;
    src: string | null;
    srcSet?: string | null;
    sizes: { [key: string]: string | number };
    aspectFill?: boolean;
    contain?: boolean;
    scaleDown?: boolean;
    aspectRatio?: number;
    placeholder?: string;
    intersectionOptions?: object;
    children?: React.ReactNode | null;
    overlay?: string;
    fill?: string;
    noBackground?: boolean;
    cropperDescriptor?: {
        data: any;
        transformOrigin: any;
        transform: any;
    };
    forwardedRef?: (ref: HTMLImageElement) => void;
    width?: number | string;
    height?: number | string;
    orientation?: number;
    className?: string;
    onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    visible?: boolean;
    linkify?: boolean;
    disableLazyLoad?: boolean;
}

class ImageView extends React.PureComponent<Props, State> {
    static defaultProps = {
        intersectionOptions: {
            rootMargin: '360px',
        },
        linkify: true,
    };

    disconnect?: () => void;
    ref = React.createRef<HTMLImageElement>();
    observerRef = React.createRef<HTMLSpanElement>();

    state = {
        visible: Boolean(this.props.visible || this.props.disableLazyLoad),
        loaded: Boolean(this.props.disableLazyLoad),
    };

    componentDidMount() {
        if (this.observerRef && this.observerRef.current && !this.props.disableLazyLoad) {
            this.disconnect = observeOnce(this.observerRef.current, this.refDidAppear, this.props.intersectionOptions);
        }

        if (this.ref && this.ref.current && this.props.forwardedRef) {
            this.props.forwardedRef(this.ref.current);
        }
    }

    componentDidUpdate(previousProps: Props) {
        const ref = this.ref.current;

        if (
            ref &&
            !this.props.disableLazyLoad &&
            (previousProps.src !== this.props.src || previousProps.srcSet !== this.props.srcSet)
        ) {
            this.setLoaded(false);

            const style = window.getComputedStyle(ref);

            if (style.objectFit === undefined || style.objectPosition === undefined) {
                objectFitImages(ref, { watchMQ: true });
            }
        }
    }

    componentWillUnmount() {
        if (this.disconnect) {
            this.disconnect();
        }
    }

    refDidAppear = () => {
        this.setState({ visible: true });
    };

    get src() {
        return this.state.visible ? this.props.src : undefined;
    }

    get srcSet() {
        return this.state.visible ? this.props.srcSet : undefined;
    }

    get aspectRatio() {
        const { cropperDescriptor } = this.props;

        if (cropperDescriptor) {
            const { data } = cropperDescriptor;
            return data.height / data.width;
        }

        return this.props.aspectRatio;
    }

    get style() {
        const { cropperDescriptor } = this.props;

        if (cropperDescriptor) {
            const { transformOrigin, transform } = cropperDescriptor;

            const { x, y } = transformOrigin;
            const { translateX, translateY, scale } = transform;

            if (x != null && y != null && translateX != null && translateY != null && scale != null) {
                return {
                    transformOrigin: `${Math.round(transformOrigin.x.toFixed(5))}% ${transformOrigin.y.toFixed(5)}%`,
                    transform: `translateX(${transform.translateX.toFixed(
                        5
                    )}%) translateY(${transform.translateY.toFixed(5)}%) scale(${transform.scale.toFixed(5)})`,
                };
            }
        }

        return undefined;
    }

    size(value: string | number, scale: number) {
        let size = value as any;

        if (!Number.isNaN(Number(size))) {
            size = `${Number(size) * scale}px`;
        } else if (VW_REGEX.test(size)) {
            size = `${parseInt(size.replace('vw', ''), 10) * scale}vw`;
        } else if (PX_REGEX.test(size)) {
            size = `${parseInt(size.replace('px', ''), 10) * scale}px`;
        }

        return size;
    }

    get sizes() {
        const { cropperDescriptor } = this.props;
        const scale = cropperDescriptor ? cropperDescriptor.transform.scale : 1;

        if (this.props.sizes instanceof Object) {
            let xs;

            const sizes = Object.keys(this.props.sizes).reduce((value: string[], key) => {
                const mediaKey = media[key];

                if (mediaKey) {
                    const size = this.size(this.props.sizes[key], scale);
                    value.push(`${mediaKey} ${size}`);
                } else if (key === 'xs') {
                    xs = this.size(this.props.sizes[key], scale);
                }

                return value;
            }, []);

            if (xs) {
                sizes.push(xs);
            }

            return sizes.join(',');
        }

        return this.props.sizes;
    }

    onLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (this.props.onLoad) {
            this.props.onLoad(event);
        }

        this.setLoaded(true);
    };

    setLoaded = (loaded: boolean) => {
        this.setState({ loaded });
    };

    // Don't the anything, because we handle photoswipe gallery
    // opening thru button overlay elsewhere..
    onImageClick = (event: Event) => {
        event.preventDefault();
    };

    render() {
        const {
            placeholder,
            styles,
            intersectionOptions,
            children,
            overlay,
            aspectFill,
            contain,
            scaleDown,
            fill,
            noBackground,
            cropperDescriptor,
            aspectRatio: _aspectRatio,
            forwardedRef,
            width,
            height,
            orientation,
            className,
            alt,
            visible,
            linkify,
            disableLazyLoad,
            ...rest
        } = this.props;

        const { style, aspectRatio } = this;
        const { loaded } = this.state;

        let placeholderChild;
        let aspectChild;
        let overlayChild;

        if (placeholder) {
            placeholderChild = <img style={style} className={styles.placeholder} src={placeholder} />;
        }

        if (aspectRatio) {
            aspectChild = (
                <span
                    className={styles.aspect}
                    style={{
                        paddingTop: `${aspectRatio * 100}%`,
                    }}
                />
            );
        }

        if (children) {
            overlayChild = <span className={styles.overlay}>{children}</span>;
        }

        const conditionalClassnames: { [key: string]: boolean } = {};

        if (aspectRatio) {
            let aspectClassName: string;

            if (orientation && orientation >= 5) {
                aspectClassName = aspectRatio > 1 ? styles.landscape : styles.portrait;
            } else {
                aspectClassName = aspectRatio > 1 ? styles.portrait : styles.landscape;
            }

            conditionalClassnames[aspectClassName] = Boolean(aspectClassName);
        }

        conditionalClassnames[styles.fill] = Boolean(fill);
        conditionalClassnames[styles.background] = Boolean(!placeholder && !noBackground);
        conditionalClassnames[styles.loaded] = Boolean(loaded);
        conditionalClassnames[styles.aspectFill] = Boolean(aspectFill && !cropperDescriptor);
        conditionalClassnames[styles.aspectRatio] = Boolean(aspectRatio);
        conditionalClassnames[styles.containSpan] = Boolean(contain);
        conditionalClassnames[styles.scaleDown] = Boolean(scaleDown);

        const element = (
            <>
                <span ref={this.observerRef} className={classnames(styles.image, conditionalClassnames)}>
                    {placeholderChild}
                    {React.createElement(
                        linkify ? 'a' : React.Fragment,
                        linkify ? { href: this.props.src, onClick: this.onImageClick } : {},
                        <img
                            ref={this.ref}
                            className={classnames(
                                styles.img,
                                className,
                                (styles as any)[`orientation-${orientation}`],
                                {
                                    [styles.contain]: contain,
                                }
                            )}
                            {...rest}
                            style={this.style}
                            src={this.src as string}
                            srcSet={this.srcSet as string}
                            alt={alt || ''}
                            sizes={this.sizes}
                            onLoad={this.onLoad}
                        />
                    )}
                    {aspectChild}
                    {overlayChild}
                </span>
                <noscript>
                    <a href={this.props.src as string}>
                        <img
                            src={this.props.src as string}
                            alt={alt || ''}
                            sizes='100px'
                            srcSet={this.props.srcSet as string}
                            className={classnames(
                                styles.img,
                                className,
                                (styles as any)[`orientation-${orientation}`],
                                {
                                    [styles.contain]: contain,
                                }
                            )}
                        />
                    </a>
                </noscript>
            </>
        );

        if (width || height) {
            return React.cloneElement(element, {
                style: {
                    height: Number.isNaN(parseInt(height as string, 10)) ? height : parseInt(height as string, 10),
                    minHeight: Number.isNaN(parseInt(height as string, 10)) ? height : parseInt(height as string, 10),
                    width: Number.isNaN(parseInt(width as string, 10)) ? width : parseInt(width as string, 10),
                    minWidth: Number.isNaN(parseInt(width as string, 10)) ? width : parseInt(width as string, 10),
                },
            });
        }

        return element;
    }
}

export default Styled(defaultStyles)(ImageView);
