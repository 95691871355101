import React from 'react';

import Styled from 'styled';
import SVGIcon from 'lib/images/svg-icon/svg-icon';
import svgIconStyles from 'lib/images/svg-icon/svg-icon.scss';

import checkbox from 'four-nets/icons/svg/icon_checkbox.svg';
import checkboxActive from 'four-nets/icons/svg/icon_checkbox_active.svg';
import defaultStyles from './checkbox.scss';

export interface Props {
    styles: typeof defaultStyles;
    id?: string;
    name?: string;
    value?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: React.ReactNode;
    errors?: React.ReactNode[];
    touched?: boolean;
    iconStyle?: Partial<typeof svgIconStyles>;
}

class Checkbox extends React.Component<Props> {
    render() {
        const { styles, id, name, value, onChange, label, errors, touched, iconStyle } = this.props;

        const showValidationErrors = Boolean(errors && errors.length) && touched;
        return (
            <span>
                <label className={styles.wrapper}>
                    <span className={styles.checkboxInput}>
                        <input
                            id={id || name}
                            name={name}
                            value={value}
                            checked={value}
                            onChange={onChange}
                            type='checkbox'
                            className={styles.input}
                        />
                        <SVGIcon style={iconStyle || { image: styles.icon }} src={value ? checkboxActive : checkbox} />
                    </span>
                    {label ? <span className={styles.label}>{label}</span> : null}
                </label>
                {showValidationErrors &&
                    errors &&
                    errors.map((error, index) => {
                        return (
                            <p key={index.toString()} className={styles.error}>
                                {error}
                            </p>
                        );
                    })}
            </span>
        );
    }
}

export default Styled(defaultStyles)(Checkbox);
