import backoff from 'backoff';
import Promise from 'bluebird';

export const loadScriptWithBackoff = options =>
    new Promise((resolve, reject, onCancel) => {
        const { src, loadWillStart, loadWillBackoff } = options;

        const fibonacciBackoff = backoff.fibonacci({
            randomisationFactor: 0,
            initialDelay: 5000,
            maxDelay: 30000,
        });

        const context = {};

        const load = () => {
            const script = document.createElement('script');
            script.async = true;

            if (loadWillStart) {
                loadWillStart({ script });
            }

            script.src = src;
            script.onerror = (event: Event) => {
                script.parentNode.removeChild(script);
                fibonacciBackoff.backoff(event);
            };

            script.onload = (event: Event) => {
                resolve(event);
            };

            context.script = script;
            document.body.appendChild(script);
        };

        onCancel(() => {
            const { script } = context;
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }

            fibonacciBackoff.reset();
        });

        fibonacciBackoff.failAfter(4);

        if (loadWillBackoff) {
            fibonacciBackoff.on('backoff', loadWillBackoff);
        }

        fibonacciBackoff.on('ready', load);

        fibonacciBackoff.on('fail', err => {
            reject(err);
        });

        load();
    });

export function isInputCarretAtBeggining({ element: el }: { element: HTMLElement }) {
    // from http://stackoverflow.com/questions/4928586/get-caret-position-in-html-input

    let start = 0;
    let normalizedValue;
    let range;
    let textInputRange;
    let len;
    let endRange;

    if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
        start = el.selectionStart;
    } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, '\n');

            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
                start = len;
            } else {
                start = -textInputRange.moveStart('character', -len);
                start += normalizedValue.slice(0, start).split('\n').length - 1;
            }
        }
    }
    return start === 0;
}

export function imageDataURL({ file, maxWidth }: { file: File, maxWidth: number }) {
    const options = {
        canvas: true,
        maxWidth,
    };

    return new Promise((resolve, reject) => {
        import('blueimp-load-image')
            .then(({ default: loadImage }) => {
                loadImage.parseMetaData(file, data => {
                    if (data.exif) {
                        options.orientation = data.exif.get('Orientation');
                    }
                    loadImage(
                        file,
                        result => {
                            if (result.type === 'error') {
                                reject(result);
                            } else {
                                resolve(result.toDataURL());
                            }
                        },
                        options
                    );
                });
            })
            .catch(reject);
    });
}
