import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Styled from 'styled';

import FormView from 'lib/form/form-view';
import SVGIcon from 'lib/images/svg-icon/svg-icon';
import { SectionCountConsumer, SiteSection } from 'lib/section-count-context/section-count-context';

import arrowSvg from 'four-nets/icons/svg/icon_arrow-down.svg';
import MaxHeightSettable from 'four-nets/ui/max-height-settable';
import SearchInputV2 from 'four-nets/ui-v2/search-input/search-input-v2';
import zoznamLogo from 'four-nets/img/zoznam-biele.svg';

import { MAX_HEIGHT_OFFSET } from '../common';
import { CONFIG } from './navigation-menu-mobile-config';

import defaultStyles from './navigation-menu-view.scss';
import { ViewerConsumer } from 'four-nets/user/viewer/viewer';

export class NavigationMenuViewModel {
    links: any;
    searchForm: any;

    constructor(options: { links: any }) {
        const { links } = options;
        this.links = links;
    }
}

interface Props extends RouteComponentProps {
    styles: typeof defaultStyles;
    closeMenu: () => void;
    maxHeight?: number;
}

interface State {
    search: string;
    openIndex: number | null;
}

@MaxHeightSettable({ offset: MAX_HEIGHT_OFFSET })
@observer
class NavigationMenuView extends React.Component<Props, State> {
    static contextTypes = {
        site: PropTypes.object,
    };

    state = { openIndex: null, search: '' };

    handleSearchSubmit = () => {
        const { search } = this.state;
        this.props.closeMenu();
        this.setState({ search: '' });

        return new Promise(resolve => {
            this.props.history.push(`/search/?query=${search}`);
            resolve();
        });
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: event.target.value || '' });
    };

    openLinks = (index: number) => () => {
        this.setState(prevState => {
            if (prevState.openIndex === index) {
                return { openIndex: null };
            }
            return { openIndex: index };
        });
    };

    render() {
        const { styles, maxHeight } = this.props;
        const { openIndex } = this.state;
        const { site } = this.context;

        let SITE_CONFIG:
            | Map<
                  { label: string; to?: string; target?: string },
                  Array<{ label: JSX.Element | string; to: string; authOnly?: boolean; target?: string }>
              >
            | undefined;
        if (site && site.id && CONFIG.has(site.id)) {
            SITE_CONFIG = CONFIG.get(site && site.id)!;
        }

        return SITE_CONFIG ? (
            <SectionCountConsumer>
                {({ updateSectionCount }) => (
                    <div className={styles.navigationMenu} style={maxHeight ? { maxHeight } : {}}>
                        <nav className={styles.navigation}>
                            <ul className={styles.list}>
                                {Array.from(SITE_CONFIG!.keys()).map((value, index) => (
                                    <li className={styles.item} key={value.label}>
                                        {SITE_CONFIG!.has(value) && SITE_CONFIG!.get(value)!.length ? (
                                            <ViewerConsumer>
                                                {({ viewer }) => (
                                                    <React.Fragment>
                                                        <button
                                                            className={styles.category}
                                                            onClick={this.openLinks(index)}
                                                        >
                                                            {value.label}
                                                            <SVGIcon style={{ image: styles.icon }} src={arrowSvg} />
                                                        </button>
                                                        <ul
                                                            className={classnames(styles.list, styles.links, {
                                                                [styles.open]: openIndex === index,
                                                            })}
                                                        >
                                                            {SITE_CONFIG!.get(value)!.map(link =>
                                                                !link.authOnly || viewer ? (
                                                                    <li className={styles.linkWrapper} key={link.to}>
                                                                        {(() => {
                                                                            if (link.to === '/market/') {
                                                                                return (
                                                                                    <a
                                                                                        className={styles.link}
                                                                                        href='/market/'
                                                                                    >
                                                                                        {link.label}
                                                                                    </a>
                                                                                );
                                                                            }

                                                                            if (link.to === '/catalog/') {
                                                                                return (
                                                                                    <a
                                                                                        className={styles.link}
                                                                                        href='/catalog/'
                                                                                    >
                                                                                        {link.label}
                                                                                    </a>
                                                                                );
                                                                            }

                                                                            if (link.to === '/market/my_bazaar/') {
                                                                                return (
                                                                                    <a
                                                                                        className={styles.link}
                                                                                        href='/market/moje-inzeraty/'
                                                                                    >
                                                                                        {link.label}
                                                                                    </a>
                                                                                );
                                                                            }

                                                                            if (link.label === 'SIEN_SLAVY') {
                                                                                return (
                                                                                    <a
                                                                                        className={styles.link}
                                                                                        href={link.to}
                                                                                    >
                                                                                        {site.isCzech
                                                                                            ? 'Síň slavy'
                                                                                            : 'Sieň slávy'}
                                                                                    </a>
                                                                                );
                                                                            }

                                                                            if (link.to.startsWith('/forum')) {
                                                                                return (
                                                                                    <a
                                                                                        className={styles.link}
                                                                                        href={link.to}
                                                                                    >
                                                                                        {link.label}
                                                                                    </a>
                                                                                );
                                                                            }

                                                                            return (
                                                                                <Link
                                                                                    to={link.to}
                                                                                    className={styles.link}
                                                                                    onClick={() => {
                                                                                        if (link.to === '/forum/') {
                                                                                            updateSectionCount(
                                                                                                SiteSection.FORUM
                                                                                            );
                                                                                        } else if (
                                                                                            link.to === '/blogs/'
                                                                                        ) {
                                                                                            updateSectionCount(
                                                                                                SiteSection.BLOGS
                                                                                            );
                                                                                        } else if (
                                                                                            link.to === '/catalog/'
                                                                                        ) {
                                                                                            updateSectionCount(
                                                                                                SiteSection.CATALOG
                                                                                            );
                                                                                        }
                                                                                        this.props.closeMenu();
                                                                                    }}
                                                                                    target={link.target}
                                                                                >
                                                                                    {link.label}
                                                                                </Link>
                                                                            );
                                                                        })()}
                                                                    </li>
                                                                ) : null
                                                            )}
                                                        </ul>
                                                    </React.Fragment>
                                                )}
                                            </ViewerConsumer>
                                        ) : (
                                            <Link
                                                to={value.to || ''}
                                                className={classnames(styles.category, {
                                                    [styles.highlight]: value.to === '/atlas/',
                                                })}
                                                onClick={this.props.closeMenu}
                                                target={value.target}
                                            >
                                                {value.label}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                                {(site.isWedding || site.isLiving) && (
                                    <li className={styles.item} key='tools'>
                                        <a
                                            href='/tools'
                                            className={classnames(styles.category)}
                                            onClick={this.props.closeMenu}
                                        >
                                            Nástroje
                                        </a>
                                    </li>
                                )}
                                {site.isLiving && (
                                    <li className={styles.item}>
                                        <a
                                            href='/magazine'
                                            className={classnames(styles.category)}
                                            onClick={this.props.closeMenu}
                                        >
                                            Magazín
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </nav>
                        <div className={styles.search}>
                            <FormView
                                submitOnEnter={true}
                                submitNotDirty={true}
                                submit={this.handleSearchSubmit}
                                form={{ valid: Boolean(this.state.search), dirty: true }}
                            >
                                <SearchInputV2 isRedirectToMainSearch />
                            </FormView>
                        </div>
                        {site && site.isSlovak && site.isWedding ? (
                            <a href='https://www.zoznam.sk/' target='_blank' className={styles.zoznamLink}>
                                <SVGIcon style={{ image: styles.zoznamIcon }} src={zoznamLogo} />
                            </a>
                        ) : null}
                    </div>
                )}
            </SectionCountConsumer>
        ) : null;
    }
}

export default Styled(defaultStyles)(withRouter(NavigationMenuView));
