import React from 'react';
import Styled from 'styled';

import EmojiOneView from 'lib/emojione/emojione-view';

import defaultStyles from './emoji-selector.scss';

const EMOJIS = [
    ':)',
    ';-)',
    ':-(',
    ':|',
    ']-(',
    '8-)',
    ':-S',
    ':-o',
    ';-(',
    ':-D',
    ':-p',
    '|-|',
    ':-N',
    '=-)',
    '8-(',
    ':-.',
    ':-x',
    'x-)',
];

interface Props {
    styles: typeof defaultStyles;
    onClick: (event: React.MouseEvent<HTMLElement>, emoji: string) => void;
}

class EmojiSelectorView extends React.Component<Props> {
    onEmojiClick = (emoji: string) => (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.onClick) {
            this.props.onClick(event, emoji);
        }
    };

    render() {
        const { styles } = this.props;

        return (
            <div className={styles.emojiSelector}>
                {EMOJIS.map(item => (
                    <span key={item} className={styles.emojis}>
                        <EmojiOneView options={{ ascii: true }} pngToSvg={true} onClick={this.onEmojiClick(item)}>
                            {item}
                        </EmojiOneView>
                    </span>
                ))}
            </div>
        );
    }
}

export default Styled(defaultStyles)(EmojiSelectorView);
