import JSONSerializable from 'lib/serialization/json-serializable';
import { ApiItem } from 'lib/api/api-item';
import { ApiStore } from 'lib/api/django-restframework/api-store';
import computed from 'lib/mobx/computed';

function sortNumber(a, b) {
    return a - b;
}

const SIZE_REGEX = /\d+x(\d+)/;

export { PhotoFile };

@JSONSerializable()
class PhotoFile extends ApiItem {
    @computed get versions() {
        if (!this.versionsStored) {
            return [];
        }

        const objectKeys = Object.keys(this.versionsStored);
        const numKey = {};

        return objectKeys
            .map(key => {
                let num = parseInt(key, 10);

                if (isNaN(num)) {
                    const matched = key.match(/\d+/);

                    if (matched.length) {
                        num = parseInt(matched[0], 10);
                    } else {
                        return undefined;
                    }
                }

                numKey[num] = key;
                return num;
            })
            .filter(value => value !== undefined)
            .sort(sortNumber)
            .map(num => numKey[num]);
    }

    @computed get src() {
        let src;

        if (this.srcSet) {
            const split = this.srcSet.split(',');
            let previousWidth = 0;

            for (let i = 0; i < split.length; i++) {
                const part = split[i];
                const [href, widthString] = part.split(' ');

                const width = parseInt(widthString.replace('w', ''), 10);

                if (width && width > previousWidth && width <= 768) {
                    src = href;
                }

                previousWidth = width;
            }
        }

        if (src) {
            return src;
        }

        return this.maxUrl;
    }

    @computed get srcSet() {
        const { versionsStored } = this;

        const srcSet = [];

        Object.keys(versionsStored).map(width => {
            const { url } = versionsStored[width];

            if (parseInt(width, 10)) {
                srcSet.push(`${url} ${width}w`);
            } else {
                const match = width.match(SIZE_REGEX);

                if (match) {
                    srcSet.push(`${url} ${match[1]}w`);
                }
            }
        });

        return srcSet.join(',');
    }

    @computed get maxUrl() {
        const { versionsStored } = this;

        const max = this.versions.slice(-1)[0];
        const { url } = versionsStored[max] || {};

        return url;
    }

    @computed get cropperDescriptor() {
        if (this.data && this.data.cropperDescriptor) {
            return this.data.cropperDescriptor;
        }
        return null;
    }

    findThumbnailKey(versions) {
        if (versions.length > 1) {
            return versions[1];
        }
        return versions[0];
    }

    findMaxKey(versions) {
        return versions.slice(-1)[0];
    }

    photoSwipeItem = () => {
        const { versionsStored, versions } = this;

        const maxKey = this.findMaxKey(versions);
        const minKey = this.findThumbnailKey(versions);

        const { url: src, width, height } = versionsStored[maxKey];
        const { url: msrc } = versionsStored[minKey];

        return {
            src,
            msrc,
            srcSet: this.srcSet,
            w: width,
            h: height,
        };
    };

    saveCropperDescriptorDidSucceed = ({ json }) => {
        return this.fromJSON({ json });
    };

    saveCropperDescriptor({ cropperDescriptor }) {
        return this.delegate
            .patch({
                item: this,
                data: {
                    ...this.data,
                    cropperDescriptor,
                },
            })
            .then(this.saveCropperDescriptorDidSucceed);
    }
}

export class PhotoFileStore extends ApiStore<Photofile> {
    constructor(options) {
        super(options);
        this.delegate = this;
    }

    create(options: { file: File, type: number, progress?: (event: Event) => void }) {
        const { file, type, progress } = options;

        const request = this.api.post({
            path: 'photos',
        });

        request.attach('file', file);
        request.field('type', type);

        if (progress) {
            request.on('progress', progress);
        }

        return super.create({
            ...options,
            request,
        });
    }

    patch({ item, data }) {
        const request = this.api.patch({
            path: `photos/${item.id}`,
        });

        request.send({ data });
        return request.json();
    }

    delete({ item }) {
        return this.api
            .del({
                path: `photos/${item.id}`,
            })
            .json();
    }

    item() {
        return new PhotoFile({
            delegate: this,
        });
    }
}
