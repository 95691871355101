import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Styled from 'styled';

import { LinearProgress } from '@rmwc/linear-progress';

import LoadingProgressProvider from './loading-progress-provider';

import '@rmwc/linear-progress/styles';
import './loading-progress-bar.scss';
import defaultStyles from './loading-progress-bar.scss';

export interface Props {
    styles: typeof defaultStyles;
}
interface State {
    aside?: HTMLElement | null;
}

interface Context {
    loadingProgressProvider: LoadingProgressProvider;
}

const LoadingProgressBar = (props: Props & { progress: number }) =>
    props.progress ? (
        <LinearProgress className={props.styles.loadingProgressBar} buffer={1} progress={props.progress} />
    ) : null;

@observer
class LoadingProgressBarPortal extends React.Component<Props, State> {
    static contextTypes = {
        loadingProgressProvider: PropTypes.instanceOf(LoadingProgressProvider),
    };

    context!: Context;
    state: State = {};

    componentDidMount() {
        requestAnimationFrame(() => {
            const aside = document.createElement('aside');
            aside.classList.add(this.props.styles.routerProgress);
            document.body.appendChild(aside);

            this.setState({
                aside,
            });
        });
    }

    componentWillUnmount() {
        if (this.state.aside && this.state.aside.parentNode) {
            this.state.aside.parentNode.removeChild(this.state.aside);
        }
    }

    render() {
        if (this.state.aside) {
            return ReactDOM.createPortal(
                <LoadingProgressBar
                    styles={this.props.styles}
                    progress={this.context.loadingProgressProvider.progress / 100}
                />,
                this.state.aside
            );
        }

        return null;
    }
}

export default Styled(defaultStyles)(LoadingProgressBarPortal);
