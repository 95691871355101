import React from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import { URLBuilder } from 'url-builder/url-builder';
import Helmet from 'helmet/helmet';

import LoadingProgressBar from 'lib/react-router/loading-progress-bar';
import LoadingProgressProvider from 'lib/react-router/loading-progress-provider';
import FetchData, { Fetch, Render } from 'lib/fetch-data/fetch-data';
import ScrollView from 'lib/scroll/scroll-view';
import { AlertContextProvider } from 'lib/alert-context/alert-context';

import VendorCardChangeNoticeProvider from 'four-nets/vendor-card-change-notice';
import { FourNetsApi } from 'four-nets/api/api';
import { UserStore } from 'four-nets/user/user';
import { AuthOrigin } from 'four-nets/user/auth/auth-ga-events';
import LoginDialog, { Tab as LoginDialogTab } from 'four-nets/user/auth/login-dialog';
import { Site } from 'four-nets/site';
import { FACEBOOK_APP_ID } from 'four-nets/lib/facebook';
import ViewerProvider from 'four-nets/user/viewer/viewer';
import { ModalRefProvider } from 'four-nets/ui-v2/modal/modal';
import { withRouter } from 'react-router-dom';
import { getScript as getQuantCastScript } from './lib/quantcast-cookieconsent';
import BottomFixedAd from 'utils/bottom-fixed-ad';

export const SiteContext = React.createContext<Site>(new Site('www.modrastrecha.sk'));
export const ApiContext = React.createContext<any>(null);

export interface Props {
    api: any; // FourNetsApi;
    children: (props: { loading: boolean; error?: Error; facebookAppId?: string }) => React.ReactNode;
    HtmlElement?: HTMLElement;
    userAgent: string;
    userStore: any;
    site: Site;
    serializer: object;
    desktopVersion?: Boolean;
    rootHtmlElement: HTMLElement | null;
}

interface Data {
    userStore: any;
}

interface State {
    loginOpen: boolean;
    loginTab: LoginDialogTab;
    loginOrigin: AuthOrigin;
}

const GTM_CODES = {
    301: 'GTM-MT74FW6',
    401: 'GTM-5QKF4R9',
    101: 'GTM-5LXPMN8',
    102: 'GTM-M83BCTM',
    302: 'GTM-5KQ8VRH',
};

// For Google Analytics V4
const NEW_GTM_CODES = {
    101: 'G-8L4ES8NB5C',
    102: 'G-1P7306KDTV',
    301: 'G-P0YNHQ8TQ8',
    302: 'G-DSFCTV1GY5',
    401: 'G-QGL2PWWNQ5',
};

export const UserStoreContext = React.createContext<UserStore | null>(null);

class CloseLogin extends Error {}

class FourNets extends React.PureComponent<Props, State> {
    static contextTypes = {
        intl: PropTypes.object,
    };

    static childContextTypes = {
        api: PropTypes.instanceOf(FourNetsApi),
        authCheck: PropTypes.func,
        showLogin: PropTypes.func,
        site: PropTypes.instanceOf(Site),
        userStore: PropTypes.instanceOf(UserStore),
        userAgent: PropTypes.string,
        urlBuilder: PropTypes.instanceOf(URLBuilder),
        serializer: PropTypes.object,
    };

    facebookAppId?: string;
    theme?: string;

    siteHelmet: Array<React.ReactElement<any>>;

    constructor(props: Props) {
        super(props);

        const { site } = props;

        this.state = {
            loginOpen: false,
            loginTab: LoginDialogTab.Login,
            loginOrigin: AuthOrigin.Unknown,
        };

        if (site.isGarden) {
            this.theme = 'garden';
        } else if (site.isLiving) {
            this.theme = 'living';
        } else if (site.isWedding) {
            this.theme = 'wedding';
        }

        if (this.theme) {
            if (process.env.ENV_WEB) {
                document.body.classList.add(this.theme);
            }
        }

        if (FACEBOOK_APP_ID[site.domain]) {
            this.facebookAppId = FACEBOOK_APP_ID[site.domain];
        } else if (process.env.NODE_ENV === 'development') {
            console.error(`Could not find FACEBOOK APP ID for: ${site.domain}`);
        }

        this.siteHelmet = [
            <script>
                {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_CODES[site.id]}');`}
            </script>,
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${NEW_GTM_CODES[site.id]}`}></script>,
            <script>
                {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', "${NEW_GTM_CODES[site.id]}");
            `}
            </script>,
        ];

        this.siteHelmet.push(
            <script>
                {`function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};
    gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event');`}
            </script>
        );

        this.siteHelmet.push(
            <noscript>
                {`
                        <iframe
                            title="googletagmanager"
                            src="https://www.googletagmanager.com/ns.html?id=${GTM_CODES[site.id]}"
                            height="0"
                            width="0"
                            style={{display: "none", visibility: "hidden"}}
                            />
                    `}
            </noscript>,
            <link rel='shortcut icon' href={process.env.PUBLIC_URL + `/favicon/${site.domain}/favicon.ico`} />,
            <link
                rel='icon'
                type='image/png'
                sizes='16x16'
                href={process.env.PUBLIC_URL + `/favicon/${site.domain}/favicon-16x16.png`}
            />,
            <link
                rel='icon'
                type='image/png'
                sizes='32x32'
                href={process.env.PUBLIC_URL + `/favicon/${site.domain}/favicon-32x32.png`}
            />,
            <link
                rel='icon'
                type='image/png'
                sizes='96x96'
                href={process.env.PUBLIC_URL + `/favicon/${site.domain}/favicon-96x96.png`}
            />,
            <meta name='msapplication-TileColor' content='#ffffff' />,
            <meta name='theme-color' content='#ffffff' />,
            <meta name='referrer' content='origin' />
        );

        if (site.isCzech && site.isLiving) {
            this.siteHelmet.push(<meta name='seznam-wmt' content='NCvLhs1cROxx0b21321BdYMx2vwexATz' />);
        } else if (site.isCzech && site.isWedding) {
            this.siteHelmet.push(<meta name='seznam-wmt' content='i2kXd91noiDXQdMmqB5AR3bC0Yrnw8yG' />);
        }

        this.siteHelmet.push(<meta name='Author' content='4networks SK&amp;CZ (C) 2015-2020'></meta>);

        if (site.isLiving && site.isSlovak) {
            this.siteHelmet.push(
                <script
                    defer
                    data-domain='modrastrecha.sk'
                    src='https://plausible.modrastrecha.sk/js/script.js'
                ></script>
            );
        }

        if (site.isGarden) {
            // specific stuff for zahrada.sk
            this.siteHelmet.unshift(
                <noscript>
                    {`<iframe
                    src='https://www.googletagmanager.com/ns.html?id=GTM-WPX4R34'
                    height='0'
                    width='0'
                    style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>`}
                </noscript>
            );

            // specific stuff for zahrada.sk
            this.siteHelmet.unshift(
                <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WPX4R34');`}</script>
            );
        }
    }

    loginResolve: (user: any) => void = () => {
        /* pass */
    };

    loginReject: (reason: any) => void = () => {
        /* pass */
    };

    getChildContext() {
        return {
            api: this.props.api,
            authCheck: this.authCheck,
            showLogin: this.showLogin,
            userAgent: this.props.userAgent,
            site: this.props.site,
            userStore: this.props.userStore,
            urlBuilder: URLBuilder.defaultURLBuilder,
            serializer: this.props.serializer,
        };
    }

    renderHelmet = (H: React.ComponentClass) => {
        const { site } = this.props;

        const children = [
            <html lang={this.context.intl.locale} />,
            <meta name='google' content='notranslate' />,
            <meta http-equiv='Content-Language' content={this.context.intl.locale} />,
            <meta charSet='UTF-8' />,
            <meta
                name='viewport'
                content={`width=${this.props.desktopVersion ? '1470' : 'device-width'}, initial-scale=${
                    this.props.desktopVersion ? '0' : '1'
                }`}
            />,
        ];

        children.push(...this.siteHelmet);
        children.push(<title>{site.name}</title>);

        if (this.facebookAppId) {
            children.push(<meta property='fb:app_id' content={`${this.facebookAppId}`} />);
        }

        if (this.theme) {
            if (process.env.ENV_NODE) {
                children.push(<body className={this.theme} />);
            }
        }

        // add quantcast cookie script everywhere
        children.push(getQuantCastScript(this.props.site) as JSX.Element);

        return <H>{React.Children.toArray(children)}</H>;
    };

    transform = (data: { userStore: object }) => {
        this.props.userStore.fromJSON({ json: data.userStore });

        return {
            userStore: this.props.userStore,
        };
    };

    fetch: Fetch<Data, any> = setData => {
        setData({
            userStore: this.props.userStore,
        });
        return Promise.resolve();
    };

    componentDidMount() {
        this.props.userStore.loadMe();
    }

    renderFetch: Render<Data> = ({ loading, error, data }) => {
        return this.props.children({
            loading,
            error,
            facebookAppId: this.facebookAppId,
        });
    };

    authCheck = (action: () => Promise<any> = Promise.resolve, origin: AuthOrigin = AuthOrigin.Unknown) => {
        if (this.props.userStore.me) {
            const result = action();
            if (result && result.catch) {
                return result.catch(err => {
                    if (err.code === 401) {
                        return this.showLogin(action, LoginDialogTab.Login, origin);
                    }
                    return Promise.reject(err);
                });
            }
            return result;
        }
        return this.showLogin(action, LoginDialogTab.Login, origin);
    };

    showLogin = (
        action: () => Promise<any> = Promise.resolve,
        tab: LoginDialogTab = LoginDialogTab.Login,
        origin: AuthOrigin = AuthOrigin.Unknown
    ) => {
        this.setState({
            loginOpen: true,
            loginTab: tab,
            loginOrigin: origin,
        });
        return new Promise((resolve, reject) => {
            this.loginResolve = resolve;
            this.loginReject = reject;
        })
            .then(action)
            .catch(e => {
                // handle close
                if (!(e instanceof CloseLogin)) {
                    throw e;
                }
            });
    };

    onLoginSubmit = (user: any) => {
        return this.loginResolve(user);
    };

    closeLogin = () => {
        //reset login state
        this.setState({
            loginOpen: false,
            loginTab: LoginDialogTab.Login,
            loginOrigin: AuthOrigin.Unknown,
        });
        // XXX: Should we inspect the promise here
        // and only reject if it was not resolved already
        // in onLoginSubmit?
        this.loginReject(new CloseLogin());
    };

    render() {
        return (
            <LoadingProgressProvider>
                <ScrollView asWindow={true}>
                    <UserStoreContext.Provider value={this.props.userStore}>
                        <SiteContext.Provider value={this.props.site}>
                            <ApiContext.Provider value={this.props.api}>
                                <ModalRefProvider>
                                    <AlertContextProvider>
                                        <ViewerProvider>
                                            <VendorCardChangeNoticeProvider>
                                                <LoginDialog
                                                    tab={this.state.loginTab}
                                                    open={this.state.loginOpen}
                                                    origin={this.state.loginOrigin}
                                                    onClose={this.closeLogin}
                                                    onLoginSuccess={this.onLoginSubmit}
                                                />
                                                <LoadingProgressBar />
                                                <Helmet render={this.renderHelmet} />
                                                <FetchData
                                                    serializerKey={__filename}
                                                    fetch={this.fetch}
                                                    render={this.renderFetch}
                                                    transform={this.transform}
                                                />
                                                <BottomFixedAd />
                                            </VendorCardChangeNoticeProvider>
                                        </ViewerProvider>
                                    </AlertContextProvider>
                                </ModalRefProvider>
                            </ApiContext.Provider>
                        </SiteContext.Provider>
                    </UserStoreContext.Provider>
                </ScrollView>
            </LoadingProgressProvider>
        );
    }
}

export default withRouter(FourNets as any);
