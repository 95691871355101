/* jshint maxerr: 10000 */
/* jslint unused: true */
/* jshint shadow: true */
/* jshint -W075 */
const emojione = {};

(function fn(ns) {
    // this list must be ordered from largest length of the value array, index 0, to the shortest
    ns.emojioneList = {
        ':sleeping:': { unicode: ['1f634'], uc: '1f634', isCanonical: true, fname: '1f634' },
        ':dizzy_face:': { unicode: ['1f635'], uc: '1f635', isCanonical: true, fname: '1f635' },
        ':unamused:': { unicode: ['1f612'], uc: '1f612', isCanonical: true, fname: '1f612' },
        ':angry:': { unicode: ['1f620'], uc: '1f620', isCanonical: true, fname: '1f620' },
        ':slight_smile:': { unicode: ['1f642'], uc: '1f642', isCanonical: true, fname: '1f642' },
        ':neutral_face:': { unicode: ['1f610'], uc: '1f610', isCanonical: true, fname: '1f610' },
        ':wink:': { unicode: ['1f609'], uc: '1f609', isCanonical: true, fname: '1f609' },
        ':smiley:': { unicode: ['1f603'], uc: '1f603', isCanonical: true, fname: '1f603' },
        ':frowning:': { unicode: ['1f626'], uc: '1f626', isCanonical: true, fname: '1f626' },
        ':sunglasses:': { unicode: ['1f60e'], uc: '1f60e', isCanonical: true, fname: '1f60e' },
        ':disappointed:': { unicode: ['1f61e'], uc: '1f61e', isCanonical: true, fname: '1f61e' },
        ':thinking:': { unicode: ['1f914'], uc: '1f914', isCanonical: true, fname: '1f914' },
        ':grimacing:': { unicode: ['1f62c'], uc: '1f62c', isCanonical: true, fname: '1f62c' },
        ':confused:': { unicode: ['1f615'], uc: '1f615', isCanonical: true, fname: '1f615' },
        ':no_mouth:': { unicode: ['1f636'], uc: '1f636', isCanonical: true, fname: '1f636' },
        ':cry:': { unicode: ['1f622'], uc: '1f622', isCanonical: true, fname: '1f622' },
        ':stuck_out_tongue:': { unicode: ['1f61b'], uc: '1f61b', isCanonical: true, fname: '1f61b' },
        ':open_mouth:': { unicode: ['1f62e'], uc: '1f62e', isCanonical: true, fname: '1f62e' },
    };
    var tmpShortNames = [],
        emoji;
    for (emoji in ns.emojioneList) {
        if (!ns.emojioneList.hasOwnProperty(emoji)) continue;
        tmpShortNames.push(emoji.replace(/[+]/g, '\\$&'));
    }
    ns.shortnames = tmpShortNames.join('|');
    ns.asciiList = {
        ';(': '1f622',
        ';-(': '1f622',
        ':-/': '1f615',
        '8-D': '1f60e',
        '=)': '1f642',
        ':-o': '1f62e',
        '=P': '1f61b',
        ':-D': '1f603',
        ':p': '1f61b',
        ';]': '1f609',
        '>:O': '1f62e',
        'B-D': '1f60e',
        '=/': '1f615',
        '8)': '1f60e',
        ':-#': '1f636',
        '=(': '1f61e',
        ';D': '1f609',
        '>:[': '1f61e',
        'B-)': '1f60e',
        ':[': '1f61e',
        ':P': '1f61b',
        '8-)': '1f60e',
        '=p': '1f61b',
        ':-p': '1f61b',
        '>:/': '1f615',
        ':-X': '1f636',
        '=L': '1f615',
        '>:\\': '1f615',
        ':/': '1f615',
        '=#': '1f636',
        ':o': '1f62e',
        ':L': '1f615',
        '*)': '1f609',
        ':]': '1f642',
        ':-P': '1f61b',
        '=x': '1f636',
        ':-b': '1f61b',
        ':-x': '1f636',
        ':-Þ': '1f61b',
        ';-]': '1f609',
        ':(': '1f61e',
        ':\\': '1f615',
        ':Þ': '1f61b',
        ':x': '1f636',
        'B)': '1f60e',
        'd:': '1f61b',
        ':-þ': '1f61b',
        ':-[': '1f61e',
        ';)': '1f609',
        ":'-(": '1f622',
        ';^)': '1f609',
        ':#': '1f636',
        ':O': '1f62e',
        ':-.': '1f615',
        ":'(": '1f622',
        '=D': '1f603',
        ':)': '1f642',
        '*-)': '1f609',
        O_O: '1f62e',
        ':D': '1f603',
        ';-)': '1f609',
        ':-O': '1f62e',
        '=X': '1f636',
        ':X': '1f636',
        '=]': '1f642',
        ':þ': '1f61b',
        ':b': '1f61b',
        ':-)': '1f642',
        '=\\': '1f615',
        ':-(': '1f61e',
    };
    ns.asciiRegexp = '(\\:\\-x|\\:\\-p|\\:\\-o|\\:\\-D|\\:\\-.|\\:\\)|;\\-\\(|\\:\\-\\(|;\\-\\)|8\\-\\))';
    // javascript escapes here must be ordered from largest length to shortest
    ns.unicodeRegexp =
        '(\uD83D\uDE03|\uD83D\uDE09|\uD83D\uDE12|\uD83D\uDE1E|\uD83D\uDE20|\uD83D\uDE22|\uD83D\uDE35|\uD83D\uDE0E|\uD83D\uDE10|\uD83D\uDE15|\uD83D\uDE1B|\uD83D\uDE26|\uD83D\uDE2C|\uD83D\uDE2E|\uD83D\uDE34|\uD83D\uDE36|\uD83D\uDE42|\uD83E\uDD14)';
    ns.jsEscapeMap = {
        '\\uD83D\\uDE22': '1f622',
        '\\uD83D\\uDE34': '1f634',
        '\\uD83D\\uDE15': '1f615',
        '\\uD83D\\uDE1B': '1f61b',
        '\\uD83D\\uDE2E': '1f62e',
        '\\uD83D\\uDE12': '1f612',
        '\\uD83D\\uDE42': '1f642',
        '\\uD83E\\uDD14': '1f914',
        '\\uD83D\\uDE10': '1f610',
        '\\uD83D\\uDE20': '1f620',
        '\\uD83D\\uDE35': '1f635',
        '\\uD83D\\uDE03': '1f603',
        '\\uD83D\\uDE1E': '1f61e',
        '\\uD83D\\uDE09': '1f609',
        '\\uD83D\\uDE36': '1f636',
        '\\uD83D\\uDE0E': '1f60e',
        '\\uD83D\\uDE2C': '1f62c',
        '\\uD83D\\uDE26': '1f626',
    };
    ns.imagePathPNG = 'https://cdn.jsdelivr.net/emojione/assets/png/';
    ns.imagePathSVG = 'https://cdn.jsdelivr.net/emojione/assets/svg/';
    ns.imagePathSVGSprites = './../assets/sprites/emojione.sprites.svg';
    ns.imageType = 'png'; // or svg
    ns.imageTitleTag = true; //set to false to remove title attribute from img tag
    ns.sprites = false; // if this is true then sprite markup will be used (if SVG image type is set then you must include the SVG sprite file locally)
    ns.unicodeAlt = true; // use the unicode char as the alt attribute (makes copy and pasting the resulting text better)
    ns.ascii = false; // change to true to convert ascii smileys
    ns.cacheBustParam = '?v=2.2.7'; // you can [optionally] modify this to force browsers to refresh their cache. it will be appended to the send of the filenames

    ns.regShortNames = new RegExp(
        '<object[^>]*>.*?</object>|<span[^>]*>.*?</span>|<(?:object|embed|svg|img|div|span|p|a)[^>]*>|(' +
            ns.shortnames +
            ')',
        'gi'
    );
    ns.regAscii = new RegExp(
        '<object[^>]*>.*?</object>|<span[^>]*>.*?</span>|<(?:object|embed|svg|img|div|span|p|a)[^>]*>|((\\s|^)' +
            ns.asciiRegexp +
            '(?=\\s|$|[!,.?]))',
        'g'
    );
    ns.regUnicode = new RegExp(
        '<object[^>]*>.*?</object>|<span[^>]*>.*?</span>|<(?:object|embed|svg|img|div|span|p|a)[^>]*>|(' +
            ns.unicodeRegexp +
            ')',
        'gi'
    );

    ns.toImage = function (str) {
        str = ns.unicodeToImage(str);
        str = ns.shortnameToImage(str);
        return str;
    };

    // Uses toShort to transform all unicode into a standard shortname
    // then transforms the shortname into unicode
    // This is done for standardization when converting several unicode types
    ns.unifyUnicode = function (str) {
        str = ns.toShort(str);
        str = ns.shortnameToUnicode(str);
        return str;
    };

    // Replace shortnames (:wink:) with Ascii equivalents ( ;^) )
    // Useful for systems that dont support unicode nor images
    ns.shortnameToAscii = function (str) {
        var unicode,
            // something to keep in mind here is that array flip will destroy
            // half of the ascii text "emojis" because the unicode numbers are duplicated
            // this is ok for what it's being used for
            unicodeToAscii = ns.objectFlip(ns.asciiList);

        str = str.replace(ns.regShortNames, function (shortname) {
            if (typeof shortname === 'undefined' || shortname === '' || !(shortname in ns.emojioneList)) {
                // if the shortname doesnt exist just return the entire match
                return shortname;
            } else {
                unicode = ns.emojioneList[shortname].unicode[ns.emojioneList[shortname].unicode.length - 1];
                if (typeof unicodeToAscii[unicode] !== 'undefined') {
                    return unicodeToAscii[unicode];
                } else {
                    return shortname;
                }
            }
        });
        return str;
    };

    // will output unicode from shortname
    // useful for sending emojis back to mobile devices
    ns.shortnameToUnicode = function (str) {
        // replace regular shortnames first
        var unicode, fname, uc;
        str = str.replace(ns.regShortNames, function (shortname) {
            if (typeof shortname === 'undefined' || shortname === '' || !(shortname in ns.emojioneList)) {
                // if the shortname doesnt exist just return the entire match
                return shortname;
            }
            unicode = ns.emojioneList[shortname].unicode[0].toUpperCase();
            fname = ns.emojioneList[shortname].fname;
            uc = ns.emojioneList[shortname].uc;
            //return ns.convert(unicode);
            return ns.convert(uc);
        });

        // if ascii smileys are turned on, then we'll replace them!
        if (ns.ascii) {
            str = str.replace(ns.regAscii, function (entire, m1, m2, m3) {
                if (typeof m3 === 'undefined' || m3 === '' || !(ns.unescapeHTML(m3) in ns.asciiList)) {
                    // if the shortname doesnt exist just return the entire match
                    return entire;
                }

                m3 = ns.unescapeHTML(m3);
                unicode = ns.asciiList[m3].toUpperCase();
                return m2 + ns.convert(unicode);
            });
        }

        return str;
    };

    ns.shortnameToImage = function (str) {
        // replace regular shortnames first
        var replaceWith, unicode, alt, title;
        str = str.replace(ns.regShortNames, function (shortname) {
            if (typeof shortname === 'undefined' || shortname === '' || !(shortname in ns.emojioneList)) {
                // if the shortname doesnt exist just return the entire match
                return shortname;
            } else {
                unicode = ns.emojioneList[shortname].unicode[ns.emojioneList[shortname].unicode.length - 1];
                title = ns.imageTitleTag ? 'title="' + shortname + '"' : '';

                // depending on the settings, we'll either add the native unicode as the alt tag, otherwise the shortname
                alt = ns.unicodeAlt ? ns.convert(unicode.toUpperCase()) : shortname;

                if (ns.imageType === 'png') {
                    if (ns.sprites) {
                        replaceWith =
                            '<span class="emojione emojione-' + unicode + '" ' + title + '>' + alt + '</span>';
                    } else {
                        replaceWith =
                            '<img class="emojione" alt="' +
                            alt +
                            '" ' +
                            title +
                            ' src="' +
                            ns.imagePathPNG +
                            unicode +
                            '.png' +
                            ns.cacheBustParam +
                            '"/>';
                    }
                } else {
                    // svg
                    if (ns.sprites) {
                        replaceWith =
                            '<svg class="emojione"><description>' +
                            alt +
                            '</description><use xlink:href="' +
                            ns.imagePathSVGSprites +
                            '#emoji-' +
                            unicode +
                            '"></use></svg>';
                    } else {
                        replaceWith =
                            '<object class="emojione" data="' +
                            ns.imagePathSVG +
                            unicode +
                            '.svg' +
                            ns.cacheBustParam +
                            '" type="image/svg+xml" standby="' +
                            alt +
                            '">' +
                            alt +
                            '</object>';
                    }
                }

                return replaceWith;
            }
        });

        // if ascii smileys are turned on, then we'll replace them!
        if (ns.ascii) {
            str = str.replace(ns.regAscii, function (entire, m1, m2, m3) {
                if (typeof m3 === 'undefined' || m3 === '' || !(ns.unescapeHTML(m3) in ns.asciiList)) {
                    // if the ascii doesnt exist just return the entire match
                    return entire;
                }

                m3 = ns.unescapeHTML(m3);
                unicode = ns.asciiList[m3];
                title = ns.imageTitleTag ? 'title="' + ns.escapeHTML(m3) + '"' : '';

                // depending on the settings, we'll either add the native unicode as the alt tag, otherwise the shortname
                alt = ns.unicodeAlt ? ns.convert(unicode.toUpperCase()) : ns.escapeHTML(m3);

                if (ns.imageType === 'png') {
                    if (ns.sprites) {
                        replaceWith =
                            m2 + '<span class="emojione emojione-' + unicode + '"  ' + title + '>' + alt + '</span>';
                    } else {
                        replaceWith =
                            m2 +
                            '<img class="emojione" alt="' +
                            alt +
                            '" ' +
                            title +
                            ' src="' +
                            ns.imagePathPNG +
                            unicode +
                            '.png' +
                            ns.cacheBustParam +
                            '"/>';
                    }
                } else {
                    // svg
                    if (ns.sprites) {
                        replaceWith =
                            '<svg class="emojione"><description>' +
                            alt +
                            '</description><use xlink:href="' +
                            ns.imagePathSVGSprites +
                            '#emoji-' +
                            unicode +
                            '"></use></svg>';
                    } else {
                        replaceWith =
                            m2 +
                            '<object class="emojione" data="' +
                            ns.imagePathSVG +
                            unicode +
                            '.svg' +
                            ns.cacheBustParam +
                            '" type="image/svg+xml" standby="' +
                            alt +
                            '">' +
                            alt +
                            '</object>';
                    }
                }

                return replaceWith;
            });
        }

        return str;
    };

    ns.unicodeToImage = function (str) {
        var replaceWith, unicode, short, fname, alt, title;
        var mappedUnicode = ns.mapUnicodeToShort();
        str = str.replace(ns.regUnicode, function (unicodeChar) {
            if (typeof unicodeChar === 'undefined' || unicodeChar === '' || !(unicodeChar in ns.jsEscapeMap)) {
                // if the unicodeChar doesnt exist just return the entire match
                return unicodeChar;
            } else {
                // get the unicode codepoint from the actual char
                unicode = ns.jsEscapeMap[unicodeChar];

                //then map to shortname and locate the filename
                short = mappedUnicode[unicode];
                fname = ns.emojioneList[short].fname;

                // depending on the settings, we'll either add the native unicode as the alt tag, otherwise the shortname
                alt = ns.unicodeAlt ? ns.convert(unicode.toUpperCase()) : short;
                title = ns.imageTitleTag ? 'title="' + short + '"' : '';

                if (ns.imageType === 'png') {
                    if (ns.sprites) {
                        replaceWith =
                            '<span class="emojione emojione-' + unicode + '" ' + title + '>' + alt + '</span>';
                    } else {
                        replaceWith =
                            '<img class="emojione" alt="' +
                            alt +
                            '" ' +
                            title +
                            ' src="' +
                            ns.imagePathPNG +
                            fname +
                            '.png' +
                            ns.cacheBustParam +
                            '"/>';
                    }
                } else {
                    // svg
                    if (ns.sprites) {
                        replaceWith =
                            '<svg class="emojione"><description>' +
                            alt +
                            '</description><use xlink:href="' +
                            ns.imagePathSVGSprites +
                            '#emoji-' +
                            unicode +
                            '"></use></svg>';
                    } else {
                        replaceWith =
                            '<img class="emojione" alt="' +
                            alt +
                            '" ' +
                            title +
                            ' src="' +
                            ns.imagePathSVG +
                            fname +
                            '.svg' +
                            ns.cacheBustParam +
                            '"/>';
                    }
                }

                return replaceWith;
            }
        });

        return str;
    };

    // this is really just unicodeToShortname() but I opted for the shorthand name to match toImage()
    ns.toShort = function (str) {
        var find = ns.getUnicodeReplacementRegEx(),
            replacementList = ns.mapUnicodeCharactersToShort();
        return ns.replaceAll(str, find, replacementList);
    };

    // for converting unicode code points and code pairs to their respective characters
    ns.convert = function (unicode) {
        if (unicode.indexOf('-') > -1) {
            var parts = [];
            var s = unicode.split('-');
            for (var i = 0; i < s.length; i++) {
                var part = parseInt(s[i], 16);
                if (part >= 0x10000 && part <= 0x10ffff) {
                    var hi = Math.floor((part - 0x10000) / 0x400) + 0xd800;
                    var lo = ((part - 0x10000) % 0x400) + 0xdc00;
                    part = String.fromCharCode(hi) + String.fromCharCode(lo);
                } else {
                    part = String.fromCharCode(part);
                }
                parts.push(part);
            }
            return parts.join('');
        } else {
            var s = parseInt(unicode, 16);
            if (s >= 0x10000 && s <= 0x10ffff) {
                var hi = Math.floor((s - 0x10000) / 0x400) + 0xd800;
                var lo = ((s - 0x10000) % 0x400) + 0xdc00;
                return String.fromCharCode(hi) + String.fromCharCode(lo);
            } else {
                return String.fromCharCode(s);
            }
        }
    };

    ns.escapeHTML = function (string) {
        var escaped = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;',
        };

        return string.replace(/[&<>"']/g, function (match) {
            return escaped[match];
        });
    };
    ns.unescapeHTML = function (string) {
        var unescaped = {
            '&amp;': '&',
            '&#38;': '&',
            '&#x26;': '&',
            '&lt;': '<',
            '&#60;': '<',
            '&#x3C;': '<',
            '&gt;': '>',
            '&#62;': '>',
            '&#x3E;': '>',
            '&quot;': '"',
            '&#34;': '"',
            '&#x22;': '"',
            '&apos;': "'",
            '&#39;': "'",
            '&#x27;': "'",
        };

        return string.replace(/&(?:amp|#38|#x26|lt|#60|#x3C|gt|#62|#x3E|apos|#39|#x27|quot|#34|#x22);/gi, function (
            match
        ) {
            return unescaped[match];
        });
    };

    ns.mapEmojioneList = function (addToMapStorage) {
        for (var shortname in ns.emojioneList) {
            if (!ns.emojioneList.hasOwnProperty(shortname)) {
                continue;
            }
            for (var i = 0, len = ns.emojioneList[shortname].unicode.length; i < len; i++) {
                var unicode = ns.emojioneList[shortname].unicode[i];
                addToMapStorage(unicode, shortname);
            }
        }
    };

    ns.mapUnicodeToShort = function () {
        if (!ns.memMapShortToUnicode) {
            ns.memMapShortToUnicode = {};
            ns.mapEmojioneList(function (unicode, shortname) {
                ns.memMapShortToUnicode[unicode] = shortname;
            });
        }
        return ns.memMapShortToUnicode;
    };

    ns.memoizeReplacement = function () {
        if (!ns.unicodeReplacementRegEx || !ns.memMapShortToUnicodeCharacters) {
            var unicodeList = [];
            ns.memMapShortToUnicodeCharacters = {};
            ns.mapEmojioneList(function (unicode, shortname) {
                var emojiCharacter = ns.convert(unicode);
                if (ns.emojioneList[shortname].isCanonical) {
                    ns.memMapShortToUnicodeCharacters[emojiCharacter] = shortname;
                }
                unicodeList.push(emojiCharacter);
            });
            ns.unicodeReplacementRegEx = unicodeList.join('|');
        }
    };

    ns.mapUnicodeCharactersToShort = function () {
        ns.memoizeReplacement();
        return ns.memMapShortToUnicodeCharacters;
    };

    ns.getUnicodeReplacementRegEx = function () {
        ns.memoizeReplacement();
        return ns.unicodeReplacementRegEx;
    };

    //reverse an object
    ns.objectFlip = function (obj) {
        var key,
            tmp_obj = {};

        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                tmp_obj[obj[key]] = key;
            }
        }

        return tmp_obj;
    };

    ns.escapeRegExp = function (string) {
        return string.replace(/[-[\]{}()*+?.,;:&\\^$#\s]/g, '\\$&');
    };

    ns.replaceAll = function (string, find, replacementList) {
        var escapedFind = ns.escapeRegExp(find);
        var search = new RegExp(
            '<object[^>]*>.*?</object>|<span[^>]*>.*?</span>|<(?:object|embed|svg|img|div|span|p|a)[^>]*>|(' +
                escapedFind +
                ')',
            'gi'
        );

        // callback prevents replacing anything inside of these common html tags as well as between an <object></object> tag
        var replace = function (entire, m1) {
            return typeof m1 === 'undefined' || m1 === '' ? entire : replacementList[m1];
        };

        return string.replace(search, replace);
    };
})(emojione);

export default emojione;
