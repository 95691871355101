/* @flow */

import React from 'react';
import { observer } from 'mobx-react';
import Styled from 'styled';

import BadgeView from './badge-view';
import defaultStyles from './badge-wrapper-view.scss';

const BadgeWrapperView = Styled(defaultStyles)(
    observer(function BadgeWrapperView(props: {
        value: number,
        size: number,
        styles: Object,
        children?: ?(React.Element<any>[]),
    }) {
        const { value, styles, children } = props;

        return (
            <span className={styles.badgeWrapper}>
                {children}
                <span className={styles.badge}>
                    <BadgeView value={value} />
                </span>
            </span>
        );
    })
);

export default BadgeWrapperView;
