import React from 'react';
import Styled from 'styled';
import { useLocation } from 'react-router';

import SVGIcon from 'lib/images/svg-icon/svg-icon';

import fbIcon from './fblogo.svg';
import defaultStyles from './fb-login-button.scss';


interface Props {
    styles: typeof defaultStyles;
}

const FbLoginButton: React.FC<Props> = props => {
    const { styles } = props;

    const location = useLocation();

    return (
        <a href={`/login/facebook/?next=${location ? location.pathname : '/'}`}>
            <button className={styles.facebookButton}>
                <span className={styles.facebookButtonInnerWrapper}>
                    <SVGIcon style={{image: styles.icon}} src={fbIcon} />
                    <span className={styles.facebookButtonText}>Facebook</span>
                </span>
            </button>
        </a>
    );
};

export default Styled(defaultStyles)(FbLoginButton);
