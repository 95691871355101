import { SiteContext } from 'four-nets';
import React, { useContext, useEffect, useRef } from 'react';

const allowedPathsPatterns = [/^\/$/];

const slotId = 'div-gpt-ad-1680003698449-0';
const rgx = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
export const storageKey = 'bottom-ad-closed-date';
const holdClosedForSeconds = 3 * 60; // 3 minutes

const slotIds = {
    // 101: '/39524221/mojasvadba.sk/mojasvadba.sk-branding',
    102: '/39524221/beremese.cz/beremese.cz-branding',
    301: '/39524221/modrastrecha.sk/modrastrecha.sk-branding',
    302: '/39524221/modrastrecha.cz/modrastrecha.cz-branding',
};

const canShowSlotOnSite = site => {
    return !!slotIds[site.id];
};

const isAdAllowedforPathname = pathname => {
    return allowedPathsPatterns.some(pattern => pattern.test(pathname));
};

const isMobile = () => {
    return rgx.test(navigator.userAgent);
};

const userClosedAd = () => {
    const now = new Date();
    const storageValue = localStorage.getItem(storageKey);

    if (storageValue === null) {
        return false;
    }

    const parsedDate = new Date(storageValue);
    const expiryDate = new Date(parsedDate.getTime() + holdClosedForSeconds * 1000);

    if (now > expiryDate) {
        localStorage.removeItem(storageKey);
        return false;
    }

    return true;
};

const loadAd = siteId => {
    window.googletag = window.googletag || { cmd: [] };

    window.googletag.cmd.push(function () {
        const slot = window.googletag
            .defineSlot(slotIds[siteId], ['fluid', [320, 50], [320, 100], [320, 150]], slotId)
            .addService(window.googletag.pubads());

        slot.defineSizeMapping(
            window.googletag
                .sizeMapping()
                .addSize([0, 0], ['fluid', [320, 50], [320, 100], [320, 150]])
                .build()
        );

        slot.setTargeting('page', 'homepage');
        slot.setTargeting('pathname', '/');

        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().collapseEmptyDivs();
        window.googletag.enableServices();

        const command = () => {
            window.googletag.pubads().addEventListener('slotRenderEnded', arg => {
                const domId = arg.slot.getSlotId().getDomId();
                const isEmpty = arg.isEmpty;

                if (domId === slotId && !isEmpty) {
                    const adDom = document.getElementById(domId);
                    const fixedDiv = adDom.parentNode.parentNode;

                    fixedDiv.style.bottom = '0';
                }
            });
        };

        // add command when script is ready
        window.googletag.cmd.push(command);
        window.googletag.display(slotId);
    });
};

const BottomFixedAd = () => {
    const containerRef = useRef(null);
    const timeoutRef = useRef(null);
    const site = useContext(SiteContext);

    useEffect(() => {
        if (
            canShowSlotOnSite(site) &&
            containerRef.current &&
            isMobile() &&
            isAdAllowedforPathname(window.location.pathname) &&
            !userClosedAd()
        ) {
            containerRef.current.style.display = 'block';
            loadAd(site.id);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const handleClose = () => {
        if (containerRef.current) {
            containerRef.current.style.bottom = '-150px';
        }

        const now = new Date();

        window.localStorage.setItem(storageKey, now.toISOString());

        timeoutRef.current = setTimeout(() => {
            containerRef.current.style.display = 'none';
        }, 500);
    };

    return (
        <div
            ref={containerRef}
            style={{
                position: 'fixed',
                bottom: -150,
                width: '100%',
                height: 150,
                background: 'white',
                zIndex: 100,
                display: 'none',
            }}
            className='transition-all duration-500'
        >
            <div
                style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <div
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: -28,
                        right: 0,
                        background: '#0000007a',
                        color: 'white',
                        padding: '5px 10px',
                    }}
                >
                    {site.isCzech ? 'Zavřít reklamu' : 'Zavrieť reklamu'}
                </div>
                <div
                    id={slotId}
                    style={{
                        height: 150,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default BottomFixedAd;
