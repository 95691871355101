import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, RouteComponentProps } from 'react-router';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import Promise from 'bluebird';

import Styled from 'styled';
import { loadScriptWithBackoff } from 'lib/utils/dom';

import { ViewerContext } from 'four-nets/user/viewer/viewer';
import { Site } from 'four-nets/site';
import { MD5 } from './md5';

import defaultStyles from './google-dfp.scss';

const DFP_SCRIPT_SRC = '//securepubads.g.doubleclick.net/tag/js/gpt.js';
const NO_ADS_URLS = [/\/pro\/.*\/payments/, /\/pro(\/)?$/, /\/catalog\/vendor\/.+/];

export class Targetting {
    key: string;
    values: string;

    constructor(key: string, values: string) {
        this.key = key;
        this.values = values;
    }
}

/* AdUnitView */
export interface AdUnitProps extends Partial<RouteComponentProps> {
    styles?: typeof defaultStyles;
    wrapperStyle?: object;
    targettings?: Targetting[];
    slotRenderEndedCb?: null | Function;
    overrideAdUnitCode?: boolean;
    adUnitCode?: string;
    sizeMapping?: any[];
    sizes?: (string | number | number[])[];
    label?: string | React.ReactNode;
    shrinkAtMobile?: boolean;
    divAdId?: string;
    refreshSeconds?: null | number;
}

interface AdUnitState {
    visible?: boolean;
    adId: string;
}

const getHashForPathname = pathname => {
    const hash = MD5(pathname);
    if (hash) {
        return hash.slice(0, 40);
    }
    return '';
};

class AdUnitView extends React.PureComponent<AdUnitProps, AdUnitState> {
    static defaultProps = {
        targettings: [],
        slotRenderEndedCb: null,
        overrideAdUnitCode: false,
        shrinkAtMobile: false,
        refreshSeconds: null,
    };

    static propTypes = {
        targettings: PropTypes.arrayOf(PropTypes.instanceOf(Targetting)),
        slotRenderEndedCb: PropTypes.func,
    };

    static contextTypes = {
        site: PropTypes.instanceOf(Site),
    };

    static promise?: Promise<any>;
    static slot: any;
    static loadDfp() {
        if (!AdUnitView.promise || AdUnitView.promise.isRejected()) {
            AdUnitView.promise = loadScriptWithBackoff({ src: DFP_SCRIPT_SRC }).then(() => {
                window.googletag.cmd.push(() => {
                    window.googletag.pubads().disableInitialLoad();
                    window.googletag.pubads().collapseEmptyDivs(true);
                    window.googletag.enableServices();
                });
            });
        }

        return AdUnitView.promise;
    }

    slot: any = null;
    googletag: any;

    constructor(props: AdUnitProps) {
        super(props);
        this.state = {
            visible: false,
            adId: '',
        };
        this.interval = null;
    }

    initDfp = () => {
        return AdUnitView.loadDfp().then(() => {
            const adCodePrefix = `/${this.context.site.networkCode}/${this.context.site.domain}/${this.context.site.domain}-`;
            let adCode = `${adCodePrefix}${this.props.adUnitCode}`;
            if (this.props.overrideAdUnitCode) {
                adCode = this.props.adUnitCode!;
            }

            this.googletag = window.googletag;
            this.googletag.cmd.push(() => {
                const builder = this.googletag.sizeMapping();
                const sizeMapping = this.props.sizeMapping || [[[0, 0], this.props.sizes]];
                sizeMapping.forEach(m => {
                    const [viewport, sizes] = m;
                    builder.addSize(viewport, sizes);
                });
                const mapping = builder.build();

                this.googletag
                    .pubads()
                    .getSlots()
                    .forEach((slot: any) => {
                        if (slot.getSlotElementId() === this.state.adId) {
                            this.slot = slot;
                        }
                    });

                if (!this.slot) {
                    this.slot = this.googletag
                        .defineSlot(adCode, this.props.sizes, this.state.adId)
                        .defineSizeMapping(mapping);
                }

                this.googletag.pubads().addEventListener('slotRenderEnded', (event: any) => {
                    if (event.slot === this.slot) {
                        if (event.isEmpty) {
                            this.setState({ visible: false });
                        } else {
                            this.setState({ visible: true });
                        }

                        if (this.props.slotRenderEndedCb) {
                            this.props.slotRenderEndedCb(event);
                        }
                    }
                });

                this.props.targettings!.map(targetting => {
                    this.slot.setTargeting(targetting.key, targetting.values);
                });

                this.slot.addService(this.googletag.pubads());
            });
            this.googletag.cmd.push(() => {
                this.googletag.display(this.state.adId);
            });
            this.googletag.cmd.push(() => {
                this.googletag.pubads().refresh([this.slot]);
            });

            if (this.props.refreshSeconds) {
                this.interval = setInterval(() => {
                    this.googletag.cmd.push(() => {
                        this.googletag.pubads().refresh([this.slot]);
                    });
                }, this.props.refreshSeconds * 1000);
            }
        });
    };

    componentDidMount() {
        requestAnimationFrame(() => {
            this.setState(
                {
                    adId: this.props.divAdId || uniqueId(`div-gpt-ad-${this.props.adUnitCode}`),
                },
                this.initDfp
            );
        });
    }

    componentWillUnmount() {
        if (this.googletag && this.googletag.destroySlots) {
            this.googletag.destroySlots([this.slot]);
        }

        if (this.props.slotRenderEndedCb) {
            this.props.slotRenderEndedCb({ isEmpty: true });
        }

        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    componentDidUpdate(prevProps: AdUnitProps) {
        if (!isEqual(prevProps.targettings, this.props.targettings) && this.googletag && this.slot) {
            this.slot.clearTargeting();
            this.props.targettings!.map(targetting => {
                this.slot.setTargeting(targetting.key, targetting.values);
            });

            this.googletag.display(this.state.adId);
            this.googletag.pubads().refresh([this.slot]);
        }
    }

    render() {
        const { styles } = this.props;
        const classes = [styles!.wrapper];

        if (this.props.shrinkAtMobile) {
            classes.push(styles!.small);
        }

        let style = this.props.wrapperStyle;
        if (!this.state.visible) {
            classes.push(styles!.empty);
            style = undefined;
        }

        return (
            <div className={classes.join(' ')} style={style}>
                <div id={this.state.adId} className={styles!.dfpBanner} />
                {this.props.label ? <div className={styles!.label}>{this.props.label}</div> : null}
            </div>
        );
    }
}

const AdUnitWithRoute = (props: AdUnitProps) => {
    const { viewer } = useContext(ViewerContext);

    // This should ideally be in AdUnitView but that class uses old context api
    if (new Date(viewer?.profile?.vendor?.paidTillDate) >= new Date()) {
        return null;
    }

    return (
        <Route
            render={routeProps => {
                const {
                    location: { pathname },
                } = routeProps;

                let match = false;
                NO_ADS_URLS.forEach(regexp => {
                    if (regexp.test(pathname)) {
                        match = true;
                    }
                });
                if (match) {
                    return null;
                }

                const targettings = props.targettings || [];
                const rootPath = pathname.split('/')[1];
                let targettingValue = rootPath;
                if (targettingValue === 'blog' || targettingValue === 'group' || targettingValue === 'list-of-groups') {
                    targettingValue = 'blogs';
                }

                targettings.push(new Targetting('page', targettingValue || 'homepage'));
                targettings.push(new Targetting('pathname', pathname));
                targettings.push(new Targetting('pathname_md5', getHashForPathname(pathname)));

                return <AdUnitView {...routeProps} {...props} targettings={targettings} />;
            }}
        />
    );
};

export default Styled(defaultStyles)(AdUnitWithRoute);
