import React from 'react';
import { useMutation } from '@apollo/client/react/hooks';

import paymentMutation, { PaymentMutation, PaymentMutationVariables } from './payment.graphql';

export default () => {
    const [cardPaymentFn] = useMutation<PaymentMutation, PaymentMutationVariables>(paymentMutation);

    return (period: number) => {
        cardPaymentFn({ variables: { input: { period } } }).then(({ data }) => {
            if (data && data.payment && data.payment.cardPayment) {
                window.location.href = data.payment.redirectUrl as string;
            }
        });
    };
};
