import React, { useState, useEffect, useContext } from 'react';
import Styled from 'styled';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import cookies from 'js-cookie';

import SVGIcon from 'lib/images/svg-icon/svg-icon';

import iconClose from 'four-nets/icons/svg/icon_close.svg';
import { VendorCardChangeNoticeContext } from 'four-nets/vendor-card-change-notice';

import defaultStyles from './unpaid-banner.scss';
import { ViewerContext } from 'four-nets/user/viewer/viewer';

interface Props {
    styles: typeof defaultStyles;
}

const COOKIE_NAME = 'vendor_unpaid_banner';

type VisitorType = 'UnpaidVendor' | 'WcVendor' | 'Other';

const isVendor = (visitorType: VisitorType) => {
    return visitorType === 'UnpaidVendor' || visitorType === 'WcVendor';
};

const UnpaidBanner: React.FC<Props> = ({ styles }) => {
    const [seen, setSeen] = useState<string | undefined>(cookies.get(COOKIE_NAME));
    const [visitorType, setVisitorType] = useState<VisitorType>('Other');

    const { viewer } = useContext(ViewerContext);
    const showVendorCardChangeNotice = useContext(VendorCardChangeNoticeContext);

    useEffect(() => {
        if (viewer?.profile?.vendor) {
            if (!viewer.profile.vendor.isPresentationPaid) {
                setVisitorType('UnpaidVendor');
            } else if (viewer.profile.vendor.isRecurringWc) {
                setVisitorType('WcVendor');
            } else {
                setVisitorType('Other');
            }
        } else {
            setVisitorType('Other');
        }
    }, [viewer]);

    const close = () => {
        if (!cookies.get(COOKIE_NAME)) {
            cookies.set(COOKIE_NAME, 'seen', { expires: 30 });
            setSeen('seen');
        }
    };

    if (isVendor(visitorType) && !seen) {
        const btn =
            visitorType === 'UnpaidVendor' ? (
                <Link to={`/pro/${viewer && viewer.username}/payments/`} className={styles.expiredLink}>
                    <FormattedMessage id='BUY_SUBSCRIPTION' />
                </Link>
            ) : (
                <button onClick={showVendorCardChangeNotice} className={styles.expiredLink}>
                    <FormattedMessage id='ADD_CARD' />
                </button>
            );
        return (
            <div className={styles.expired}>
                <span className={styles.expiredText}>
                    {visitorType === 'UnpaidVendor' ? (
                        <FormattedMessage id='VENDOR_PROFILE_NOT_DISPLAYING' />
                    ) : (
                        <FormattedMessage id='3D_SECURE_NOTICE_PART1' />
                    )}
                </span>
                {btn}
                <button onClick={close} className={styles.expiredClose}>
                    <SVGIcon style={{ image: styles.expiredCloseIcon }} src={iconClose} />
                </button>
            </div>
        );
    }
    return null;
};

export default Styled(defaultStyles)(UnpaidBanner);
