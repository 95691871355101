import React from 'react';
import { intlShape, IntlProvider, addLocaleData, FormattedMessage } from 'react-intl';
import { IntlMessage } from 'react-intl/intl-message';

interface Messages {
    [id: string]: string;
}

interface LocaleMessages {
    [locale: string]: Messages;
}

interface Props {
    messages: object;
    locale: string;
    initialNow?: Date;
}

process.env.LOCALES.forEach(locale => addLocaleData(require(`react-intl/locale-data/${locale.split('-')[0]}`)));

class PatchedIntlProvider extends React.Component {
    static contextTypes = {
        intl: intlShape,
    };

    static childContextTypes = {
        intl: intlShape,
    };

    getChildContext() {
        return {
            intl: {
                ...this.context.intl,
                formatMessage: (
                    message: IntlMessage | React.ReactElement<any> | FormattedMessage.MessageDescriptor,
                    ...rest: any[]
                ) => {
                    const args = rest || [];

                    if (message instanceof IntlMessage) {
                        return this.context.intl.formatMessage(message.message, message.values);
                    } else if (React.isValidElement(message)) {
                        return message;
                    } else if (message instanceof Object) {
                        return this.context.intl.formatMessage(message as FormattedMessage.MessageDescriptor, ...args);
                    }

                    return message as string;
                },
            },
        };
    }

    render() {
        return this.props.children;
    }
}

const ref = {
    current: class WithReactIntl extends React.Component<Props> {
        static localeMessages: LocaleMessages = {};

        constructor(props: any, context: any) {
            super(props, context);

            if (props.messages && props.locale) {
                Object.assign(ref.current.localeMessages, {
                    [props.locale]: props.messages,
                });
            }
        }

        // this will come from ssr
        state = {
            locale: this.props.locale,
        };

        componentDidMount() {
            window.onintlhotupdate = (hot: Messages) => {
                Object.keys(hot).forEach(locale => {
                    const messages = hot[locale];

                    if (!ref.current.localeMessages[locale]) {
                        ref.current.localeMessages[locale] = {};
                    }

                    Object.assign(ref.current.localeMessages[locale], messages);
                });
            };
        }

        render() {
            const { locale } = this.state;
            const { initialNow } = this.props;
            const messages = ref.current.localeMessages[locale];

            return (
                <IntlProvider locale={locale} messages={messages} initialNow={initialNow}>
                    <PatchedIntlProvider>{this.props.children}</PatchedIntlProvider>
                </IntlProvider>
            );
        }
    },
};

export default ref.current;
