import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router';

import { AlertContext } from 'lib/alert-context/alert-context';

import ComposeMail from './compose-mail';
import defaultStyles from './compose-mail-dialog.scss';
import Modal from 'four-nets/ui-v2/modal/modal';

interface Props {
    open?: boolean;
    onClose: () => void;

    to?: string[];
    subject?: string;
    body?: string;
}

const ComposeMailDialog: React.FC<Props> = props => {
    const { open, onClose, to = [], subject = '', body = '' } = props;
    const { acknowledge } = useContext(AlertContext);

    return (
        <Modal
            title={<FormattedMessage id='MAILS_FORM_TITLE_WRITE_NEW' />}
            open={Boolean(open)}
            dirty={true}
            onClose={onClose}
        >
            <Route>
                {props => {
                    props.location.state = { to, subject, body };
                    return (
                        <FormattedMessage id='MESSAGE_WAS_SUCCESSFULLY_SENT'>
                            {msg => (
                                <ComposeMail
                                    style={{ form: defaultStyles.form }}
                                    {...props}
                                    hideTitle={true}
                                    onMessageSuccessfullySent={() => {
                                        acknowledge!({
                                            title: msg,
                                            onAccept: onClose,
                                            onClose,
                                        });
                                    }}
                                />
                            )}
                        </FormattedMessage>
                    );
                }}
            </Route>
        </Modal>
    );
};

export default ComposeMailDialog;
