import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Styled from 'styled';
import lock from 'four-nets/icons/svg/icon_lock.svg';

import SVGIcon from 'lib/images/svg-icon/svg-icon';
import Status from 'lib/react-router/status';
import ScrollToTop from 'lib/scroll/scroll-to-top';

import ButtonView from 'four-nets/ui/button-view';
import { Tab } from 'four-nets/user/auth/login-dialog';
import { AuthOrigin } from 'four-nets/user/auth/auth-ga-events';

import defaultStyles from './unauthorized.scss';
import buttonStyles from './button-view.scss';
import { FormattedMessage } from 'react-intl';

export interface Props {
    styles: typeof defaultStyles;
}

interface State {
    loginOpen: boolean;
    tab: Tab;
}

@observer
class Unauthorized extends React.Component<Props, State> {
    static contextTypes = {
        showLogin: PropTypes.func,
    };

    render() {
        const { styles } = this.props;

        return (
            <React.Fragment>
                <Status code={401} />
                <ScrollToTop />
                <div className={styles.centered}>
                    <div className={styles.wrapper}>
                        <SVGIcon style={{ image: styles.lockIcon }} src={lock} />
                        <span className={styles.login}>
                            <p className={styles.loginText}>
                                <FormattedMessage id='THIS_SITE_IS_ONLY_FOR_LOGGED_IN' />
                            </p>
                            <span className={styles.button}>
                                <ButtonView
                                    onClick={() =>
                                        this.context.showLogin(Promise.resolve, Tab.Login, AuthOrigin.Unauthorized)
                                    }
                                    styles={buttonStyles}
                                >
                                    <FormattedMessage id='LOGIN' />
                                </ButtonView>
                            </span>
                        </span>
                    </div>
                    <p className={styles.register}>
                        <FormattedMessage id='IF_YOU_DONT_HAVE_PROFILE' />
                        &nbsp;
                        <button
                            onClick={() =>
                                this.context.showLogin(Promise.resolve, Tab.Register, AuthOrigin.Unauthorized)
                            }
                            className={styles.registerButton}
                        >
                            <FormattedMessage id='VISIBILITY_ALERT_REGISTER_HERE' values={{ gender: true }} />
                        </button>
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default Styled(defaultStyles)(Unauthorized);
