import React from 'react';
import { padStart } from 'lodash';
import { FormattedMessage } from 'react-intl';

export type Validator<T> = (value: T) => React.ReactElement<FormattedMessage> | undefined;

export function mergeValidators(validators: Array<Validator<any>>) {
    return (value: any) => {
        const result = [];
        for (const validator of validators) {
            const errorMessage = validator(value);
            if (errorMessage) {
                result.push(errorMessage);
            }
        }

        if (result.length) {
            return result;
        }
    };
}

export function emptyValidator(value?: string | null | any[]) {
    if (value === undefined || value === null || value.length === 0) {
        return <FormattedMessage id='FORM_REQUIRED_VALIDATOR_ERROR' />;
    }
}

export function trueValidator(value: any) {
    if (value !== true) {
        return <FormattedMessage id='FORM_TRUE_VALIDATOR_ERROR' />;
    }
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function emailValidator(value: string) {
    if (!EMAIL_REGEX.test(value)) {
        return <FormattedMessage id='FORM_INVALID_EMAIL_VALIDATOR_ERROR' />;
    }
}

export const regexValidator = (regExp: RegExp) => (value: string) => {
    if (!regExp.test(value)) {
        return <FormattedMessage id='INVALID_FORMAT' />;
    }
};

export const vendorUsernameValidator = (value: string) => {
    if (!/^[_0-9a-zA-Z]+$/.test(value)) {
        return <FormattedMessage id='VENDOR_USERNAMES_CAN_ONLY_CONTAIN_LETTERS_NUMBERS_AND_UNDERSCORES.' />;
    }
};

export const minMaxLengthValidator = (min: number, max?: number) => (value: string) => {
    if ((value || '').length < min) {
        return <FormattedMessage id='FORM_MIN_VALIDATOR_ERROR' values={{ min }} />;
    }

    if (max !== undefined && (value || '').length > max) {
        return <FormattedMessage id='FORM_MAX_VALIDATOR_ERROR' values={{ max }} />;
    }
};

const isValidDate = (date: Date) => {
    const iso8601 = [
        padStart(String(date.getFullYear()) || '', 4, '0'),
        padStart(String(date.getMonth()) || '', 2, '0'),
        padStart(String(date.getDate()) || '', 2, '0'),
    ].join('-');

    const timestamp = Date.parse(iso8601);

    if (timestamp === null || isNaN(timestamp)) {
        return false;
    }
    return true;
};

export function dateValidator(value: Date) {
    if (!isValidDate(value)) {
        return <FormattedMessage id='FORM_DATE_VALIDATOR_ERROR' />;
    }
}

export function birthdayValidator(value: Date) {
    const dateValidation = dateValidator(value);
    if (dateValidation) {
        return dateValidation;
    }

    if (value > new Date()) {
        return <FormattedMessage id='FORM_BIRTHDAY_VALIDATOR_ERROR' />;
    }
}

export function positiveValueValidator(value: number | string) {
    const typeCheckedValue = typeof value === 'number' ? value : parseFloat(value);
    if (typeCheckedValue && typeCheckedValue < 0) {
        return <FormattedMessage id='FORM_POSITIVE_VALUE_VALIDATOR_ERROR' />;
    }
}

export const ICOValidator = (value: string) => {
    if (value == null || value === '') {
        return;
    }

    let a = 0,
        c = 0;
    const digits = value.split('').map(parseFloat);

    if (value.length !== 8) {
        return <FormattedMessage id='INVALID_FORMAT' />;
    }

    for (let i = 0; i < 7; i++) {
        a += digits[i] * (8 - i);
    }

    a = a % 11;
    c = 11 - a;

    if (a === 1) {
        c = 0;
    } else if (a === 0 || a === 10) {
        c = 1;
    }

    if (digits[7] !== c) {
        return <FormattedMessage id='INVALID_FORMAT' />;
    }
};
