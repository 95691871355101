import {observable, extendObservable, computed} from 'mobx';
import isFunction from 'lodash/isFunction';
import decorator from 'lib/lang/decorator';

export default decorator(() => Class => {
    class WithCompute extends Class {
        @observable $computeModel: Object;

        constructor(options) {
            super(options);
            const object = {};

            if (Class.prototype.$computeProperties) {
                Class.prototype.$computeProperties.forEach(key => {
                    const value = options[key];

                    if (value) {
                        Object.assign(object, {
                            [key]: isFunction(value) ? computed(value) : value,
                        });
                    }
                });
            }

            if (this.$computeModel) {
                extendObservable(this.$computeModel, object);
            } else {
                this.$computeModel = observable(object);
            }
        }
    }

    return WithCompute;
});
