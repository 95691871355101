import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import loadable from '@loadable/component';

import { URLBuilder } from 'url-builder/url-builder';
import { Route, Redirect, Switch } from 'react-router';

import LoadingSwitch from 'lib/react-router/loading-switch';
import Styled from 'styled';
import Localizable from 'lib/intl/localizable';
import Gemius from 'lib/gemius/gemius';

import GoogleMapsLoader from 'lib/google/maps/google-maps-loader';
import FacebookSDKLoaderView from 'lib/facebook/facebook-sdk-loader-view';
import media from 'lib/media/media';
import RouteWithSearch from 'lib/react-router/route-with-search';
import ScrollToTop from 'lib/scroll/scroll-to-top';
import maps from './lib/google-maps';

import NotFound from 'four-nets/errors/404/not-found';
import { FourNetsApi } from 'four-nets/api/api';
import { UserStore } from 'four-nets/user/user';
import MainWrapperView, { MainWrapperViewModel } from 'four-nets/main-wrapper-view';
import ErrorPage from 'four-nets/errors/error-page';
import gaTrack from 'four-nets/lib/google-analytics/track';

import { Site } from './site';
import defaultStyles from './four-nets-view.scss';

const loadableOptions = {
    fallback: <ScrollToTop />,
};

const LoadableForum = loadable(() => import('four-nets/forum/forum-loader'), loadableOptions);
const LoadableMagazine = loadable(() => import('four-nets/blog/blog-loader'), loadableOptions);
const LoadableSearch = loadable(() => import('four-nets/search/search'), loadableOptions);
const LoadableHome = loadable(() => import('four-nets/homepage/homepage-loader'), loadableOptions);
const LoadableBlogs = loadable(() => import('four-nets/photoblog/photoblog-router'), loadableOptions);
const LoadableBlogsUsers = loadable(() => import('four-nets/photoblog/users/users-loader'), loadableOptions);
const LoadableFavoritedImages = loadable(
    () => import('four-nets/photoblog/favorited-images/favorited-images-loader'),
    loadableOptions
);
const LoadableGroups = loadable(() => import('four-nets/photoblog/groups/groups-router'), loadableOptions);
const LoadableMail = loadable(() => import('four-nets/mails/mails-wrapper'), loadableOptions);
const LoadableBazaar = loadable(() => import('four-nets/bazaar/bazaar-loader'), loadableOptions);
const LoadableNotifications = loadable(() => import('four-nets/notifications/notifications-loader'), loadableOptions);
const LoadableVerification = loadable(
    () => import('four-nets/user/settings/email-verification-loader'),
    loadableOptions
);
const LoadableActivation = loadable(() => import('four-nets/user/activation-link-loader'), loadableOptions);
const LoadableSettings = loadable(() => import('four-nets/user/settings/settings-router'), loadableOptions);

const LoadableCms = loadable(() => import('four-nets/cms/cms-loader'), loadableOptions);
const LoadableReset = loadable(() => import('four-nets/user/password-reset-form-loader'), loadableOptions);
const LoadableWeddingTodolistLoader = loadable(
    () => import('four-nets/wedding-todolist/wedding-todolist-loader'),
    loadableOptions
);

const LoadableVendor = loadable(() => import('four-nets/vendor/vendor-router'), loadableOptions);
const LoadableRegister = loadable(() => import('four-nets/user/register-loader'), loadableOptions);
const LoadableVendorHomepage = loadable(() => import('four-nets/vendor/homepage/vendor-homepage'), loadableOptions);
const LoadableVendorProfile = loadable(() => import('four-nets/vendor/profile/vendor-profile'), loadableOptions);
const LoadableFbLoginFailed = loadable(() => import('four-nets/user/auth/fb-login-failed'), loadableOptions);
const LoadableHiring = loadable(() => import('four-nets/hiring/hiring'), loadableOptions);
const LoadableDemander = loadable(() => import('four-nets/demander'), loadableOptions);
const LoadableSuccessfulDemand = loadable(() => import('four-nets/demander/successful'), loadableOptions);

@Localizable()
@observer
@Styled(defaultStyles)
class FourNetsView extends React.Component {
    @observable googleMapsQuery = observable.map(maps);
    @observable gaId: ?string;

    disposer: Function;

    static contextTypes = {
        api: PropTypes.instanceOf(FourNetsApi),
        site: PropTypes.instanceOf(Site),
        userStore: PropTypes.instanceOf(UserStore),
    };

    UNSAFE_componentWillMount() {
        const { intl } = this.props;
        const { locale } = intl;
        const { site, userStore } = this.context;

        this.googleMapsQuery.set('language', locale);

        this.mainWrapperViewModel = new MainWrapperViewModel({
            userStore,
            site,
            urlBuilder: URLBuilder.defaultURLBuilder,
            intl,
        });
    }

    componentWillReceiveProps(props) {
        const {
            intl: { locale },
        } = props;
        this.googleMapsQuery.set('language', locale);
    }

    componentDidMount() {
        const { site } = this.context;

        import('./lib/google-analytics/google-analytics')
            .then(({ default: loadGA }) => loadGA({ media }))
            .catch(console.error)
            .finally(() => {
                this.timeout = setTimeout(() => {
                    gaTrack('event', '15_seconds', 'read', 'Bounce Rate 15 seconds');
                }, 15000);
            });
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    render() {
        const { mainWrapperViewModel } = this;

        const { error, styles } = this.props;

        let siteRoutes = null;

        if (this.context.site.isWedding) {
            const todolistSlug = this.context.site.isSlovak ? 'svadobny-planovac' : 'svatebni-planovac';
            siteRoutes = [
                <Route
                    key={`/todolist/${todolistSlug}`}
                    exact={true}
                    path={`/todolist/${todolistSlug}`}
                    component={LoadableWeddingTodolistLoader}
                />,
            ];
        }

        let element = (
            <div className={styles.fourNets}>
                {!this.context.site.isGarden ? <Gemius /> : null}
                <Switch>
                    <Route path={['/dopytovac', '/poptavac']} exact component={() => <LoadableDemander mainWrapperViewModel={mainWrapperViewModel} />} />
                    <Route path={['/dopytovac/uspesne-zadany-dopyt', '/poptavac/uspesne-zadana-poptavka']} exact component={() => <LoadableSuccessfulDemand mainWrapperViewModel={mainWrapperViewModel} />} />
                    <GoogleMapsLoader query={this.googleMapsQuery}>
                        <MainWrapperView mainWrapperViewModel={mainWrapperViewModel}>
                            <ErrorPage error={error}>
                                <LoadingSwitch>
                                    <Route exact path='/' component={LoadableHome} />
                                    <Route exact path='/pro' component={LoadableVendorHomepage} />
                                    <Route path='/pro/:username' component={LoadableVendorProfile} />
                                    <Route path='/users_directory' component={LoadableBlogsUsers} />
                                    <Route
                                        path='/friends/confirm/:confirmationKey'
                                        exact={true}
                                        component={LoadableBlogs}
                                    />
                                    <Route
                                        path={['/blogs', '/blog', '/fotoblog', '/profile', '/inspirations']}
                                        component={LoadableBlogs}
                                    />
                                    <Route
                                        path='/favourite-photos/:username'
                                        exact={true}
                                        component={LoadableFavoritedImages}
                                    />
                                    <RouteWithSearch path='/list-of-groups/' component={LoadableGroups} />
                                    <Route path='/group' component={LoadableGroups} />
                                    <Route path='/new-group/' exact={true} component={LoadableGroups} />
                                    <Route path={['/forum', '/diskuse']} component={LoadableForum} />
                                    <Route path='/magazine' component={LoadableMagazine} />
                                    <RouteWithSearch path='/mail' component={LoadableMail} />
                                    <Route path={['/market', '/bazar', '/shops', '/shopping']} component={LoadableBazaar} />
                                    <RouteWithSearch path='/search' component={LoadableSearch} />
                                    <Route path='/wall' exact={true} component={LoadableNotifications} />
                                    <Route path='/settings/:username' component={LoadableSettings} />
                                    <Route
                                        path='/email/verify/:verificationCode'
                                        exact={true}
                                        component={LoadableVerification}
                                    />
                                    <Route
                                        path='/account/verify/:activationCode'
                                        exact={true}
                                        component={LoadableActivation}
                                    />
                                    <Route
                                        path='/rules'
                                        exact={true}
                                        render={props => <LoadableCms {...props} cmsKey='site-rules' />}
                                    />
                                    <Route
                                        path='/rules-pro'
                                        exact={true}
                                        render={props => <LoadableCms {...props} cmsKey='site-rules-pro' />}
                                    />
                                    <Route
                                        path='/about-privacy'
                                        exact={true}
                                        render={props => <LoadableCms {...props} cmsKey='about-privacy-page' />}
                                    />
                                    <Redirect from='/about_privacy' to='about-privacy' exact={true} />
                                    <Route
                                        path='/cookie-policy'
                                        exact={true}
                                        render={props => <LoadableCms {...props} cmsKey='cookie-policy-page' />}
                                    />
                                    <Route path='/cms/:cmsKey' component={LoadableCms} />
                                    <Route
                                        path='/reset/:b36userId([0-9A-Za-z]+)-:token'
                                        exact={true}
                                        component={LoadableReset}
                                    />
                                    <Route path='/catalog' component={LoadableVendor} />
                                    <Route path='/hiring' component={LoadableHiring} />
                                    <Route path='/signup' exact={true} component={LoadableRegister} />
                                    <Route path='/fb-login-failed' exact={true} component={LoadableFbLoginFailed} />
                                    <Redirect from='/logout' to='/' exact={true} />
                                    {siteRoutes}
                                    <Route component={NotFound} />
                                </LoadingSwitch>
                            </ErrorPage>
                        </MainWrapperView>
                    </GoogleMapsLoader>
                </Switch>
            </div>
        );

        if (this.props.facebookAppId) {
            element = (
                <FacebookSDKLoaderView
                    params={{
                        appId: this.props.facebookAppId,
                        xfbml: true,
                        version: 'v2.8',
                    }}
                >
                    {element}
                </FacebookSDKLoaderView>
            );
        }

        return element;
    }
}

export default FourNetsView;
