import React from 'react';
import { observer } from 'mobx-react';
import RouteLinkView from './route-link-view';

@observer
class LinkView extends React.Component {
    onClick = event => {
        if (this.props.onClick) {
            this.props.onClick(event, this.props.link);
        }
    };

    render() {
        const { link, ...rest } = this.props;

        const { href, target, disabled, label } = link || {};

        return (
            <RouteLinkView
                href={href}
                style={{
                    textDecoration: 'none',
                }}
                target={target}
                disabled={disabled}
                {...rest}
                onClick={this.onClick}
                aria-label={label}
            >
                {this.props.children || label}
            </RouteLinkView>
        );
    }
}

export default LinkView;
