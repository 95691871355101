import React from 'react';
import Styled from 'styled';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router';
import classnames from 'classnames';

import { ViewerConsumer } from 'four-nets/user/viewer/viewer';
import { AlertContext } from 'lib/alert-context/alert-context';
import NotFound from 'four-nets/errors/404/not-found';

import defaultStyles from './permissions.scss';

const getDisplayName = (WrappedComponent: React.ComponentType<any>) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export const notAllowedForVendor = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    class NotAllowedForVendor extends React.Component<P> {
        render() {
            return (
                <ViewerConsumer>
                    {({ viewer }) => {
                        return viewer && viewer.profile && viewer.profile.vendor ? (
                            <NotFound />
                        ) : (
                            <WrappedComponent {...(this.props as P)} />
                        );
                    }}
                </ViewerConsumer>
            );
        }
    }

    (NotAllowedForVendor as any).displayName = `NotAllowedForVendor(${getDisplayName(WrappedComponent)})`;
    return NotAllowedForVendor;
};

export const notAllowedForUnpaidVendor = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    class NotAllowedForUnpaidVendor extends React.Component<P> {
        render() {
            return (
                <ViewerConsumer>
                    {({ viewer }) =>
                        viewer &&
                        viewer.profile &&
                        viewer.profile.vendor &&
                        !viewer.profile.vendor.isPresentationPaid ? (
                            <NotFound />
                        ) : (
                            <WrappedComponent {...(this.props as P)} />
                        )
                    }
                </ViewerConsumer>
            );
        }
    }

    (NotAllowedForUnpaidVendor as any).displayName = `NotAllowedForUnpaidVendor(${getDisplayName(WrappedComponent)})`;
    return NotAllowedForUnpaidVendor;
};

export const HideForVendor: React.FC<{ children: React.ReactChild | React.ReactChild[] }> = ({ children }) => (
    <ViewerConsumer>
        {({ viewer }) => (viewer && viewer.profile && viewer.profile.vendor ? null : children)}
    </ViewerConsumer>
);

const UnstyledHideForUnpaidVendor: React.FC<{
    children: React.ReactChild;
    styles: typeof defaultStyles;
    grey?: boolean;
}> = ({ children, styles, grey }) => {
    return (
        <ViewerConsumer>
            {({ viewer }) =>
                viewer && viewer.profile && viewer.profile.vendor && !viewer.profile.vendor.isPresentationPaid ? (
                    <AlertContext.Consumer>
                        {({ confirm }) => (
                            <div className={styles!.relativeWrapper}>
                                {children}
                                <Route>
                                    {({ history }) => (
                                        <button
                                            className={classnames(styles.overlayButton, { [styles.grey]: grey })}
                                            onClick={() => {
                                                confirm({
                                                    body: <FormattedMessage id='VENDOR_PAID_TILL_DATE_EXPIRED' />,
                                                    acceptButtonLabel: (
                                                        <FormattedMessage id='INVOICE_FOR_VENDOR_PRESENTATION_PROLONGATION_PAYMENT' />
                                                    ),
                                                    onAccept: () => {
                                                        history.push(`/pro/${viewer.username}/payments/`);
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </Route>
                            </div>
                        )}
                    </AlertContext.Consumer>
                ) : (
                    children
                )
            }
        </ViewerConsumer>
    );
};

export const HideForUnpaidVendor = Styled(defaultStyles)(UnstyledHideForUnpaidVendor);

export const ShowForUnpaidVendor: React.FC<{ children: React.ReactChild }> = ({ children }) => {
    return (
        <ViewerConsumer>
            {({ viewer }) =>
                viewer && viewer.profile && viewer.profile.vendor && !viewer.profile.vendor.isPresentationPaid
                    ? children
                    : null
            }
        </ViewerConsumer>
    );
};

interface VendorFragment {
    isPresentationPaid: boolean;
}

export const hideExpiredVendorContent = (vendor: VendorFragment | null, viewerIsAuthor?: boolean) => {
    if (viewerIsAuthor) {
        return false;
    }

    if (vendor && !vendor.isPresentationPaid) {
        return true;
    }
    return false;
};
