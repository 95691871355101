
const NOOP = () => {
    // pass
};

export default function observeOnce<T extends Element>(element: T, onIntersect: (element: T) => void, options?: IntersectionObserverInit) {
    if (!window.IntersectionObserver) {
        onIntersect(element);
        return NOOP;
    }

    const context: {intersectionObserver?: IntersectionObserver} = {};

    context.intersectionObserver = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting || entry.isIntersecting === undefined) {
            context.intersectionObserver!.unobserve(element);
            onIntersect(element);
        }
    }, options);

    if (!document.body.contains(element)) {
        setTimeout(() => {
            context.intersectionObserver!.observe(element);
        }, 0);
    } else {
        context.intersectionObserver.observe(element);
    }


    return () => context.intersectionObserver!.unobserve(element);
}
