import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';

import { UserStoreContext } from 'four-nets';

import query, { ViewerQuery, ViewerFragment } from './viewer.graphql';
import { User } from '../user';

export interface Context {
    viewer: ViewerFragment | null;
    updateViewer: () => Promise<User | null>;
    logout: () => Promise<any>;
}

export const ViewerContext = React.createContext<Context>({
    viewer: null,
    updateViewer: () => Promise.resolve(null),
    logout: () => Promise.resolve(null),
});
export const ViewerConsumer = ViewerContext.Consumer;

const ViewerProvider: React.FC = props => {
    const [getViewer, { data, loading }] = useLazyQuery<ViewerQuery>(query);

    const [viewer, setViewer] = useState<ViewerFragment | null>(null);
    const userStore = useContext(UserStoreContext);

    const updateViewer = () => {
        getViewer();
        if (userStore) {
            return userStore.loadMe();
        }
        return Promise.resolve(null);
    };

    const logout = () => {
        setViewer(null);
        if (userStore) {
            return userStore.logout();
        }
    };

    useEffect(() => {
        updateViewer();
    }, []);

    useEffect(() => {
        if (data && !loading) {
            setViewer(data.viewer);
            if (userStore) {
                userStore!.loadMe();
            }
        }
    }, [data]);

    return <ViewerContext.Provider value={{ viewer, updateViewer, logout }}>{props.children}</ViewerContext.Provider>;
};

export default ViewerProvider;
