import React from 'react';
import Styled from 'styled';
import classnames from 'classnames';

import Localizable from 'lib/intl/localizable';
import FadingCircleView from 'lib/loaders/spinkit/fading-circle-view';
import withInnerRef from 'lib/react/with-inner-ref';
import { Field } from 'lib/form/form';

import defaultStyles from './input.scss';

export interface Props {
    styles: typeof defaultStyles;
    autocompleteElement?: React.ReactNode;
    dynamicWidth?: boolean;
    elementsBeforeInput?: React.ReactNode;
    errors?: React.ReactNode[];
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: any) => void;
    value?: string;
    name?: string;
    id?: string;
    invalid?: boolean;
    loading?: boolean;
    minDynamicWidth?: number;
    placeholder?: string;
    touched?: boolean;
    type?: string;
    disabled?: boolean;
    intl?: any;

    field?: Field;
}

export interface State {
    focused: boolean;
}

@Localizable()
@withInnerRef()
class Input extends React.Component<Props, State> {
    static defaultProps = {
        type: 'text',
        value: '',
        minDynamicWidth: 30,
        elementsBeforeInput: null,
    };

    state = {
        focused: false,
    };

    ref?: HTMLInputElement;
    setRef = (ref: HTMLInputElement) => {
        this.ref = ref;
    };

    focus = () => {
        if (this.ref) {
            this.ref.focus();
        }
    };

    onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }

        this.setState({ focused: true });
    };

    onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }

        this.setState({ focused: false });
    };

    render() {
        const {
            id,
            loading,
            intl: { formatMessage },
            dynamicWidth,
            minDynamicWidth,
            type,
            autocompleteElement,
            elementsBeforeInput,
            disabled,
            styles,
            field,
        } = this.props;

        let placeholder,
            name,
            dirty,
            onChange: React.ChangeEventHandler<HTMLInputElement>,
            touched,
            valid,
            validationErrors,
            value,
            visited;

        if (field) {
            ({
                name,
                placeholder,
                data: { dirty, onChange, touched, valid, validationErrors, value, visited },
            } = field);
        } else {
            ({ onChange, value, touched, errors: validationErrors, name, placeholder } = {
                ...this.props,
                onChange: this.props.onChange!,
            });
        }

        const showValidationErrors = field ? (visited || dirty || touched) && !valid : Boolean(validationErrors);

        let style;
        if (dynamicWidth && minDynamicWidth && value) {
            style = {
                width: minDynamicWidth + value.length * 13,
            };
        }

        return (
            <div className={styles.wrapper}>
                <div
                    className={classnames(styles.textInput, {
                        [styles.focused]: this.state.focused,
                        [styles.invalid]: showValidationErrors,
                    })}
                    onClick={this.focus}
                >
                    {elementsBeforeInput}
                    <input
                        size={1}
                        id={id || name}
                        name={name}
                        type={type}
                        value={value}
                        style={style}
                        placeholder={placeholder}
                        autoCapitalize='none'
                        className={styles.input}
                        ref={this.setRef}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        onChange={onChange}
                        disabled={disabled}
                    />
                    {loading ? <FadingCircleView /> : null}
                </div>
                {showValidationErrors &&
                    validationErrors &&
                    validationErrors.map((error: any, index: number) => {
                        if (error?.intlMessage) {
                            return (
                                <p key={index.toString()} className={styles.error}>
                                    {formatMessage(error.intlMessage)}
                                </p>
                            );
                        }
                        return (
                            <p key={index.toString()} className={styles.error}>
                                {error}
                            </p>
                        );
                    })}
                <div className={styles.autocomplete}>{autocompleteElement}</div>
            </div>
        );
    }
}

export default Styled(defaultStyles)(Input);
