import React from 'react';
import classnames from 'classnames';
import { observable, computed, action, autorun } from 'mobx';
import { observer } from 'mobx-react';
import Styled from 'styled';
import observeOnce from 'lib/intersection-observer/observe-once';
import defaultStyles from './image-view.scss';

@Styled(defaultStyles)
@observer
class SVGImageView extends React.Component {
    static defaultProps = {
        preserveOnChange: false,
        intersectionOptions: {
            rootMargin: '360px',
        },
    };

    request: ?XMLHttpRequest;
    requestDate: Date;

    @observable.ref html;
    @observable cached = false;
    @observable visible = false;

    disconnect: ?Function;

    @action refDidAppear = () => {
        this.visible = true;
    };

    @action setRef = ref => {
        this.ref = ref;

        if (ref) {
            this.disconnect = observeOnce(ref, this.refDidAppear, this.props.intersectionOptions);
        }
    };

    componentDidMount() {
        this.disposer = autorun(() => {
            if (this.visible) {
                const { src } = this.props;

                if (!this.props.preserveOnChange) {
                    this.html = undefined;
                }

                this.abort();

                if (src) {
                    const request = new XMLHttpRequest();
                    request.open('GET', src);
                    request.addEventListener('load', this.requestDidLoad);
                    this.request = request;
                    this.requestDate = new Date();
                    request.send();
                }
            }
        });
    }

    @action requestDidLoad = event => {
        const request = event.target;

        this.cached = new Date(request.getResponseHeader('date')) <= this.requestDate;

        if (request.status >= 200 && request.status < 300) {
            this.html = { __html: request.responseText };
        }
    };

    abort() {
        if (this.request && this.request.readyState === 3) {
            // LOADING
            this.request.abort();
        }
    }

    componentWillUnmount() {
        if (this.disconnect) {
            this.disconnect();
        }

        if (this.disposer) {
            this.disposer();
        }
    }

    render() {
        const { styles } = this.props;

        let className = classnames(styles.image, {
            [styles.image__loaded]: this.html,
            [styles.image__cached]: this.cached,
        });

        if (this.props.className) {
            className += ` ${this.props.className}`;
        }

        return (
            <span
                ref={this.setRef}
                aria-label={this.props.alt}
                className={className}
                dangerouslySetInnerHTML={this.html}
            />
        );
    }
}

export default SVGImageView;
