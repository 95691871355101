/* @flow */

import { ApiStore } from 'lib/api/django-restframework/api-store';
import { ApiItem } from 'lib/api/api-item';
import JSONSerializable from 'lib/serialization/json-serializable';

export { Complaint };

@JSONSerializable()
class Complaint extends ApiItem {}

export { ComplaintStore };

@JSONSerializable()
class ComplaintStore extends ApiStore {
    constructor(options) {
        super(options);
        this.delegate = this;
    }

    item() {
        return new Complaint({
            delegate: this,
        });
    }

    create({ ...rest }: { comment?: string, appLabel: string, model: string, objectId: number }) {
        return this.api
            .post({ path: 'admin/complaints' })
            .send(rest)
            .json()
            .then(({ json }) => this.processJSON({ json }));
    }
}

export { Rule };

@JSONSerializable()
class Rule extends ApiItem {}

export class RuleStore extends ApiStore {
    constructor(options) {
        super(options);
        this.delegate = this;
    }

    item() {
        return new Rule({
            delegate: this,
        });
    }

    loadAll() {
        const request = this.api.get({
            path: 'admin/product-complaint-rules',
        });

        return super.loadAll({ request, paged: false });
    }
}
