/* @flow */

import 'spinkit/scss/spinners/10-fading-circle.scss';

import React from 'react';
import Styled from 'styled';
import range from 'lodash/range';
import defaultStyles from './fading-circle-view.scss';

export default Styled(defaultStyles)(function FadingCircleView(props) {
    const { styles } = props;

    return (
        <span className={`${styles.fadingCircle} sk-fading-circle`}>
            {range(1, 13).map(n => {
                return <span key={`${n}`} className={`${styles.circle} sk-circle sk-circle${n}`} />;
            })}
        </span>
    );
});
