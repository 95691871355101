/* @flow */


import { observable, action } from 'mobx';
import { Api, ApiError } from "lib/api/api";
import URLComponents from 'lib/url/url-components';
import { IntlMessage } from 'react-intl/intl-message';


export class FourNetsApiError extends ApiError {
    code: number;
    extra: ?Object;
    handled: Boolean;

    constructor(options: {
        message?: string,
        intlMessage?: IntlMessage,
        code: number,
        extra?: ?Object,
        underlayingError?: ?Error,
    }) {

        const { code, extra } = options || {};
        super(options);

        this.code = code;
        this.extra = extra;
        this.handled = false;
    }
}

export class FourNetsApi extends Api {

    @observable.ref lastError: {
        error: Error,
        response?: Response,
    } | null;

    constructor(options: {
        host: string,
        path?: string,
        port?: number,
        headers?: () => object,
        scheme?: string,
    }) {
        const { host, port, path = '/api/v1', scheme, headers = () => ({}) } = options;

        super({
            urlComponents: new URLComponents({
                host,
                port,
                path,
                scheme,
            }),
            headers,
        });
    }

    @action apiError(options) {
        const { response, error: underlayingError } = options;

        if (response && response.body && response.body.error) {
            const {
                body: {
                    error: {
                        code,
                        message,
                        extra,
                    },
                },
            } = response;

            const error = new FourNetsApiError({
                message,
                extra,
                code,
                underlayingError,
            });

            this.lastError = {
                response,
                error,
            };

            return error;
        }

        return super.apiError(options);
    }
}

export default FourNetsApi;
