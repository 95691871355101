import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CodeSplitView from 'lib/view/code-split-view';
import withCompute from 'lib/mobx/with-compute';
import compute from 'lib/mobx/compute';
import ClickAwayDetectableView, { isClickAway } from 'lib/ui/click-away-detectable-view';
import { URLBuilder } from 'url-builder/url-builder';
import computed from 'lib/mobx/computed';
import Styled from 'styled';
import { Link } from 'lib/ui/link';

import LoadingWrapperView from 'four-nets/ui/loaders/loading-wrapper-view';
import SVGIcon from 'lib/images/svg-pure-icon/svg-pure-icon';

import { Tab } from 'four-nets/user/auth/login-dialog';
import { AuthOrigin } from 'four-nets/user/auth/auth-ga-events';
import userIcon from 'four-nets/icons/svg/icon_user-full.svg';
import userTransparent from 'four-nets/icons/svg/icon_user.svg';
import notification from 'four-nets/icons/svg/icon_notifications.svg';
import notificationTransparent from 'four-nets/icons/svg/icon_notifications-full.svg';
import menu from 'four-nets/icons/svg/icon_menu.svg';
import envelopeTransparent from 'four-nets/icons/svg/icon_message-2.svg';
import envelope from 'four-nets/icons/svg/icon_message-3.svg';
import { UserStore } from 'four-nets/user/user';

import UserPanelButtonView, { UserPanelButtonViewModel } from './user-panel-button-view';
import NavigationMenuView, { NavigationMenuViewModel } from './menus/navigation-menu/navigation-menu-view';

import defaultStyles from './user-panel-view.scss';

type MenuViewModel = AccountMenuViewModel | NotificationMenuViewModel | NavigationMenuViewModel;

@withCompute()
class UserPanelItem {
    mobile: boolean;
    userPanelButtonViewModel: UserPanelButtonViewModel;
    loadViewModel: MenuViewModel;
    preserve: boolean;

    @compute shouldLoad;

    constructor(options: {
        userPanelButtonViewModel: UserPanelButtonViewModel,
        loadViewModel: () => Promise<MenuViewModel>,
        mobile?: boolean,
        shouldLoad: () => boolean,
    }) {
        const { userPanelButtonViewModel, loadViewModel, mobile = false, preserve = false } = options;

        this.userPanelButtonViewModel = userPanelButtonViewModel;
        this.loadViewModel = loadViewModel;
        this.mobile = mobile;
        this.preserve = preserve;
    }
}

export class UserPanelViewModel {
    items: { [key: string]: UserPanelItem };
    userStore: UserStore;
    user: User;

    constructor(options: { userStore: UserStore, links: Link[], urlBuilder: URLBuilder, intl: any }) {
        const { userStore, links, site, urlBuilder, intl } = options;

        this.userStore = userStore;

        this.items = {
            accountMenu: new UserPanelItem({
                userPanelButtonViewModel: new UserPanelButtonViewModel({
                    closedIcon: userTransparent,
                    openedIcon: userIcon,
                    leftBorder: true,
                    badge: () => userStore.newBazaarMessagesCount,
                }),
                loadViewModel: AccountMenuViewModel =>
                    new AccountMenuViewModel({
                        userStore,
                        urlBuilder,
                        site,
                        intl,
                    }),
                shouldLoad: () => this.user != null,
                preserve: true,
            }),
            mailMenu: new UserPanelItem({
                userPanelButtonViewModel: new UserPanelButtonViewModel({
                    closedIcon: envelopeTransparent,
                    openedIcon: envelope,
                    badge: () => userStore.newMailsCount,
                }),
                loadViewModel: () => null,
                shouldLoad: () => this.user != null,
            }),
            notificationMenu: new UserPanelItem({
                userPanelButtonViewModel: new UserPanelButtonViewModel({
                    closedIcon: notification,
                    openedIcon: notificationTransparent,
                    menuLeftShift: 74,
                    badge: () => userStore.newNotificationsCount,
                }),
                loadViewModel: NotificationMenuViewModel =>
                    new NotificationMenuViewModel({
                        notificationsManager: () => userStore.notificationsManager,
                    }),
                shouldLoad: () => this.user != null,
            }),
            navigationMenu: new UserPanelItem({
                userPanelButtonViewModel: new UserPanelButtonViewModel({
                    closedIcon: menu,
                    openedIcon: menu,
                    rightBorder: false,
                    menuLeftShift: 74,
                }),
                loadViewModel: () =>
                    new NavigationMenuViewModel({
                        links,
                    }),
                shouldLoad: () => true,
                mobile: true,
            }),
        };
    }

    @computed get user() {
        return this.userStore.me;
    }

    toggle = (options: { userPanelButtonViewModel: UserPanelButtonViewModel }) => {
        const { userPanelButtonViewModel } = options;

        Object.values(this.items).forEach(item => {
            if (item.userPanelButtonViewModel === userPanelButtonViewModel) {
                item.userPanelButtonViewModel.toggle();
                return;
            }

            item.userPanelButtonViewModel.close();
        });
    };

    close = () => {
        Object.values(this.items).forEach(item => {
            item.userPanelButtonViewModel.close();
        });
    };
}

@ClickAwayDetectableView()
@observer
class MenuView extends React.Component {
    static childContextTypes = {
        menu: PropTypes.instanceOf(React.Component),
    };

    state = {
        element: null,
    };

    getChildContext() {
        return {
            menu: this,
        };
    }

    @observable ref;

    @computed get disabled() {
        return this.props.disabled;
    }

    get onClickAway() {
        return !this.props.disabled && this.props.onClickAway;
    }

    componentDidMount() {
        const { load, shouldLoad, id, loadViewModel, styles, ...rest } = this.props;

        if (shouldLoad && id) {
            switch (id) {
                case 'accountMenu':
                    import('./menus/account-menu-view')
                        .then(({ default: AccountMenuView, AccountMenuViewModel }) => {
                            this.setState({
                                element: (
                                    <AccountMenuView
                                        accountMenuViewModel={loadViewModel(AccountMenuViewModel)}
                                        {...rest}
                                    />
                                ),
                            });
                        })
                        .catch(err => console.log(err));
                    break;

                case 'mailMenu':
                    import('./menus/mail-menu-view')
                        .then(({ default: MailMenuView }) => {
                            this.setState({
                                element: <MailMenuView {...rest} />,
                            });
                        })
                        .catch(err => console.log(err));
                    break;
                case 'notificationMenu':
                    import('./menus/notification-menu-view')
                        .then(({ default: NotificationMenuView, NotificationMenuViewModel }) => {
                            this.setState({
                                element: (
                                    <NotificationMenuView
                                        notificationMenuViewModel={loadViewModel(NotificationMenuViewModel)}
                                        {...rest}
                                    />
                                ),
                            });
                        })
                        .catch(err => console.log(err));
                    break;

                case 'navigationMenu':
                    this.setState({
                        element: (
                            <NavigationMenuView
                                navigationMenuViewModel={loadViewModel()}
                                closeMenu={this.props.closeMenu}
                                {...rest}
                            />
                        ),
                    });

                    break;
                default:
                // pass
            }
        }
    }

    render() {
        const { shouldLoad, styles } = this.props;

        return (
            <div className={styles.menu}>
                {this.state.element}
                <LoadingWrapperView />
            </div>
        );
    }
}

@Styled(defaultStyles)
@observer
class UserPanelView extends React.Component {
    @observable loading;
    ref: ?HTMLElement;

    static contextTypes = {
        showLogin: PropTypes.func,
    };

    setRef = (ref: ?HTMLElement) => {
        this.ref = ref;
    };

    onClickAway = (options: { event: MouseEvent }) => {
        const { ref } = this;
        const { event } = options;

        if (ref && isClickAway({ ref, event })) {
            this.props.userPanelViewModel.close();
        }
    };

    onClick = (event: MouseEvent, userPanelButtonViewModel: UserPanelButtonViewModel) => {
        this.props.userPanelViewModel.toggle({ userPanelButtonViewModel });
    };

    componentDidMount() {
        this.disposer = this.props.userPanelViewModel.userStore.refresh();
    }

    componentWillUnmount() {
        this.disposer();
    }

    render() {
        const { userPanelViewModel, styles } = this.props;
        const { user } = userPanelViewModel;

        const classNames = [styles.userPanel];
        if (user) {
            classNames.push(styles.hasUser);
        }

        return (
            <div ref={this.setRef} className={classNames.join(' ')}>
                {user === null ? (
                    // XXX: this should be separate component
                    <div className={styles.noUser}>
                        <button
                            onClick={() => this.context.showLogin(() => Promise.resolve, Tab.Login, AuthOrigin.Header)}
                            className={styles.loginButton}
                        >
                            <span className={styles.centerer}>
                                <SVGIcon src={userTransparent} className={styles.icon} />
                                <SVGIcon src={userIcon} className={styles.iconHover} />
                                <LoadingWrapperView loading={this.loading} minHeight={1}>
                                    <FormattedMessage id='LOGIN'>
                                        {msgLogin => (
                                            <FormattedMessage id='REGISTERED'>
                                                {msgRegistered => (
                                                    <span className={styles.text}>
                                                        {`${msgLogin} / ${msgRegistered}`}
                                                    </span>
                                                )}
                                            </FormattedMessage>
                                        )}
                                    </FormattedMessage>
                                </LoadingWrapperView>
                            </span>
                        </button>
                    </div>
                ) : null}
                {Object.entries(userPanelViewModel.items).map(
                    ([key, { userPanelButtonViewModel, loadViewModel, mobile, shouldLoad, preserve }], index) => {
                        if (!shouldLoad) {
                            return null;
                        }

                        const child = (
                            <UserPanelButtonView
                                key={key}
                                index={index}
                                userPanelButtonViewModel={userPanelButtonViewModel}
                                onClick={this.onClick}
                                preserve={preserve}
                            >
                                <MenuView
                                    disabled={!userPanelButtonViewModel.menuIsShown}
                                    onClickAway={this.onClickAway}
                                    shouldLoad={shouldLoad}
                                    onClick={userPanelViewModel.close}
                                    loadViewModel={loadViewModel}
                                    closeMenu={this.props.userPanelViewModel.close}
                                    id={key}
                                    styles={styles}
                                />
                            </UserPanelButtonView>
                        );

                        return (
                            <div key={key} className={`${mobile ? styles.mobile : ''}`}>
                                {child}
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
}

export default UserPanelView;
