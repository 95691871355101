import { observable } from 'mobx';

import JSONSerializable from 'lib/serialization/json-serializable';
import { ApiItem } from 'lib/api/api-item';
import { ApiStore } from 'lib/api/django-restframework/api-store';

import { decamelize } from 'humps';

export { Country };

@JSONSerializable()
class Country extends ApiItem {}

export { County };

@JSONSerializable()
class County extends ApiItem {
    constructor(options) {
        super(options);

        const { countryStore } = options;
        this.countryStore = countryStore;
    }

    fromJSON({ json }) {
        const { country, ...rest } = json;

        this.country = this.countryStore.processJSON({ json: country });
        super.fromJSON({ json: rest });
    }
}

export class CountyStore extends ApiStore {
    constructor(options) {
        super(options);

        const { countryStore } = options;
        this.countryStore = countryStore;
        this.delegate = this;
    }

    item() {
        return new County({
            delegate: this,
            countryStore: this.countryStore,
        });
    }

    loadAll() {
        const request = this.api.get({
            path: `counties`,
        });

        return super.loadAll({
            request,
        });
    }

    loadCounties({ country }) {
        const request = this.api.get({
            path: `countries/${country.id}/get_counties`,
        });

        return super.loadAll({
            request,
            paged: false,
        });
    }

    loadSlovakCounties = () => {
        return this.loadCounties({ country: { id: 1 } });
    };

    loadCzechCounties = () => {
        return this.loadCounties({ country: { id: 2 } });
    };
}

export class CountryStore extends ApiStore {
    constructor(options) {
        super(options);

        this.delegate = this;
    }

    item() {
        return new Country({
            delegate: this,
        });
    }

    loadAll() {
        const request = this.api.get({
            path: `countries`,
        });

        return super.loadAll({
            request,
            paged: false,
        });
    }

    load({ id }) {
        const request = this.api.get({
            path: `countries/${id}`,
        });

        return super.load({ id, request });
    }
}
