import React, { useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Formik, Form, Field, FieldProps } from 'formik';
import Styled from 'styled';
import { Dialogs } from 'four-nets/ui-v2/modal/modal';

import { SiteContext } from 'four-nets';

import gaTrack from 'four-nets/lib/google-analytics/track';
import SubmitButtonView from 'four-nets/ui/submit-button/submit-button-view';
import FieldWithLabelView from 'four-nets/ui/form/field-with-label-view';
import Input from 'four-nets/ui-v2/form/input/input';
import Checkbox from 'four-nets/ui-v2/form/checkbox/checkbox';
import Select from 'four-nets/ui-v2/select/select';
import { mergeValidators, emptyValidator, trueValidator, emailValidator } from 'four-nets/ui-v2/form/validators';
import countiesQuery, {
    CountiesQuery,
    CountiesQueryVariables,
} from 'four-nets/vendor/homepage/register-form/counties.graphql';

import { AuthOrigin } from './auth-ga-events';
import registerMutation, { RegisterMutation, RegisterMutationVariables } from './register.graphql';
import { RegistrationStatus } from './register.graphql.types';
import defaultStyles from './register-form.scss';

interface Props {
    styles: typeof defaultStyles;
    dialogs: Dialogs;
    origin: AuthOrigin;
    onClose: () => {};
    onSuccess?: () => {};
    setSubmitting?: (isSubmitting: boolean) => {};
}

enum Gender {
    Female = 1,
    Male,
}

export interface Values {
    username: string;
    password: string;
    gender: Gender;
    email: string;
    county: string;
    personaldata: boolean;
    newsletter: boolean;
}

type Errors = Partial<{ [key in keyof Values]: React.ReactElement<FormattedMessage>[] | true }>;

export const gaEventCategory = 'Registration Form';

const Register: React.FC<Props> = React.memo(props => {
    const { styles } = props;
    const site = useContext(SiteContext);

    const { data: counties, loading } = useQuery<CountiesQuery, CountiesQueryVariables>(countiesQuery, {
        variables: { countryName: site && site.isSlovak ? 'SLOVAKIA' : 'CZECH_REPUBLIC' },
    });

    const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(registerMutation);

    useEffect(() => {
        if (props.setSubmitting && !(counties && loading)) {
            props.setSubmitting(loading);
        }
    }, [loading, props, counties]);

    return (
        <Formik<Values>
            initialValues={{
                username: '',
                password: '',
                gender: Gender.Male,
                email: '',
                personaldata: false,
                newsletter: false,
                county: '',
            }}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                if (props.setSubmitting) {
                    props.setSubmitting(true);
                }
                register({
                    variables: values,
                })
                    .then(
                        ({
                            data: {
                                registerMutation: { status },
                            },
                        }) => {
                            if (status === RegistrationStatus.SUCCESS) {
                                gaTrack('event', gaEventCategory, 'submit registration form', props.origin);
                                props.dialogs.acknowledge({
                                    title: <FormattedMessage id='ONE_MORE_STEP_TO_COMPLETE_THE_REGISTRATION' />,
                                    body: (
                                        <FormattedHTMLMessage
                                            id='CLICK_THE_ACTIVATION_LINK'
                                            values={{
                                                email: values.email,
                                            }}
                                        />
                                    ),
                                    onAccept: () => {
                                        props.onClose && props.onClose();
                                    },
                                });
                            }
                            if (status === RegistrationStatus.USER_EXISTS) {
                                setFieldError('username', [<FormattedMessage id='API_USER_EXIST' />]);
                            }
                            if (status === RegistrationStatus.WRONG_USERNAME) {
                                setFieldError('username', [<FormattedMessage id='API_WRONG_USERNAME' />]);
                            }
                            if (status === RegistrationStatus.EMAIL_EXISTS) {
                                setFieldError('email', [<FormattedMessage id='API_EMAIL_EXISTS' />]);
                            }
                        }
                    )
                    .catch(() => {
                        props.dialogs.fail({
                            body: <FormattedMessage id='ERROR_500' />,
                        });
                    })
                    .finally(() => {
                        setSubmitting(false);
                        if (props.setSubmitting) {
                            props.setSubmitting(false);
                        }
                    });
            }}
            validate={values => {
                const errors: Errors = {};
                const usernameError = mergeValidators([emptyValidator])(values.username);
                if (usernameError) {
                    errors.username = usernameError;
                }
                const passwordError = mergeValidators([emptyValidator])(values.password);
                if (passwordError) {
                    errors.password = passwordError;
                }
                const emailError = mergeValidators([emptyValidator, emailValidator])(values.email);
                if (emailError) {
                    errors.email = emailError;
                }
                const personaldataError = mergeValidators([trueValidator])(values.personaldata);
                if (personaldataError) {
                    errors.personaldata = personaldataError;
                }
                return errors;
            }}
        >
            {formik => {
                const { touched, errors, isSubmitting, values } = formik;
                return (
                    <Form className={styles.form}>
                        <FieldWithLabelView
                            style={{ label: styles.label, field: styles.field }}
                            label={<FormattedMessage id='USERNAME' />}
                            field={
                                <Field name='username'>
                                    {({ field }: FieldProps) => (
                                        <Input errors={errors.username} touched={touched.username} {...field} />
                                    )}
                                </Field>
                            }
                        />
                        <FieldWithLabelView
                            style={{ label: styles.label, field: styles.field }}
                            label={<FormattedMessage id='PASSWORD' />}
                            field={
                                <Field name='password'>
                                    {({ field }: FieldProps) => (
                                        <Input
                                            type='password'
                                            errors={errors.password}
                                            touched={touched.password}
                                            {...field}
                                        />
                                    )}
                                </Field>
                            }
                        />
                        <FieldWithLabelView
                            style={{ label: styles.label, field: styles.field }}
                            label={<FormattedMessage id='EMAIL' />}
                            field={
                                <Field name='email' type='email'>
                                    {({ field }: FieldProps) => (
                                        <Input errors={errors.email} touched={touched.email} {...field} />
                                    )}
                                </Field>
                            }
                        />
                        <FieldWithLabelView
                            style={{ label: styles.label, field: styles.field }}
                            label={<FormattedMessage id='GENDER' />}
                            field={
                                <Field name='gender'>
                                    {({ field }: FieldProps) => (
                                        <Select
                                            style={{ wrapper: styles.wrapper, select: styles.select }}
                                            errors={errors.gender}
                                            touched={touched.gender}
                                            value={values.county}
                                            {...field}
                                        >
                                            <FormattedMessage id='FEMALE'>
                                                {gender => (
                                                    <option key={Gender.Female} value={Gender.Female}>
                                                        {gender}
                                                    </option>
                                                )}
                                            </FormattedMessage>
                                            <FormattedMessage id='MALE'>
                                                {gender => (
                                                    <option key={Gender.Male} value={Gender.Male}>
                                                        {gender}
                                                    </option>
                                                )}
                                            </FormattedMessage>
                                        </Select>
                                    )}
                                </Field>
                            }
                        />
                        <FieldWithLabelView
                            style={{ label: styles.label, field: styles.field }}
                            label={<FormattedMessage id='RESIDENCE' />}
                            field={
                                <Field name='county'>
                                    {({ field }: FieldProps) => (
                                        <Select
                                            style={{ wrapper: styles.wrapper, select: styles.select }}
                                            errors={errors.county}
                                            touched={touched.county}
                                            value={values.county}
                                            {...field}
                                        >
                                            <option value='' key='default'>
                                                ---
                                            </option>
                                            {counties &&
                                                counties.counties &&
                                                counties.counties.edges &&
                                                counties.counties.edges.map(e => (
                                                    <option value={e!.node!.id} key={e!.node!.id}>
                                                        {e!.node!.name}
                                                    </option>
                                                ))}
                                        </Select>
                                    )}
                                </Field>
                            }
                        />
                        <div className={styles.checkboxes}>
                            <Field name='personaldata'>
                                {({ field }: FieldProps) => (
                                    <Checkbox
                                        errors={errors.personaldata}
                                        label={
                                            <FormattedHTMLMessage
                                                id='I_AGREE_WITH_RULES_AND_GDPR'
                                                values={{ className: styles.link }}
                                            />
                                        }
                                        touched={touched.personaldata}
                                        {...field}
                                    />
                                )}
                            </Field>
                            <Field name='newsletter'>
                                {({ field }: FieldProps) => (
                                    <FormattedMessage id='I_SUBSCRIBE_TO_NEWSLETTER'>
                                        {label => <Checkbox label={label} touched={touched.personaldata} {...field} />}
                                    </FormattedMessage>
                                )}
                            </Field>
                        </div>
                        <SubmitButtonView type='submit' disabled={isSubmitting}>
                            <FormattedMessage id='SIGNUP_SUBMIT' />
                        </SubmitButtonView>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default Styled(defaultStyles)(Register);
