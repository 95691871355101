import React from 'react';
import FadingCircleView from 'lib/loaders/spinkit/fading-circle-view';
import Styled from 'styled';

import defaultStyles, { ISpinnerScss } from './spinner.scss';

interface Props {
    mini?: boolean;
    styles?: ISpinnerScss;
}

class Spinner extends React.PureComponent<Props> {
    render() {
        const { mini } = this.props;
        const styles = this.props.styles!;

        const classNames = [styles.spinner];

        if (mini) {
            classNames.push(styles.mini);
        }

        return (
            <span className={classNames.join(' ')}>
                <FadingCircleView styles={styles} />
            </span>
        );
    }
}

export default Styled(defaultStyles)(Spinner);
