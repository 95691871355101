import React, { ReactNode, useState, useEffect, useContext, createContext } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { FormattedMessage } from 'react-intl';
import cookies from 'js-cookie';
import Styled from 'styled';

import { ViewerContext } from 'four-nets/user/viewer/viewer';
import Spinner from 'four-nets/ui/spinner/spinner';
import Modal from 'four-nets/ui-v2/modal/modal';
import useCardPay from 'four-nets/vendor/profile/payment/use-card-pay';
import subscriptionsQuery, { SubscriptionsQuery } from 'four-nets/vendor/profile/payments/subscriptions.graphql';

import defaultStyles from './vendor-card-change-notice.scss';

const vendorCpNoticeCookie = 'cp_notice';

interface Props {
    styles: typeof defaultStyles;
    children: ReactNode | ReactNode[];
}

export const VendorCardChangeNoticeContext = createContext(() => { });

const VendorCardChangeNoticeProvider: React.FC<Props> = props => {
    const { styles, children } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [sndPart, setSndPart] = useState<boolean>(false);

    const { viewer } = useContext(ViewerContext);

    const [loadSubscriptions, { data, loading }] = useLazyQuery<SubscriptionsQuery>(subscriptionsQuery);

    const payWithCard = useCardPay();

    const show = () => {
        setOpen(true);
    }

    const close = () => {
        cookies.set(vendorCpNoticeCookie, '1', { expires: 1 });
        setOpen(false);
    };

    const handleAccept = () => {
        if (sndPart) {
            cookies.set(vendorCpNoticeCookie, '1', { expires: 1 });
            payWithCard(1);
        } else {
            loadSubscriptions();
            setSndPart(true);
        }
    };

    useEffect(() => {
        if (viewer?.profile?.vendor?.isRecurringWc && !cookies.get(vendorCpNoticeCookie)) {
            setOpen(true);
        }
    }, [viewer]);

    const afterPaidTillDate = (): boolean => (
        viewer?.profile?.vendor?.paidTillDate && new Date(viewer?.profile?.vendor?.paidTillDate) < new Date()
    );

    const getTitle = () => (
        sndPart ? <FormattedMessage id='CHECK_PAYMENT_OPTIONS' /> : (afterPaidTillDate() ? <FormattedMessage id='PAYMENT_FAILED_NOTICE' /> : <FormattedMessage id='IMPORTANT_NOTICE' />)
    );

    const getButtonText = () => (
       sndPart ? (afterPaidTillDate() ? <FormattedMessage id='VENDOR_PAY_WITH_CREDIT_CARD' /> : <FormattedMessage id='ADD_CARD' />) : (afterPaidTillDate() ? <FormattedMessage id='RENEW_SUBSCRIPTION' /> : <FormattedMessage id='CONTINUE' />)
    );

    const getFstPartBody = () => afterPaidTillDate() ? (
        <div>
            <p>
                <FormattedMessage id='3D_SECURE_PAYMENT_FAILED_NOTICE' />
            </p>
        </div>
    ) : (
        <div>
            <p>
                <FormattedMessage id='3D_SECURE_NOTICE_PART1' />
            </p>
            <p>
                <FormattedMessage id='3D_SECURE_NOTICE_PART2' />
            </p>
            <p className={styles.footNote}>
                <FormattedMessage id='3D_SECURE_NOTICE_FOOTNOTE' />
            </p>
        </div>
        );

    const getSndPartBody = () => {
        if (loading) {
            return (
                <div className={styles.spinnerWrapper}>
                    <Spinner />
                </div>
            );
        }
        if (data) {
            const oneMonthPrice = data?.subscriptions?.filter(s => s?.period === 1)[0];

            if (!oneMonthPrice) {
                return <div>Chyba</div>;
            }

            return (
                <div>
                    <div className={styles.period}>
                        <FormattedMessage id='ONE_MONTH_EVERY_MONTH' />
                    </div>
                    <div className={styles.price}>
                        <div>
                            <FormattedMessage id='INVOICE_AMOUNT_WITH_VAT' />
                        </div>
                        <FormattedMessage id='FORM_LABEL_MONTH'>
                            {message => (
                                <div>{`${oneMonthPrice.value} ${
                                    oneMonthPrice.currency
                                    } / ${message.toLowerCase()}`}</div>
                            )}
                        </FormattedMessage>
                    </div>
                    <p className={styles.footNote}>
                        *{afterPaidTillDate() ? <FormattedMessage id='CARD_UPDATE_NOTICE_UNPAID_VENDOR' /> : <FormattedMessage id='CARD_UPDATE_NOTICE' />}
                    </p>
                </div>
            );
        }

        return <div>Chyba</div>;
    };

    return (
        <VendorCardChangeNoticeContext.Provider value={show}>
            <Modal
                title={getTitle()}
                open={open}
                onClose={close}
                style={{ modal: styles.modal }}
                onAccept={handleAccept}
                onCancel={close}
                cancelButtonLabel={<FormattedMessage id='REMIND_LATER' />}
                acceptButtonLabel={getButtonText()}
                acceptButtonType='action'
            >
                {sndPart ? getSndPartBody() : getFstPartBody()}
            </Modal>
            {children}
        </VendorCardChangeNoticeContext.Provider>
    );
};

export default Styled(defaultStyles)(VendorCardChangeNoticeProvider);
