import {action, observable, computed} from 'mobx';
import parser from 'react-url/build/parser';


export type Urls = {
    [key: string]: string,
};

export class URLBuilder {
    @observable urls = observable.shallowMap();

    @action updateUrls(urls: Urls) {
        this.urls.merge(urls);
    }

    @computed get getUrl(): (options: {url: string, value?: Object}) => string {
        return ({url, value}) => {

            const urls = this.urls.toJS();

            if (!urls[url]) {
                throw new Error(`Url '${url}' not found in url builder database`);
            }

            return parser(urls, url, value);
        };
    }

    fromJSON({urls}) {
        this.urls.merge(urls);
    }

    toJSON() {
        return {
            urls: this.urls.toJS(),
        };
    }
}

URLBuilder.defaultURLBuilder = new URLBuilder();
