interface User {
    vendor?: { name?: string | null };
    username: string;
    socialName: string | null;
}

export const displayedName = (user: User) => {
    if (user.vendor && user.vendor.name) {
        return user.vendor.name;
    }
    return user.socialName || user.username;
};
