import React from 'react';
import decorator from 'lib/lang/decorator';

interface Decorator extends React.Component {
    onClickAway: (event: Event) => void;
}

export function isClickAway(options: { event: MouseEvent, ref: HTMLElement }) {
    const { event, ref } = options;
    return !ref.contains(event.target);
}

export default decorator((style = {}) => (Component: typeof Decorated) => {
    class ClickAwayDetectableView extends React.PureComponent {
        ref;
        componentRef;

        setRef = ref => {
            if (ref && this.ref !== ref) {
                this.ref = ref;
            }
        };

        setComponentRef = ref => {
            if (ref && this.componentRef !== ref) {
                this.componentRef = ref;
            }
        };

        onClick = event => {
            const { componentRef, ref } = this;
            if (componentRef && ref && componentRef.onClickAway && isClickAway({ ref, event })) {
                componentRef.onClickAway({ event, ref });
            }
        };

        componentDidMount() {
            window.addEventListener('click', this.onClick);
        }

        componentWillUnmount() {
            window.removeEventListener('click', this.onClick);

            this.ref = null;
            this.componentRef = null;
        }

        render() {
            return (
                <div style={style} ref={this.setRef}>
                    <Component ref={this.setComponentRef} {...this.props} />
                </div>
            );
        }
    }

    return ClickAwayDetectableView;
});
