import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'helmet/helmet';
import { FormattedMessage } from 'react-intl';
import cookies from 'js-cookie';

import Styled from 'styled';

import { AuthOrigin } from 'four-nets/user/auth/auth-ga-events';
import ComposeMailDialog from 'four-nets/mails/compose-mail/compose-mail-dialog';
import gaTrack from 'four-nets/lib/google-analytics/track';
import { Site } from 'four-nets/site';

import { FooterChild } from '../child';
import defaultStyles from './action.scss';

export enum Type {
    MAIL_ADMIN = 'MAIL_ADMIN',
    CHANGE_VIEWPORT = 'CHANGE_VIEWPORT',
}

export interface FooterActionChild extends FooterChild {
    label: string;
    action: string;
}

export interface Props extends FooterActionChild {
    styles?: typeof defaultStyles;
    onActionClick?: Function;
}

interface Context {
    site: Site;
    authCheck: (action: () => Promise<any>, origin: AuthOrigin) => Promise<any>;
}

interface State {
    action: React.ReactNode;
    open: boolean;
    label: string | null;
    data: object | null;
}

const COOKIE_NAME = 'DesktopVersion';

class Action extends React.PureComponent<Props, State> {
    static contextTypes = {
        site: PropTypes.instanceOf(Site),
        authCheck: PropTypes.func,
    };

    state = {
        action: null,
        open: false,
        label: null,
        data: null,
    };

    context!: Context;

    mailAdmin = () => {
        this.context.authCheck(() => {
            this.setState({ open: true });
            return Promise.resolve();
        }, AuthOrigin.NewMail);
    };

    onClose = () => {
        this.setState({ open: false });
    };

    changeViewport = (desktopVersion: Boolean) => {
        this.setState((state, props) => {
            cookies.set(COOKIE_NAME, desktopVersion.toString(), { expires: 1 });
            if (this.props.onClick) {
                this.props.onClick(!desktopVersion);
            }
            return {
                action: (
                    <Helmet
                        render={(H: React.ComponentClass) => (
                            <H>
                                <meta
                                    name='viewport'
                                    content={
                                        desktopVersion
                                            ? 'width=1470, initial-scale=0'
                                            : 'width=screen-width, initial-scale=1'
                                    }
                                />
                            </H>
                        )}
                    />
                ),
                label: desktopVersion ? <FormattedMessage id='SWITCH_TO_MOBILE_VERSION' /> : props.label,
                data: {
                    desktopVersion,
                },
            };
        });
    };

    componentDidMount() {
        const { action } = this.props;
        switch (action) {
            case Type.CHANGE_VIEWPORT: {
                const desktopVersion = cookies.get(COOKIE_NAME);
                this.changeViewport(desktopVersion === 'true');
            }
        }
    }

    render() {
        const { action, label, styles } = this.props;
        const containerClasses = [styles!.container];

        let onClick;

        switch (action) {
            case Type.MAIL_ADMIN:
                onClick = this.mailAdmin;
                break;
            case Type.CHANGE_VIEWPORT: {
                onClick = () => {
                    const changeToDesktopVersion = this.state.data === null ? true : !this.state.data.desktopVersion;
                    gaTrack('event', 'desktopVersion', changeToDesktopVersion, 'Desktop version setting on mobile');
                    this.changeViewport(changeToDesktopVersion);
                };
                if (!(this.state.data && this.state.data.desktopVersion)) {
                    containerClasses.push(styles!.mobileOnly);
                }
                break;
            }
        }

        return (
            <span className={containerClasses.join(' ')}>
                <ComposeMailDialog
                    open={this.state.open}
                    onClose={this.onClose}
                    to={[this.context.site.admin]}
                    subject={this.props.label}
                />
                <button className={styles!.action} onClick={onClick}>
                    {this.state.label || label}
                </button>
                {this.state.action}
            </span>
        );
    }
}

export default Styled(defaultStyles)(Action);
