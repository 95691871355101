import React from 'react';
import ImageView from './image-view';

class ResponsiveImageView extends React.PureComponent {
    render() {
        const { image, noPlaceholder, linkify, ...rest } = this.props;
        const { placeholder, srcSet, srcset, src, alt, cropperDescriptor } = image || {};

        return (
            <ImageView
                linkify={linkify}
                placeholder={!noPlaceholder && placeholder}
                srcSet={srcSet || srcset}
                src={src}
                cropperDescriptor={cropperDescriptor}
                alt={alt}
                {...rest}
            />
        );
    }
}

export default ResponsiveImageView;
