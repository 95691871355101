import React, { useState } from 'react';

import { FooterButtonType } from 'four-nets/ui-v2/modal/modal';
import { Alert, AlertProps } from 'four-nets/ui-v2/modal/alert';

const defaultFn = () => {
    /* pass */
};

type AlertOptions = Options;

export const AlertContext = React.createContext<{
    acknowledge: (oprions: AlertOptions) => void;
    fail: (oprions: AlertOptions) => void;
    retry: (oprions: AlertOptions) => void;
    confirm: (oprions: AlertOptions) => void;
}>({
    acknowledge: defaultFn as any,
    fail: defaultFn as any,
    retry: defaultFn as any,
    confirm: defaultFn as any,
});

interface Options {
    open?: boolean;
    title?: React.ReactNode;
    body?: React.ReactNode;

    onClose?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, from?: string) => any;

    onCancel?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, from?: string) => any;
    cancelButtonLabel?: React.ReactNode;

    onAccept?: (event?: React.MouseEvent) => any;
    acceptButtonType?: FooterButtonType;
    acceptButtonLabel?: React.ReactNode;
}

export const AlertContextProvider: React.FC = ({ children }) => {
    const [state, setState] = useState<Options>({});

    const onClose = (event: React.MouseEvent | React.KeyboardEvent, from?: string) => {
        if (state.onClose) {
            state.onClose(event, from);
        }
        setState(prevState => ({ ...prevState, open: false }));
    };

    const acknowledge = (options: Options) => {
        setState(prevState => {
            return {
                ...prevState,
                ...options,
                open: true,
                onCancel: undefined,
            };
        });
    };

    const retry = (options: Options) => {
        setState(prevState => {
            return {
                ...prevState,
                ...options,
                open: true,
                onCancel: options.onClose,
            };
        });
    };

    const fail = (options: Options) => {
        setState(prevState => {
            return {
                ...prevState,
                ...options,
                open: true,
                onCancel: undefined,
            };
        });
    };

    const confirm = (options: Options) => {
        setState(prevState => {
            return {
                ...prevState,
                ...options,
                open: true,
                onCancel: options.onClose || onClose,
            };
        });
    };

    return (
        <AlertContext.Provider value={{ acknowledge, retry, fail, confirm }}>
            {children}
            <Alert {...state} onClose={onClose} />
        </AlertContext.Provider>
    );
};

AlertContext.displayName = 'AlertContext';

export default AlertContext.Consumer;
