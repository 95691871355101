import React from 'react';
import * as PropTypes from 'prop-types';
import { action, observable } from 'mobx';

export interface Props {
    completionTimeout?: number;
}

export interface LoadingProgressParent {
    increment: (reference: object, block?: boolean) => void;
    decrement: (reference: object) => void;
}

export const LoadingProgressParentShape = PropTypes.shape({
    increment: PropTypes.func,
    decrement: PropTypes.func,
});

class LoadingProgressProvider extends React.PureComponent<Props> implements LoadingProgressParent {
    static defaultProps = {
        completionTimeout: 850,
    };

    static childContextTypes = {
        loadingProgressProvider: PropTypes.object,
        loadingProgressParent: LoadingProgressParentShape,
    };

    count = 0;
    previousDecrementCount = 0;

    @observable progress = 0;

    map = new WeakMap();
    timer?: NodeJS.Timer;

    getChildContext() {
        return {
            loadingProgressProvider: this,
            loadingProgressParent: this,
        };
    }

    @action setProgress(progress: number) {
        this.progress = progress;
    }

    calculateProgress(newCount: number) {
        if (this.timer) {
            clearTimeout(this.timer);
            delete this.timer;
        }

        if (this.count === 0 && newCount === 1) {
            this.previousDecrementCount = 0;
            this.setProgress(50);
        } else if (newCount === 0 && this.count === 1) {
            this.setProgress(100);

            this.timer = setTimeout(() => {
                window.dispatchEvent(new CustomEvent('loadingCompleted'));
                this.setProgress(0);
            }, this.props.completionTimeout!);
        } else if (
            newCount < this.count &&
            (newCount < this.previousDecrementCount || this.previousDecrementCount === 0)
        ) {
            this.setProgress(this.progress + (100 - this.progress) / 2);
            this.previousDecrementCount = newCount;
        }

        this.count = newCount;
    }

    increment(reference: object) {
        if (!this.map.has(reference)) {
            this.map.set(reference, true);
            this.calculateProgress(this.count + 1);
        }
    }

    decrement(reference: object) {
        if (this.map.has(reference)) {
            this.map.delete(reference);

            this.calculateProgress(this.count - 1);
        }
    }

    render() {
        return this.props.children || null;
    }
}

export default LoadingProgressProvider;
