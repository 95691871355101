import React, { CSSProperties } from 'react';

import Styled from 'styled';
import RouteLinkView from 'lib/ui/route-link-view';

import { FooterChild } from '../child';
import defaultStyles from './link.scss';
import { HideForVendor } from 'four-nets/vendor/permissions';

export interface FooterLinkChild extends FooterChild {
    label: string;
    href?: string;
    target?: string;
    rel?: string;
    download?: boolean;
}

export interface Props extends FooterLinkChild {
    styles: typeof defaultStyles;
}

class Link extends React.PureComponent<Props> {
    render() {
        const { styles, href, target, label, rel, download } = this.props;

        let component = (
            <RouteLinkView className={styles.link} href={href} target={target} rel={rel} style={{ whiteSpace: 'pre' }}>
                {label}
            </RouteLinkView>
        );

        if (download) {
            component = (
                <a className={styles.link} href={href} download>
                    {label}
                </a>
            );
        }

        if (/users_directory/.test(href || '')) {
            return <HideForVendor>{component}</HideForVendor>;
        }
        return component;
    }
}

export default Styled(defaultStyles)(Link);
