/* @flow */

import React from 'react';
import { observer } from 'mobx-react';
import Styled from 'styled';

import defaultStyles from './badge-view.scss';

const BadgeView = Styled(defaultStyles)(
    observer(function BadgeView(props: { value: number, size: number, styles: Object }) {
        const { value, styles } = props;

        return <span className={`${styles.badge} ${value ? styles.visible : ''}`}>{value}</span>;
    })
);

export default BadgeView;
